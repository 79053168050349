import * as m from "@mui/material"
import { useRecoilState, useSetRecoilState } from "recoil"
import { ReactComponent as BotHeaderIcon } from "../../../assets/chatboticons/bot-header.svg"
import { useWindowDimensions } from "../../../GlobalFileContainer"
import { openBotState, showSideBarState } from "../../../state/projectState"
import CustomChatbotApp from "../../chat bot/CustomChatbotApp"
import { mobileScreenSize } from "../../../state/StatusState"

const DiscoverWithChatBoxLayout = ({ children }) => {
  const { width } = useWindowDimensions()
  const theme = m.useTheme()

  const [open, setOpen] = useRecoilState(openBotState)
  const setOpenSidebar = useSetRecoilState(showSideBarState)

  const widthLessThanSize = width < mobileScreenSize
  const openAndSpecifiedWidth = open && !widthLessThanSize
  const isAgentPage = window.location.pathname.includes("/agent")

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOpenSidebar(true)
  }

  const content = (
    <>
      <m.Grid
        item
        sx={{ overflowX: "hidden" }}
        xs={openAndSpecifiedWidth ? 8 : 12}
      >
        {children}
      </m.Grid>
      <m.Grid item xs={openAndSpecifiedWidth ? 4 : 0}>
        {!open ? (
          <m.Button
            variant="contained"
            color="primary"
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
              zIndex: 1000,
              gap: 5,
              padding: "10px 30px",
              borderRadius: "30px",
              color: theme.palette.common.white,
            }}
            onClick={handleOpen}
          >
            Ask me <BotHeaderIcon fontSize={"16px"} />
            💬
          </m.Button>
        ) : widthLessThanSize ? (
          <m.Modal open={open} onClose={handleClose}>
            <m.Box
              className="ml-display-flex ml-flex-dir-col"
              sx={{
                position: "fixed",
                bottom: 20,
                right: 20,
                bgcolor: theme.palette.common.white,
                borderRadius: "10px",
                boxShadow: `0 2px 4px ${theme.palette.grey.main}`,
                height: "90vh",
                width: { xs: "80vw", sm: "50vw", md: "40vw" },
                transformOrigin: "bottom right",
                transform: "scale(1)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <CustomChatbotApp setOpen={setOpen} />
            </m.Box>
          </m.Modal>
        ) : (
          <m.Box
            className={`${open && "chat-bot-open"}`}
            sx={{
              position: "fixed",
              padding: "0 16px",
              top: isAgentPage ? "230px" : "75px",
            }}
          >
            {open && <CustomChatbotApp setOpen={setOpen} embedded />}
          </m.Box>
        )}
      </m.Grid>
    </>
  )

  return (
    <m.Grid
      container
      flexDirection={"row"}
      className="height-and-overflow"
      sx={{
        backgroundColor: theme.palette.grey.light,
      }}
    >
      {content}
    </m.Grid>
  )
}

export default DiscoverWithChatBoxLayout
