import { useRecoilState } from "recoil"
import { useNavigate, useParams } from "react-router-dom"
import { Box, Typography, useTheme } from "@mui/material"
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined"
import { mongoProfileState } from "../../state/userState"
import { EmulationLab } from "../../GlobalFileContainer"
import { getUserActiveMode } from "../../state/requests"

const ContributorModeHandler = ({ showSideBar }) => {
  const navigate = useNavigate()
  const { emulatorId } = useParams()
  const theme = useTheme()

  const [mongoProfile, setMongoProfile] = useRecoilState(mongoProfileState)

  const useModes = mongoProfile?.useModes
  const useModesIncludesContributr = useModes?.includes("contributor")

  const changeActiveMode = async () => {
    try {
      await EmulationLab.post(getUserActiveMode, {
        mode: "contributor",
      })
      const myData = await EmulationLab.get("/users/me")
      setMongoProfile(myData.data)
      navigate(`/em/${emulatorId}/summary`)
    } catch (error) {
      console.error(error)
    }
  }

  const HandleUserModes = () => {
    if (useModesIncludesContributr) {
      changeActiveMode()
    }
  }

  return (
    <Box
      className="ml-display-flex ml-align-center ml-justify-center"
      sx={{
        width: "100%",
        padding: "8px 24px",
        gap: "8px",
        borderRadius: showSideBar ? "6px" : "0px",
        border: showSideBar
          ? null
          : `1px solid ${theme.palette.grey.primaryLight}`,
        backgroundColor: "rgba(244,244,245,1)",
        cursor: useModesIncludesContributr ? "pointer" : "default",
      }}
      onClick={useModesIncludesContributr ? HandleUserModes : undefined}
    >
      <NoteAltOutlinedIcon fontSize="small" />
      <Typography fontWeight={500} style={{ fontSize: "14px" }}>
        {useModesIncludesContributr
          ? "Go To Contributor Mode"
          : "Become A Member To Contribute"}
      </Typography>
    </Box>
  )
}

export default ContributorModeHandler
