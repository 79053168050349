import calculatorImages from "../assets/calculatorSVGs"

export const commonFontFamily = "Inter, Helvetica"
// "sans-serif,Lucida Sans,Lucida Sans Regular,Lucida Grande,Lucida Sans Unicode,Geneva,Verdana"

export const ERROR_MESSAGE = {
  requiredField: "Required field",
  outOfRange: "Out of range value",
}

export const RUN_TYPES = ["calculate", "lookup", "emulate"]

export const optimiseModesStrings = {
  genetic: "genetic algorithm",
  differential: "differential evolution",
  nsg: "NSGA2",
}

export const OPTIMIZE_MODES = [
  optimiseModesStrings.genetic,
  optimiseModesStrings.differential,
  optimiseModesStrings.nsg,
]

export const colRegex = /[ \-.\s]/g

export const OUTPUT_OBJECTIVES = ["minimize", "maximize", "equal"]

export const EMPTY_STATES = ["none", undefined, ""]

export const PREPARING_ARRAY = ["idea", "preparing"]

export const TRAINING_ARRAY = ["idea", "preparing", "prepared"]

export const showCalcIcon = ["prepared", "deployed", "training"]

export const uniqueListofStatus = [
  "busy",
  "idle",
  "off",
  "None",
  "others",
  "broken",
  "ERROR: status missing",
]

export const allStatus = [
  "Idea Initiated",
  "Function Defined",
  "Calculator Testing",
  "Calculator Released",
  "Data Upload",
  "Data Reference",
  "Data Synthesis",
  "Data Available",
  "Lookup Released",
  "Training in Progress",
  "Emulator Testing",
  "Emulator Released",
]

export const allSoftwareWithDependency = [
  "excel: none",
  "excel: excel_wpm",
  "excel: null",
  "excel: deprecated",
  "grasshopper: none",
  "grasshopper: gh_eng",
  "grasshopper: null",
  "grasshopper: deprecated",
  "python: none",
  "python: python_data",
  "python: null",
  "python: deprecated",
  "mathcad: null",
  "mathcad: none",
  "optimization: none",
]

export const trainingDeployed = ["deployed", "training"]

export const trainingArchived = ["deployed", "training", "archived"]

export const breadCrumbsStrings = {
  none: "None",
  role: "em",
  beta: "(Beta)",
  released: "(Released)",
  archived: "(Archived)",
}

export const ALGO_STATUS = {
  ready: "ready",
  pending: "pending",
  running: "running",
  done: "done",
  failed: "failed",
  queued: "queued",
  complete: "complete",
  received: "received",
  canceled: "canceled",
  timedOut: "timed-out",
  incomplete: "incomplete",
}

export const passFailedArray = [ALGO_STATUS.complete, ALGO_STATUS.failed]

export const TEST_STATUS = {
  pass: "pass",
  fail: "fail",
  running: "running",
  draft: "draft",
  calculation_fail: "calculation fail",
  canceled: "canceled",
}

export const TEST_LEGEND_DESC = {
  pass: "Test Result- Pass",
  fail: "Test Result- Fail",
  running: "Calculation under process",
  draft: "Test Created",
  calculation_fail: "Calculation Failed",
  canceled: "Test canceled by user",
}

export const errorStrings = ["error", "warning"]

export const inputOutputLabels = {
  input: "valid_input_labels",
  output: "valid_output_labels",
}

export const failedValidationNote = "Failed to validate"

export const confirmDelete = "Confirm Delete"

export const excel = ["xlsx", "xlsm", "xls"]

export const grasshopper = ["gh", "ghx"]

export const mathcad = ["mcdx"]

export const python = ["py"]

export const jobTypes = ["all", "active", "inactive"]

export const deletePropsMap = {
  host: ["Alias", "ParamName", "Cell", "Worksheet", "PythonVariableName"],
  python: ["Alias", "ParamName", "Cell", "Worksheet", "ApiLabel"],
  mathcad: ["ApiLabel", "Cell", "Worksheet", "ParamName", "PythonVariableName"],
  grasshopper: ["Alias", "ApiLabel", "Cell", "Worksheet", "PythonVariableName"],
  default: ["Alias", "ParamName", "ApiLabel", "PythonVariableName"],
}

export const handeEmptyTextfield = {
  missingName: "You must Enter Name.",
  missingNickName: "You must Enter Nickname.",
  missingLabel: "You must Enter Column Label.",
  missingDesc: "You must Enter Description.",
  missingUnit: "You must select a Unit.",
  missingPythonVar: "You must Enter a Python Variable Name.",
}

export const handleEmptyTest = {
  missingRule: "You must Enter Rule.",
  missingOutput: "You must Enter Expected Output.",
  missingTolerance: "You Must enter Tolarance.",
  missingMaxValue: "You must enter Max Value.",
}

export const iconMappings = {
  [RUN_TYPES[0]]: {
    selected: calculatorImages["calcActive"],
    inactive: calculatorImages["calcInactive"],
    unavailable: calculatorImages["calcUnavail"],
  },
  [RUN_TYPES[1]]: {
    selected: calculatorImages["lookupActive"],
    inactive: calculatorImages["lookupInactive"],
    unavailable: calculatorImages["lookupUnavail"],
  },
  [RUN_TYPES[2]]: {
    selected: calculatorImages["emuActive"],
    inactive: calculatorImages["emuInactive"],
    unavailable: calculatorImages["emuUnavail"],
  },
}

export const emulatorMode = {
  emulate: "emulate",
  calculate: "calculate",
  lookup: "lookup",
  optimize: "optimize",
}

export const FULL_VIEW_OPEN_TEXT = "Open"
export const FULL_VIEW_EXIT_TEXT = "Exit"

export const allEmulatorStatus = {
  idea: "idea",
  initialized: "initialized",
  preparing: "preparing",
  prepared: "prepared",
  training: "training",
  deployed: "deployed",
  predict: "predict",
  release: "release",
}

export const subStatus = [
  {
    title: allStatus[0],
    mode: RUN_TYPES,
  },
  {
    title: allStatus[1],
    mode: RUN_TYPES,
  },
  {
    title: allStatus[2],
    mode: [RUN_TYPES[0]],
  },
  {
    title: allStatus[3],
    mode: [RUN_TYPES[0]],
  },
  {
    title: allStatus[4],
    mode: [RUN_TYPES[1], RUN_TYPES[2]],
  },
  {
    title: allStatus[5],
    mode: [RUN_TYPES[1], RUN_TYPES[2]],
  },
  {
    title: allStatus[6],
    mode: [RUN_TYPES[2]],
  },
  {
    title: allStatus[7],
    mode: [RUN_TYPES[1], RUN_TYPES[2]],
  },
  {
    title: allStatus[8],
    mode: [RUN_TYPES[1]],
  },
  {
    title: allStatus[9],
    mode: [RUN_TYPES[2]],
  },
  {
    title: allStatus[10],
    mode: [RUN_TYPES[2]],
  },
  {
    title: allStatus[11],
    mode: [RUN_TYPES[2]],
  },
]

export const emulatorSoftwareType = {
  excel: "excel",
  hosted: "hosted_calculator",
  mathcad: "mathcad",
  upload: "upload",
  grasshopper: "grasshopper",
  python: "python",
}

export const testRule = {
  between: "between",
  greater: "greater_than",
  greaterOrEqual: "greater_than_or_equal",
  equal: "equal", //equal with tolerance
  lesser: "less_than",
  lesserOrEqual: "less_than_or_equal",
  equalTto: "equal_to", //equal to <value>
  contains: "output contains",
  notEqual: "not_equals",
  inSet: "in_set",
}

export const textCatArray = ["text", "categorical"]

export const fontWeight650 = "650"
export const mobileScreenSize = "1050"

export const uploadEmFilesOrTrain = "uploadEmFilesOrTrain"

export const urlPattern = new RegExp("^https?://.*", "i")

export const calc = ["excel", "mathcad", "grasshopper", "python"]
