import * as m from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { toggleDrawer } from "../result-optimization/OptimizationHistory"
import { roundValues, convertToUppercase } from "../../state/services"

export default function AdvanceSearchFilter({
  openFilter,
  setOpenFilter,
  appliedFilters,
  setAppliedFilters,
  anchorEl,
  cards,
  isAppliedFilter,
  resetFilter,
  renderSearchField,
  filterOptions,
  table,
  health,
  capabilityData,
  setKey,
}) {
  const theme = m.useTheme()
  const extractCommonValues = (category, round) => {
    return Array.from(
      new Set(
        cards?.map((item) =>
          round ? roundValues(item[category]) : item[category]
        )
      )
    )
  }
  const categoryMeta = extractCommonValues("category")
  const tagsMeta = Array.from(new Set(cards?.flatMap((item) => item.tags)))
  const allStatus = extractCommonValues("status")
  const allModes = Array.from(new Set(cards?.flatMap((item) => item.modes)))
  const allCapabilityStatus = ["busy", "idle", "off", "broken", "others"]
  const uniqueTeamNames = Array.from(
    new Set(
      cards?.flatMap((item) => {
        const teamNames = []

        if (item.team && item.team.director && item.team.director.name) {
          teamNames.push(item.team.director.name)
        }
        if (item.team && item.team.members) {
          item.team.members.forEach((member) => {
            if (member.name) {
              teamNames.push(member.name)
            }
          })
        }

        return teamNames
      })
    )
  )

  const generation = extractCommonValues("generation_count")
  const runs = extractCommonValues("iteration_count")
  const best = extractCommonValues("best", true)
  const user = extractCommonValues("requesting_user_email")
  const capability = extractCommonValues("capability")

  const handleChange = (newValue, newTextState) => {
    const updateValueMap = table
      ? {
          ["Status/progress"]: "status",
          ["#Generation"]: "generation",
          ["#Runs"]: "runs",
          Best: "best",
          User: "user",
        }
      : health
      ? {
          Status: "status",
          Capability: "capability",
        }
      : capabilityData
      ? {
          Status: "capabilityData",
        }
      : {
          Tags: "tagList",
          Category: "categoryList",
          Modes: "modeList",
          Status: "statusList",
          Members: "memberList",
        }

    const updateValue = updateValueMap[newTextState]

    if (updateValue) {
      if (capabilityData) {
        const scrollableContainer = document.querySelector(
          ".MuiDataGrid-virtualScroller"
        )
        setKey(Math.random())
        if (scrollableContainer && scrollableContainer.scrollLeft !== 0) {
          scrollableContainer.scrollLeft = 0
        }
      }

      setAppliedFilters((prevOptions) => ({
        ...prevOptions,
        [updateValue]: newValue,
      }))
    }
  }

  const showOptionForTags = (text) => {
    const metaMap = table
      ? {
          ["Status/progress"]: {
            meta: allStatus,
            state: appliedFilters.status,
            textData: text,
          },
          ["#Generation"]: {
            meta: generation,
            state: appliedFilters.generation,
            textData: text,
          },
          ["#Runs"]: {
            meta: runs,
            state: appliedFilters.runs,
            textData: text,
          },
          Best: {
            meta: best,
            state: appliedFilters.best,
            textData: text,
          },
          User: {
            meta: user,
            state: appliedFilters.user,
            textData: text,
          },
        }
      : health
      ? {
          Status: {
            meta: allStatus,
            state: appliedFilters.status,
            textData: text,
          },
          Capability: {
            meta: capability,
            state: appliedFilters.capability,
            textData: text,
          },
        }
      : capabilityData
      ? {
          Status: {
            meta: allCapabilityStatus,
            state: appliedFilters.capabilityData,
            textData: text,
          },
        }
      : {
          Tags: {
            meta: tagsMeta,
            state: appliedFilters.tagList,
            textData: text,
          },
          Category: {
            meta: categoryMeta,
            state: appliedFilters.categoryList,
            textData: text,
          },
          Modes: {
            meta: allModes,
            state: appliedFilters.modeList,
            textData: text,
          },
          Status: {
            meta: allStatus,
            state: appliedFilters.statusList,
            textData: text,
          },
          Members: {
            meta: uniqueTeamNames,
            state: appliedFilters.memberList,
            textData: text,
          },
        }

    const { meta, state, textData } = metaMap[text] || {}

    const allTagCat = meta || []
    const newState = state || []
    const setNewState = textData

    return (
      <m.ListItem disablePadding>
        <m.Accordion defaultExpanded={newState?.length > 0}>
          <m.AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <m.Typography variant="body1">{text}</m.Typography>
          </m.AccordionSummary>
          <m.AccordionDetails>
            <m.FormControl sx={{ width: 300 }}>
              <m.Autocomplete
                value={newState}
                onChange={(event, newValue) => {
                  handleChange(newValue, setNewState)
                }}
                multiple
                size="small"
                id="tags-filled"
                options={allTagCat.map((option) => option)}
                freeSolo
                getOptionLabel={(option) => option}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <m.Chip
                      key={index}
                      variant="outlined"
                      label={
                        isNaN(option) ? convertToUppercase(option) : option
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <m.TextField
                    {...params}
                    variant="outlined"
                    placeholder={
                      newState?.length > 0
                        ? ""
                        : `Type to search ${text?.toLowerCase()}.`
                    }
                  />
                )}
                renderOption={(props, option, state) => (
                  <li {...props}>
                    {isNaN(option) ? convertToUppercase(option) : option}
                  </li>
                )}
              />
            </m.FormControl>
          </m.AccordionDetails>
        </m.Accordion>
      </m.ListItem>
    )
  }

  const renderFilterOptions = () => (
    <m.Box sx={{ width: 330 }} role="presentation">
      <m.List sx={{ padding: "0px" }}>
        {filterOptions.map((text, index) => (
          <div key={`${text}_${index}`}>
            {showOptionForTags(text)}
            <m.Divider light />
          </div>
        ))}
      </m.List>
    </m.Box>
  )

  return (
    <m.Menu
      sx={{ boxShadow: "none" }}
      anchorEl={anchorEl}
      open={openFilter}
      onClose={toggleDrawer(false, setOpenFilter)}
      className="search-filter"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <m.Box sx={{ padding: "0 10px" }}>
        {renderSearchField && renderSearchField("flex", "none")}
      </m.Box>
      {renderFilterOptions()}
      <m.Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "5px",
        }}
      >
        {isAppliedFilter && (
          <m.Button
            variant="contained"
            sx={{ color: theme.palette.common.white }}
            onClick={resetFilter}
          >
            <m.Typography variant="caption">Reset Filter</m.Typography>
          </m.Button>
        )}
        <m.Button
          onClick={() => {
            setOpenFilter(false)
          }}
          variant="contained"
          sx={{ color: theme.palette.common.white }}
        >
          <m.Typography variant="caption">Close</m.Typography>
        </m.Button>
      </m.Box>
    </m.Menu>
  )
}
