import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import useStyles from "../card_style"
import "./MainTest.css"
import * as files from "../../GlobalFileContainer"
import {
  errorFieldsState,
  finalTestObject,
  getEmulatorData,
  handleKeyDown,
  populateResultData,
  saveBtnState,
} from "../../state/projectState"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import OutputsForm from "./OutputsForm"
import { handleEmptyTest, testRule } from "../../state/StatusState"

export const callCalculateToRun = async (postBody) => {
  const api_url = `/engine/calculate`

  const response = await files.EmulationLab.post(api_url, postBody, {
    headers: {
      severity: SeverityLevel.Error,
    },
    params: { client: "TryIt", version: "beta" },
  })

  return response?.data
}

export const postNewTest = async (postTest, finalTestObjectState) => {
  try {
    const res = await files.EmulationLab.post(postTest, finalTestObjectState, {
      headers: {
        severity: SeverityLevel.Warning,
      },
    })
    return res.data
  } catch (error) {
    alert("error in fetching data...")
  }
}

export const validateInput = (
  field,
  value,
  validationError,
  setValidationError
) => {
  if (!value || value.length === 0) {
    switch (field) {
      case "label":
        setValidationError({
          ...validationError,
          labelError: true,
        })
        return handleEmptyTest.missingRule
      case "mode":
        setValidationError({
          ...validationError,
          ruleError: true,
        })
        return handleEmptyTest.missingRule
      case "value":
        setValidationError({
          ...validationError,
          expOutError: true,
          maxOutErr: true,
        })
        return handleEmptyTest.missingOutput
      case "valueMin":
        setValidationError({
          ...validationError,
          expOutError: true,
          maxOutErr: true,
        })
        return handleEmptyTest.missingOutput
      case "tolerance":
        setValidationError({
          ...validationError,
          maxOutErr: true,
        })
        return handleEmptyTest.missingTolerance
      case "maxValue":
        setValidationError({
          ...validationError,
          maxOutErr: true,
        })
        return handleEmptyTest.missingMaxValue
      default:
        return ""
    }
  }
  return ""
}

export const handleValidation = (
  testDetails,
  validationError,
  setValidationError
) => {
  let errorMessage = ""
  errorMessage = validateInput(
    "label",
    testDetails.label,
    validationError,
    setValidationError
  )
  if (errorMessage) return errorMessage
  errorMessage = validateInput(
    "mode",
    testDetails.mode,
    validationError,
    setValidationError
  )
  if (errorMessage) return errorMessage

  errorMessage =
    testDetails.mode === testRule.between
      ? validateInput(
          "valueMin",
          testDetails.valueMin,
          validationError,
          setValidationError
        )
      : validateInput(
          "value",
          testDetails.value,
          validationError,
          setValidationError
        )
  if (errorMessage) return errorMessage

  errorMessage =
    testDetails.mode === testRule.equal &&
    validateInput(
      "tolerance",
      testDetails.tolerance,
      validationError,
      setValidationError
    )
  if (errorMessage) return errorMessage

  errorMessage =
    testDetails.mode === testRule.between &&
    validateInput(
      "maxValue",
      testDetails.valueMax,
      validationError,
      setValidationError
    )
  if (errorMessage) return errorMessage

  return ""
}

const AddTestForm = ({
  openNewTest,
  setOpenNewTest,
  updateTest,
  index,
  setUpdateTest,
  addForm,
  setAddForm,
  sendTest,
  setSendTest,
  postTest,
}) => {
  const classes = useStyles()
  let { emulatorId } = useParams()
  const theme = m.useTheme()

  const emConfig = useRecoilValue(getEmulatorData)
  const setErrorFields = useSetRecoilState(errorFieldsState)
  const finalTestObjectState = useRecoilValue(finalTestObject)
  const setSaveBtn = useSetRecoilState(saveBtnState)
  const setPopulateResultDataState = useSetRecoilState(populateResultData)

  const [postBody, setPostBody1] = useState({})
  const [selectedLabel, setSelectedLabel] = useState(false)
  const [checkingRangeWarning, setCheckingRangeWarning] = useState(
    Array(emConfig?.calculator?.InputVariables.length).fill(false)
  )
  const [validationErrors, setValidationErrors] = useState({
    ruleError: false,
    expOutError: false,
    maxOutErr: false,
  })
  const [testList, setTestList] = useState([])

  const calcConfig = emConfig.calculator
  const outputVars = calcConfig?.OutputVariables?.map((outp) => {
    const { Name, ColumnLabel, Type, Units } = outp
    return { Name, ColumnLabel, Type, Units }
  })

  const emptySubTestObject = {
    label: outputVars[0].ColumnLabel,
    mode: "",
    value: "",
    valueMin: "",
    valueMax: "",
    tolerance: "",
    status: "",
  }

  const [testDetails, setTestDetails] = useState(emptySubTestObject)

  useEffect(() => {
    setErrorFields(false)
    if (updateTest) {
      let selectedTestObject = finalTestObjectState?.tests[index]
      let selectedObjSubTests = selectedTestObject?.subTests
      let selectedObjInputs = selectedTestObject?.inputs

      setTestList(selectedObjSubTests)
      setPostBody1({
        FeaturesData: selectedObjInputs,
        emulator_id: finalTestObjectState?.emulator_id,
      })
      setPopulateResultDataState(true)
      setTestDetails(selectedObjSubTests[selectedObjSubTests.length - 1])
    }
  }, [updateTest])

  useEffect(() => {
    if (sendTest) {
      postNewTest(postTest, finalTestObjectState)
    }
  }, [finalTestObjectState])

  const handleTestDialogClose = () => {
    setSaveBtn("")
    setOpenNewTest(false)
    setUpdateTest(false)
    setValidationErrors({
      ruleError: false,
      expOutError: false,
      maxOutErr: false,
    })
    setTestDetails(emptySubTestObject)
    setTestList([])
    setPostBody1({})
    setSelectedLabel(false)
    setErrorFields(false)
    setCheckingRangeWarning(
      Array(emConfig?.calculator?.InputVariables.length).fill(false)
    )
  }
  const handleCheckboxChange = () => {
    setSelectedLabel(!selectedLabel)
  }
  return (
    <core.Dialog
      onKeyDown={(e) => handleKeyDown(e, handleTestDialogClose)}
      open={openNewTest}
      fullWidth={true}
      maxWidth="xl"
    >
      <core.Box sx={{ minWidth: { xs: "0" } }} className="gallery-dialog">
        <files.AddDialogTitle
          callFunction={handleTestDialogClose}
          title={`${updateTest ? "Edit" : "Add New"} Test`}
        />
        <core.DialogContent style={{ padding: "0 0 10px" }}>
          <core.Box>
            <core.Grid container justifyContent="space-between">
              <core.Grid
                className="inputs-grid ml-display-flex ml-flex-dir-row"
                item
                xs={12}
              >
                <core.Grid
                  style={{
                    background: theme.palette.primary.light,
                    paddingLeft: theme.spacing(3),
                    paddingTop: theme.spacing(2),
                  }}
                  item
                  xl={4}
                  md={3}
                  xs={12}
                >
                  <core.Typography
                    variant="h5"
                    style={{ marginBottom: "0px" }}
                    className={classes.calculatorTypo}
                  >
                    Inputs
                  </core.Typography>
                  <core.Box className="test-dialog">
                    <files.CalculatorInput
                      calcId={emulatorId}
                      calcConfig={calcConfig}
                      setPostBody2={setPostBody1}
                      updateTest={updateTest}
                      postBody={postBody}
                      setCheckingRangeWarning={setCheckingRangeWarning}
                      checkingRangeWarning={checkingRangeWarning}
                      page={"test"}
                    />
                  </core.Box>
                </core.Grid>
                <OutputsForm
                  postBody={postBody}
                  checkingRangeWarning={checkingRangeWarning}
                  setErrorFields={setErrorFields}
                  setTestDetails={setTestDetails}
                  testDetails={testDetails}
                  testList={testList}
                  setTestList={setTestList}
                  updateTest={updateTest}
                  setSendTest={setSendTest}
                  handleTestDialogClose={handleTestDialogClose}
                  addForm={addForm}
                  emptySubTestObject={emptySubTestObject}
                  setAddForm={setAddForm}
                  selectedLabel={selectedLabel}
                  index={index}
                  setSelectedLabel={setSelectedLabel}
                  isConstraints={false}
                  validationError={validationErrors}
                  setValidationErrors={setValidationErrors}
                />
              </core.Grid>
              <core.Grid
                item
                xs={12}
                className="ml-display-flex ml-flex-dir-row ml-justify-end margin-bottom-5"
              >
                <m.FormControlLabel
                  control={
                    <m.Checkbox
                      value={selectedLabel}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={
                    <m.Typography variant="body1">Run On Save</m.Typography>
                  }
                />
                <core.Button
                  variant="contained"
                  color="primary"
                  className="actions-delete gd-width"
                  style={{ marginRight: theme.spacing(3) }}
                  onClick={() => setSaveBtn(true)}
                >
                  <m.Typography variant="caption">Save</m.Typography>
                </core.Button>
              </core.Grid>
            </core.Grid>
          </core.Box>
        </core.DialogContent>
      </core.Box>
    </core.Dialog>
  )
}

export default AddTestForm
