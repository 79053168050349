import { useState } from "react"
import * as m from "@mui/material"
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined"

const AlternateEmulatorBox = ({
  message,
  returnCardObject,
  renderCardsForLaunch,
  children,
  mode,
  note,
}) => {
  const theme = m.useTheme()

  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev)
  }

  const emulatorNotes = note
    ? note
    : children
    ? ` advance ${mode === "lookup" ? "lookup" : "optimization"} options`
    : " alternative emulators"

  return (
    <m.Box className={!children && "width-75"}>
      {!isExpanded && (
        <m.Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            color: theme.palette.grey.main,
          }}
          onClick={toggleExpand}
        >
          <m.Typography
            variant="body2"
            sx={{
              fontStyle: "italic",
            }}
          >
            Show
            {emulatorNotes}
          </m.Typography>

          <m.Divider sx={{ flexGrow: 1, margin: "0 8px" }} />

          <m.IconButton
            size="small"
            sx={{
              padding: "4px",
              transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s",
            }}
          >
            <ExpandCircleDownOutlinedIcon sx={{ fontSize: 18 }} />
          </m.IconButton>
        </m.Box>
      )}
      {isExpanded && (
        <>
          <m.Box
            sx={{
              padding: "4px 12px",
              boxSizing: "border-box",
              border: `1px solid ${
                note
                  ? theme.palette.grey.primaryLight
                  : theme.palette.common.black
              }`,
              borderRadius: "6px",
            }}
          >
            {children ??
              message?.options?.emulator_ids?.map((alternateId, i) => {
                if (alternateId !== "") {
                  const alternateEmulatorObject = returnCardObject(alternateId)
                  return (
                    <m.Box key={i} sx={{ marginBottom: "8px" }}>
                      {renderCardsForLaunch(
                        alternateEmulatorObject,
                        message,
                        message?.alternative_launch_id,
                        !message?.alternative_launch_id,
                        alternateId,
                        true
                      )}
                    </m.Box>
                  )
                }
                return null
              })}

            <m.Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                cursor: "pointer",
                color: theme.palette.grey.main,
              }}
              onClick={toggleExpand}
            >
              <m.Typography
                variant="body2"
                sx={{
                  fontStyle: "italic",
                }}
              >
                Hide {emulatorNotes}
              </m.Typography>
              <m.IconButton
                size="small"
                sx={{
                  padding: "4px",
                  transform: "rotate(180deg)",
                  transition: "transform 0.3s",
                }}
              >
                <ExpandCircleDownOutlinedIcon sx={{ fontSize: 18 }} />
              </m.IconButton>
            </m.Box>
          </m.Box>
        </>
      )}
    </m.Box>
  )
}

export default AlternateEmulatorBox
