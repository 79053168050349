import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ResponsiveHeatMap } from "@nivo/heatmap"
import { useRecoilState, useRecoilValue } from "recoil"
import { Typography } from "@mui/material"
import { EmulationLab, Loader } from "../GlobalFileContainer"
import useErrorPage from "../hooks/useErrorPage"
import {
  projectCorrelationState,
  featureSelectionReload,
} from "../state/projectState"
import { cleanReturnJson } from "../state/axios"
import useStyles from "../components/card_style"

const CorrelationMatrix = () => {
  const colorScale = [
    "#039c62",
    "#40bf80",
    "#05e18c",
    "#50fbba",
    "#9bfdd7",
    "#cdfeeb",
    "#9bfdd7",
    "#50fbba",
    "#05e18c",
    "#40bf80",
    "#039c62",
  ]

  const gradient = `linear-gradient(to bottom, ${colorScale.join(", ")})`
  const { emulatorId } = useParams()
  const classes = useStyles()

  const correlationUrl = `/emulators/${emulatorId}/data/charts/correlation`
  const errorPage = useErrorPage("feature correlation matrix")

  const activeProject = useRecoilValue(projectCorrelationState(emulatorId))
  const [reloadPage, setReloadPage] = useRecoilState(featureSelectionReload)

  const [corr, setCorr] = useState(activeProject)
  const [mainLoader, setMainLoader] = useState(reloadPage)

  useEffect(() => {
    if (reloadPage) {
      getLatestProject()
    }
  }, [reloadPage])

  const getLatestProject = async () => {
    try {
      const corr = await EmulationLab.get(correlationUrl)
      setCorr(cleanReturnJson(corr.data))
      setMainLoader(false)
      setReloadPage(false)
    } catch (err) {
      setMainLoader(false)
      setReloadPage(false)
      return []
    }
  }

  if (!corr || !Object.keys(corr).length > 0) {
    return errorPage
  }

  const markers = Array.from({ length: 11 }, (_, index) => (index - 5) / 5)

  return (
    <>
      {mainLoader && <Loader />}
      {!mainLoader && (
        <>
          <div className="ml-display-flex ml-flex-dir-row margin-auto heatmap-wrapper">
            <ResponsiveHeatMap
              data={corr.data}
              keys={corr.columns}
              indexBy="row"
              minValue={-1}
              maxValue={1}
              colors={colorScale}
              margin={{ top: 120, right: 0, bottom: 30, left: 0 }}
              forceSquare={true}
              sizeVariation={false}
              axisTop={{
                orient: "top",
                tickSize: 5,
                tickPadding: 10,
                tickRotation: -30,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 0,
                legend: "",
                legendPosition: "middle",
                legendOffset: -40,
              }}
              cellOpacity={1}
              cellBorderColor={{ from: "color", modifiers: [["darker", 0.4]] }}
              labelTextColor={{ from: "color", modifiers: [["darker", 3.0]] }}
              label={(datum, key) =>
                `${Number(datum[key]).toLocaleString("en-EN", {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 2,
                })}`
              }
              fill={[{ id: "lines" }]}
              animate={true}
              motionConfig="gentle"
              motionStiffness={80}
              motionDamping={9}
              cellHoverOthersOpacity={0.25}
              valueFormat=">-.2s"
              cellComponent="circle"
              enableGridX={true}
              enableGridY={true}
            />
            <div
              className="ml-display-flex ml-flex-dir-col margin-left-20 ml-align-center dropdown gradientfeatures"
              style={{
                background: gradient,
              }}
            >
              {markers.map((marker, i) => (
                <div
                  className="gradientnumber"
                  key={`${marker}_${i}`}
                  style={{
                    top: `${(marker + 1) * 50}%`,
                  }}
                >
                  <Typography
                    className={classes.colorBlack}
                    variant="body2"
                    fontWeight={"600"}
                  >
                    {marker.toFixed(1)}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default CorrelationMatrix
