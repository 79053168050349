import { Suspense, useEffect, useState } from "react"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { MsalProvider } from "@azure/msal-react"
import { ThemeProvider } from "@material-ui/core/styles"
import { ThemeProvider as ThemeProvider2 } from "@mui/material/styles"
import { CloudinaryContext } from "cloudinary-react"
import { useRecoilValue } from "recoil"
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { alertStates, darkModeState } from "./state/vizState"
import "./App.css"
import { MainAppTheme } from "./MuiTheme"
import { appInsightsReactPlugin } from "./errorManagement/AppInsights"
import * as files from "./GlobalFileContainer"
import { globalDialogBox, jsonDialogOpenClose } from "./state/projectState"
import MainAppLayout from "./components/shared components/Layouts/MainAppLayout"
import DiscoverWithChatBoxLayout from "./components/shared components/Layouts/DiscoverWithChatBoxLayout"

const App = ({ msalInstance }) => {
  const alertState = useRecoilValue(alertStates)
  const darkMode = useRecoilValue(darkModeState)
  const jsonDialogOpen = useRecoilValue(jsonDialogOpenClose)
  const globalDialogOpen = useRecoilValue(globalDialogBox)

  const [openFilter, setOpenFilter] = useState(false)

  useEffect(() => {
    MainAppTheme.palette.mode = darkMode
  }, [darkMode])

  return (
    <HelmetProvider>
      <MsalProvider instance={msalInstance}>
        <AppInsightsContext.Provider value={appInsightsReactPlugin}>
          <ThemeProvider theme={MainAppTheme}>
            <ThemeProvider2 theme={MainAppTheme}>
              <Suspense fallback={<files.Loader />}>
                <files.AuthEntryPoint>
                  <Router>
                    <CloudinaryContext cloudName="dgv68yiec">
                      <MainAppLayout>
                        <DiscoverWithChatBoxLayout>
                          <Routes>
                            <Route
                              path="/*"
                              element={
                                <files.ErrorBoundary>
                                  <files.ExploreNav
                                    navGroup="explore"
                                    setOpenFilter={setOpenFilter}
                                    openFilter={openFilter}
                                  />
                                </files.ErrorBoundary>
                              }
                            />
                            <Route
                              path="/my/*"
                              exact
                              element={
                                <files.ErrorBoundary>
                                  <files.ExploreNav
                                    navGroup={"my"}
                                    setOpenFilter={setOpenFilter}
                                    openFilter={openFilter}
                                  />
                                </files.ErrorBoundary>
                              }
                            />
                            <Route
                              path="/em/:emulatorId/*"
                              element={
                                <files.ErrorBoundary>
                                  <files.EmulatorEndpoint />
                                </files.ErrorBoundary>
                              }
                            />
                            <Route
                              path="/new/:emulatorId/*"
                              element={
                                <files.ErrorBoundary>
                                  <files.ConsumerFlowEndPoint />
                                </files.ErrorBoundary>
                              }
                            />
                            <Route
                              path="/agent/*"
                              exact
                              element={
                                <files.ErrorBoundary>
                                  <files.AdminDashboardMain />
                                </files.ErrorBoundary>
                              }
                            />
                          </Routes>
                        </DiscoverWithChatBoxLayout>
                      </MainAppLayout>
                    </CloudinaryContext>
                  </Router>
                </files.AuthEntryPoint>
              </Suspense>
              {alertState.boolState ? (
                <files.SnackBar
                  open={alertState.boolState}
                  message={alertState.message}
                />
              ) : (
                ""
              )}
              {jsonDialogOpen?.state && <files.JsonObjViewer />}
              {globalDialogOpen.isOpen && <files.DialogBox />}
            </ThemeProvider2>
          </ThemeProvider>
        </AppInsightsContext.Provider>
      </MsalProvider>
    </HelmetProvider>
  )
}

export default App
