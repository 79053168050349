import { useEffect, useState } from "react"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import { useRecoilState, useRecoilValue } from "recoil"
import { TitleVariable } from "./VariableDefiner"
import { useParams } from "react-router"
import useStyles from "../../card_style"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import * as storedSatus from "../../../state/StatusState"
import { renderFormLabel } from "../../shared components/CommonStyles/CommonStyles"
import { sourceSoftware, sourceType } from "../../../state/services"

export default function InputDefiner({
  listIndex,
  visible,
  unique1,
  setInputDialogVisible,
  btnStyle,
  updateVariable,
  addVar,
  domainData,
  dataColumnLabels,
}) {
  let { emulatorId } = useParams()
  const classes = useStyles()

  const updateToggle = useRecoilValue(storedSates.updateVar)
  const updateData = useRecoilValue(storedSates.addData)
  const [emulatorStatusState, setEmulatorStatusState] = useRecoilState(
    storedSates.emulatorStatus
  )
  const [emData, setEmData] = useRecoilState(storedSates.getEmulatorData)
  const allNames = useRecoilValue(storedSates.manageConfigsNames)
  const [isSameValues, setIsSameValues] = useRecoilState(
    storedSates.manageConfigsSameValues
  )

  const InputVar = emData?.calculator?.InputVariables
  const worksheet = InputVar && InputVar[InputVar?.length - 1]?.Worksheet

  let defaultVar = {
    Name: "",
    NickName: "",
    ColumnLabel: "",
    Worksheet: worksheet || "",
    Cell: "",
    Type: "number",
    Units: "",
    Description: "",
    ApiLabel: "",
    Domain: {
      Type: "",
      Start: "",
      End: "",
      Options: [],
    },
    Alias: "",
    ParamName: "",
    PythonVariableName: "",
    DefaultValue: null,
  }
  const [formValues, setFormValues] = useState(defaultVar)
  const [subdivide, setSubdivide] = useState(false)
  const [hasDefaultValue, setHasDefaultValue] = useState(false)
  const [increment, setIncrement] = useState("")
  const [nicknameError, setNicknameError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [startError, setStartError] = useState(false)
  const [optionError, setOptionError] = useState(false)
  const [EndError, setEndError] = useState(false)
  const [incrementError, setIncreamentError] = useState(false)
  const [defaultValError, setDefaultValueError] = useState({
    error: false,
    message: "",
  })
  const [unitError, setUnitError] = useState(false)
  const [columnLabelError, setColumnLabelError] = useState(false)
  const [descriptionError, setSDescriptionError] = useState(false)
  const [pythonVarError, setPythonVarError] = useState(false)
  const [columnLabelValidation, setColumnLabelValidation] = useState(false)

  const getMinMaxValue = (property) => {
    if (updateData) {
      return domainData?.[Object.keys(domainData)[0]]?.[property] ?? false
    }

    const summaryData = emData?.data?.variables?.summary
    const columnData = summaryData?.[formValues?.ColumnLabel]

    if (updateToggle && summaryData) {
      return columnData?.[property] ?? false
    }

    if (!updateData && !updateToggle) {
      return columnData?.[property] ?? false
    }

    return false
  }

  const minValue = getMinMaxValue("Min")
  const maxValue = getMinMaxValue("Max")
  const disableDefaultCheck =
    (formValues.Domain.Type === "continuous" &&
      (!formValues.Domain.Start || !formValues.Domain.End)) ||
    (formValues.Domain.Type === "discrete" && !formValues.Domain.Options.length)

  let myRef = {}

  useEffect(() => {
    setIsSameValues({
      isSameName: false,
      isSameNameError: false,
      isSameNickName: false,
      isSameNickNameError: false,
      isSamecolumnLabel: false,
      isSameColumnLabelError: false,
    })
  }, [])

  useEffect(() => {
    if (updateToggle) {
      const inputVariables = JSON.parse(
        JSON.stringify(emData.calculator.InputVariables.at(listIndex))
      )
      const Nickname = inputVariables.NickName
      if (
        inputVariables.DefaultValue !== undefined &&
        inputVariables.DefaultValue !== null
      ) {
        setHasDefaultValue(true)
      }
      if (!inputVariables.ApiLabel) {
        const updatedForm = {
          ...inputVariables,
          ApiLabel: `in_${Nickname}`,
        }
        setFormValues(updatedForm)
      } else {
        setFormValues(inputVariables)
      }
    }
  }, [])

  useEffect(() => {
    if (updateData) {
      const nickname = Object.keys(domainData)?.toString()
      const varType = domainData[nickname]?.sensed_type
      const domainType = domainData[nickname]?.domain_or_range_type
      const filterArray = ["sensed_type", "domain_or_range_type"]
      setFormValues({
        ...formValues,
        Name: nickname,
        NickName: nickname,
        ColumnLabel: nickname,
        ApiLabel: nickname,
        Type:
          (varType === "categorical" && "text") ||
          (varType === "numerical" && "number") ||
          "quantity",
        Domain: {
          ...formValues.Domain,
          Type: domainType,
          Options:
            domainType === "discrete"
              ? Object.keys(domainData[nickname])?.filter(
                  (key) => !filterArray.includes(key)
                )
              : null,
          Start:
            domainType === "continuous"
              ? domainData[nickname]?.Min.toString()
              : null,
          End:
            domainType === "continuous"
              ? domainData[nickname]?.Max.toString()
              : null,
        },
      })
    }
  }, [updateData])

  useEffect(() => {
    if (updateToggle) {
      if (!formValues.Domain) {
        formValues["Domain"] = {}
        formValues.Domain["Type"] = "continuous"
      }
      if (
        formValues.Domain &&
        formValues.Domain.Type === "discrete" &&
        (!formValues.Domain.Start || !formValues.Domain.End)
      ) {
        delete formValues.Domain.Start
        delete formValues.Domain.End
      }
      if (
        formValues.Domain &&
        formValues.Domain.Type === "continuous" &&
        !formValues.Domain.Options
      ) {
        formValues.Domain["Options"] = ""
      }
    }
  }, [formValues.Domain])

  useEffect(() => {
    if (!updateToggle && !updateData) {
      if (formValues.Type === "text") {
        setFormValues({
          ...formValues,
          Domain: {
            ...formValues.Domain,
            Type: "discrete",
          },
        })
      } else {
        setFormValues({
          ...formValues,
          Domain: {
            ...formValues.Domain,
            Type: "continuous",
          },
        })
      }
    }
    if (updateToggle) {
      if (formValues.Type === "text") {
        setFormValues({
          ...formValues,
          Domain: {
            ...formValues.Domain,
            Type: "discrete",
          },
        })
      }
    }
  }, [formValues.Type])

  const handleInputChange = (e) => {
    let { name, value } = e.currentTarget
    setHasDefaultValue(false)
    setDefaultValueError({ error: false, message: "" })
    setFormValues((current) => {
      if (name?.includes(".")) {
        const [mainAddress, subAddress] = name.split(".")
        const part = current[mainAddress]
        part[subAddress] = value
        name = mainAddress
        value = part
      }
      if (name === "NickName")
        return { ...formValues, [name]: value, DefaultValue: null }
      else return { ...formValues, [name]: value, DefaultValue: null }
    })
  }

  const handleIncreament = (e) => {
    const regex = /^[0-9 ^ .|e|E]*$/
    const reg = new RegExp("[a-d][f-z]")
    if (
      e.target.value === "" ||
      (regex.test(e.target.value) && reg.test(e.target.value) === false)
    ) {
      setIncrement(e.target.value)
    }
  }

  const handleMultilineInput = (e) => {
    const regex = /^[0-9 ^ ,|.|e|E \r\n]*$/
    const reg = new RegExp("[a-d][f-z]")
    if (formValues.Type === "number" || formValues.Type === "quantity") {
      if (
        e.target.value === "" ||
        (regex.test(e.target.value) && reg.test(e.target.value) === false)
      ) {
        setFormValues({
          ...formValues,
          Domain: {
            ...formValues.Domain,
            Options: [e.target.value],
          },
        })
      }
    } else if (formValues.Type === "text") {
      setFormValues({
        ...formValues,
        Domain: {
          ...formValues.Domain,
          Options: [e.target.value],
        },
      })
    }
  }

  const handleSubdivide = () => {
    setSubdivide(!subdivide)
  }

  const downgradeStatusandAddVar = () => {
    storedSates
      .updateTheStatus(emulatorId, storedSatus.allEmulatorStatus.preparing)
      .then(() => {
        setEmulatorStatusState(storedSatus.allEmulatorStatus.preparing)
        handleSubmit()
      })
      .catch((err) => console.error(err))
  }

  const validateInput = (field, value) => {
    if (!value || value.length === 0) {
      switch (field) {
        case "Name":
          setNameError(true)
          return storedSatus.handeEmptyTextfield.missingName
        case "NickName":
          setNicknameError(true)
          return storedSatus.handeEmptyTextfield.missingNickName
        case "ColumnLabel":
          setColumnLabelError(true)
          return storedSatus.handeEmptyTextfield.missingLabel
        case "Description":
          formValues.Description = ""
          setSDescriptionError(true)
          return storedSatus.handeEmptyTextfield.missingDesc
        case "Units":
          setUnitError(true)
          return storedSatus.handeEmptyTextfield.missingUnit
        case "PythonVariableName":
          setPythonVarError(true)
          return storedSatus.handeEmptyTextfield.missingPythonVar
        default:
          return ""
      }
    }
    return ""
  }

  const handleValidation = () => {
    let errorMessage = ""

    errorMessage = validateInput("Name", formValues.Name)
    if (errorMessage) return errorMessage

    errorMessage = validateInput("NickName", formValues.NickName)
    if (errorMessage) return errorMessage

    errorMessage = validateInput("ColumnLabel", formValues.ColumnLabel)
    if (errorMessage) return errorMessage
    errorMessage = validateInput(
      "PythonVariableName",
      formValues.PythonVariableName
    )
    if (
      errorMessage &&
      emData?.source?.software === storedSatus.emulatorSoftwareType.python &&
      emData?.source?.type !== storedSatus.emulatorSoftwareType.hosted
    )
      return errorMessage
    errorMessage = validateInput("Description", formValues.Description)
    if (errorMessage) return errorMessage

    if (
      formValues.Type === "quantity" &&
      (!formValues.Units || formValues.Units === "")
    ) {
      setUnitError(true)
      return storedSatus.handeEmptyTextfield.missingUnit
    }
    if (
      formValues.Domain.Type === "continuous" &&
      formValues.Domain?.Start?.length === 0
    ) {
      setStartError(true)
      return "You must Enter Start Value."
    }
    if (
      formValues.Domain.Type === "discrete" &&
      formValues.Domain.Options?.length === 0
    ) {
      setOptionError(true)
      return "You must Enter Options."
    }
    if (
      formValues.Domain.Type === "continuous" &&
      formValues.Domain?.End?.length === 0
    ) {
      setEndError(true)
      return "You must Enter End Value."
    }
    if (
      formValues.Domain.Type === "continuous" &&
      subdivide &&
      increment === ""
    ) {
      setIncreamentError(true)
      return "You must Enter Increament Value."
    }
    if (hasDefaultValue && !formValues.DefaultValue) {
      setDefaultValueError({
        error: true,
        message: "You must enter default value.",
      })

      return "You must enter default value."
    }
    if (defaultValError.error) {
      return defaultValError.message
    }
    return ""
  }

  const containsName = (array, nameToFind, label) => {
    if (updateToggle) {
      const newArray = array.filter(
        (item) => item !== emData.calculator.InputVariables[listIndex][label]
      )
      return newArray.some(
        (item) => item.toLowerCase().trim() === nameToFind.toLowerCase().trim()
      )
    }
    return array.some(
      (item) => item.toLowerCase().trim() === nameToFind.toLowerCase().trim()
    )
  }

  const commonNoteSameName = (names, name, sameName, note, error, label) => {
    if (containsName(names, name, label)) {
      if (!updateToggle) {
        setIsSameValues((prevState) => ({
          ...prevState,
          [sameName]: true,
        }))
        return note
      } else if (updateToggle && error) {
        setIsSameValues((prevState) => ({
          ...prevState,
          [sameName]: true,
        }))
        return note
      }
    } else {
      setIsSameValues((prevState) => ({
        ...prevState,
        [sameName]: false,
      }))
    }
  }

  const handleSameError = () => {
    let errorMessage = ""
    errorMessage = commonNoteSameName(
      allNames.names,
      formValues.Name,
      "isSameName",
      storedSatus.handeEmptyTextfield.missingName,
      isSameValues.isSameNameError,
      "Name"
    )
    if (errorMessage) return errorMessage
    errorMessage = commonNoteSameName(
      allNames.nickNames,
      formValues.NickName,
      "isSameNickName",
      storedSatus.handeEmptyTextfield.missingNickName,
      isSameValues.isSameNickNameError,
      "NickName"
    )
    if (errorMessage) return errorMessage
    errorMessage = commonNoteSameName(
      allNames.columnLabels,
      formValues.ColumnLabel,
      "isSamecolumnLabel",
      storedSatus.handeEmptyTextfield.missingLabel,
      isSameValues.isSameColumnLabelError,
      "ColumnLabel"
    )
    if (errorMessage) return errorMessage
  }

  const handleSubmit = () => {
    const validationError = handleValidation()
    if (validationError) return validationError

    const sameNameValidations = handleSameError()
    if (sameNameValidations) return sameNameValidations

    if (columnLabelValidation) return

    const tempInputObj = formValues
    if (
      (formValues.Type === "text" || formValues.Type === "number") &&
      formValues?.Units?.length >= 0
    ) {
      delete tempInputObj.Units
    }
    if (tempInputObj.Domain.Type === "continuous") {
      delete formValues.Domain.Options
      if (subdivide) {
        let subObj = {}
        subObj.Type = "discrete"

        const start = parseFloat(formValues.Domain.Start)
        const end = parseFloat(formValues.Domain.End)
        const Increment = parseFloat(increment)

        if (Increment) {
          let tempArray = []
          tempArray.push(start)
          for (let i = 0; i < 100000; i++) {
            const incVal = tempArray[i] + Increment
            if (incVal <= end) {
              tempArray.push(incVal)
            } else break
          }
          subObj.Options = tempArray?.map((item) =>
            `${increment?.includes(".") ? item?.toFixed(2) : item}`?.trim()
          )
        }
        tempInputObj.Domain = subObj
      }
    }
    if (
      !subdivide &&
      tempInputObj.Domain.Type === "discrete" &&
      tempInputObj.Domain.Options?.length > 0
    ) {
      let subObj = {}
      subObj.Type = "discrete"
      subObj.Options = formValues.Domain.Options.join()
        .split(",")
        .map((item) => `${item.trim()}`)
      if (
        updateToggle &&
        tempInputObj.Domain.Options?.includes(/\p{L}/u) &&
        tempInputObj.Type !== "text"
      ) {
        subObj.Options = formValues.Domain.Options.map(
          (op) => `${op.split(" ")[0]}`
        )
      }
      tempInputObj.Domain = subObj
    }
    if (
      updateToggle &&
      tempInputObj.Domain.Type === "discrete" &&
      tempInputObj.Type !== "text"
    ) {
      tempInputObj.Domain.Options = formValues.Domain.Options.map(
        (op) => `${op?.split(" ")[0]?.trim()}`
      )
    }
    defaultVar.Domain = {}
    const temp = JSON.parse(JSON.stringify(emData))
    const Software = sourceSoftware(emData)
    const softwareType = sourceType(emData)

    const propertiesToDelete =
      (softwareType === storedSatus.emulatorSoftwareType.hosted &&
        storedSatus.deletePropsMap["host"]) ||
      storedSatus.deletePropsMap[Software] ||
      storedSatus.deletePropsMap.default

    propertiesToDelete.forEach((prop) => {
      delete tempInputObj[prop]
    })
    if (updateToggle) {
      temp.calculator.InputVariables[listIndex] = tempInputObj
    } else if (temp?.calculator?.InputVariables) {
      temp.calculator.InputVariables.push(tempInputObj)
    } else {
      temp.calculator["InputVariables"] = [tempInputObj]
    }
    setEmData(temp)
    updateToggle
      ? updateVariable("input", tempInputObj)
      : addVar("input", tempInputObj)
  }

  const handleChangeDefTextfield = (e) => {
    setFormValues((prev) => ({
      ...prev,
      DefaultValue: e.target.value,
    }))
    if (
      e.target.value !== "" &&
      (+e.target.value < formValues.Domain.Start ||
        +e.target.value > formValues.Domain.End)
    ) {
      setDefaultValueError({
        error: true,
        message: "Value out of range",
      })
    } else {
      setDefaultValueError({
        error: false,
        message: "",
      })
    }
  }

  const renderButtons = (small, medium, margin) => (
    <m.Box
      className="ml-display-flex btnbox"
      paddingBottom="1rem"
      sx={{ display: { xs: small, md: medium } }}
      marginTop={margin}
    >
      <m.Button
        onClick={() => setInputDialogVisible(false)}
        style={btnStyle}
        color="primary"
        variant="contained"
      >
        <m.Typography variant="caption" margin="4px">
          Cancel
        </m.Typography>
      </m.Button>
      <m.Button
        onClick={
          emulatorStatusState === "training"
            ? downgradeStatusandAddVar
            : handleSubmit
        }
        style={btnStyle}
        color="primary"
        variant="contained"
        fullWidth
      >
        <m.Typography variant="caption" margin="4px">
          Save
        </m.Typography>
      </m.Button>
    </m.Box>
  )

  if (!visible) {
    return <></>
  } else {
    return (
      <>
        <core.Paper sx={{ width: "auto", margin: "0.5rem" }} elevation={1}>
          {TitleVariable(updateToggle, "Input")}
          <m.Box>
            <m.Stack direction="column" spacing={2}>
              <m.Grid
                container
                sx={{ flexDirection: { xs: "column", md: "row" } }}
              >
                <m.Grid item padding="1rem">
                  <file.VariableDefiner
                    formValuesOutput={formValues}
                    unique1={unique1}
                    handleInputChange={handleInputChange}
                    nicknameError={nicknameError}
                    nameError={nameError}
                    unitError={unitError}
                    setFormValuesOutput={setFormValues}
                    descriptionError={descriptionError}
                    columnLabelError={columnLabelError}
                    columnPrefix={"in"}
                    domainData={domainData}
                    dataColumnLabels={dataColumnLabels}
                    listIndex={listIndex}
                    pythonVarError={pythonVarError}
                    columnLabelValidation={columnLabelValidation}
                    setColumnLabelValidation={setColumnLabelValidation}
                  />
                  <m.Stack direction="row" marginTop={"4px"}>
                    <m.FormControl>
                      <m.FormLabel size="small" color="primary">
                        <m.Typography
                          variant="body1"
                          fontWeight={storedSatus.fontWeight650}
                          className={`${classes.colorBlack} boldLabel`}
                        >
                          Domain Type
                        </m.Typography>
                      </m.FormLabel>
                      <core.RadioGroup
                        color="primary"
                        name="Domain.Type"
                        value={
                          formValues?.Domain?.Type?.length > 0
                            ? formValues.Domain.Type
                            : ""
                        }
                        onChange={handleInputChange}
                        row
                        size="small"
                        aria-label="Domain.Type"
                      >
                        <m.FormControlLabel
                          value="continuous"
                          name="Domain.Type"
                          color="primary"
                          control={
                            <core.Radio name="Domain.Type" color="primary" />
                          }
                          disabled={formValues.Type === "text"}
                          label="Continuous"
                        />
                        <m.FormControlLabel
                          value="discrete"
                          name="Domain.Type"
                          color="primary"
                          control={
                            <core.Radio name="Domain.Type" color="primary" />
                          }
                          label="List"
                        />
                      </core.RadioGroup>
                    </m.FormControl>
                  </m.Stack>
                  {formValues?.Domain?.Type === "continuous" &&
                  formValues.Type !== "text" ? (
                    <>
                      <m.Stack id="stackAlign" marginTop={"4px"}>
                        <m.FormControl>
                          <m.FormLabel
                            size="small"
                            id="Start-label"
                            color="primary"
                          >
                            {renderFormLabel("Start", classes, minValue)}
                          </m.FormLabel>
                          <m.TextField
                            required
                            inputRef={myRef}
                            className="inlineTextField"
                            id="domainstart"
                            name="Domain.Start"
                            color="primary"
                            type="number"
                            size="small"
                            autoComplete="off"
                            value={formValues?.Domain?.Start}
                            onChange={handleInputChange}
                            error={
                              startError &&
                              formValues.Domain.Start?.length === 0
                            }
                            helperText={
                              startError &&
                              formValues.Domain.Start?.length === 0 &&
                              "You must enter Start Value."
                            }
                          />
                        </m.FormControl>
                        <m.FormControl>
                          <m.FormLabel size="small" id="End-label">
                            {renderFormLabel("End", classes, maxValue)}
                          </m.FormLabel>
                          <m.TextField
                            required
                            inputRef={myRef}
                            className=""
                            id="domainend"
                            name="Domain.End"
                            color="primary"
                            type="number"
                            size="small"
                            autoComplete="off"
                            value={formValues?.Domain?.End}
                            onChange={handleInputChange}
                            error={
                              EndError && formValues.Domain.End?.length === 0
                            }
                            helperText={
                              EndError &&
                              formValues.Domain?.End?.length === 0 &&
                              "You must enter End Value."
                            }
                          />
                        </m.FormControl>
                      </m.Stack>
                      {formValues?.Domain?.Type === "continuous" && (
                        <core.FormGroup>
                          <m.FormControlLabel
                            color="primary"
                            control={
                              <core.Checkbox
                                color="primary"
                                name="subdivide"
                                size="small"
                                value={subdivide}
                                onChange={handleSubdivide}
                              />
                            }
                            label={
                              <m.Typography variant="body1">
                                Subdivide Into Discrete Set
                              </m.Typography>
                            }
                          />
                        </core.FormGroup>
                      )}
                      {subdivide && (
                        <m.Stack direction={"row"} marginTop={"4px"}>
                          <m.FormLabel
                            size="small"
                            id="Increment-label"
                            color="primary"
                            style={{ paddingTop: "6px" }}
                          >
                            {renderFormLabel("Increment", classes)}
                          </m.FormLabel>
                          <m.TextField
                            id="Increment"
                            color="primary"
                            name="Increment"
                            style={{ paddingLeft: "10px" }}
                            size="small"
                            autoComplete="off"
                            value={increment}
                            required
                            inputRef={myRef}
                            onChange={handleIncreament}
                            error={
                              incrementError && subdivide && increment === ""
                            }
                            helperText={
                              incrementError &&
                              subdivide &&
                              increment === "" &&
                              "You must enter Increment Value."
                            }
                          />
                        </m.Stack>
                      )}
                    </>
                  ) : (
                    <>
                      <m.FormLabel
                        size="small"
                        id="Increment-label"
                        color="primary"
                      >
                        {renderFormLabel(
                          "Multiline (comma seperated)",
                          classes
                        )}
                      </m.FormLabel>
                      <m.TextField
                        id="outlined-multiline-static"
                        color="primary"
                        multiline
                        fullWidth
                        rows={3}
                        name="Domain.Options"
                        size="small"
                        autoComplete="off"
                        required
                        type="number"
                        inputRef={myRef}
                        onChange={handleMultilineInput}
                        value={
                          formValues.Domain?.Options
                            ? formValues.Domain.Options !== ""
                              ? formValues?.Domain?.Options?.join(", ")
                                  ?.replaceAll(formValues.Units, "")
                                  ?.replaceAll(" ,", ",")
                              : ""
                            : ""
                        }
                        error={
                          optionError && formValues.Domain.Options?.length === 0
                        }
                        helperText={
                          optionError &&
                          formValues.Domain.Options?.length === 0 &&
                          "You must enter Options."
                        }
                      />
                    </>
                  )}
                  <core.FormGroup>
                    <m.FormControlLabel
                      color="primary"
                      control={
                        <core.Checkbox
                          color="primary"
                          name="default_value"
                          size="small"
                          disabled={disableDefaultCheck}
                          checked={hasDefaultValue}
                          onChange={() => {
                            setDefaultValueError({ error: false, message: "" })
                            setFormValues((prev) => ({
                              ...prev,
                              DefaultValue: null,
                            }))
                            setHasDefaultValue(!hasDefaultValue)
                          }}
                        />
                      }
                      label={
                        <m.Typography variant="body1">
                          Set default value
                        </m.Typography>
                      }
                    />
                  </core.FormGroup>
                  {hasDefaultValue && !disableDefaultCheck && (
                    <m.Stack direction={"row"} marginTop={"4px"}>
                      <m.FormLabel
                        size="small"
                        id="default_label"
                        color="primary"
                        style={{ paddingTop: "6px" }}
                      >
                        {renderFormLabel("Default Value", classes)}
                      </m.FormLabel>
                      {formValues?.Domain?.Type === "continuous" ? (
                        <m.Box className="ml-display-flex ml-flex-dir-col">
                          <m.TextField
                            id="default_cont"
                            color="primary"
                            name="default_cont"
                            type="number"
                            style={{ paddingLeft: "10px" }}
                            size="small"
                            autoComplete="off"
                            value={formValues.DefaultValue}
                            required
                            onChange={handleChangeDefTextfield}
                            error={defaultValError.error}
                            helperText={
                              defaultValError.error && defaultValError.message
                            }
                          />
                          <m.Typography
                            variant="caption"
                            textTransform={"none"}
                            className={classes.colorGraySec}
                            sx={{ pl: "10px" }}
                          >
                            {`Range: ${formValues.Domain.Start}${
                              formValues.Units !== "none"
                                ? formValues.Units
                                : ""
                            }-${formValues.Domain.End}${
                              formValues.Units !== "none"
                                ? formValues.Units
                                : ""
                            }`}
                          </m.Typography>
                        </m.Box>
                      ) : (
                        <m.FormControl
                          sx={{ pl: "10px", minWidth: "200px" }}
                          size="small"
                        >
                          <m.Select
                            id="default_discrete"
                            value={formValues.DefaultValue}
                            required
                            error={defaultValError.error}
                            onChange={(e) => {
                              setDefaultValueError({
                                error: false,
                                message: "",
                              })
                              setFormValues((prev) => ({
                                ...prev,
                                DefaultValue: e.target.value,
                              }))
                            }}
                          >
                            {formValues.Domain.Options.join()
                              .split(",")
                              .map((option) => (
                                <m.MenuItem key={option} value={option.trim()}>
                                  {option.trim()}
                                </m.MenuItem>
                              ))}
                          </m.Select>
                          {defaultValError.error && (
                            <m.FormHelperText
                              sx={{ color: classes.colorDangerIcon }}
                            >
                              {defaultValError.message}
                            </m.FormHelperText>
                          )}
                        </m.FormControl>
                      )}
                    </m.Stack>
                  )}
                  <m.Divider sx={{ display: { xs: "flex", md: "none" } }} />
                  {renderButtons("none", "flex", "1rem")}
                </m.Grid>
                <m.Grid
                  display={"flex"}
                  className={classes.backgroundPrimaryLight}
                >
                  <m.Divider
                    orientation="vertical"
                    sx={{ display: { xs: "none", md: "block" } }}
                  />
                  <file.VariableHelperText />
                </m.Grid>
              </m.Grid>
              {renderButtons("flex", "none", "1rem")}
            </m.Stack>
          </m.Box>
        </core.Paper>
      </>
    )
  }
}
