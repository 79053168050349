import * as core from "@material-ui/core"
import * as m from "@mui/material"
import { Suspense, useEffect, useState } from "react"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import "./MemberManagement.css"
import {
  authorization,
  apiNotificationData,
  editAlertBoxState,
  emulatorSpecificRole,
  fetchEmulator,
  siteWideRole,
  getEmulatorData,
  handleKeyDown,
  globalDialogBox,
} from "../../state/projectState"
import * as files from "../../GlobalFileContainer"
import { useParams, useNavigate } from "react-router-dom"
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { fetchTheNotifications } from "../navContent/ExploreNav"
import { mongoProfileState } from "../../state/userState"
import { alertStates } from "../../state/vizState"

const MemberTable = ({
  emulatorId,
  director,
  members,
  setTeam,
  siteWideRoleState,
}) => {
  const navigate = useNavigate()
  const totalMembers = members.length + 1
  const url = `/emulators/${emulatorId}/team/members`

  const [buttonStatus, setButtonStatus] = useState({})
  const [dropdownValue, setDropdownValue] = useState([])
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [message, setMessage] = useState("")
  const [openWarnDialog, setOpenWarnDialog] = useState(false)
  const [storeValue, setStoreValue] = useState({})

  const [editAlertBox, setEditAlertBox] = useRecoilState(editAlertBoxState)
  const emulatorSpecificRoleState = useRecoilValue(emulatorSpecificRole)
  const setUpdatedEmulatorData = useSetRecoilState(getEmulatorData)
  const setNotificationCount = useSetRecoilState(apiNotificationData)
  const setGlobalDialogOpen = useSetRecoilState(globalDialogBox)
  const mongoProfile = useRecoilValue(mongoProfileState)
  const setAlertState = useSetRecoilState(alertStates)
  const [updateEmulatorData, setUpdateEmulatorData] = useState(false)
  const { width } = files.useWindowDimensions()
  const isSmallScreen = width < 600
  const isBigScreen = width > 600
  const roleIsConsumer = siteWideRoleState === "consumer"

  const tableHeadings = [
    {
      name: `Members(${totalMembers})`,
      width: "100px",
    },
    {
      name: "Roles",
      width: "100px",
    },
    {
      name: "Status",
      width: "100px",
    },
    ...(!roleIsConsumer
      ? [{ name: "Actions", width: "250px" }]
      : []),
  ]

  useEffect(() => {
    setAlertState((prevState) => ({
      ...prevState,
      severityState: "success",
    }))
  }, [])

  useEffect(() => {
    handleUserRequest()
  }, [buttonStatus])

  useEffect(() => {
    if (updateEmulatorData) {
      let newConfig = fetchEmulator(emulatorId)
      newConfig
        .then((data) => {
          setTeam(data.team)
          setUpdatedEmulatorData(data)
        })
        .catch((error) => alert("Error while updating emulator config-", error))
    }
  }, [updateEmulatorData])

  const storeDropdownValues = (event, id, email) => {
    setStoreValue({
      id: id,
      email: email,
      value: event.target.value,
    })
  }

  const returnDialogContent = () => {
    return (
      <Suspense fallback={<files.Loader />}>
        <div>
          <files.TransferRoleDialogContent
            members={members}
            emulatorId={emulatorId}
            setMessage={setMessage}
            setUpdateSuccess={setUpdateSuccess}
            setUpdateEmulatorData={setUpdateEmulatorData}
            setGlobalDialogOpen={setGlobalDialogOpen}
            tabulatorMargin={tabulatorMargin}
          />
        </div>
      </Suspense>
    )
  }

  const returnTransferDirectorButton = () => {
    return (
      <core.TableCell align="center">
        <div className="action-buttons">
          <m.Button
            disabled={
              emulatorSpecificRoleState !== "director" &&
              siteWideRoleState !== "admin"
            }
            align="center"
            size="small"
            variant="outlined"
            color="primary"
            style={tabulatorMargin}
            onClick={() =>
              setGlobalDialogOpen((prev) => ({
                ...prev,
                isOpen: true,
                content: returnDialogContent(),
                title: "Transfer Director Role",
                maxWidth: "md",
              }))
            }
          >
            Reassign Director
          </m.Button>
        </div>
      </core.TableCell>
    )
  }

  //returns role, status and action buttons for director
  const directorCells = () => {
    return (
      <>
        <core.TableCell align="center">Director</core.TableCell>
        {isBigScreen && (
          <>
            {returnStatusCell()}
            {!roleIsConsumer && returnTransferDirectorButton()}
          </>
        )}
      </>
    )
  }

  //returns role, status and action buttons for members
  const memberCells = (
    email,
    requested_role,
    request_status,
    id,
    approved_role
  ) => {
    const roleStatusCells = () => {
      return (
        <>
          {requested_role
            ? returnRoleCell(requested_role, request_status, email, id)
            : returnRoleCell(approved_role, "approved", email, id)}
          {request_status && approved_role
            ? returnStatusCell(request_status, `Requested for role upgrade`)
            : request_status
            ? returnStatusCell(request_status)
            : returnStatusCell("approved")}
          {!roleIsConsumer && (
            <>
              {requested_role
                ? isBigScreen
                  ? returnActionsCell(request_status, email, requested_role)
                  : ""
                : returnActionsCell("approved", email, approved_role)}
            </>
          )}
        </>
      )
    }
    return (
      <>
        <m.Box
          sx={{ display: { xs: "flex", md: "none" } }}
          className="ml-align-center"
        >
          {roleStatusCells()}
        </m.Box>
        {isBigScreen && roleStatusCells()}
        {request_status && (
          <m.Box
            sx={{ display: { xs: "flex", md: "none" } }}
            className="ml-align-center ml-justify-end"
          >
            <core.TableCell align="center">
              <core.Typography className="member-note" variant="body1">
                {request_status && approved_role
                  ? "Requested for role upgrade"
                  : "Requested to become member"}
              </core.Typography>
            </core.TableCell>
            {requested_role
              ? returnActionsCell(request_status, email, requested_role)
              : returnActionsCell("approved", email, approved_role)}
          </m.Box>
        )}
      </>
    )
  }

  const openAlert = (text) => {
    setMessage(text)
    setOpenWarnDialog(true)
    setEditAlertBox({
      heading: "Confirmation",
      content:
        text === "deleted"
          ? "Are you sure you want to remove this member?"
          : "Are you sure you want to change member role?",
    })
  }

  //main function
  const handleUserRequest = async () => {
    if (Object.keys(buttonStatus).length !== 0) {
      setUpdateEmulatorData(false)
      try {
        await files.EmulationLab.post(url, buttonStatus, {
          headers: {
            severity: SeverityLevel.Error,
          },
        })
        setButtonStatus({})
        setStoreValue({})
        setUpdateEmulatorData(true)
        fetchTheNotifications(setNotificationCount)
        setUpdateSuccess(true)
      } catch (error) {
        setButtonStatus({})
        setStoreValue({})
        alert("data is not submitted..")
      }
    }
  }

  const handleDropdownCall = (email, role) => {
    setUpdateSuccess(false)
    siteWideRoleState === "admin" || emulatorSpecificRoleState !== "technician"
      ? setButtonStatus({
          email: email,
          approved_role: role,
        })
      : setButtonStatus({
          email: email,
          requested_role: role,
          request_status: "pending",
        })
  }

  //main function for delete
  const handleDeleteRequest = async () => {
    let deleteUrl = url + `?member=${storeValue.email}`
    setUpdateSuccess(false)
    setUpdateEmulatorData(false)
    try {
      await files.EmulationLab.delete(deleteUrl, {
        headers: {
          severity: SeverityLevel.Error,
        },
      })
      setUpdateSuccess(true)
      if (storeValue.email === mongoProfile.email) {
        setTimeout(() => {
          navigate("/")
        }, 2000)
      }
      setDropdownValue([])
      setUpdateEmulatorData(true)
      setStoreValue({})
    } catch (error) {
      alert("data is not submitted..")
    }
  }

  const handleDropdownChange = (value, id) => {
    const newDropdownValues = [...dropdownValue]
    newDropdownValues[id] = value
    setDropdownValue(newDropdownValues)
  }

  const dropdownMainFunction = () => {
    handleDropdownCall(storeValue.email, storeValue.value)
    updateSuccess && handleDropdownChange(storeValue.value, storeValue.id)
  }

  const handleDeleteFunction = (text, email) => {
    openAlert(text)
    setStoreValue({
      email: email,
    })
  }

  const tabulatorMargin = isBigScreen
    ? {
        margin: "5px",
      }
    : {
        minWidth: "10px",
      }

  const closeAlert = () => setOpenWarnDialog(false)

  function returnRoleCell(role, status, email, id) {
    if (status !== "approved") {
      return (
        <core.TableCell className="role-cells" align="center">
          <core.Chip
            size={isBigScreen ? "medium" : "small"}
            label={<core.Typography variant="body2">{role}</core.Typography>}
          />
        </core.TableCell>
      )
    }
    return (
      <core.TableCell className="role-cells" align="center">
        <m.FormControl
          disabled={
            siteWideRoleState === "admin" ||
            (emulatorSpecificRoleState !== "em" &&
              emulatorSpecificRoleState !== "technician")
              ? false
              : roleIsConsumer
              ? true
              : mongoProfile.email !== email
          }
          size="small"
        >
          <m.Select
            variant="outlined"
            value={dropdownValue[id] ? dropdownValue[id] : role}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            onChange={(event) => {
              openAlert("role changed")
              storeDropdownValues(event, id, email)
            }}
          >
            <m.MenuItem value="manager">Manager</m.MenuItem>
            <m.MenuItem value="technician">Technician</m.MenuItem>
          </m.Select>
        </m.FormControl>
      </core.TableCell>
    )
  }

  function returnActionsCell(status, email, role) {
    return (
      <core.TableCell align="center">
        <div className="action-buttons">
          {status === "pending" && (
            <m.Button
              disabled={
                !(
                  authorization["editMetadataOrManageCollaborator"].includes(
                    siteWideRoleState
                  ) ||
                  authorization["editMetadataOrManageCollaborator"].includes(
                    emulatorSpecificRoleState
                  )
                )
              }
              align="center"
              size="small"
              variant={isBigScreen ? "outlined" : "text"}
              color="success"
              style={tabulatorMargin}
              onClick={() => {
                setButtonStatus({
                  email: email,
                  requested_role: role,
                  request_status: "approved",
                })
                setMessage("request approved")
                handleUserRequest()
              }}
            >
              {isBigScreen ? "Approve Request" : <DoneIcon />}
            </m.Button>
          )}
          <m.Button
            disabled={
              status === "director" ||
              !(
                authorization["editMetadataOrManageCollaborator"].includes(
                  siteWideRoleState
                ) ||
                authorization["editMetadataOrManageCollaborator"].includes(
                  emulatorSpecificRoleState
                )
              )
            }
            align="center"
            size="small"
            variant={isBigScreen ? "outlined" : "text"}
            color="error"
            style={tabulatorMargin}
            onClick={() => {
              status === "pending" &&
                setButtonStatus({
                  email: email,
                  requested_role: role,
                  request_status: "denied",
                })
              setMessage("request denied")
              status === "pending"
                ? handleUserRequest()
                : handleDeleteFunction("deleted", email)
            }}
          >
            {isBigScreen ? (
              status === "pending" ? (
                "Decline Request"
              ) : (
                "Remove Member"
              )
            ) : status === "pending" ? (
              <CloseIcon />
            ) : (
              <DeleteOutlineIcon />
            )}
          </m.Button>
        </div>
      </core.TableCell>
    )
  }

  function returnStatusCell(status, request) {
    if (!status) {
      return <core.TableCell align="center">-</core.TableCell>
    }
    return (
      <core.TableCell align="center" className="status-cell">
        <core.Chip
          size={isBigScreen ? "medium" : "small"}
          label={<core.Typography variant="body2">{status}</core.Typography>}
        />
        <br />
        {request && isBigScreen && (
          <core.Typography className="member-note" variant="caption">
            {request}
          </core.Typography>
        )}
      </core.TableCell>
    )
  }

  return (
    <>
      <m.Paper sx={{ width: "100%", overflow: "hidden" }}>
        <m.TableContainer className="member-table">
          <m.Table stickyHeader>
            <m.TableHead>
              <m.TableRow>
                {tableHeadings.map((tbHead, index) =>
                  isSmallScreen ? (
                    tbHead.name === `Members(${totalMembers})` && (
                      <core.TableCell
                        className="table-col-header"
                        style={{ width: tbHead.width }}
                        key={`${index}-cell`}
                      >
                        <b>{tbHead.name}</b>
                      </core.TableCell>
                    )
                  ) : (
                    <core.TableCell
                      className="table-col-header"
                      align={`${
                        tbHead.name !== `Members(${totalMembers})`
                          ? "center"
                          : "inherit"
                      }`}
                      style={{ width: tbHead.width }}
                      key={`${index}-cell`}
                    >
                      <b>{tbHead.name}</b>
                      {tbHead.name === "Roles" && (
                        <core.IconButton size="small">
                          <HelpOutlineIcon
                            sx={{
                              position: "absolute",
                              marginLeft: "10px",
                              marginBottom: "10px",
                              fontSize: 16,
                            }}
                            color="primary"
                            onClick={() =>
                              setGlobalDialogOpen((prev) => ({
                                ...prev,
                                isOpen: true,
                                content: (
                                  <files.CollaboratorDialog
                                    setShowCollaboratorDialog={
                                      setGlobalDialogOpen
                                    }
                                    director={true}
                                  />
                                ),
                                title: "Permissions",
                                maxWidth: "md",
                              }))
                            }
                          />
                        </core.IconButton>
                      )}
                    </core.TableCell>
                  )
                )}
              </m.TableRow>
            </m.TableHead>
            <m.TableBody style={{ overflowX: "auto" }}>
              <m.TableRow key={director.name}>
                <files.ReturnMemberCell
                  name={director.name}
                  email={director.email}
                  avatar={director.avatar}
                  onlyNames={false}
                  returnTransferDirectorButton={returnTransferDirectorButton}
                />
                {isBigScreen && directorCells()}
              </m.TableRow>
              <m.Divider sx={{ display: { xs: "flex", md: "none" } }} />
              {members.map((member, id) => (
                <>
                  <m.TableRow key={`${id}-${member.name}`}>
                    <files.ReturnMemberCell
                      name={member.name}
                      email={member.email}
                      member={member}
                      id={id}
                      memberCells={memberCells}
                      onlyNames={false}
                    />
                    {isBigScreen &&
                      memberCells(
                        member.email,
                        member.requested_role,
                        member.request_status,
                        id,
                        member.approved_role
                      )}
                  </m.TableRow>
                  <m.Divider sx={{ display: { xs: "flex", md: "none" } }} />
                </>
              ))}
            </m.TableBody>
          </m.Table>
        </m.TableContainer>
      </m.Paper>
      {updateSuccess && (
        <files.SnackBar
          open={updateSuccess}
          message={`Member ${message} successfully!`}
        />
      )}
      <core.Dialog
        onKeyDown={(e) => handleKeyDown(e, closeAlert)}
        open={openWarnDialog}
        fullWidth={true}
      >
        <files.AlertBox
          heading={editAlertBox.heading}
          content={editAlertBox.content}
          closeDialog={closeAlert}
          callFunction={
            message === "deleted" ? handleDeleteRequest : dropdownMainFunction
          }
        />
      </core.Dialog>
    </>
  )
}

const Team = () => {
  const { emulatorId } = useParams()
  const [team, setTeam] = useState({})
  const siteWideRoleState = useRecoilValue(siteWideRole)

  useEffect(() => {
    let getConfig = fetchEmulator(emulatorId)
    getConfig
      .then((data) => {
        setTeam(data.team)
      })
      .catch((error) => alert("Error while updating emulator config-", error))
  }, [emulatorId])

  const director = Object.keys(team).length !== 0 && team.director
  const filterPendingMembers = team.members?.filter((member) =>
    member.request_status ? member?.request_status !== "pending" : true
  )
  const members =
    Object.keys(team).length !== 0 &&
    (siteWideRoleState === "consumer" ? filterPendingMembers : team.members)

  return (
    <>
      {director && members ? (
        <>
          <files.UserForm
            members={members}
            director={director}
            setTeam={setTeam}
            emulatorId={emulatorId}
            siteWideRoleState={siteWideRoleState}
          />
          <MemberTable
            emulatorId={emulatorId}
            director={director}
            members={members}
            setTeam={setTeam}
            siteWideRoleState={siteWideRoleState}
          />
        </>
      ) : (
        <files.Loader margin="0px" />
      )}
    </>
  )
}

export default Team
