import { useParams } from "react-router"
import { useRecoilValue } from "recoil"
import * as m from "@mui/material"
import { getEmulatorData } from "../../state/projectState"
import CommonerrorPageHandle from "../CommonerrorPageHandle"
import { CalculatorInputCard } from "../../GlobalFileContainer"

const InputComponent = ({
  setAsVariable,
  generateRandomInputs,
  setGenerateRandomInputs,
  setValidateOptimizeButton,
  randomizeBtn,
}) => {
  const { emulatorId } = useParams()
  const emData = useRecoilValue(getEmulatorData)

  if (Object.keys(emData?.calculator).length === 0) {
    return <div></div>
  } else {
    return (
      <div>
        <CommonerrorPageHandle title="Calculator">
          <m.Grid container>
            <m.Grid item xs={12}>
              <CalculatorInputCard
                activeEmulatorId={emulatorId}
                calcConfig={emData?.calculator}
                loader={false}
                pageOptimize={true}
                setAsVariable={setAsVariable}
                generateRandomInputs={generateRandomInputs}
                setGenerateRandomInputs={setGenerateRandomInputs}
                setValidateOptimizeButton={setValidateOptimizeButton}
                randomizeBtn={randomizeBtn}
              />
            </m.Grid>
          </m.Grid>
        </CommonerrorPageHandle>
      </div>
    )
  }
}

export default InputComponent
