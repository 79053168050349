import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { FaRegSun, FaChartLine, FaBriefcase, FaRocket } from "react-icons/fa"
import * as m from "@mui/material"
import { useParams } from "react-router-dom"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { getEmulatorData } from "../../state/projectState"
import { allEmulatorStatus } from "../../state/StatusState"
import { EmulationLab, ErrorBoundary, Loader } from "../../GlobalFileContainer"
import { convertToUppercase } from "../../state/services"

const itemsPerPage = 14
const commonStyle = {
  style: {
    margin: "5px",
  },
  width: {
    width: "100px",
  },
  width2: {
    width: "50px",
  },
}

function EventLogs() {
  let { emulatorId } = useParams()
  const eventUrl = `/emulators/${emulatorId}/timeline`

  const emulatorConfig = useRecoilValue(getEmulatorData)

  const [currentPage, setCurrentPage] = useState(1)
  const [events, setEvents] = useState([])
  const [eventTypes, setEventTypes] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [loader, setLoader] = useState(false)

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const checkboxes = eventTypes?.map((type) => ({
    label: type,
    value: type,
  }))
  const filteredEvents =
    events?.length > 0 &&
    events?.filter((event) =>
      selectedTypes?.includes(event?.type.toLowerCase())
    )

  const visibleEventsbaseOnIndex =
    filteredEvents?.length > 0 && filteredEvents?.slice(startIndex, endIndex)

  useEffect(() => {
    getEventLogs()
  }, [])

  useEffect(() => {
    try {
      const uniqueTypes = [
        ...(new Set(events?.map((event) => event.type)) || []),
      ]
      setEventTypes(uniqueTypes)
      setSelectedTypes(uniqueTypes)
    } catch (err) {
      console.error("Error while fetching data:", err)
    }
  }, [events])

  const handleChangeFilter = (event) => {
    const { value, checked } = event.target
    setCurrentPage(1)
    if (checked) {
      setSelectedTypes([...selectedTypes, value])
    } else {
      setSelectedTypes(selectedTypes?.filter((type) => type !== value))
    }
  }

  const getEventLogs = async () => {
    try {
      setLoader(true)
      const getEvent = await EmulationLab.get(eventUrl, {
        headers: {
          severity: SeverityLevel.Error,
        },
      })
      if (getEvent.status === 200) {
        if (getEvent?.data?.length > 0) {
          const allEvents = getEvent?.data?.reverse()
          const updatedObjects = [...allEvents]
          for (let i = 0; i < updatedObjects?.length - 1; i++) {
            const currentObject = updatedObjects[i]
            const nextObject = updatedObjects[i + 1]
            if (currentObject.message === nextObject.message) {
              if (!currentObject.children) {
                currentObject.children = []
              }
              currentObject.children.push(nextObject)
              updatedObjects.splice(i + 1, 1)
              i--
            }
          }
          setEvents(updatedObjects)
        } else {
          setEvents([])
        }

        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.error(error)
      alert(
        error?.response?.data?.message ??
          "Failed to Load event logs, please try again.."
      )
    }
  }

  const handleChangePage = (event, page) => {
    setCurrentPage(page)
  }

  const renderAccordion = (object, index, data) => {
    const { details, message, time, type } = object

    const previousEvent = data
      ? data[index - 1]
      : visibleEventsbaseOnIndex[index - 1]
    const eventTime = new Date(time)
    const eventDate = eventTime.toLocaleString("default", {
      year: "2-digit",
      month: "long",
      day: "numeric",
    })

    const eventTimeStr = eventTime.toLocaleString("default", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    })
    const isSameDate =
      previousEvent &&
      eventTime.getDate() === new Date(previousEvent.time).getDate()

    const accordionHeader = (
      <m.Grid id="accordiontime">
        <m.Grid container sx={{ flexDirection: { xs: "column", md: "row" } }}>
          <m.Typography width={commonStyle.width} variant="body1">
            {isSameDate ? null : eventDate}
          </m.Typography>
          <m.Typography width={commonStyle.width2} variant="body1">
            {eventTimeStr}
          </m.Typography>
        </m.Grid>
      </m.Grid>
    )

    const getIconForType = (type) => {
      switch (type) {
        case allEmulatorStatus.training:
          return <FaRegSun style={commonStyle.style} />
        case allEmulatorStatus.predict:
          return <FaChartLine style={commonStyle.style} />
        case allEmulatorStatus.release:
          return <FaRocket style={commonStyle.style} />
        default:
          return <FaBriefcase style={commonStyle.style} />
      }
    }

    const getMessageForType = (type, details, message) => {
      switch (type) {
        case allEmulatorStatus.predict:
          return `${details?.user_email || ""} ${message?.split(".")[0]} for ${
            emulatorConfig.name
          } using ${details?.user_client || ""}.`
        case allEmulatorStatus.training:
          return `${message?.split(".")[0]}${
            details["user_email"]?.length > 0
              ? ` by ${details["user_email"] || ""}`
              : "."
          }`
        default:
          return message
      }
    }

    const renderDetailsForType = (type, details) => {
      const commonInputOutputDetails = (input) => (
        <div style={commonStyle.style}>
          <m.Typography variant="body2">
            <b>{convertToUppercase(input)}:</b>
          </m.Typography>
          <br />
          {Object.keys(details[input])?.map((key, i) => (
            <div key={key + i} style={commonStyle.style}>
              <div>
                <m.Typography variant="body2">
                  {key}:
                  {details[input][key]?.length > 1
                    ? details[input][key]?.map((inputs, i) => (
                        <ul key={inputs + i}>
                          <li>{inputs}</li>
                        </ul>
                      ))
                    : details[input][key]}
                </m.Typography>
              </div>
            </div>
          ))}
        </div>
      )

      const renderLabels = (name) => (
        <div style={commonStyle.style}>
          <m.Typography variant="body2">
            <b>{convertToUppercase(name)}:</b>
          </m.Typography>
          {name === "status" ? details["new status"] : details[name]}
        </div>
      )

      switch (type) {
        case allEmulatorStatus.predict:
          return (
            <>
              {commonInputOutputDetails("inputs")}
              {commonInputOutputDetails("outputs")}
            </>
          )
        case allEmulatorStatus.training:
          return (
            <div style={commonStyle.style}>
              {renderLabels("target")}
              {renderLabels("algorithm")}
              {renderLabels("status")}
            </div>
          )
        default:
          return null
      }
    }

    return (
      <m.Accordion key={index + message}>
        <m.AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <m.Typography sx={{ flexShrink: 0 }}>{accordionHeader}</m.Typography>
          <m.Typography
            sx={{
              color: "text.secondary",
              width: { xs: "12%", md: "5%" },
            }}
            variant="body1"
            id="accordicon"
          >
            {getIconForType(type)}
          </m.Typography>
          <m.Typography variant="body1" id="accordsumry">
            {getMessageForType(type, details, message)}
          </m.Typography>
        </m.AccordionSummary>
        <m.AccordionDetails id="accordsummry">
          <m.Typography variant="body2">
            {renderDetailsForType(type, details)}
          </m.Typography>
          {object.children && (
            <div>
              {object.children?.map((child, i) =>
                renderAccordion(child, i, object.children)
              )}
            </div>
          )}
        </m.AccordionDetails>
      </m.Accordion>
    )
  }

  return (
    <>
      {loader && <Loader />}
      {!loader && (
        <div className="eventlogcontainer">
          <ErrorBoundary>
            <m.Box className="ml-display-flex ml-justify-end">
              {checkboxes.map(({ label, value }) => {
                const labelWords = label
                  .split(" ")
                  .map((word) => convertToUppercase(word))
                  .join(" ")
                return (
                  <m.FormControlLabel
                    key={value}
                    control={
                      <m.Checkbox
                        value={value}
                        checked={selectedTypes.includes(value)}
                        onChange={handleChangeFilter}
                      />
                    }
                    label={
                      <m.Typography variant="body1">{labelWords}</m.Typography>
                    }
                  />
                )
              })}
            </m.Box>
          </ErrorBoundary>
          {visibleEventsbaseOnIndex?.length > 0 ? (
            visibleEventsbaseOnIndex.map((object, i) =>
              renderAccordion(object, i)
            )
          ) : (
            <m.Typography variant="body1">No Events to Show.</m.Typography>
          )}
          {visibleEventsbaseOnIndex?.length > 0 && (
            <m.Pagination
              count={Math.ceil(filteredEvents.length / itemsPerPage)}
              page={currentPage}
              onChange={handleChangePage}
              className="eventpagination"
            />
          )}
        </div>
      )}
    </>
  )
}

export default EventLogs
