import { useEffect, useLayoutEffect, useState } from "react"
import { Grid, Typography } from "@mui/material"
import {
  DropDowns,
  RangeInput,
  ErrorBoundary,
} from "../../../GlobalFileContainer"
import useStyles from "../../card_style"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  getUnitList,
  matchUnit,
  outputsParametersAndVariables,
  populateResultData,
} from "../../../state/projectState"
import { emulatorMode } from "../../../state/StatusState"
import { generatePostBody } from "../../../state/services"

const generateSingleFeaturePostPart = (label, featureValue) => {
  return {
    Label: label,
    Data: [String(featureValue)],
  }
}

const CalculatorInput = (props) => {
  const classes = useStyles()
  const searchParamsString = window.location.search
  const queryParams = new URLSearchParams(searchParamsString)
  const tryitMode = queryParams.get("mode")
  const [features, setFeatures] = useState([])

  const setParametersAndVariablesState = useSetRecoilState(
    outputsParametersAndVariables
  )
  const uniqueList = useRecoilValue(getUnitList)
  const [populateResultDataState, setPopulateResultDataState] =
    useRecoilState(populateResultData)

  const lookupLength =
    props.lookupInstruction && Object.keys(props.lookupInstruction)?.length
  const allInputsOutputs = [
    ...(props.calcConfig?.InputVariables || []),
    ...(props.calcConfig?.OutputVariables || []),
  ]
  const filteredInpOutp =
    lookupLength &&
    allInputsOutputs.filter((item1) =>
      props.lookupInstruction.userFilters.some(
        (item2) => item1.ColumnLabel === item2.label
      )
    )
  const inputVars =
    tryitMode === emulatorMode.lookup
      ? lookupLength
        ? filteredInpOutp
        : []
      : props?.calcConfig?.InputVariables

  useEffect(() => {
    if (
      searchParamsString?.length > 0 &&
      window.location.pathname.includes("tryit") &&
      populateResultDataState
    ) {
      const urlSearchParams = new URLSearchParams(searchParamsString)
      const mode = urlSearchParams.get("mode")
      const inputVars =
        mode === emulatorMode.lookup
          ? [
              ...(props.calcConfig?.InputVariables || []),
              ...(props.calcConfig?.OutputVariables || []),
            ]
          : props.calcConfig?.InputVariables
      urlSearchParams.delete(urlSearchParams.keys().next().value)

      const searchParamsObject = Object.fromEntries(urlSearchParams.entries())
      const { launchid, index, ...filteredObj } = searchParamsObject

      const featuresData = Object.entries(filteredObj).map(([key, value]) => {
        const findLabel = inputVars?.find((input) => input.ColumnLabel === key)
        const selectedKey = findLabel ? key : `in ${key}`
        return generateSingleFeaturePostPart(selectedKey, value)
      })
      props.setPostBody2(
        generatePostBody(props.calcId, featuresData, props.consumer)
      )
      setPopulateResultDataState(false)
    }
  }, [populateResultDataState])

  useLayoutEffect(() => {
    setFeatures(Array(props.calcConfig?.InputVariables?.length))
  }, [])

  const updateFeatureOutput = (i, label, featureValue) => {
    setFeatures((arr) => {
      const updatedArr = Object.assign([], arr)
      updatedArr[i] = generateSingleFeaturePostPart(
        label,
        removeUnits(featureValue === true ? "" : featureValue)
      )
      featureValue &&
        props.setPostBody2(
          generatePostBody(props.calcId, updatedArr, props.consumer)
        )
      !props.page &&
        setParametersAndVariablesState((prev) => ({
          ...prev,
          parameters: prev.parameters.map((param) =>
            param.label === label ? { ...param, value: featureValue } : param
          ),
        }))
      return updatedArr
    })
  }

  const removeUnits = (input) => {
    if (typeof input !== "string") {
      return input
    }
    const unitPattern = /( ft| psf| inch| pcf| in| ksi| pli| psi)+$/

    return input.replace(unitPattern, "").trim()
  }

  const rangeInputTemplate = (input, inputindex) => {
    return (
      <ErrorBoundary>
        <RangeInput
          variable={input}
          unit={matchUnit(input?.Units ?? "none", uniqueList)}
          inputindex={inputindex}
          updateFeatureOutput={updateFeatureOutput}
          setCheckingRangeWarning={props.setCheckingRangeWarning}
          checkingRangeWarning={props.checkingRangeWarning}
          postBody={props.postBody}
          generateRandomInputs={props.generateRandomInputs}
          setGenerateRandomInputs={props.setGenerateRandomInputs}
          pageOptimize={props.pageOptimize}
          page={props.page}
          lookupInstruction={props.lookupInstruction}
          disableInputs={props.disableInputs}
        />
      </ErrorBoundary>
    )
  }

  const catInputTemplate = (input, inputindex) => {
    let options = input.Domain.Options

    return (
      <DropDowns
        variable={input}
        unit={matchUnit(input?.Units ?? "none", uniqueList)}
        options={options}
        inputindex={inputindex}
        updateFeatureOutput={updateFeatureOutput}
        postBody={props.postBody}
        generateRandomInputs={props.generateRandomInputs}
        setGenerateRandomInputs={props.setGenerateRandomInputs}
        pageOptimize={props.pageOptimize}
        page={props.page}
        lookupInstruction={props.lookupInstruction}
        disableInputs={props.disableInputs}
      />
    )
  }
  let inputDOMElements = inputVars?.map((input, inputindex) => {
    if (input.Domain?.Type === "discrete") {
      // also works for text
      return catInputTemplate(input, inputindex)
    } else if (input.Type === "quantity") {
      return rangeInputTemplate(input, inputindex)
    } else if (input.Type === "number") {
      return rangeInputTemplate(input, inputindex)
    } else if (input.Type === "categorical") {
      return catInputTemplate(input, inputindex)
    } else {
      console.error("Bad Input Type on ", `${input.Type}`)
      console.error("Bad Input Type on ", `${input.Domain.Type}`)
      return null
    }
  })

  return !inputVars?.length ? (
    <Typography padding={1} variant="body2" className="ed-small">
      No curated instructions available! Try custom lookup?
    </Typography>
  ) : (
    <Grid
      container
      direction="row"
      rowSpacing={1}
      columnSpacing={2}
      className={classes.calcCardMargin}
    >
      {inputDOMElements?.map((inputDomElement, index) => {
        return (
          <Grid
            item
            xs="auto"
            className="tryit-dropdown-grid"
            key={inputDomElement + index}
          >
            {inputDomElement}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default CalculatorInput
