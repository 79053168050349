import { useState } from "react"
import * as m from "@mui/material"
import { useRecoilValue, useSetRecoilState } from "recoil"
import feedBackSrc from "../../assets/comment.svg"
import sharepointIcon from "../../assets/SharepointIcon.svg"
import { handleKeyDown } from "../../state/projectState"
import * as files from "../../GlobalFileContainer"
import StepperStyleCreateEm from "../StepperStyleCreateEm"
import { feedbackRequest } from "../../state/requests"
import { mongoProfileState } from "../../state/userState"
import { alertStates } from "../../state/vizState"

const FeedbackOrSharepoint = ({
  title,
  clickFunction,
  icon,
  backgroundColor,
}) => (
  <m.Box
    className="ml-display-flex ml-flex-dir-row ml-justify-center ml-align-center width-100 ml-gap-1 cursor-point"
    sx={{
      padding: "8px 24px",
      borderRadius: "6.75px",
      backgroundColor: backgroundColor,
    }}
    onClick={clickFunction}
  >
    {icon}
    <m.Typography fontWeight={500} style={{ fontSize: "14px" }}>
      {title}
    </m.Typography>
  </m.Box>
)

const MemberAndCalcFileButton = ({
  isLaunchPage,
  showCalcFile,
  selectedCardData,
}) => {
  const maxWords = 100
  const theme = m.useTheme()

  const mongoProfile = useRecoilValue(mongoProfileState)
  const setAlertState = useSetRecoilState(alertStates)

  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [description, setDescription] = useState("")

  const handleInputChange = (e) => {
    const input = e.target.value
    const wordCount = input.trim().split(/\s+/).filter(Boolean).length

    if (wordCount <= maxWords) {
      setDescription(input)
    }
  }

  const closeCreate = () => {
    setOpenFeedbackDialog(false)
  }

  const handleSubmittingFeedback = async () => {
    const feedBackBody = {
      email: mongoProfile.email,
      mode: mongoProfile.active_user_mode,
      webpage: window.location.href,
      feedback: description,
    }

    try {
      const submitFeedBack = await files.EmulationLab.post(
        feedbackRequest,
        feedBackBody
      )
      if ([200, 201].includes(submitFeedBack.status)) {
        setAlertState({
          boolState: true,
          message: "Feedback Submitted Successfully!",
          severityState: "success",
        })
      }
    } catch (err) {
      console.err(err)
    }
  }

  const feedbackStrings = {
    title: "Use the space below to share your feedback.",
    description:
      "You can provide general feedback on the entire website. For page-specific issues or bugs, mention the page name; otherwise, the current page will be used as a reference.",
  }

  return (
    <>
      {isLaunchPage ? (
        <files.MemberRoleButton />
      ) : showCalcFile ? (
        <files.DownloadCalcFileButton selectedCardData={selectedCardData} />
      ) : null}
      {!isLaunchPage && selectedCardData?.sharepoint_ref && (
        <FeedbackOrSharepoint
          title={"Sharepoint Documentation"}
          clickFunction={() =>
            window.open(selectedCardData.sharepoint_ref, "_blank")
          }
          icon={<img src={sharepointIcon} alt={"Sharepoint Icon"} />}
          backgroundColor={theme.palette.grey[200]}
        />
      )}
      <FeedbackOrSharepoint
        title={"Give us your Feedback"}
        clickFunction={() => setOpenFeedbackDialog(true)}
        icon={<img src={feedBackSrc} alt={"Feedback Icon"} />}
        backgroundColor={theme.palette.grey[200]}
      />
      {openFeedbackDialog && (
        <m.Dialog
          onKeyDown={(e) => handleKeyDown(e, closeCreate)}
          open={openFeedbackDialog}
          fullWidth={true}
          margin={"5px"}
          onClose={closeCreate}
          PaperProps={{
            style: {
              background: theme.palette.common.white,
              minWidth: "45vw",
            },
          }}
        >
          <files.AddDialogTitle
            closeCreate={closeCreate}
            title={"We want your feedback!"}
            createEm
          />
          <m.DialogContent dividers>
            <StepperStyleCreateEm
              title={feedbackStrings.title}
              detail={feedbackStrings.description}
            />
            <m.Box ml={5} mb={2}>
              <m.TextField
                className="createEm-input"
                multiline
                minRows={10}
                name="description"
                value={description}
                onChange={handleInputChange}
                placeholder="Write your feedback here (max 100 words)."
                variant="outlined"
                helperText={`${
                  description.trim().split(/\s+/).filter(Boolean).length
                } / ${maxWords} words`}
                sx={{
                  "& .MuiFormHelperText-root": {
                    textTransform: "none",
                    margin: 0,
                    fontSize: "0.9rem",
                  },
                }}
              />
            </m.Box>
            <m.Divider />
            <m.Box className="ml-display-flex ml-float-right" mt={2}>
              <m.Button
                disabled={
                  description.trim().split(/\s+/).filter(Boolean).length >
                    100 || description.trim().length === 0
                }
                color="primary"
                variant="contained"
                onClick={handleSubmittingFeedback}
                style={{ color: theme.palette.common.white }}
              >
                Submit
              </m.Button>
            </m.Box>
          </m.DialogContent>
        </m.Dialog>
      )}
    </>
  )
}

export default MemberAndCalcFileButton
