import { useState, useEffect, Suspense } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useNavigate } from "react-router-dom"
import * as core from "@material-ui/core"
import * as files from "../GlobalFileContainer"
import CreateEmulator from "./CreateEmulator"
import { testJWTToken } from "../state/axios"
import { JWTTokenState, mongoProfileState } from "../state/userState"
import {
  commonCategoryChangeHandler,
  dataSourceType,
  handleKeyDown,
} from "../state/projectState"
import { alertStates } from "../state/vizState"
import { emulatorMode, emulatorSoftwareType, urlPattern } from "../state/StatusState"
import { Typography, useTheme } from "@mui/material"
import { base64Regex, urlRegex } from "./project/Define/CalculatorConfig"
import {
  convertToUppercase,
  getEmulatorModes,
  sourceMethod,
  sourceRef,
  sourceSoftware,
  sourceType,
} from "../state/services"

function CloneEmulator({ open, setEditMeta, emulator }) {
  const url = `/emulators/${emulator.id}/clone`
  let navigate = useNavigate()
  const theme = useTheme()
  const emSourceType = sourceType(emulator)
  const softwareType = sourceSoftware(emulator)
  const emSourceRef = sourceRef(emulator)
  const emSourceMethod = sourceMethod(emulator)

  const setMongoProfile = useSetRecoilState(mongoProfileState)
  const setFiles = useSetRecoilState(dataSourceType)
  const JWTToken = useRecoilValue(JWTTokenState)
  const setAlertState = useSetRecoilState(alertStates)

  const [textfieldError, setTextfieldError] = useState(false)
  const [newEmulator, setNewEmulator] = useState({
    name: `clone_${emulator.name || ""}`,
    description: `${emulator.description || ""}`,
    image: `${emulator.image}`,
  })
  const [fileType, setFileType] = useState(
    emSourceType === "calculator" ? softwareType : emSourceType || "none"
  )
  const [dataSource, setDataSource] = useState({
    ...emulator.source,
    type: emSourceType || "",
    software: softwareType || "",
    ref: emSourceType === "hosted_calculator" ? emSourceRef : "",
    method: emSourceMethod || "",
  })
  const result = getEmulatorModes(emulator?.modes, true)

  const [tagValue, setTagValue] = useState(emulator.tags)
  const [categoryValue, setCategoryValue] = useState(emulator.category)
  const [tempCategoryAlert, setTempCategoryAlert] = useState("")
  const [loader, setLoader] = useState(false)
  const [modes, setModes] = useState(result)

  useEffect(() => {
    setNewEmulator({
      name: `clone_${emulator.name || ""}`,
      description: `${emulator.description || ""}`,
      image: `${emulator.image}`,
    })
    setTagValue(emulator.tags)
    setCategoryValue(emulator.category)
  }, [emulator])

  const handleInput = (e) => {
    const name = e.target.name
    const value = e.target.value
    setNewEmulator({ ...newEmulator, [name]: value })
    setTextfieldError(false)
  }

  const handleCategoryChange = (e) => {
    const category = e.target.value
    setTempCategoryAlert(commonCategoryChangeHandler(category))
    setCategoryValue(e.target.value)
    setTextfieldError(false)
  }

  const handleTagChange = (event, value, reason) => {
    setTagValue(value)
    setTextfieldError(false)
  }

  const handleModeChange = (event) => {
    setModes(event.target.value)
    setTextfieldError(false)
  }

  const mode = modes?.map((m) => m?.toLowerCase())
  const newDataS =
    dataSource.type === "hosted_calculator"
      ? dataSource
      : {
          ...dataSource,
          type: dataSource.type,
          software: dataSource.software,
          ref: null,
        }

  const data = {
    emulator_name: newEmulator.name,
    description: newEmulator.description,
    image_url: newEmulator.image,
    tags: tagValue,
    category: categoryValue,
    modes: mode,
    source: newDataS,
  }

  const SubmitManage = async (e) => {
    if (newEmulator.name?.length === 0) {
      setTextfieldError({
        name: true,
      })
      return
    } else if (
      newEmulator.description?.length === 0 ||
      newEmulator.description?.trim() === ""
    ) {
      setTextfieldError({ description: true })
      return
    } else if (categoryValue?.length === 0) {
      setTextfieldError({ category: true })
      return
    } else if (modes?.length === 0) {
      setTextfieldError({ mode: true })
      return
    } else if (
      modes?.includes(convertToUppercase(emulatorMode.calculate)) &&
      dataSource.type === emulatorSoftwareType.upload
    ) {
      setTextfieldError({ source: true })
      return false
    } else if (
      newEmulator.image?.length > 0 &&
      !(urlRegex.test(newEmulator.image) || base64Regex.test(newEmulator.image))
    ) {
      setTextfieldError({ image: true })
      return
    } else if (
      dataSource.type === "hosted_calculator" &&
      (!urlPattern.test(dataSource.ref) || dataSource.type.length === 0)
    ) {
      setTextfieldError({ url: true })
      return
    }
    setLoader(true)
    try {
      const response = await files.EmulationLab.post(url, data)
      if (response.status === 200) {
        setLoader(false)
        setAlertState({
          boolState: true,
          message: "Emulator cloned successfully!",
          severityState: "success",
        })
        setTimeout(() => {
          const path = `/em/${response.data}/manage_configs`
          navigate(path)
        }, 1000)
        closeMeta(false)
        testJWTToken(setMongoProfile, JWTToken)
        setFiles(fileType)
      }
    } catch (error) {
      setLoader(false)
      setAlertState({
        boolState: true,
        message:
          error.response.status === 409
            ? "Failed! Emulator name already in use. Try different name!"
            : "Some error occured in creating new emulator. Please try after some time.",
        severityState: "error",
      })
      alert("Failed to clone the emulator, please try again.")
    }
  }
  const closeMeta = () => {
    setEditMeta(false)
    setFileType(
      emSourceType === "calculator" ? softwareType : emSourceType || "none"
    )
    setDataSource({
      type: emSourceType || "",
      software: softwareType || "",
      ref: emSourceType === "hosted_calculator" ? emSourceRef : "",
      method: emSourceMethod || "",
    })
  }

  return (
    <>
      <core.Dialog
        onKeyDown={(e) => handleKeyDown(e, closeMeta)}
        open={open}
        fullWidth={true}
      >
        <files.AddDialogTitle
          closeCreate={closeMeta}
          title={"Clone Emulator"}
          createEm
        />
        <core.DialogContent dividers>
          <Suspense fallback={<files.Loader />}>
            <div>
              <core.Box sx={{ flexGrow: 1 }}>
                {tagValue && categoryValue && newEmulator && !loader ? (
                  <>
                    <CreateEmulator
                      newEmulator={newEmulator}
                      handleInput={handleInput}
                      handleCategoryChange={handleCategoryChange}
                      handleTagChange={handleTagChange}
                      categoryValue={categoryValue}
                      tagValue={tagValue}
                      tempCategoryAlert={tempCategoryAlert}
                      textfieldError={textfieldError}
                      fileType={fileType}
                      setFileType={setFileType}
                      source={dataSource}
                      setDataSource={setDataSource}
                      modes={modes}
                      handleModeChange={handleModeChange}
                      setTextfieldError={setTextfieldError}
                    />
                    <core.Grid
                      container
                      className="mui-grid ml-flex-dir-row ml-align-center ml-justify-center"
                    >
                      <core.Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        className="createEm-submit"
                        onClick={SubmitManage}
                      >
                        <Typography
                          variant="caption"
                          margin="4px"
                          color={theme.palette.common.white}
                        >
                          Clone
                        </Typography>
                      </core.Button>
                    </core.Grid>
                  </>
                ) : (
                  <files.Loader />
                )}
              </core.Box>
            </div>
          </Suspense>
        </core.DialogContent>
      </core.Dialog>
    </>
  )
}

export default CloneEmulator
