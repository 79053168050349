import * as router from "react-router-dom"
import * as m from "@mui/material"
import * as file from "../../GlobalFileContainer"
import OptimizationConsumer from "./consumer optimization/OptimizationConsumer"
import CustomLookupOpt from "./CustomLookupOpt"

const LaunchSidebarRoutes = ({
  selectedOptions,
  setSelectedOptions,
  setSelectedCardData,
  selectedCardData,
}) => {
  const [idSearchParams] = router.useSearchParams()

  const tryitMode = idSearchParams.get("mode")
  const isOptimise = tryitMode === "optimize"

  return (
    <router.Routes>
      <router.Route
        path="teams"
        element={
          <m.Grid marginTop={2}>
            <file.Team />
          </m.Grid>
        }
      />
      <router.Route
        path="optimization"
        element={
          <CustomLookupOpt name="optimization">
            <file.MemberManagement
              name="Optimization"
              launch
              component={<file.OptimizationMain consumer />}
            />
          </CustomLookupOpt>
        }
      />
      <router.Route
        path="tryit"
        element={
          <file.TryitTabs
            selectedCardData={selectedCardData}
            setSelectedCardData={setSelectedCardData}
          >
            {isOptimise ? <OptimizationConsumer /> : <file.TryitConsumer />}
          </file.TryitTabs>
        }
      />
      <router.Route
        path="lookup"
        element={
          <CustomLookupOpt name="lookup">
            <file.LookupMain />
          </CustomLookupOpt>
        }
      />
      <router.Route
        path="launches"
        element={
          <file.LaunchPageTabs
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          >
            <file.ConsumerFlowAllPages
              setSelectedCardData={setSelectedCardData}
            />
          </file.LaunchPageTabs>
        }
      />
    </router.Routes>
  )
}

export default LaunchSidebarRoutes
