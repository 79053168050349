import { Chip, Typography } from "@mui/material"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import { ReactComponent as DMTBadge } from "../../assets/newIcons/badge-check.svg"

const CommonDMTBadge = ({ validations, style, size, fontSize }) => {
  const isDmtTag = Array.isArray(validations)
  return (
    <>
      <Chip
        size="small"
        sx={style}
        icon={
          isDmtTag ? (
            <DMTBadge height={size} width={size} stroke="#389950" />
          ) : (
            <WarningAmberIcon fontSize="small" color="warning" />
          )
        }
        label={
          <Typography
            fontWeight={500}
            style={{ fontSize: fontSize }}
            color={isDmtTag ? "success" : "warning"}
          >
            {isDmtTag ? validations[0] : validations}
          </Typography>
        }
        variant="outlined"
        color={isDmtTag ? "success" : "warning"}
      />
    </>
  )
}

export default CommonDMTBadge
