import { useState, useEffect } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import { useSearchParams } from "react-router-dom"
import * as files from "../../../GlobalFileContainer"
import {
  calculatorResult,
  emulatorLauncheGroupDataState,
  getEmulatorData,
} from "../../../state/projectState"
import { isModalObjectBoolean } from "../../../state/services"
import { useTryitModeEffect } from "../../../hooks/useTryitModeEffect"
import LookupOptimizationMain from "../LookupOptimizationMain"
import { emulatorMode, FULL_VIEW_OPEN_TEXT } from "../../../state/StatusState"
import useCalculatorSocketConnection from "../../../hooks/useCalculatorSocketConnection"

const TryitConsumer = () => {
  const [idSearchParams] = useSearchParams()

  const tryitMode = idSearchParams.get("mode")
  const selectedIndex = idSearchParams.get("index")
  const selectedID = idSearchParams.get("launchid")

  const groupedData = useRecoilValue(emulatorLauncheGroupDataState)
  const [result, setResult] = useRecoilState(calculatorResult)
  const emulatorConfig = useRecoilValue(getEmulatorData)

  const [outputResponse, setOutput] = useState([])
  const [postBody, setPostBody1] = useState({})
  const [loader, setLoader] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [generateRandomInputs, setGenerateRandomInputs] = useState(false)
  const [disableKeys, setDisableKeys] = useState(false)
  const [fullView, setFullView] = useState(FULL_VIEW_OPEN_TEXT)
  const [outputLog, setOutputLog] = useState([])
  const [socketMembers, setSocketMembers] = useState([])
  const [socketPostBody, setSocketPostBody] = useState({
    payload: {},
    inputs: {},
  })

  const selectedDataForTryit = groupedData[selectedIndex]
  const selectedCalcObject = selectedDataForTryit?.find(
    (obj) => obj.id === selectedID
  )
  const isModalObject = isModalObjectBoolean(result)
  const { dependency_set } = selectedCalcObject?.package?.source ?? "none"
  const isSocketTestingLaunch =
    dependency_set !== "none" ? dependency_set?.replace("-", "_") : false
  const emulatorMetaData = {
    name: emulatorConfig?.name,
    description: emulatorConfig?.description,
    version: selectedCalcObject?.version,
    image: emulatorConfig?.image,
  }

  useEffect(() => {
    if (tryitMode === emulatorMode.lookup) {
      const storedData = localStorage.getItem("lookupHistory")
      const parsedData = JSON.parse(storedData) || []
      if (parsedData.length) {
        const emulatorWiseFilteredResult = parsedData
          .filter((data) => data.emulatorId === selectedIndex)
          .reverse()
        setResult(emulatorWiseFilteredResult)
      }
    }
  }, [tryitMode])

  useTryitModeEffect(
    postBody,
    tryitMode,
    setOutput,
    setPostBody1,
    setLoader,
    true,
    selectedID,
    isSocketTestingLaunch
  )

  useCalculatorSocketConnection(
    true,
    setPostBody1,
    isSocketTestingLaunch,
    setLoader,
    selectedID,
    selectedCalcObject,
    socketPostBody,
    setSocketMembers,
    setOutputLog
  )

  return (
    <>
      <LookupOptimizationMain
        name="lookup"
        OutputVariableCards={
          <files.CalculatorOutput
            outputResponse={outputResponse}
            inputResponse={postBody}
            loader={loader}
            setLoader={setLoader}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            isModalObject={isModalObject}
            setDisableKeys={setDisableKeys}
            disableKeys={disableKeys}
            activeEmulatorId={selectedID}
            consumer
            fullView={fullView}
            setFullView={setFullView}
            emulatorConfig={emulatorMetaData}
            channelInstance={isSocketTestingLaunch}
          />
        }
        isFullView={fullView === FULL_VIEW_OPEN_TEXT}
        inputCrads={
          <files.CalculatorInputCard
            activeEmulatorId={selectedID}
            calcConfig={selectedCalcObject?.package?.io}
            setPostBody1={setPostBody1}
            loader={loader}
            generateRandomInputs={generateRandomInputs}
            setGenerateRandomInputs={setGenerateRandomInputs}
            page="tryit"
            setAsVariable={false}
            consumer
            emulatorConfig={selectedCalcObject}
            setCurrentIndex={setCurrentIndex}
            setLoader={setLoader}
            setFullView={setFullView}
            channelInstance={isSocketTestingLaunch}
            socketMembers={socketMembers}
            setSocketPostBody={setSocketPostBody}
          />
        }
        mode={tryitMode}
        outputLog={outputLog}
      />
    </>
  )
}

export default TryitConsumer
