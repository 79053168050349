import React from "react"
import { useSearchParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { emulatorLauncheGroupDataState } from "../../../state/projectState"
import { Box, Divider, Grid, Typography } from "@mui/material"
import { formatStringWithChips } from "../../../state/services"

const LookupDefaultFilterNSort = () => {
  const [idSearchParams] = useSearchParams()

  const selectedIndex = idSearchParams.get("index")
  const selectedID = idSearchParams.get("launchid")

  const groupedData = useRecoilValue(emulatorLauncheGroupDataState)

  const selectedDataForTryit = groupedData[selectedIndex]
  const selectedCalcObject = selectedDataForTryit?.find(
    (obj) => obj.id === selectedID
  )
  const instructions = selectedCalcObject?.package?.instructions

  const formattedString = formatStringWithChips(instructions?.method)

  return instructions && Object.keys(instructions).length ? (
    <Grid container gap={"10px"}>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          fontWeight={500}
          style={{ fontSize: "24px" }}
          color={"primary"}
        >
          How It Works
        </Typography>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Divider />
      </Grid>
      <Grid container direction="column" gap={"8px"}>
        {formattedString && (
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {formattedString.map((str) => (
              <>
                {typeof str === "string" ? str : str}
                &nbsp;
              </>
            ))}
          </Box>
        )}
      </Grid>
    </Grid>
  ) : null
}

export default LookupDefaultFilterNSort
