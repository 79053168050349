import { useState, useEffect, useRef } from "react"
import * as m from "@mui/material"
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil"
import { useParams, useSearchParams } from "react-router-dom"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import * as files from "../../../GlobalFileContainer"
import {
  emulatorLauncheGroupDataState,
  getEmulatorData,
  getGraphResult,
  outputsParametersAndVariables,
  populateResultData,
  resultJobIdState,
  updateURLSearchParams,
} from "../../../state/projectState"
import {
  ALGO_STATUS,
  passFailedArray,
  FULL_VIEW_OPEN_TEXT,
} from "../../../state/StatusState"
import { optimizeUrl } from "../../../state/requests"
import useStyles from "../../card_style"
import { getButtonStyle } from "../../result-optimization/OptimizationStepper"
import RefreshButtonStyling from "../../shared components/RefreshStyling/RefreshButtonStyling"
import LookupOptimizationMain from "../LookupOptimizationMain"
import NoResultNote from "../NoResultNote"
import CapsuleProgressBar from "./CapsuleProgressBar"

const OptimizationConsumer = () => {
  const [idSearchParams] = useSearchParams()
  const { emulatorId } = useParams()
  const classes = useStyles()
  const buttonRef = useRef(null)
  const theme = m.useTheme()

  const selectedIndex = idSearchParams.get("index")
  const selectedID = idSearchParams.get("launchid")

  const groupedData = useRecoilValue(emulatorLauncheGroupDataState)
  const [selectedOutputs, setSelectedOutputs] = useRecoilState(
    outputsParametersAndVariables
  )
  const [graph, setGraph] = useRecoilState(getGraphResult)
  const [jobId, setJobId] = useRecoilState(resultJobIdState)
  const setPopulateResultDataState = useSetRecoilState(populateResultData)
  const emulatorConfig = useRecoilValue(getEmulatorData)

  const [postBody, setPostBody1] = useState({})
  const [loader, setLoader] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [disableKeys, setDisableKeys] = useState(false)
  const [fullView, setFullView] = useState(FULL_VIEW_OPEN_TEXT)
  const [generateRandomInputs, setGenerateRandomInputs] = useState(false)
  const [newRecord, setNewRecord] = useState(false)
  const [isPolling, setIspolling] = useState(false)
  const [timer, setTimer] = useState(10)
  const [progress, setProgress] = useState(0)
  const [optresults, setOptresults] = useState([])

  const selectedDataForTryit = groupedData[selectedIndex]
  const selectedCalcObject = selectedDataForTryit?.find(
    (obj) => obj.id === selectedID
  )
  const CalcPackage = selectedCalcObject?.package

  const inpParam = CalcPackage?.instructions?.parameters?.map((pp) => pp.label)

  const selectedParams = CalcPackage?.io?.InputVariables?.filter((inpVars) =>
    inpParam?.includes(inpVars?.ColumnLabel)
  )

  const emulatorMetaData = {
    name: emulatorConfig?.name,
    description: emulatorConfig?.description,
    version: selectedCalcObject?.version,
    image: emulatorConfig?.image,
  }

  const fetchData = async (postBody) => {
    setLoader(true)
    try {
      const response = await files.EmulationLab.post(optimizeUrl, postBody, {
        headers: { severity: SeverityLevel.Error },
      })

      if (typeof response.data === "string") {
        setJobId(response.data)
        setPostBody1({})
        getResults(response.data)
        const storedData =
          JSON.parse(localStorage.getItem("Consumer Optimize")) || []
        const index = storedData.findIndex(
          (item) => item.emulator_id === emulatorId
        )

        if (index !== -1) {
          storedData[index] = { ...postBody, jobId: response.data }
        } else {
          storedData.push({ ...postBody, jobId: response.data })
        }
        localStorage.setItem("Consumer Optimize", JSON.stringify(storedData))
      }
    } catch (err) {
      console.error("Error fetching data:", err)
    } finally {
      setLoader(false)
    }
  }

  const getResults = async (id) => {
    const resultUrl = `/calc/job/${id ?? jobId}/results2`
    try {
      const result = await files.EmulationLab.get(resultUrl)
      const results = result.data
      setGraph(results)
      const isComplete = passFailedArray?.includes(results?.status)
      const hasResult = results.ranking.length
        ? !selectedOutputs.bestResult ||
          selectedOutputs.bestResult === "Infinity" ||
          selectedOutputs.bestResult > results?.ranking[0]?.value
        : false
      if (hasResult && isPolling) {
        setSelectedOutputs((prev) => ({
          ...prev,
          bestResult: results.ranking[0].value,
        }))
        setNewRecord(true)
      }
      setIspolling(!isComplete)
      setTimer(isComplete ? false : 10)
    } catch (err) {
      console.log(err)
    }
  }

  const extractProgress = (str) => {
    const match = str.match(/(\d+)%/)
    return match ? parseInt(match[1], 10) : ""
  }

  useEffect(() => {
    if (graph && Object?.keys(graph)?.length > 0) {
      const updatedResults = graph?.ranking?.map((rank) => ({
        emulatorId,
        inputValues: Object.keys(rank?.inputs)?.map((rkey) => ({
          Label: rkey,
          Data: [rank?.inputs[rkey]?.VariableValue],
        })),
        outputValues: Object.keys(rank?.outputs)?.flatMap((rkey) => ({
          Ref: { Label: rkey },
          Data: [rank?.outputs[rkey]?.VariableValue],
        })),
        resultMode: "optimize",
        jobId: "",
        resultStatus: "complete",
      }))
      setOptresults(updatedResults)
    }
  }, [graph])

  useEffect(() => {
    if (isPolling) {
      const timerInterval = setInterval(() => {
        setTimer((prevTime) => (prevTime > 1 ? prevTime - 250 / 1000 : 10))
      }, 250)
      return () => clearInterval(timerInterval)
    }
  }, [isPolling])

  useEffect(() => {
    if (isPolling) {
      const percentComplete = ((10 - timer) * 100) / 10
      setProgress(percentComplete)
    } else setProgress(0)
  }, [timer])

  useEffect(() => {
    if (jobId) {
      const pollingID = setInterval(async () => {
        if (!graph || passFailedArray?.includes(graph?.status)) {
          clearInterval(pollingID)
          setTimer(false)
          return
        }
        await getResults()
      }, 10000)

      return () => {
        clearInterval(pollingID)
      }
    }
  }, [graph, jobId])

  useEffect(() => {
    const storedData = localStorage.getItem("Consumer Optimize")
    const parsedData = JSON.parse(storedData)
    if (parsedData?.length > 0) {
      const modifiedData = parsedData.find(
        (item) => item.emulator_id === emulatorId
      )
      if (modifiedData) {
        setSelectedOutputs(modifiedData?.instructions)
        setJobId(modifiedData?.jobId)
        const outputObject = modifiedData?.instructions?.parameters?.map(
          (params) => ({
            [params.label]: params.value?.toString(),
          })
        )
        updateURLSearchParams(outputObject)
        setPopulateResultDataState(true)
      }
    }
  }, [])

  useEffect(() => {
    if (Object.keys(postBody).length !== 0) {
      fetchData(postBody)
    }
  }, [postBody])

  const btnStyle = getButtonStyle(isPolling)

  const handleStopApiCalls = () => {
    setProgress(""), setIspolling(false)
    setTimer(0)
    setJobId("")
  }

  const disableInputs =
    (!passFailedArray?.includes(graph?.status) && graph?.status === "") ||
    isPolling

  return (
    <LookupOptimizationMain
      progressCard={
        <m.Grid
          item
          xs={12}
          sx={{
            border: "1px solid rgba(228,228,231,1)",
            borderRadius: "8px",
            height: "100%",
          }}
        >
          <files.ErrorBoundary>
            <m.Card>
              <m.CardContent className={classes.calcMainCard}>
                <m.Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <m.Grid container>
                    <m.Grid
                      container
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <m.Grid item>
                        <m.Typography
                          fontWeight={500}
                          style={{ fontSize: "24px" }}
                          color={"primary"}
                        >
                          Progress
                        </m.Typography>
                      </m.Grid>
                      {jobId && (
                        <RefreshButtonStyling
                          progress={progress}
                          strokeDasharray={
                            buttonRef?.current?.getBoundingClientRect()?.width +
                              340 || 350
                          }
                          height={
                            buttonRef?.current?.getBoundingClientRect()
                              ?.height + 16 || 40
                          }
                          width={
                            buttonRef?.current?.getBoundingClientRect()?.width +
                              16 || 200
                          }
                          isPolling={isPolling}
                        >
                          <m.Button
                            ref={buttonRef}
                            variant="contained"
                            className={
                              isPolling && (timer < 2 || timer === 30)
                                ? "pulse"
                                : ""
                            }
                            style={
                              passFailedArray?.includes(graph?.status) ||
                              graph?.status === "incomplete"
                                ? { pointerEvents: "none" }
                                : undefined
                            }
                            sx={btnStyle}
                            onClick={() => getResults()}
                            title={graph?.status_message}
                          >
                            <m.Typography
                              variant="caption"
                              color={theme.palette.common.white}
                            >
                              {passFailedArray?.includes(graph?.status)
                                ? `Optimization Job ${graph?.status}`
                                : `Refreshing (${
                                    graph?.status?.includes("%")
                                      ? graph?.status + "complete"
                                      : graph?.status
                                  })`}
                            </m.Typography>
                          </m.Button>
                        </RefreshButtonStyling>
                      )}
                    </m.Grid>{" "}
                    <m.Grid item xs={12}>
                      <m.Divider sx={{ mb: 1 }} />
                    </m.Grid>
                    {jobId ? (
                      <files.ScatterGraph
                        newRecord={newRecord}
                        activeStep={4}
                        curated
                      />
                    ) : (
                      <NoResultNote title={"Progress"} />
                    )}
                  </m.Grid>
                </m.Stack>
              </m.CardContent>
            </m.Card>
          </files.ErrorBoundary>
        </m.Grid>
      }
      isFullView={fullView === FULL_VIEW_OPEN_TEXT}
      name="optimization"
      OutputVariableCards={
        <files.CalculatorOutput
          inputResponse={postBody}
          loader={loader}
          setLoader={setLoader}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          setDisableKeys={setDisableKeys}
          disableKeys={disableKeys}
          activeEmulatorId={selectedID}
          consumer
          fullView={fullView}
          optresults={optresults}
          setFullView={setFullView}
          emulatorConfig={emulatorMetaData}
          resultComponent={
            graph?.status !== ALGO_STATUS.complete && (
              <m.Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <m.Typography variant="body2">
                  Optimization Progress :
                </m.Typography>
                <m.Grid>
                  <CapsuleProgressBar
                    value={extractProgress(graph?.status_message ?? "")}
                  />
                </m.Grid>
                <m.Button
                  disabled
                  // disabled={
                  //   passFailedArray?.includes(graph?.status) || !isPolling
                  // }
                  variant="contained"
                  color="warning"
                  sx={{
                    borderRadius: "8px",
                    height: "30px",
                    textTransform: "none",
                    color: "white",
                  }}
                  onClick={handleStopApiCalls}
                >
                  Stop
                </m.Button>
              </m.Grid>
            )
          }
        />
      }
      inputCrads={
        <files.CalculatorInputCard
          activeEmulatorId={emulatorId}
          calcConfig={{
            InputVariables: selectedParams,
            OutputVariables: CalcPackage?.io?.OutputVariables,
          }}
          setPostBody1={setPostBody1}
          loader={loader}
          page="optimize"
          setAsVariable={false}
          consumer
          emulatorConfig={selectedCalcObject}
          setCurrentIndex={setCurrentIndex}
          setLoader={setLoader}
          instructions={CalcPackage?.instructions}
          setGenerateRandomInputs={setGenerateRandomInputs}
          generateRandomInputs={generateRandomInputs}
          disableInputs={disableInputs}
        />
      }
    />
  )
}

export default OptimizationConsumer
