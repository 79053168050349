import { Chip } from "@mui/material"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CircleIcon from "@mui/icons-material/Circle"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import excelImages from "../assets/Agent Health Icons - v1/Excel"
import ghImages from "../assets/Agent Health Icons - v1/Grasshopper"
import pythonImages from "../assets/Agent Health Icons - v1/Python"
import mathcadImages from "../assets/Agent Health Icons - v1/Mathcad"
import optImages from "../assets/Agent Health Icons - v1/Optimization"
import allImage from "../assets/Agent Health Icons - v1/All"
import * as result from "../assets/Results/Utils"
import {
  emulatorSoftwareType,
  RUN_TYPES,
  subStatus,
  testRule,
} from "./StatusState"
import EmulationLab from "./axios"
import { estimateUrl, fetchSearchBlobAPI } from "./requests"
import { ReactComponent as CalcIcon } from "../assets/newIcons/calculator.svg"
import { ReactComponent as EmuIcon } from "../assets/newIcons/Virus-Lab-Research-Test-Tube--Streamline-Ultimate.svg"
import { ReactComponent as LookupIcon } from "../assets/newIcons/search.svg"
import { ReactComponent as Optimize } from "../assets/newIcons/optimise.svg"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"

export const getAllMatrixUrl = (id, i, j) => {
  return `/emulators/${id}/data/charts/scatterplot-matrix/${i}/${j}?folder=explore_matrix`
}

export const sourceType = (emulatorConfig) => {
  return emulatorConfig?.source?.type
}

export const sourceRef = (emulatorConfig) => {
  return emulatorConfig?.source?.ref
}

export const sourceSupRef = (emulatorConfig) => {
  return emulatorConfig?.source?.supporting_refs
}

export const sourceSoftware = (emulatorConfig) => {
  return emulatorConfig?.source?.software
}

export const sourceMethod = (emulatorConfig) => {
  return emulatorConfig?.source?.method
}

export const latestVersion = (emulatorConfig) => {
  return emulatorConfig?.versions?.latest_stable
}

export const betaVersion = (emulatorConfig) => {
  return emulatorConfig?.versions?.beta
}

export const areObjectsEqual = (obj1, obj2) => {
  //compare two json objects, stringify
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export const isDataValid = (postBody) => {
  //all values in calculator input fields are filled
  //with valid input is checked(" ", space not valid)
  return postBody?.FeaturesData?.every((item) => {
    return item.Data.every((value) => value.trim() !== "")
  })
}

export const allValuesFilled = (checkingRangeWarning) => {
  //all values are within range or not is checked here
  return checkingRangeWarning.every((value) => value === false)
}

export const getEmulatorModes = (modes, caps) => {
  //different structures of modes array in cards api for
  //contributor and consumer is handled in this function
  const result =
    modes?.map((m) => {
      if (typeof m === "object" && m !== null && !Array.isArray(m)) {
        const firstKey = Object.keys(m)[0]
        return caps
          ? firstKey?.charAt(0)?.toUpperCase() + firstKey.slice(1)
          : firstKey
      }

      if (typeof m === "string") {
        return caps ? m?.charAt(0)?.toUpperCase() + m.slice(1) : m
      }

      return ""
    }) || []

  return result
}

const getIconForStatus = (software, subType, status, size, title) => {
  //helper function for pickStatusWithSoftwareIcons()
  const statusPrefix = status.substring(0, 2)
  const result = subType + statusPrefix

  let imgSrc = ""
  switch (status) {
    case "none":
    case "idle":
    case "off":
    case "busy":
    case "broken":
      imgSrc = software[result]
      break
    default:
      break
  }

  return imgSrc ? (
    <img
      className="object-scale-down"
      title={title}
      width={size}
      src={imgSrc}
    />
  ) : (
    title
  )
}

export const pickStatusWithSoftwareIcons = (
  key,
  subType,
  status,
  size,
  title
) => {
  //used to return icon that will show current agent
  //status(off, idle...) with software type(excel, mathcad...)
  let images
  let imageIndex = null

  switch (key) {
    case "all":
      images = allImage
      switch (subType) {
        case "none":
          imageIndex = 0
          break
      }
      break
    case "excel":
      images = excelImages
      switch (subType) {
        case "none":
          imageIndex = 0
          break
        case "excel_wpm":
          imageIndex = 1
          break
        case "null":
          imageIndex = 2
          break
        case "deprecated":
          imageIndex = 3
          break
        default:
          break
      }
      break
    case "python":
      images = pythonImages
      switch (subType) {
        case "none":
          imageIndex = 0
          break
        case "python_data":
          imageIndex = 1
          break
        case "null":
          imageIndex = 2
          break
        case "deprecated":
          imageIndex = 3
          break
        default:
          break
      }
      break
    case "grasshopper":
      images = ghImages
      switch (subType) {
        case "none":
          imageIndex = 0
          break
        case "gh_eng":
          imageIndex = 1
          break
        case "null":
          imageIndex = 2
          break
        case "deprecated":
          imageIndex = 3
          break
        default:
          break
      }
      break
    case "mathcad":
      images = mathcadImages
      switch (subType) {
        case "none":
          imageIndex = 0
          break
        case "null":
          imageIndex = 2
          break
        default:
          break
      }
      break
    case "optimization":
      images = optImages
      switch (subType) {
        case "none":
          imageIndex = 0
          break
        case "null":
          imageIndex = 2
          break
        default:
          break
      }
      break
    default:
      return `${key}: ${subType}`
  }

  return imageIndex === null
    ? `${key}: ${subType}`
    : getIconForStatus(images, imageIndex, status, size, title)
}

export const seperateSoftwareAndSubType = (software) => {
  //extracting different values from incoming string for
  //agent dependency- "excel: none (off: unknown_status_message)"
  const includesColon = software?.includes(":")
  const includesStatus = software?.includes("(")

  if (!includesColon) {
    return [software, "", ""]
  }

  const [extractedSoftware, extractedStatus] = includesStatus
    ? [String(software.split(" (")[0]), String(software.split(" (")[1])]
    : [software, "none"]
  const softwareType = includesColon
    ? String(extractedSoftware.split(":")[0])
    : extractedSoftware
  const softwareSubType =
    (!includesColon
      ? false
      : String(extractedSoftware.split(":")[1]?.replace(" ", ""))) ?? false
  const extractStatus = String(extractedStatus?.split(":")[0]) ?? "none"
  const softwareStatus = extractStatus === "?" ? "none" : extractStatus

  return [softwareType, softwareSubType, softwareStatus]
}

export const returnAgentStatusIcon = (status, theme, classes, size) => {
  //this function handles descriptive icon that shows current agent status
  const iconProps = {
    fontSize: size ?? "medium",
    titleAccess: status,
  }

  switch (String(status)) {
    case "ERROR: status missing":
      return <CancelIcon {...iconProps} className={classes.colorDangerIcon} />
    case "None":
      return ""
    case "busy":
      return (
        <RemoveCircleIcon {...iconProps} className={classes.colorSecondary} />
      )
    case "off":
      return <CircleIcon {...iconProps} className={classes.colorGraySec} />
    case "idle":
      return (
        <CheckCircleIcon
          {...iconProps}
          sx={{ color: theme.palette.status.success }}
        />
      )
    case "broken":
      return <ErrorIcon {...iconProps} className={classes.colorDangerIcon} />
    default:
      return
  }
}

export const handleContextCopy = (value, setAlertState, id) => {
  //copy given 'value' to clipboard
  const jobId = value
  navigator.clipboard
    .writeText(jobId)
    .then(() => {
      setAlertState({
        boolState: true,
        message: `${id} copied to clipboard!`,
        severityState: "info",
      })
    })
    .catch((error) => {
      console.error(`Failed to copy ${id} to clipboard:`, error)
    })
}

export const convertBytesToHighestMeasurement = (bytes) => {
  //depending on the file size on upload files this function converts
  //large numbers to specific file size eg- bytes=150000 return 0.15MB
  const sizes = {
    Bytes: 10,
    KB: 1000,
    MB: 1000000,
    GB: 1000000000,
    TB: 1000000000000,
  }

  const roundNumber = (value) => {
    return Math.round((value + Number.EPSILON) * 100) / 100
  }

  if (bytes === 0) return "0 Byte"

  if (bytes < 10) return `${bytes} B`

  if (Math.floor(bytes / sizes.KB) < 100) {
    const val = bytes / sizes.KB
    return `${roundNumber(val)} ${Object.keys(sizes)[1]}`
  } else if (Math.floor(bytes / sizes.MB) < 100) {
    const val = bytes / sizes.MB
    return `${roundNumber(val)} ${Object.keys(sizes)[2]}`
  } else if (Math.floor(bytes / sizes.GB) < 100) {
    const val = bytes / sizes.GB
    return `${roundNumber(val)} ${Object.keys(sizes)[3]}`
  } else {
    const val = bytes / sizes.TB
    return `${roundNumber(val)} ${Object.keys(sizes)[4]}`
  }
}

export const formatStringWithChips = (str) => {
  //this function formats thedescription string on curated lookup
  //add chips with specific color and data.
  if (!str) {
    return ""
  }

  const chipStyles = {
    chip_mode: "primary",
    chip_label: "secondary",
    chip_operator: "primary",
  }

  const regex = /{(chip_\w+):([^}]+)}/g
  const parts = []
  let lastIndex = 0
  let match

  while ((match = regex.exec(str)) !== null) {
    if (match.index > lastIndex) {
      parts.push(str.slice(lastIndex, match.index))
    }

    const chipType = match[1]
    const chipValue = match[2]

    parts.push(
      <Chip
        key={`${chipType}-${chipValue}`}
        label={chipValue}
        variant={chipType === "chip_value" ? "outlined" : "default"}
        color={chipStyles[chipType] ?? "default"}
      />
    )

    lastIndex = match.index + match[0].length
  }
  if (lastIndex < str.length) {
    parts.push(str.slice(lastIndex))
  }

  return parts
}

export const getDisableState = (name, modes) => {
  //sidebar menu has hover text if the option is
  //disabled those messages are handled here
  switch (name) {
    case "Emulator Members":
      return "You must become a collaborator to view this page."
    case "Explore Data":
      return "Upload data files to view this page."
    case "Generate Data":
      return "Emulator should be ready for calculations to generate data."
    case "ML Evaluation":
      return "Upload data files to view this page."
    case "Emulator File Manager":
      return "Only admins can access this page."
    case "Calculator": {
      if (modes.includes(RUN_TYPES[2])) {
        return "Data files must be uploaded and ML training should be complete to use calculator."
      } else if (modes.includes(RUN_TYPES[0])) {
        return "Config and active source ref must be provided to use calculator."
      } else {
        return ""
      }
    }
    case "Calculator Tests":
      return "Calculate mode must be selected and active source file should be defined to try tests."
    case "Optimization":
      return "Calculator should be ready for calculations to try optimize."

    default:
      return ""
  }
}

function countZerosAfterDecimal(value) {
  //helper function for roundValues()
  const stringValue = value.toString()
  let countZeros = 0
  let foundDecimalPoint = false

  for (let i = 0; i < stringValue.length; i++) {
    const currentChar = stringValue[i]

    if (foundDecimalPoint) {
      if (currentChar === "0") {
        countZeros++
      } else {
        break
      }
    }

    if (currentChar === ".") {
      foundDecimalPoint = true
    }
  }

  return countZeros <= 1 ? 2 : countZeros + 1
}

export const roundValues = (value) => {
  //removes more than two decimal values without
  //rounding off eg-20.6893645 will be returned 20.68
  const parsedNumber = parseFloat(value)
  if (!isNaN(parsedNumber)) {
    const stringValue = value.toString()
    const hasZeros = /\.00+\d*$/.test(stringValue)
    const countZeros = hasZeros ? countZerosAfterDecimal(value) : 2
    return Number(parsedNumber.toFixed(countZeros))
  } else return value
}

export const getTransformedData = (chartData) => {
  //to start the optimization result chart from x-axis 0th index
  //we had to transform data
  const transformedData =
    chartData &&
    chartData?.map((current, index, array) => {
      const outputObj = Object.keys(current).reduce(
        (result, key, index, keys) => {
          const currentValue = current[key]
          const previousKey = keys[index - 1]

          if (index > 0) {
            result[key] = currentValue - current[previousKey]
          } else {
            result[key] = currentValue
          }

          return result
        },
        {}
      )

      return outputObj
    })

  return transformedData ?? []
}

export const optimisationObject = (id, outputObject, isSameEmulator) => {
  //create and return the optimization body with required values
  const updatedObject = {
    emulator_id: id,
    instructions: {
      solver: outputObject?.solver,
      mode: outputObject?.mode,
      max_time: outputObject?.max_time,
      max_iteration: outputObject?.max_iteration,
      objectives: outputObject?.objectives,
      parameters: outputObject?.parameters || [],
      variables: outputObject?.variables || [],
      constraints: isSameEmulator
        ? [
            {
              label: "out FS Overturning",
              mode: "greater_than_or_equal",
              value: "1.5",
            },
            {
              label: "out FS Sliding",
              mode: "greater_than_or_equal",
              value: "1.5",
            },
            {
              label: "out e",
              mode: "less_than_or_equal",
              reference: "L/6 (TODO: add)",
            },
            {
              label: "out qtoe",
              mode: "less_than_or_equal",
              reference: "in Allowable Bearing Pressure",
            },
            {
              label: "out qheel",
              mode: "greater_than_or_equal",
              value: "0",
            },
          ]
        : outputObject?.constraints || [],
    },
  }
  return updatedObject
}

export const compareLengths = (selectedOutputs, emulatorConfig) => {
  //on the basis of boolean returned by this function,
  //we will handle calls to best api in optimization
  let variableLength = selectedOutputs?.variables.length
  let parameterLength = selectedOutputs?.parameters.length
  let configLength = emulatorConfig?.calculator?.InputVariables.length
  let boolValue = variableLength + parameterLength !== configLength
  return boolValue
}

export const evaluateTestRuleTrueOrFalse = async (
  rule,
  expectedData,
  minValue,
  maxValue,
  tolerance,
  actualData
) => {
  //user adds and runs new test the results are calculated by this function
  //it returns pass/fail as result, helper function for evaluateTestRule()
  switch (rule) {
    case testRule.between:
      return result.areValuesInBetween(minValue, maxValue, actualData)
    case testRule.greater:
      return result.isValueMoreThan(expectedData, actualData)
    case testRule.equal:
      return result.areValuesEqualWithTolerancePercent(
        expectedData,
        tolerance,
        actualData
      )
    case testRule.lesser:
      return result.isValueLess(expectedData, actualData)
    case testRule.equalTto:
      return result.isValueExactMatch(expectedData, actualData)
    case testRule.contains:
      return result.stringContains(expectedData, actualData)
    case testRule.greaterOrEqual:
      return result.isValueMoreOrEqual(expectedData, actualData)
    case testRule.lesserOrEqual:
      return result.isValueLessOrEqual(expectedData, actualData)
    case testRule.notEqual:
      return result.isValueNotEqual(expectedData, actualData)
    case testRule.inSet:
      return result.isValueInSet(expectedData, actualData)
    default:
      return ""
  }
}

export const evaluateTestRule = async (
  rule,
  expectedData,
  minValue,
  maxValue,
  tolerance,
  actualData
) => {
  //main functions that returns the calculated output
  const status = evaluateTestRuleTrueOrFalse(
    rule,
    expectedData,
    minValue,
    maxValue,
    tolerance,
    actualData
  )
  return status
    .then((res) => (res ? "pass" : "fail"))
    .catch(() => alert("Some error occurred in evaluating test."))
}

export const getFilteredSubStatusConfig = (modes) => {
  //based on the modes of the emulator the status map on
  //summary gets its filtered options through this function
  const filteredSubStatus = subStatus.filter((status) =>
    modes.some((value) => {
      let hasMode = status?.mode?.includes(value)
      if (status.title !== "Data Reference") {
        if (status.title === "Data Synthesis") {
          return modes.includes("calculate") && hasMode
        } else return hasMode
      }
    })
  )
  return filteredSubStatus
}

export const getParamString = (emulatorCalcInputConfig, mode) => {
  //serach param string for input variables gets created here
  const paramString = mode
    ? emulatorCalcInputConfig
        ?.map((input) => {
          const value =
            typeof input?.value === "string"
              ? input.value.split(" ")[0]
              : input?.value
          const label = input.label.includes("in ")
            ? input.label?.replace("in ", "")
            : input.label

          return `${label}=${value}`
        })
        .join("&")
    : emulatorCalcInputConfig
        ?.map((input) => {
          return `${
            input.ColumnLabel.includes("in ")
              ? input.ColumnLabel?.replace("in ", "")
              : input.ColumnLabel
          }=`
        })
        .join("&")

  return paramString
}

export const startPollingData = (
  fetched,
  CallData_polling,
  interval,
  setPollingIntervalId,
  setTime
) => {
  //polling api for ML evaluation
  if (fetched) {
    const id = setInterval(() => CallData_polling(), 11000)
    interval.push(id)
    setPollingIntervalId(id)
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 10))
    }, 1000)
    return () => clearInterval(timer)
  } else {
    interval.forEach((id) => {
      clearInterval(id)
    })
  }
}

export const paginationOptions = (number) => {
  //tabulator table pagination options
  const pages = {
    pagination: "local",
    paginationSize: number,
    paginationSizeSelector: [number, 25, 50, 100, true],
  }
  return pages
}

export const checkAuthorization = (
  emulator,
  mongoProfile,
  noOfReleases,
  renderIconButton
) => {
  //for non-members the calc, emulate icons on cards
  //and summary should have warning icon to use with caution
  //hence, "styled" button
  const emulatorTeam = emulator?.team
  const isMember =
    emulatorTeam?.director.name === mongoProfile.name ||
    emulatorTeam?.members.some((member) => member.name === mongoProfile.name)

  if (noOfReleases === 0) {
    return isMember ? renderIconButton("styled") : null
  } else {
    return <>{renderIconButton("none")}</>
  }
}

export const formatTime = (timeString) => {
  //convert hh:mm:ss time in hours mins secs format
  let [hours, minutes, seconds] = (timeString ?? "").split(":").map(parseFloat)

  seconds = Math.max(1, seconds)
  minutes += Math.floor(seconds / 60)
  hours += Math.floor(minutes / 60)

  if (hours >= 1) {
    return `${Math.round(hours)}hour${hours >= 2 ? "s" : ""}`
  } else if (minutes >= 1) {
    return `${Math.round(minutes)}min${minutes >= 2 ? "s" : ""}`
  } else {
    return `${Math.round(seconds)}sec${seconds >= 2 ? "s" : ""}`
  }
}

export const findEstimatedTime = async (data, setEstimateTime, time) => {
  //get estimated time before begining calcs on tryit/generate data page
  try {
    const res = await EmulationLab.post(estimateUrl, data)
    const inputTime =
      res &&
      res.data[time ? "expected compute time" : "expected roundtrip time"]
    const formattedTime =
      inputTime === "infinity" ? inputTime : formatTime(inputTime)

    formattedTime &&
      setEstimateTime({
        agentCount: res.data.active_agent_count,
        wait: formattedTime,
      })
  } catch (error) {
    console.error(error)
  }
}

export const convertToUppercase = (data, multiple) => {
  //convert first letters or whole string to uppercase
  //multiple true eg-"run calc" => "RUN CALC"
  //multiple false eg-"run calc" => "Run Calc"
  const convertedString =
    data &&
    (multiple
      ? data
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : data?.charAt(0)?.toUpperCase() + data?.slice(1))
  return convertedString
}

export const getOperator = (operator) => {
  //returns mathematical operator on receiving string input. eg- "input-greater_than, return-'>'"
  switch (operator) {
    case testRule.equalTto:
      return "="
    case testRule.greater:
      return ">"
    case testRule.greaterOrEqual:
      return ">="
    case testRule.lesser:
      return "<"
    case testRule.lesserOrEqual:
      return "<="
    case testRule.notEqual:
      return "!="
    case testRule.inSet:
      return "in"
  }
}

export const getAllImages = async (emulatorId, setImages) => {
  //get all images uploaded by user for the emulator
  const getImages = `/emulators/${emulatorId}/images`
  try {
    const result = await EmulationLab.get(getImages)
    const coludinaryImage = result.data
    const newImages = coludinaryImage?.resources.map((image) => ({
      image: image.url,
      id: image.public_id,
      name: image.filename,
      height: image.height,
      width: image.width,
    }))
    setImages(newImages)
  } catch (error) {
    alert("Eror in Fetching images, please try again..")
  }
}

export const isModalObjectBoolean = (result) =>
  //return true if the data inside calc result "Data" is an
  //object(that means it is a visualization variable output for 3D modal)
  result?.some((ress) =>
    ress?.outputValues?.some((outp) => {
      try {
        return typeof JSON.parse(outp.Data) === "object"
      } catch {
        return false
      }
    })
  )

export const convertOutputVal = (res, io) => {
  //for lookup we convert the API response array to array with name,
  //value pairs so that our outputCard component can process data
  const inputoutputVars = [...io.InputVariables, ...io.OutputVariables]
  return res.length > 0
    ? res.map((obj) => {
        const result = Object.entries(obj)
          .map(([key, value]) => ({
            name: key,
            value: value,
          }))
          .filter((var1) =>
            inputoutputVars.some((var2) => var2?.ColumnLabel === var1.name)
          )

        return { Result: result }
      })
    : [
        {
          Result: [
            {
              name: "Result-",
              value: "No output for these input values",
            },
          ],
        },
      ]
}

export const convertConstraints = async (constraints, limit) => {
  //for lookup we convert out input data in calc input default format,
  //to make this data compatible for outputCard to process and show
  if (limit) {
    return constraints.map((constraint) => {
      return {
        Label: constraint.label,
        Data: [
          `${getOperator(constraint.mode ?? constraint.operator)} ${
            constraint.value
          }`,
        ],
      }
    })
  } else {
    return constraints.map((constraint) => {
      return {
        label: constraint.label,
        operator: constraint.mode,
        value: constraint.value,
      }
    })
  }
}

export const saveLookupSession = (outputbody) => {
  //save lookup result in local storage
  const storedData = localStorage.getItem("lookupHistory")
  const parsedData = JSON.parse(storedData) || []
  try {
    if (Array.isArray(parsedData)) {
      parsedData.push(outputbody)
      localStorage.setItem("lookupHistory", JSON.stringify(parsedData))
    }
  } catch (error) {
    console.error("Error parsing or updating existing data:", error)
  }
}

export const modeValidationCombo = (emulator, modes) => {
  return emulator?.validations
    ? modes?.reduce((acc, curr, index) => {
        acc[curr] =
          emulator?.validations[index] &&
          emulator?.validations[index]?.length !== 0
            ? emulator?.validations[index][0]
            : null
        return acc
      }, {})
    : {}
}

export const getModeIcon = (mode, color, width) => {
  const iconHeight = {
    height: width < 680 ? "20px" : "24px",
    width: width < 680 ? "20px" : "24px",
  }

  const commonStyle = { ...iconHeight, stroke: color }

  const icons = {
    calculate: <CalcIcon title={mode} style={commonStyle} />,
    emulate: <EmuIcon title={mode} style={commonStyle} />,
    lookup: <LookupIcon title={mode} style={commonStyle} />,
    optimization: <Optimize title={mode} style={commonStyle} />,
    optimize: <Optimize title={mode} style={commonStyle} />,
  }

  return icons[mode] || null
}

export const isLaunchPageOpen = (location) => {
  return location.pathname.includes("new")
}

export const handleExportData = async (querySelectorId, emulatorConfig) => {
  try {
    // capture screenshot
    const canvas = await html2canvas(document.querySelector(querySelectorId), {
      scale: 2,
      ignoreElements: (element) => {
        return element.id === "excludeElement"
      },
    })

    //create jspdf instance
    const pdf = new jsPDF("p", "mm", "a4", true)

    //page props
    const pageWidth = 210
    const pageHeight = 297
    const marginLeft = 10
    const marginTop = 15

    // calculate image dimensions
    const imgWidth = pageWidth - 2 * marginLeft
    const imgHeight = (canvas.height * imgWidth) / canvas.width

    // image height
    let heightLeft = imgHeight
    //initial position
    let position = marginTop

    const convertImageToBase64 = (imageUrl) => {
      return new Promise((resolve, reject) => {
        // Create a new image element
        const img = new Image()

        // Handle CORS for cross-origin images
        img.crossOrigin = "anonymous"

        // On successful image load
        img.onload = function () {
          try {
            // Create a canvas element
            const canvas = document.createElement("canvas")
            canvas.width = img.width
            canvas.height = img.height

            // Draw the image onto the canvas
            const ctx = canvas.getContext("2d")
            ctx.drawImage(img, 0, 0)

            // Convert canvas to Base64 data URL
            const base64DataUrl = canvas.toDataURL("image/png")
            resolve(base64DataUrl)
          } catch (error) {
            reject(error)
          }
        }

        // On error during image load
        img.onerror = function () {
          img.src =
            "https://tse1.mm.bing.net/th?id=OIP.kEKWG9WO-kIzLXqm6_khxgHaFS&pid=Api&P=0&h=180"
        }

        // Set the image source to trigger the loading process
        img.src = imageUrl
      })
    }

    //add image in base64 format
    if (emulatorConfig?.image) {
      await convertImageToBase64(emulatorConfig?.image)
        .then((base64Data) => {
          pdf.addImage(base64Data, "PNG", 24, 13, 25, 25)
        })
        .catch((error) => {
          console.error("Error:", error)
        })
    }

    //system date
    const date = new Date()
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })

    const pdfTextData = [
      { type: "heading", text: `Result History(${formattedDate})`, y: 10 },
      { type: "desc", text: `Version: ${emulatorConfig?.version}`, y: 18 },
      { type: "desc", text: `Name: ${emulatorConfig?.name}`, y: 23 },
      {
        type: "desc",
        text: `Description: ${emulatorConfig?.description}`,
        y: 28,
      },
    ]

    //add text to pdf
    pdfTextData.map((textdata) => {
      if (textdata.type === "heading") {
        //title text props
        pdf.setFont("helvetica", "bold")
        pdf.setFontSize(15)

        pdf.text(textdata.text, 15, textdata.y)
      } else {
        //description text
        pdf.setFont("helvetica", "normal")
        pdf.setFontSize(9)

        pdf.text(textdata.text, pageWidth / 4, textdata.y, {
          maxWidth: imgWidth - pageWidth / 4,
        })
      }
    })

    // calculate the number of pages needed based on image height
    let pageNumber = 0
    while (heightLeft > 0) {
      position = pageNumber === 0 ? marginTop + 25 : marginTop
      pageNumber++

      const canvasSlice = document.createElement("canvas")
      const sliceHeight = Math.min(
        canvas.height,
        (canvas.width * pageHeight) / pageWidth
      )
      canvasSlice.width = canvas.width
      canvasSlice.height = sliceHeight

      const ctx = canvasSlice.getContext("2d")
      ctx.drawImage(canvas, 0, -(pageNumber - 1) * sliceHeight)

      const slicedImgData = canvasSlice.toDataURL("image/png")
      const sliceHeightInMM = (sliceHeight * imgWidth) / canvas.width
      pdf.addImage(
        slicedImgData,
        "PNG",
        marginLeft,
        position,
        imgWidth,
        sliceHeightInMM,
        "",
        "FAST"
      )

      heightLeft -= sliceHeightInMM
      if (heightLeft > 0) pdf.addPage()
    }

    // Trigger file download
    pdf.save("ResultHistory.pdf")
  } catch (error) {
    console.error("Error generating PDF:", error)
  }
}

export const generateLookupResultFormat = (data, mode) => {
  return {
    emulatorId: data?.emulator_id,
    jobId: "",
    inputValues: data?.inputs?.map((input) => ({
      Label: input.label,
      Data: [`${getOperator(input.operator)} ${input.value}`],
    })),
    outputValues: data?.outputs?.map((output) => ({
      Result: Object.keys(output)?.map((otpKey) => ({
        name: otpKey,
        value: output[otpKey],
      })),
    })),
    resultMode: mode,
    resultVersion: "0.0.0",
    resultStatus: "complete",
    resultType: "bot",
  }
}
export const isContributorEmulatorPageOpen = (location) => {
  return location.pathname.includes("em")
}

export const generatePostBody = (calculatorId, features, consumer) => {
  //generates postbody structure based on user mode- consumer, contributor
  const postBody = consumer
    ? {
        launch_id: calculatorId,
        FeaturesData: features,
      }
    : {
        emulator_id: calculatorId,
        FeaturesData: features,
      }
  return postBody
}

export const blobData = async (setSearchBlob) => {
  try {
    const request = await EmulationLab.get(fetchSearchBlobAPI)
    setSearchBlob(request.data)
  } catch (error) {
    console.error(error)
  }
}

export const extractRequiredFields = (array, dependency) => {
  //extract required fields from calculator input/output variables list
  return array.map((item) =>
    dependency?.includes(emulatorSoftwareType.excel)
      ? {
          ColumnLabel: item.ColumnLabel,
          Worksheet: item.Worksheet,
          Cell: item.Cell,
        }
      : dependency?.includes(emulatorSoftwareType.python)
      ? {
          ColumnLabel: item.ColumnLabel,
          PythonVariableName: item.PythonVariableName,
        }
      : {
          ColumnLabel: item.ColumnLabel,
          ParamName: item.ParamName,
        }
  )
}

export const isUserTeamMember = (mongoProfile, cardData) => {
  const userEmail = localStorage.getItem("email")

  return (
    mongoProfile?.useModes?.includes("admin") ||
    cardData?.team?.director.email === userEmail ||
    cardData?.team?.members.some((teamMember) => teamMember.email === userEmail)
  )
}

export const checkStatus = (array) => {
  const isEmpty = (arr) => !arr?.length || arr.every((item) => item === "")

  const features = array?.FeaturesData ?? []
  if (!features.length) return "Inputs"

  const allEmpty = features.every((item) => isEmpty(item.Data))
  const allFilled = features.every((item) => !isEmpty(item.Data))

  return allEmpty || allFilled
}
