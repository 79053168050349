import { Suspense } from "react"
import { useRecoilValue } from "recoil"
import * as core from "@material-ui/core"
import * as files from "../../GlobalFileContainer"
import { projectDatasetState } from "../../state/projectState"
import { cleanReturnJson } from "../../state/axios"

const ExploreParallel = () => <TableCard />

const TableCard = () => {
  const data = cleanReturnJson(useRecoilValue(projectDatasetState))

  return (
    <Suspense fallback={<files.Loader />}>
      <files.EmulatorBreadCrumbs msg={"Explore/parallel"}>
        <files.PageHelper info={files.pageHelpers.exploreTable} />
      </files.EmulatorBreadCrumbs>
      <core.TableContainer component={core.Paper}>
        <files.ParallelCoordinatesNivo data={data} />
      </core.TableContainer>
    </Suspense>
  )
}

export default ExploreParallel
