import { Box } from "@material-ui/core"
import { Avatar, Typography, useTheme } from "@mui/material"

const StepperStyleCreateEm = ({
  activeStep,
  isCreateEmFormOpen,
  detail,
  title,
}) => {
  const theme = useTheme()
  return (
    <Box display="flex" alignItems="flex-start" p={2}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Avatar
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.common.black,
            width: 32,
            height: 32,
            fontSize: "1rem",
          }}
        >
          {activeStep ?? ""}
        </Avatar>
        <Box
          sx={{
            width: "2px",
            height: isCreateEmFormOpen ? "350px" : "30px",
            backgroundColor: theme.palette.grey.light,
          }}
        />
      </Box>
      <Box mb={2}>
        <Box ml={2} mb={1}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {detail}
        </Typography>
      </Box>
    </Box>
  )
}

export default StepperStyleCreateEm
