import { useNavigate, useParams } from "react-router-dom"
import { Box, Typography } from "@mui/material"
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined"

const MemberRoleButton = () => {
  const { emulatorId } = useParams()
  const navigate = useNavigate()

  const handleMembersRoute = () => {
    navigate(`/new/${emulatorId}/teams`)
  }

  return (
    <Box
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        display: "flex",
        padding: "8px 24px",
        borderRadius: "6.75px",
        gap: 1,
        backgroundColor: "rgba(244,244,245,1)",
        cursor: "pointer",
      }}
      onClick={handleMembersRoute}
    >
      <PeopleOutlinedIcon fontSize="small" />
      <Typography fontWeight={500} style={{ fontSize: "14px" }}>
        Members
      </Typography>
    </Box>
  )
}

export default MemberRoleButton
