import React from "react"
import * as m from "@mui/material"
import * as files from "../../../GlobalFileContainer"
import { useRecoilValue } from "recoil"
import { openBotState, sidebarWidth } from "../../../state/projectState"

const EmulatorPagesLayout = ({ children }) => {
  const theme = m.useTheme()
  const { width } = files.useWindowDimensions()
  const large = useRecoilValue(sidebarWidth)
  const openBot = useRecoilValue(openBotState)

  const classNameString = () => {
    return `${
      width < 960 && large
        ? " display-none"
        : openBot
        ? ""
        : "height-and-overflow"
    }`
  }
  const gridParams = openBot
    ? { xs: 10, md: 8, lg: 11, xl: 9 }
    : { xs: 10, md: 8, lg: 9, xl: 8 }

  return (
    <m.Grid
      container
      direction="row"
      className={classNameString()}
      style={{
        backgroundColor: theme.palette.grey.light,
      }}
    >
      <m.Grid item>
        <files.ErrorBoundary>
          <files.EmulatorSidebar />
        </files.ErrorBoundary>
      </m.Grid>
      <m.Grid
        sx={{
          px: { xs: 1, xl: 4 },
          py: 2,
          minHeight: "calc(100vh - 4rem)",
          width: { xl: "70vw" },
        }}
        item
        {...gridParams}
      >
        {children}
      </m.Grid>
    </m.Grid>
  )
}

export default EmulatorPagesLayout
