import { useEffect, useRef, useState } from "react"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { useParams, useSearchParams } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { VscJson } from "react-icons/vsc"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import * as projState from "../../state/projectState"
import * as file from "../../GlobalFileContainer"
import * as projStatus from "../../state/StatusState"
import { synthesizerJobUrl } from "../../state/requests"
import { alertStates } from "../../state/vizState"
import {
  boldTitleHeader,
  renderIdenticalIcon,
} from "../admin-dashboard/AgentHealthMain"
import { fetchJobsData } from "../admin-dashboard/AdminDashboardMain"
import { hasData, statusColumn } from "../admin-dashboard/AgentQueueMain"
import RefreshButtonStyling from "../shared components/RefreshStyling/RefreshButtonStyling"
import "./GenerateData.css"
import { findEstimatedTime } from "../../state/services"

const headerAlign = {
  align: "center",
  headerAlign: "center",
}

const generateData = "Job History"

function GenerateTimerColumn({ setViewResult, setRefKey }) {
  const { width } = file.useWindowDimensions()
  let { emulatorId } = useParams()
  const theme = m.useTheme()
  const buttonRef = useRef(null)

  const [modeSearchParam] = useSearchParams()

  const emulatorConfig = useRecoilValue(projState.getEmulatorData)
  const setAlertState = useSetRecoilState(alertStates)
  const setJsonDialogOpen = useSetRecoilState(projState.jsonDialogOpenClose)
  const [queueDataState, setQueueDataState] = useRecoilState(
    projState.agentQueueData
  )
  const [refreshPolling, setRefreshPolling] = useRecoilState(
    projState.refreshButtonPollingStates
  )
  const setQueuedLoader = useSetRecoilState(projState.agentQuedLoader)

  const columns = [
    {
      field: "id",
      headerName: "Job Id",
      minWidth: 100,
      width: 150,
      sortable: false,
      renderCell: (params) =>
        renderIdenticalIcon(
          params?.value,
          setAlertState,
          "Job ID",
          false,
          params
        ),
      filterable: false,
    },
    {
      headerName: "User Email",
      field: "requesting_user_email",
      filterable: false,
      sortable: false,
      minWidth: 100,
      width: 150,
      renderCell: (params) => (
        <m.Box title={params.value}>{params.value?.split("@")[0]}</m.Box>
      ),
    },
    {
      headerName: "Queued At",
      field: "queued_at",
      width: 150,
      minWidth: 100,
      renderCell: (params) => {
        const dateObject = params?.value
        return projState.formatTimeBasedOnLocal(dateObject, {
          ...projState.commonDateFormat,
          year: "numeric",
          second: "2-digit",
        })
      },
    },
    {
      headerName: "Agent ID",
      field: "agent",
      minWidth: 150,
      width: 180,
      renderCell: (params) =>
        renderIdenticalIcon(
          params?.value,
          setAlertState,
          "Agent ID",
          false,
          params
        ),
    },
    {
      headerName: "Iterations",
      field: "iterations",
      width: 150,
      minWidth: 100,
      sortable: true,
      ...headerAlign,
      renderCell: (params) => {
        const cellValue = params?.value
        return cellValue
      },
    },
    statusColumn(theme),
    {
      headerName: "View Result",
      field: "result",
      width: 150,
      minWidth: 100,
      ...headerAlign,
      renderCell: (params) => {
        const rowData = params?.row
        const status = rowData.status
        const isCompleted = status === projStatus.ALGO_STATUS.complete

        if (isCompleted && rowData.data_ref && rowData.data_ref.length > 0) {
          return (
            <m.Box className="ml-display-flex ml-justify-center ml-align-center">
              <m.Button
                color="primary"
                size="small"
                variant="contained"
                sx={{ height: "30px" }}
                onClick={() => openResult(params)}
              >
                <m.Typography
                  variant="body2"
                  color={theme.palette.common.white}
                >
                  View Result
                </m.Typography>
              </m.Button>
            </m.Box>
          )
        } else {
          return (
            <m.Box className="ml-display-flex ml-justify-center">
              <m.Typography variant="body2" color={theme.palette.grey[500]}>
                No Results
              </m.Typography>
            </m.Box>
          )
        }
      },
    },
    {
      headerName: "See Job",
      field: "view",
      width: 150,
      minWidth: 100,
      filterable: false,
      ...headerAlign,
      sortable: false,
      renderCell: (params) => {
        const findAgentObj = synthesizerData?.find(
          (queue) => queue?.id === params.row?.id
        )
        return (
          <m.Box className="ml-display-flex ml-flex-dir-row, ml-justify-center ml-align-center ml-height">
            <m.Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              title="Click to view job"
            >
              <VscJson
                fontSize={"large"}
                onClick={() => handleJsonViewerClick(findAgentObj)}
              />
            </m.Box>
          </m.Box>
        )
      },
    },
  ]

  const [synthesizerData, setSynthesizerData] = useState([])
  const [estimateTime, setEstimateTime] = useState({})
  const [isLoading, setIsLoader] = useState(true)
  const [success, setSuccess] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(
    Number(modeSearchParam.get("index"))
  )
  const [time, setTime] = useState(10)
  const [timerInterval, setTimer] = useState(false)
  const [expandedAccordions, setExpanedAccordions] = useState([generateData])
  const [columnWidths, setColumnWidths] = useState(
    columns.map((col) => ({ field: col.field, width: col?.width ?? "" }))
  )
  const [showPastData, setShowPastData] = useState(false)

  const estimateData = {
    type: emulatorConfig?.source?.type,
    software: emulatorConfig?.source?.software,
    emulator_id: emulatorId,
    N: 1,
  }

  useEffect(() => {
    if (showPastData) {
      fetchSynthesizerDataForAgent()
      findEstimatedTime(estimateData, setEstimateTime, "roundtrip")
    }
  }, [showPastData])

  useEffect(() => {
    if (timerInterval) {
      const timerInterval = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 10))
      }, 1000)
      return () => clearInterval(timerInterval)
    }
  }, [timerInterval])

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false)
    }, [800])
    setAlertState((prevState) => ({
      ...prevState,
      severityState: "success",
    }))
  }, [success])

  useEffect(() => {
    if (synthesizerData?.length > 0) {
      const pollingID = setInterval(async () => {
        const jobStatuses = synthesizerData.map((item) => item.status)
        const hasQueuedOrReceivedJobs = agentStatus(jobStatuses)
        if (!hasQueuedOrReceivedJobs) {
          clearInterval(pollingID)
          setTimer(false)
          return
        }
        await getSynthesizerHistory()
      }, 11000)

      return () => {
        clearInterval(pollingID)
      }
    }
  }, [synthesizerData])

  useEffect(() => {
    if (showPastData) {
      getSynthesizerHistory()
    }
  }, [currentIndex])

  const handleColumnResize = (newColumnWidths) => {
    setColumnWidths(
      columnWidths?.map((col) =>
        col.field === newColumnWidths.colDef.field
          ? newColumnWidths.colDef
          : col
      )
    )
  }

  const agentStatus = (jobStatuses) => {
    const hasQueuedOrReceivedJobs =
      jobStatuses.includes(projStatus.ALGO_STATUS.queued) ||
      jobStatuses.includes(projStatus.ALGO_STATUS.received)
    return hasQueuedOrReceivedJobs
  }

  const synthesizerHistoryUrl = (i, job) => {
    return `/engine/synthesize/history/${emulatorId}/${currentIndex}/${
      currentIndex + 10
    }?selection=all`
  }

  const hidePastData = (hide) => {
    setShowPastData(!showPastData)
    if (hide) {
      setIsLoader(false)
      setTimer(false)
      setTime(0)
    }
  }

  const getSynthesizerHistory = async () => {
    try {
      const result = await file.EmulationLab.get(synthesizerHistoryUrl(), {
        headers: {
          severity: SeverityLevel.Information,
        },
      })
      const data = result?.data
      const modifiedData = data?.reverse()
      setIsLoader(false)
      setSynthesizerData(modifiedData || [])
      const statusQueuedOrReceived =
        modifiedData?.length > 0 &&
        modifiedData?.some(
          (item) =>
            item.status === projStatus.ALGO_STATUS.queued ||
            item.status === projStatus.ALGO_STATUS.received
        )
      setTimer(statusQueuedOrReceived)
      if (statusQueuedOrReceived) {
        setTime(10)
      }
    } catch (error) {
      console.error(error)
      setIsLoader(false)
      setTimer(false)
      setTime(0)
      setSynthesizerData([])
    }
    setIsLoader(false)
  }

  const getSynthesizerJobId = async () => {
    try {
      const result = await file.EmulationLab.post(
        synthesizerJobUrl,
        { emulator_id: emulatorId },
        {
          headers: {
            severity: SeverityLevel.Information,
          },
        }
      )
      if (result.status === 200) {
        setSuccess(true)
        fetchJobsData(
          projState.getAgentQueue,
          setQueueDataState,
          "queuedData",
          setRefreshPolling,
          "pollingState",
          0,
          10,
          setQueuedLoader,
          emulatorId
        )
      }
    } catch (error) {
      alert("error in generating data,please try again..")
    }
  }

  const fetchSynthesizerDataForAgent = async () => {
    const estimateData = await getSynthesizerHistory()

    const statusQueuedOrReceived =
      estimateData?.length > 0 &&
      estimateData?.some(
        (item) =>
          item.status === projStatus.ALGO_STATUS.queued ||
          item.status === projStatus.ALGO_STATUS.received
      )
    if (statusQueuedOrReceived) {
      setTime(10)
      setTimer(true)
    }
  }

  const handleExpansion = (e, title) => {
    expandedAccordions?.includes(title)
      ? setExpanedAccordions(
          expandedAccordions?.filter((accords) => accords !== title)
        )
      : setExpanedAccordions((prev) => [...prev, title])
  }

  const openResult = (cell) => {
    const rowData = cell?.row
    const isCompleted = rowData.status === projStatus.ALGO_STATUS.complete
    const referenceFile = rowData?.data_ref
    const hasRefFile = rowData.data_ref && rowData.data_ref.length > 0
    if (hasRefFile && isCompleted) {
      setRefKey(referenceFile)
      setViewResult(true)
    } else {
      console.log("No Result")
    }
  }

  const handleJsonViewerClick = (object) => {
    setJsonDialogOpen({
      state: true,
      obj: object,
    })
  }

  const generatedRows = synthesizerData?.map(
    ({ id, requesting_user_email, queued_at, agent, iterations, status }) => ({
      id,
      requesting_user_email,
      queued_at,
      agent,
      iterations: Object.keys(iterations)?.length,
      status,
    })
  )

  const handlePageChange = (page) => {
    const params = new URLSearchParams(modeSearchParam)
    const prevI = currentIndex - 10
    const nextI = currentIndex + 10
    const updatedI = page ? prevI : nextI
    params.index = updatedI
    setCurrentIndex(updatedI)
    setIsLoader(true)
    setTimer(false)
    setTime(0)
    projState.updateURLSearchParams(params)
  }

  const commonButtonRender = (isDisabled, page) => (
    <m.Button
      variant="contained"
      onClick={() => handlePageChange(page)}
      disabled={isDisabled}
      sx={{ marginLeft: page ? "0px" : "5px" }}
    >
      <m.Typography variant="caption" color={theme.palette.common.white}>
        {page ? "previous" : "next"}
      </m.Typography>
    </m.Button>
  )

  const getButtonStyle = (refreshButton) => {
    if (refreshButton) {
      return {
        position: "absolute",
        boxShadow: "0px 0px 1px 1px #0000001a",
      }
    } else {
      return {
        position: "relative",
        mr: 1,
      }
    }
  }

  const currentQueueRefresh = (e) => {
    e.stopPropagation()
    e.preventDefault()
    fetchJobsData(
      projState.getAgentQueue,
      setQueueDataState,
      "queuedData",
      setRefreshPolling,
      "pollingState",
      0,
      10,
      setQueuedLoader,
      emulatorId
    )
    fetchJobsData(
      projState.getCurrentJob,
      setQueueDataState,
      "currentJobData",
      setRefreshPolling,
      "currentPollingState",
      0,
      10,
      setQueuedLoader,
      emulatorId
    )
  }

  const reversedQueueData = queueDataState?.queuedData?.filter(
    (queData) => queData?.emulator_id === emulatorId
  )

  const currentJobData = queueDataState.currentJobData?.filter(
    (currData) => currData?.emulator_id === emulatorId
  )

  const hasQueuedData = hasData(reversedQueueData)
  const hasCurrentData = hasData(currentJobData)

  const btnStyle = getButtonStyle(hasQueuedData || hasCurrentData)

  const showEstimatedTime = (estimateTime) => (
    <>
      <core.Box className="ml-display-flex ml-flex-dir-row ml-align-center">
        {estimateTime.agentCount !== undefined && (
          <core.Typography variant="body2" className="ed-small">
            Active agents- {estimateTime.agentCount},
          </core.Typography>
        )}
        <core.Typography variant="body2" className="ed-small">
          Estimated wait- {estimateTime.wait}
        </core.Typography>
      </core.Box>
    </>
  )

  return (
    <>
      <file.ErrorBoundary>
        <file.EmulatorBreadCrumbs msg={generateData} data={"data"}>
          <core.Grid container justifyContent="center">
            <div
              className={`ml-display-flex ml-flex-dir-col ${
                width < 500 ? "noclas" : "gd-margin-left"
              } `}
            >
              <core.Grid
                className={`ml-display-flex ml-align-center ${
                  width > 500 ? "ml-flex-dir-row" : "ml-flex-dir-col"
                }`}
              >
                {hasQueuedData || hasCurrentData ? (
                  <RefreshButtonStyling
                    progress={
                      hasQueuedData || hasCurrentData
                        ? refreshPolling.progress
                        : 0
                    }
                    strokeDasharray={
                      buttonRef?.current?.getBoundingClientRect()?.width +
                        220 || 335
                    }
                    height={
                      buttonRef?.current?.getBoundingClientRect()?.height +
                        16 || 40
                    }
                    width={
                      buttonRef?.current?.getBoundingClientRect()?.width + 16 ||
                      130
                    }
                    isPolling={hasQueuedData || hasCurrentData}
                  >
                    <m.Button
                      variant="contained"
                      color="primary"
                      margin="5"
                      ref={buttonRef}
                      onClick={(e) => currentQueueRefresh(e)}
                      className={
                        (hasQueuedData || hasCurrentData) &&
                        (refreshPolling.time < 2 || refreshPolling.time === 30)
                          ? "pulse"
                          : ""
                      }
                      sx={btnStyle}
                    >
                      <m.Typography
                        variant="caption"
                        color={theme.palette.common.white}
                      >
                        Refresh&nbsp;Page
                      </m.Typography>
                    </m.Button>
                  </RefreshButtonStyling>
                ) : (
                  <m.Button
                    variant="contained"
                    color="primary"
                    margin="5"
                    onClick={(e) => currentQueueRefresh(e)}
                    sx={btnStyle}
                  >
                    <m.Typography
                      variant="caption"
                      color={theme.palette.common.white}
                    >
                      Refresh&nbsp;Page
                    </m.Typography>
                  </m.Button>
                )}
                <file.Authorization processName="manageIO">
                  <core.Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    id="btnvalidate"
                    style={{ color: theme.palette.common.white }}
                    className="gd-margin-left"
                    onClick={getSynthesizerJobId}
                  >
                    <m.Typography variant="caption">Generate Data</m.Typography>
                  </core.Button>
                </file.Authorization>
                {width < 500 && showEstimatedTime(estimateTime)}
              </core.Grid>
              {width > 500 && showEstimatedTime(estimateTime)}
            </div>
          </core.Grid>
        </file.EmulatorBreadCrumbs>
      </file.ErrorBoundary>
      <file.ErrorBoundary>
        <file.AgentQueueMain gndata={true} emulatorId={emulatorId} />
      </file.ErrorBoundary>
      <file.ErrorBoundary>
        <m.Box
          className={`queuegrid ${
            expandedAccordions?.includes(generateData) ? "para-1" : ""
          }`}
        >
          {showPastData && (
            <file.RenderTableAccordion
              title={generateData}
              expandedAccordions={expandedAccordions}
              handleExpansion={handleExpansion}
              data={synthesizerData}
            >
              {isLoading && <file.Loader margin={"10%"} />}
              {!isLoading && (
                <>
                  <div
                    className={
                      width > 650
                        ? "adminmuigridtable"
                        : "adminmuigridtable margin-0-10"
                    }
                    style={{
                      maxWidth: width < 650 ? `${width - 60}px` : "1380px",
                    }}
                  >
                    <DataGrid
                      rows={generatedRows}
                      columns={columns.map((col, index) => ({
                        ...col,
                        width: columnWidths[index]?.width ?? "",
                      }))}
                      onColumnResize={(newColumnWidths) =>
                        handleColumnResize(newColumnWidths)
                      }
                      sx={boldTitleHeader}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      autoHeight
                      pageSizeOptions={[10]}
                      density="compact"
                      disableRowSelectionOnClick
                      hideFooter
                      slots={{
                        noRowsOverlay: () => (
                          <div className="ml-display-flex ml-justify-center ml-align-center ml-height">
                            No Jobs Found.
                          </div>
                        ),
                      }}
                    />
                  </div>
                  <m.Box
                    sx={{ display: "flex", justifyContent: "end" }}
                    marginTop={"5px"}
                  >
                    {commonButtonRender(currentIndex === 0, "prev")}
                    {commonButtonRender(synthesizerData?.length < 10)}
                  </m.Box>
                </>
              )}
            </file.RenderTableAccordion>
          )}
        </m.Box>

        <m.Grid className="ml-display-flex ml-justify-end" mt={2}>
          <m.Button
            variant="contained"
            onClick={() => hidePastData(showPastData)}
          >
            <m.Typography variant="caption" color={theme.palette.common.white}>
              {showPastData ? "Hide Past Jobs" : "View Past Jobs"}
            </m.Typography>
          </m.Button>
        </m.Grid>
      </file.ErrorBoundary>
      {success && (
        <file.SnackBar
          message={"Data Generated successfully!"}
          open={success}
        />
      )}
    </>
  )
}

export default GenerateTimerColumn
