import { Box, Typography, useTheme } from "@mui/material"
import { CalcFileUpload } from "../../GlobalFileContainer"
import { ReactComponent as DownloadIcon } from "../../assets/newIcons/downloadIcon.svg"
import { pickStatusWithSoftwareIcons } from "../../state/services"
import { useEffect, useState } from "react"
import { uploadEmFiles } from "../../state/projectState"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { mongoProfileState } from "../../state/userState"

const DownloadCalcFileButton = ({ selectedCardData }) => {
  const theme = useTheme()

  const setUploadEmFilesState = useSetRecoilState(uploadEmFiles)
  const mongoProfile = useRecoilValue(mongoProfileState)

  const [showCalcFile, setShowCalcFile] = useState(false)
  const [calcTabIndex, setCalcTabIndex] = useState(0)
  const [loader, setLoader] = useState(false)
  const [fileList, setFileList] = useState([])

  const team = selectedCardData?.team

  const director = team && Object.keys(team).length !== 0 && team.director
  const members = team && Object.keys(team).length !== 0 && team.members

  const isMemberDirector = director && director.email === mongoProfile.email
  const approvedRole = isMemberDirector
    ? "director"
    : members &&
      members.find((member) => member.email === mongoProfile.email)
        ?.approved_role

  const handleFileDownload = async () => {
    setShowCalcFile(true)
    setUploadEmFilesState((prevState) => ({
      ...prevState,
      listStatus: true,
    }))
  }

  const closeCalcManager = () => {
    setShowCalcFile(false)
    setCalcTabIndex(0)
  }

  useEffect(() => {
    const generateFileList = (refs) =>
      refs?.map((ref) => ({
        id: ref,
        key: ref,
        name: ref?.split("/")[2] ?? "",
        size: "",
      })) || []

    const fileRefs =
      calcTabIndex === 0
        ? selectedCardData?.package?.source?.ref
          ? [selectedCardData?.package?.source?.ref]
          : []
        : selectedCardData?.package?.source?.supporting_refs

    setFileList(generateFileList(fileRefs))
  }, [calcTabIndex, selectedCardData])

  return (
    <Box
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        display: "flex",
        width: "100%",
        padding: "5px 12px",
        borderRadius: "8px",
        gap: 1,
        backgroundColor: theme.palette.primary.main,
        cursor: "pointer",
      }}
      onClick={handleFileDownload}
    >
      <Box
        sx={{
          height: "24px",
          display: "flex",
          justifyContent: "center",
          borderRadius: "4px",
          backgroundColor: theme.palette.common.white,
        }}
      >
        {pickStatusWithSoftwareIcons("excel", "none", "none", "25px", "excel")}
      </Box>
      <Typography
        fontWeight={500}
        fontFamily={"Roboto"}
        style={{ fontSize: "14px", color: theme.palette.common.white }}
      >
        Download Calc File
      </Typography>
      <DownloadIcon width={"20px"} height={"18px"} stroke="#F5F5F5" />
      <CalcFileUpload
        uploadOpen={showCalcFile}
        closeCalcManager={closeCalcManager}
        calcTabIndex={calcTabIndex}
        setCalcTabIndex={setCalcTabIndex}
        setLoader={setLoader}
        setFileList={setFileList}
        loader={loader}
        fileList={fileList}
        consumer={`launches/${selectedCardData?.id}`}
        isTeamMember={approvedRole}
      />
    </Box>
  )
}

export default DownloadCalcFileButton
