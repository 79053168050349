export const allVersionUpdates = [
  {
    "1.7.0": {
      Task: [
        "Introduced an AI-powered chatbot to enable seamless automated communication",
        "Launched a redesigned consumer journey with a new, user-friendly launch page",
        "Enhanced the user interface for cards and the Discover page for improved user experience",
        "Upgraded the calculator with history tracking, export functionality, and input pre population",
        "Integrated a curated lookup feature to streamline calculations",
        "Enabled the launch option in the Contributor view for enhanced usability",
      ],
      Bug: [
        "Resolved validation issues for lookup results to improve reliability",
        "Standardized layout and font across the application to ensure consistency",
        "Updated the Favorites feature with a generalized 'Like' functionality",
      ],
    },
  },
  {
    "1.6.0": {
      Task: [
        "Developed a JSON viewer for calculator results to enhance data transparency and usability",
        "Introduced distinct user modes: Consumer Mode and Contributor Mode, catering to varied user needs",
        "Enhanced card UX with mode-specific designs for a more intuitive experience",
        "Improved the Discover page with a new list view and advanced filtering capabilities for better navigation",
        "Added a new lookup result feature to enrich user functionality and precision",
      ],
      Bug: [
        "Enhanced validation rules for adding new variables, ensuring greater accuracy",
      ],
    },
  },
  {
    "1.5.1": {
      Task: [
        "Provided Option to run Default Test for Calculators",
        "Enhanced UI for all Dialog boxes through app",
        "Enhanced UX for for File upload",
        "Improved Usability, learnability and intelligibility in Optimization process",
        "Implemented a new way to update thumbnail for Explore Matrix",
        "Magnified UI for ease of use on pages Manage Config and Calculator",
        "Enhanced error handling",
      ],
      Bug: [
        "Resolved and refine configuration process of Hosted Calculator",
        "Added some Validation for formation of new Emulator, Calculator",
      ],
    },
  },
  {
    "1.5.0": {
      Task: [
        `Introduced new Agent Dashboard with 'Agent Health', 'Job Queue' and 'Agent Capability' Sections`,
        "New Mouse Actions added to view 3D Model more conveniently",
        "Created a common JSON Viewer to view Json Object on different pages",
        "Added Job Queue and Active Jobs Section on Generate Data",
        "Implemented Emulate Mode for Optimization jobs",
        "Converted job's timings to Local machine time for better understanding",
        "Added Favorites and Collaborator options on Emulator Summary Page",
      ],
      Bug: [
        "unwanted API calls to make Page navigation faster",
        "breaking of some pages",
      ],
    },
  },
  {
    "1.4.2": {
      Task: [
        `New "stable" Emulators tab as default tab`,
        "Supporting file feature added on file manager",
        "New condition added for ready for calc",
        "Optimization can run on Evolutionary, Pattern and Search Algorithm",
        "Implemented Emulate Mode for Optimization jobs",
        "Provided Filters, Sorting and Global Search on Optimization History",
      ],
      Bug: ["update Ref URL in Meta data in case of Hosted Calculator"],
    },
  },
  {
    "1.4.0": {
      Task: [
        "Macro Configuation setting added for Emulators",
        "Python as Data sources added to create Python based Calculators",
        "Showing 3D Model for Grasshopper Calculator on Run Calculator Page",
        "Emulator Configuration Progress and status elaborated in detail on summary",
        "Unit Test feature provided to pre-create and run tests for Calculators",
        "Optimization Fetaure added to define single objective and optimize results",
      ],
    },
  },
  {
    "1.3.6": {
      Task: [
        "Added Advanced Filter functionality on discover page",
        "Set the params for selected mode in url while sharing, on tryit page",
        "UI Enchancement on calculator page to add units after inputs",
        "Changed the icon for emulator status",
        "Added available modes icons on emulator card",
        "Changed calculator toggle buttons to new svgs",
        "Removed image route and added gallery in dialog box",
        "Changes for uniform fonts and color using MuiTheme",
        "Added Transfer director role functionality",
        "Added delete emulator option for admins on emulator card menu",
        "User can upload manage data files from manage configs",
        "Added help text next to inputs/outputs on manage configs",
        "Added changes related to label and api endpoint to /director instead of owner",
        "Generate random inputs for calculate/emulate on calculator page",
        "UI Enhancement on Generate Data",
      ],
      Bug: [
        "setting calcfile on manage config",
        "image skew at top on manage configs",
        "navigation through emulator/calculator icon on emulator card shows default error page",
      ],
    },
  },
  {
    "1.3.5": {
      Task: [
        "Generate data feature added",
        "UI Enchancement to fix right side gutter in responsive",
        "View data option provided on Manage data",
        "User Email ID property added in App Insights",
        "Enhancemnet on member management responsive layout",
        "Member permission info updated",
      ],
      Bug: [
        "run calculator",
        "data source under manage configs",
        "admin permission on tryit for hosted calculator",
      ],
    },
  },
  {
    "1.3.4": {
      Task: [
        "UI Enhancements on input section for calculator page",
        "Added validation on run calculate button",
        "UI updated for available modes to show on Emulator card",
        "Enhancement in share emulator with mode selected",
      ],
      Bug: [
        "correction in estimated wait value on calculator page",
        "UI on Gallery",
        "timer in case of failed job",
      ],
    },
  },
  {
    "1.3.3": {
      Task: [
        "Config updated for Mathcad and Grasshopper calculators",
        "Label for API Key updated on Manage configs",
        "Explore Table headings updated with Nick Name",
        "Responsive UI of breadcrumbs updated",
        "UI Enhancements on Summary Page",
        "UI Enhancements on Calculator Page",
        "Annotations displayed on thumbnail of image on calculator page",
        "UI Enhanced for image navigation while image preview",
        "Images scaled while preview",
        "Removed thumbnail placeholder from calculator page where images are not uploaded",
        "UI Enhanced for annotations by making font consistent",
        "UI enhancements on Image annotations",
        "Disabled auto complete suggestions on all Input fields throughout the application",
        "Enhance user experience by adding loader while filter on explore table",
      ],
      Bug: [
        "Member management while adding new member",
        "showing infinite loader on Image preview",
        "annotation getting removed from images",
        "incorrect breadcrumbs on Image Gallery",
        `image scaling on Image Gallery thumbnails`,
        "showing incorrect message in toaster while adding variable",
      ],
    },
  },
  {
    "1.3.2": {
      Bug: [
        "validation for run calculator using Mathcad and Grasshopper",
        "object structure on try it page",
      ],
    },
  },
  {
    "1.3.1": {
      Task: [
        "Run Calculator option is available now on calculator page",
        "Validations updated for Run Emulator and Run Calculator options",
        "Beta version will be shown by default for members of Emulator",
        "UI Enhancements on Calculator Page",
        "Added explore table enhancement",
        "Show annotation on calculator thumbnail image",
      ],
      Bug: [
        "duplicate name, nickname and column label on manage configs",
        "active calculator file deletion on manage configs",
        "validation issue on prepare for training button",
        "UI enhancements on Gallery",
        `missing Validation added for "re-run" buttons on ML Evaluation`,
        "hosted calculator",
      ],
    },
  },
  {
    "1.3.0": {
      Task: [
        "Gallery feature added to upload and manage images for calculator",
        "Added option to create Hosted Calculator",
        "Added option to set expected run modes while create emulator",
        "Added option to update expected run modes from metadata section from manage configs",
        "Enhancement on Calculator page to show variable description on hover",
        "Version selection option added on Calculator page",
        "Enhanced UI for Calculator Page",
        "Enhancement on Calculator page to show versions on results",
        "Emulator Gallery for reference on run calculator page available",
        "Version selection option added on manage configs page in breadcrumbs",
        "Data section on Summary page populated with correct data",
        "Set/Change active calculator option available on manage configs",
        "Enhancement on summary page to show data section well formatted",
        "Active calculator information displayed on Emulator Summary and manage configs page",
        "Enhancement on discover page to show icons on cards based on available modes",
        `Remove tag of "Not for Production"`,
        "Add release history page to show what's released recently",
      ],
      Bug: [
        "calculator menu getting disabled",
        "variable list was not showing on manage configs",
        "Admin user unable to add self as Manager",
        "share URL broken",
        "login which was showing blank screen after login sometimes",
      ],
    },
  },
  {
    "1.2.0": {
      Task: [
        "Enhancement to show browsing history in a better way",
        "Showing emulator's version on Summary page",
        "Add option for version selection on Manage Config pages",
        "Showing latest version on emulator cards",
        "Run calculator option added based on available modes",
      ],
      Bug: [
        "favorite icon was not working properly",
        "role was not getting updated when navigating from notifications",
        "wrong URL while share from explore data page",
        "dragging of unused column labels on Manage Configs for touch screen devices",
      ],
    },
  },
  {
    "1.1.2": {
      Task: [
        "Added share option on all pages",
        "UI Enhancement on Discover page to keep tabs and searchbar fixed on top",
        "Enhancement on ML page to refresh immediately on initiating ML Fitting",
      ],
      Bug: ["deployed filter"],
    },
  },
  {
    "1.1.1": {
      Task: [
        "Added app version on Login page",
        "Enhancements on share feature",
        "Made a responsive mobile screen for data first Approach",
      ],
    },
  },
  {
    "1.1.0": {
      Task: [
        "Added responsive UI for Member Management page",
        "Enhancement added on notifications to refresh",
        "Added breadcrumbs on Member Management page",
        "Added dialog to show info. related to authorization on Member Management page",
        "Enabled the Member Management menu for member user",
        "Added option for technician user to request upgrade to manager role",
        "Added option for technician user to add other members",
        "Added alert Box for adding input outputs if emulator is in training or deployed",
        "Enhancement on Event Logs to group it properly",
        "column(Unfiltered)",
        "UI enhancement on Feature Selection to show green color with gradient, for data",
        "Enhancement on feature Selection to show color scale legend",
        "Added feature of data first and config first on Manage Configs",
      ],
      Bug: [
        "position of breadcrumbs",
        "query speed conversion on ML page",
        "ML page",
        "user was not able to update source from metadata",
        "Explore Matrix- Filter for Graph is getting removed If user hide columns",
        "icon was showing wrong for deployed emulators",
      ],
    },
  },
  {
    "1.0": {
      Task: [
        "Added column label back on input and output cards on Manage Configs",
        "Removed the validation for uploading file on File Manager.",
        "Added training permission to technician on ML Evaluation page",
        "UI enhancement on Manage Configs Page",
        "Added category field as mandatory While creating a new emulator",
      ],
      Bug: [
        `the existing category of an emulator is not getting fetched under Clone emulators popup window`,
        "all the pages which are referring the manage configs data, to not show empty screen",
        "no message is getting displayed when there is no emulators under Favourite tab",
        "director unable to update members role due to disabled buttons on Member Management page",
        "inconsistency in summary page action icon button fontsizes",
      ],
    },
  },
]
