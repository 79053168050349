import * as m from "@mui/material"

const NoHistoryBox = () => {
  const theme = m.useTheme()

  return (
    <m.Box
      className="user-message"
      sx={{
        maxWidth: "75%",
        backgroundColor: theme.palette.primary.light,
        borderRadius: "6px",
        padding: "10px",
        marginRight: "0px",
      }}
    >
      <m.Typography variant="body2">
        Hey,
        <br />
        How can I help you today?
        <br />
        <br />
        I can assist you with searching through various hosted calculators and
        their associated datasets within the Emulation Lab.
        <br />
        <br />
        Here are some examples to get you started:
        <ul style={{ paddingLeft: "1.5em" }}>
          <li>
            Calculate the column capacity of a W12X58 with an unbraced length of
            50 ft.
          </li>
          <li>Design a retaining wall with a height of 12 ft.</li>
          <li>Find a connection for a W14 beam and a girder.</li>
        </ul>
      </m.Typography>
    </m.Box>
  )
}

export default NoHistoryBox
