import React from "react"
import * as m from "@mui/material"
import { useRecoilState } from "recoil"
import JSONView from "react-json-view"
import { handleKeyDown, jsonDialogOpenClose } from "../../../state/projectState"
import AddDialogTitle from "../DialogTitle/AddDialogTitle"
import { Loader } from "../../../GlobalFileContainer"

const StyledDialog = m.styled(m.Dialog)(() => ({
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "600px",
  },
}))

const JsonObjViewer = () => {
  const [jsonDialogOpen, setJsonDialogOpen] =
    useRecoilState(jsonDialogOpenClose)

  const closeDialog = () => {
    setJsonDialogOpen({
      state: false,
      obj: {},
    })
  }

  const shouldCollapse = (field) => {
    return field.namespace.length > 1
  }

  return (
    <StyledDialog
      onKeyDown={(e) => handleKeyDown(e, closeDialog)}
      open={jsonDialogOpen.state}
      fullWidth={true}
      maxWidth="sm"
    >
      <AddDialogTitle closeCreate={closeDialog} title={"Job Object"} />
      <m.DialogContent dividers>
        <m.Box sx={{ flexGrow: 1 }}>
          {jsonDialogOpen.obj ? (
            <JSONView
              collapsed={false}
              shouldCollapse={shouldCollapse}
              src={jsonDialogOpen.obj}
            />
          ) : (
            <Loader />
          )}
        </m.Box>
      </m.DialogContent>
    </StyledDialog>
  )
}

export default JsonObjViewer
