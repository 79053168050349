import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { ScatterplotMatrixComplete } from "../../charts/ScatterplotMatrix"
import * as files from "../../GlobalFileContainer"
import { exploreImagesState } from "../../state/projectState"

const ExploreScatterplotMatrix = () => {
  let { emulatorId } = useParams()
  const getImages = `/emulators/${emulatorId}/images?folder=explore_matrix`

  const setImages = useSetRecoilState(exploreImagesState)

  useEffect(() => {
    getAllImages()
  }, [])

  const getAllImages = async () => {
    try {
      const result = await files.EmulationLab.get(getImages)
      const coludinaryImage = result.data
      const newImages = coludinaryImage?.resources.map((image) => ({
        image: image.url,
        id: image.public_id,
        name: image.filename,
        height: image.height,
        width: image.width,
      }))
      setImages(newImages)
    } catch (error) {
      alert("Eror in Fetching images, please try again..")
    }
  }

  return (
    <files.ErrorBoundary>
      <ScatterplotMatrixCard />
    </files.ErrorBoundary>
  )
}

const ScatterplotMatrixCard = () => {
  return (
    <files.CommonerrorPageHandle title="exploratory matrix">
      <ScatterplotMatrixComplete />
    </files.CommonerrorPageHandle>
  )
}

export default ExploreScatterplotMatrix
