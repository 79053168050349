import { useEffect } from "react"

const useScrollToBottom = (messagesBoxRef, delay, dependencies) => {
  useEffect(() => {
    const scrollToBottom = () => {
      if (messagesBoxRef.current) {
        messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight
      }
    }

    const timer = setTimeout(scrollToBottom, delay)

    return () => clearTimeout(timer)
  }, [messagesBoxRef, delay, ...dependencies])
}

export default useScrollToBottom
