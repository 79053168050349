import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useRecoilState } from "recoil"
import * as files from "../../GlobalFileContainer"
import { uploadEmFiles } from "../../state/projectState"

function MLFileManager() {
  const { emulatorId } = useParams()

  const [uploadEmFilesState, setUploadEmFilesState] =
    useRecoilState(uploadEmFiles)

  const [fileList, setFileList] = useState([])
  const getEmulatorFiles = `/emulators/${emulatorId}/models/files`

  useEffect(() => {
    getFiles()
  }, [setFileList])

  const getFiles = async () => {
    setUploadEmFilesState((prevState) => ({
      ...prevState,
      loaderState: true,
      loaderMsg: "Loading Data...",
    }))
    try {
      const response = await files.EmulationLab.get(getEmulatorFiles)
      setFileList(response.data)
      if (response.status === 200) {
        setUploadEmFilesState((prevState) => ({
          ...prevState,
          listStatus: true,
          loaderState: false,
        }))
      }
    } catch (error) {
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        listStatus: false,
        loaderState: false,
      }))
      console.error(error)
    }
  }

  return (
    <div className="ml-p-5">
      {uploadEmFilesState.loaderState && (
        <files.Loader linear={true} message={uploadEmFilesState.loaderMsg} />
      )}
      <files.UploadEmulatorFiles
        fileType="models"
        getFiles={getFiles}
        fileList={fileList}
        setFileList={setFileList}
        isTeamMember={true}
      />
    </div>
  )
}

export default MLFileManager
