import { useSetRecoilState } from "recoil"
import * as m from "@mui/material"
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp"
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined"
import { ReactComponent as BotHeaderIcon } from "../../assets/chatboticons/bot-header.svg"
import { openBotState, showSideBarState } from "../../state/projectState"

const ChatbotHeader = ({ messages, handleDeleteChat }) => {
  const theme = m.useTheme()

  const setShowSideBar = useSetRecoilState(showSideBarState)
  const setOpen = useSetRecoilState(openBotState)

  const resetButton = () => (
    <m.Button
      variant="contained"
      className="ml-display-flex ml-flex-dir-row"
      sx={{
        backgroundColor: theme.palette.grey.main,
        "&:hover": {
          backgroundColor: theme.palette.grey.main,
        },
        borderRadius: "25px",
        padding: "5px 10px",
        color: "white",
      }}
      endIcon={<MapsUgcOutlinedIcon />}
      key="reset_histor_btn"
      onClick={handleDeleteChat}
    >
      <m.Typography variant="body2">New Chat</m.Typography>
    </m.Button>
  )

  return (
    <m.Box
      className="ml-display-flex ml-align-center ml-space-between"
      sx={{
        padding: "10px 20px",
        width: "100%",
        height: "55px",
        position: "sticky",
        top: 5,
        zIndex: 10,
      }}
    >
      <m.Box
        className="ml-display-flex ml-flex-dir-row"
        sx={{
          bgcolor: theme.palette.primary.main,
          padding: "10px",
          color: theme.palette.common.white,
          borderRadius: "25px",
        }}
      >
        <m.Typography variant="body1">Chat With AI Agent</m.Typography>
        <BotHeaderIcon fontSize={"16px"} />
      </m.Box>
      <m.Box
        className="ml-display-flex ml-flex-dir-row ml-align-center"
        sx={{ gap: 2 }}
      >
        {messages.length > 0 && resetButton()}
        <m.IconButton
          onClick={() => {
            setShowSideBar(true)
            setOpen(false)
          }}
          title="Click to minimize chat"
          sx={{
            bgcolor: theme.palette.grey.light,
            width: "25px",
            height: "25px",
            borderRadius: "5px",
            border: `1px solid ${theme.palette.common.black}`,
          }}
        >
          <ExpandMoreSharpIcon />
        </m.IconButton>
      </m.Box>
    </m.Box>
  )
}

export default ChatbotHeader
