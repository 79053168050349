import useModesCheck from "./useModesCheck"

const useNavigatePath = (path, setMongoProfile, siteWideRole) => {
  const url = new URL(path)
  const searchParams = new URLSearchParams(url.search)
  const pathname = url.pathname
  useModesCheck(path, setMongoProfile, siteWideRole)

  if (path?.includes("redirect")) {
    const requiredParamsArray = Array.from(searchParams.entries()).slice(2)
    const requiredParams = new URLSearchParams(requiredParamsArray)
    const requiredParamsString = requiredParams.toString()
    const emulatorId = searchParams.get("emulator_id")
    const route = searchParams.get("route")
    const isLaunchPage = searchParams.get("launch")

    const redesignedPath = isLaunchPage
      ? `/new/${emulatorId}/${route}${
          requiredParamsString ? `?${requiredParamsString}` : ""
        }`
      : `/em/${emulatorId}/${route}${
          requiredParamsString ? `?${requiredParamsString}` : ""
        }`
    return redesignedPath
  } else {
    const redesignedPath = `${pathname}${
      searchParams ? `?${searchParams}` : ""
    }`
    return redesignedPath
  }
}

export default useNavigatePath
