import { useDrag } from "react-dnd"
import { useRecoilValue, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import { varFontSize } from "./CalculatorConfig"
import { Typography } from "@mui/material"
import {
  authorization,
  emulatorSpecificRole,
  globalDialogBox,
  siteWideRole,
} from "../../../state/projectState"
import { convertToUppercase } from "../../../state/services"

function VariableList({
  btnName,
  id,
  columnLabels,
  acceptIO,
  setAcceptIO,
  handleLabelClick,
  data,
}) {
  const emulatorSpecificRoleState = useRecoilValue(emulatorSpecificRole)
  const siteWideRoleState = useRecoilValue(siteWideRole)
  const setGlobalDialogOpen = useSetRecoilState(globalDialogBox)

  const handleAddVariable = (value, label) => {
    setAcceptIO(value)
    handleLabelClick(label, value === "input", "value")
  }

  const renderCommonButton = (value, inputValue) => (
    <core.Button
      id="imgurlbtn"
      color="primary"
      variant="contained"
      onClick={() => {
        setGlobalDialogOpen({
          isOpen: false,
          content: null,
          title: "",
          style: {},
          maxWidth: "md",
          actions: null,
        })
        setTimeout(() => {
          handleAddVariable(value, inputValue)
        }, [500])
      }}
    >
      <core.Typography variant="caption">
        {convertToUppercase(value)}
      </core.Typography>
    </core.Button>
  )

  const handleOpen = (btn) => {
    setGlobalDialogOpen((prev) => ({
      ...prev,
      isOpen: true,
      content: (
        <core.DialogContentText marginTop={"10px"}>
          Add "{btn}" as new variable in
        </core.DialogContentText>
      ),
      title: "Choose Field",
      maxWidth: "xl",
      actions: (
        <core.DialogActions>
          {renderCommonButton("input", btn)}
          {renderCommonButton("output", btn)}
        </core.DialogActions>
      ),
    }))
  }

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "button",
    item: { id: id, name: btnName },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  const configHasLabels = columnLabels?.includes(btnName)

  const isAMember =
    authorization["manageIO"].includes(siteWideRoleState) ||
    authorization["manageIO"].includes(emulatorSpecificRoleState)

  return (
    <>
      <core.Button
        ref={drag}
        variant="contained"
        fullWidth
        color="grey"
        isDragging
        style={{
          varFontSize,
          border: isDragging ? "2px solid red" : "0px",
          margin: "5px",
          color: configHasLabels ? "white" : "black",
        }}
        disabled={!isAMember}
        onClick={() =>
          acceptIO && !data ? handleLabelClick(btnName) : handleOpen(btnName)
        }
      >
        <Typography variant="caption" margin="4px">
          {btnName}
        </Typography>
      </core.Button>
    </>
  )
}

export default VariableList
