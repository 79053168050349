import { useState, useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import { debounce } from "lodash"
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined"
import {
  apiNotificationData,
  appLayout,
  cardFilterSearchStringState,
  cardsApiResponse,
  fetchDataNotes,
  selectedVersion,
  siteWideRole,
} from "../../state/projectState"
import "./navContent.css"
import * as files from "../../GlobalFileContainer"
import * as requests from "../../state/requests"
import { fontWeight650 } from "../../state/StatusState"
import CommonMobileNav from "./CommonMobileNav"
import newIcons from "../../assets/newIcons"
import { convertToUppercase } from "../../state/services"
import { mongoProfileState } from "../../state/userState"

export const classNameString = ({ isActive }) => {
  const baseClass = "gl-tab-nav-item main-heading-emu"
  const activeClass = "active gl-tab-nav-item-active"

  return `${baseClass} ` + `${isActive ? activeClass : ""}`
}

export const renderFilterButton = (handleOpenFilters, isAppliedFilter) => {
  const theme = m.useTheme()

  return (
    <m.Button
      variant="contained"
      sx={{
        height: "39px",
        color: theme.palette.common.white,
        minWidth: isAppliedFilter ? "120px" : "auto",
      }}
      size="small"
      color="primary"
      onClick={(e) => handleOpenFilters(e)}
    >
      <FilterAltOutlinedIcon />
      <m.Typography variant="caption">
        {isAppliedFilter ? "Edit" : null} Filter
      </m.Typography>
    </m.Button>
  )
}

let navigationOptions = [
  {
    group: "explore",
    to: "/structures",
    routerPath: "structures",
    buttonText: "Structures",
    // fetchUrl: requests.fetchStructures,
  },
  {
    group: "explore",
    to: "/enclosures",
    routerPath: "enclosures",
    buttonText: "Enclosures",
    // fetchUrl: requests.fetchEnclosures,
  },
  {
    group: "explore",
    to: "/securedesign",
    routerPath: "securedesign",
    buttonText: "SecureDesign",
    // fetchUrl: requests.fetchSecureDesign,
  },
  {
    group: "explore",
    to: "/other",
    routerPath: "other",
    buttonText: "Other",
  },
  {
    group: "explore",
    to: "/liked",
    routerPath: "liked",
    buttonText: "Saved Emulators",
    // fetchUrl: requests.fetchUserLibraryFavorites,
  },
  {
    group: "my",
    to: "/my/all",
    routerPath: "all",
    buttonText: "All",
    fetchUrl: requests.fetchUserLibrary,
  },
  {
    group: "my",
    to: "/my/director",
    routerPath: "director",
    buttonText: "As Director",
    fetchUrl: requests.fetchUserAsDirector,
  },
  {
    group: "my",
    to: "/my/collab",
    routerPath: "collab",
    buttonText: "As Collaborator",
    fetchUrl: requests.fetchUserCollaborations,
  },
  {
    group: "my",
    to: "/my/favorites",
    routerPath: "favorites",
    buttonText: "Favorites",
    fetchUrl: requests.fetchUserLibraryFavorites,
  },
]

const textOptions = {
  my: {
    title: "My Emulators",
    description:
      "I'm working really hard on these to help revolutionize the way we do design at Walter P Moore",
  },
  explore: {
    title: "Explore Emulators",
  },
}

export const fetchTheNotifications = (setNotificationCount) => {
  fetchDataNotes()
    .then((data) => {
      setNotificationCount(data)
    })
    .catch((error) => {
      console.error("Error fetching API data:", error)
    })
}

const RedirectRoute = () => {
  const navigate = useNavigate()
  const path = window.location.href

  const setMongoProfile = useSetRecoilState(mongoProfileState)
  const siteWideRoleState = useRecoilValue(siteWideRole)

  useEffect(() => {
    const redesignedPath = files.useNavigatePath(
      path,
      setMongoProfile,
      siteWideRoleState
    )
    redesignedPath !== null && navigate(redesignedPath)
  }, [])

  return null
}

export default function ExploreNav({ navGroup, setOpenFilter, openFilter }) {
  const filterOptions = ["Tags", "Category", "Modes", "Status", "Members"]
  const { width } = files.useWindowDimensions()
  const [cardsNameFilter, setCardsNameFilter] = useRecoilState(
    cardFilterSearchStringState
  )
  const setNotificationCount = useSetRecoilState(apiNotificationData)
  const siteWideRoleState = useRecoilValue(siteWideRole)
  const [layout, setLayout] = useRecoilState(appLayout)
  const setSelectedVersions = useSetRecoilState(selectedVersion)

  const [navOptions, setNavOptions] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [appliedFilters, setAppliedFilters] = useState({
    tagList: [],
    categoryList: [],
    modeList: [],
    statusList: [],
    memberList: [],
    others: [],
  })
  const [cards, setCards] = useRecoilState(cardsApiResponse)

  const [menuItem, setMenuItem] = useState("All")
  const [isLoading, setIsLoading] = useState(true)
  const [searchFieldVal, setSearchFieldVal] = useState("")
  const [likedEmu, setLikedEmu] = useState(0)

  const theme = core.useTheme()
  const isAppliedFilter = Object.entries(appliedFilters)
    .filter(([key]) => key !== "others")
    .some(([, value]) => value.length > 0)

  useEffect(() => {
    setSearchFieldVal(cardsNameFilter)
    setSelectedVersions(null)
  }, [])

  useEffect(() => {
    if (navGroup === "my") {
      setAppliedFilters((prev) => ({
        ...prev,
        others: [],
      }))
    }
  }, [navGroup])

  useEffect(() => {
    setLayout(width < 960 ? "list" : "grid")
  }, [width])

  useEffect(() => {
    setIsLoading(true)
    const filteredOptions = navigationOptions.filter(
      (navOpt) => navGroup == navOpt.group
    )

    const myOptUrls = filteredOptions?.map(
      (options) => options?.group === "my" && options?.to
    )

    if (!myOptUrls?.includes(window.location.pathname)) {
      siteWideRoleState === "consumer"
        ? filteredOptions.unshift({
            group: "explore",
            to: "/",
            buttonText: "All",
            routerPath: "/",
            fetchUrl: requests.fetchStableCards,
          })
        : filteredOptions.unshift({
            group: "explore",
            to: "/",
            buttonText: "All",
            routerPath: "/",
            fetchUrl: requests.fetchAll,
          })
    }

    setNavOptions(filteredOptions)
  }, [navGroup, siteWideRoleState])

  useEffect(() => {
    fetchTheNotifications(setNotificationCount)
  }, [navGroup])

  const changeHandler = (event) => {
    const newValue = event.target.value
    setSearchFieldVal(newValue)
    debounceChangeHandler(event)
  }

  const debounceChangeHandler = debounce((a) => {
    setCardsNameFilter(a.target.value.toLowerCase().trim())
  }, 300)

  const handleOpenFilters = (e) => {
    setAnchorEl(e.currentTarget)
    setOpenFilter(true)
  }
  const toggleDrawer = (open) => (event) => {
    if (
      !(
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      )
    ) {
      setOpenFilter(open)
    }
  }

  const resetFilter = () => {
    setAppliedFilters({
      tagList: [],
      modeList: [],
      statusList: [],
      categoryList: [],
      memberList: [],
      others: [],
    })
  }

  const handleKeyDown = (event) => {
    const presentAlready = appliedFilters.others.some(
      (str) => str.toLowerCase().trim() === searchFieldVal.toLowerCase().trim()
    )
    if (
      window.location.pathname !== "/" ||
      searchFieldVal.trim() === "" ||
      presentAlready
    ) {
      return
    }
    if (event.key === "Enter") {
      setAppliedFilters((prev) => ({
        ...prev,
        others: [...prev.others, searchFieldVal],
      }))
      setSearchFieldVal("")
      setCardsNameFilter("")
    }
  }

  const renderSearchField = (xs, md) => (
    <m.TextField
      sx={{ display: { xs: xs, sm: md } }}
      type="search"
      name="name"
      variant="outlined"
      placeholder="Search..."
      size="small"
      spellCheck="false"
      autoComplete="off"
      style={{
        backgroundColor: theme.palette.common.white,
        minWidth: md === "flex" ? "320px" : "auto",
      }}
      value={searchFieldVal}
      onChange={changeHandler}
      onKeyDown={handleKeyDown}
    />
  )

  const handleDisplay = (event, newlayout) => {
    if (newlayout !== null) {
      setLayout(newlayout)
    }
  }

  const deleteFilterHandler = (category, value) => {
    setAppliedFilters((prev) => ({
      ...prev,
      [category]: prev[category].filter((item) => item !== value),
    }))
  }

  return (
    <>
      <files.MetaTags />
      <m.Box className="App-paper">
        <m.Grid
          container
          direction={"column"}
          sx={{ px: { xs: 1, xl: 4 }, py: 3 }}
        >
          <m.Grid item sx={{ pb: { xs: 2, xl: 3 } }}>
            <m.Box
              className={"ml-display-flex ml-space-between ml-align-center"}
            >
              <m.Typography variant="h4" fontWeight={fontWeight650}>
                {textOptions[navGroup].title}
              </m.Typography>
              <m.Box className={"ml-display-flex ml-align-center ml-gap-1"}>
                {renderSearchField("none", "flex")}
                <m.Box className={"ml-display-flex ml-gap-1"}>
                  {renderFilterButton(handleOpenFilters, isAppliedFilter)}
                  <m.ToggleButtonGroup
                    disabled={width < 960}
                    value={layout}
                    exclusive
                    onChange={handleDisplay}
                    aria-label="text alignment"
                  >
                    <m.ToggleButton value="grid">
                      <img
                        height={"16px"}
                        src={newIcons["layoutGrid"]}
                        alt={"layoutGrid"}
                      />
                    </m.ToggleButton>
                    <m.ToggleButton value="list">
                      <img
                        height={"16px"}
                        src={newIcons["layoutList"]}
                        alt={"layoutList"}
                      />
                    </m.ToggleButton>
                  </m.ToggleButtonGroup>
                </m.Box>
              </m.Box>
            </m.Box>
          </m.Grid>
          <m.Grid item sx={{ pb: { xs: 2, xl: 3 } }}>
            <m.Box
              className={
                "ml-display-flex ml-flex-dir-row ml-align-center ml-space-between"
              }
            >
              <files.ErrorBoundary>
                <CommonMobileNav
                  navOptions={navOptions}
                  menuItem={menuItem}
                  setMenuItem={setMenuItem}
                  likedEmuLength={likedEmu}
                />
              </files.ErrorBoundary>
              <m.Grid
                container
                direction="row"
                justifyContent={"end"}
                rowSpacing={1}
                columnSpacing={1}
              >
                {Object.entries(appliedFilters).map(([category, items]) =>
                  items.map((item, index) => (
                    <m.Grid key={index} item xs="auto">
                      <m.Chip
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          cursor: "pointer",
                        }}
                        variant="outlined"
                        label={
                          <m.Typography variant="body2" fontWeight={600}>
                            {convertToUppercase(item)}
                          </m.Typography>
                        }
                        onDelete={() => deleteFilterHandler(category, item)}
                      />
                    </m.Grid>
                  ))
                )}
              </m.Grid>
            </m.Box>
          </m.Grid>
          <m.Grid item className="explore-allcards">
            <Routes>
              {navOptions?.map((navigationOption) => {
                const allFetchLink = navOptions.find(
                  (opt) => opt.buttonText === "All"
                ).fetchUrl

                return navigationOption.children ? (
                  navigationOption.children.map((navChild) => (
                    <Route
                      key={navChild.group + navChild.to}
                      path={navChild.routerPath}
                      exact
                      element={
                        <files.ErrorBoundary>
                          <files.PosterGrid
                            fetchUrl={navChild.fetchUrl ?? allFetchLink}
                            appliedFilters={appliedFilters}
                            cards={cards}
                            setCards={setCards}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setLikedEmu={setLikedEmu}
                          />
                        </files.ErrorBoundary>
                      }
                    />
                  ))
                ) : (
                  <Route
                    key={navigationOption.group + navigationOption.to}
                    path={navigationOption.routerPath}
                    exact
                    element={
                      <files.ErrorBoundary>
                        <files.PosterGrid
                          fetchUrl={navigationOption.fetchUrl ?? allFetchLink}
                          appliedFilters={appliedFilters}
                          cards={cards}
                          setCards={setCards}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          setLikedEmu={setLikedEmu}
                        />
                      </files.ErrorBoundary>
                    }
                  />
                )
              })}
              <Route path="/redirect" exact element={<RedirectRoute />} />
            </Routes>
            <files.ErrorBoundary>
              <files.AdvanceSearchFilter
                toggleDrawer={toggleDrawer}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
                anchorEl={anchorEl}
                cards={cards}
                isAppliedFilter={isAppliedFilter}
                resetFilter={resetFilter}
                renderSearchField={renderSearchField}
                filterOptions={filterOptions}
              />
            </files.ErrorBoundary>
          </m.Grid>
        </m.Grid>
      </m.Box>
    </>
  )
}
