import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { useSetRecoilState, useRecoilState } from "recoil"
import {
  Button,
  TextField,
  Grid,
  IconButton,
  InputAdornment,
  Card,
  Typography,
  Box,
  Stack,
  useTheme,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {
  chipPositionState,
  fetchAnnotationsState,
  imageIndex,
  imagesState,
} from "../../state/projectState"
import "./EmulatorImages.css"
import { EvaluateTooltip } from "../MLEvaluation/MLIcons"
import useStyles from "../card_style"
import * as files from "../../GlobalFileContainer"
import { convertToUppercase } from "../../state/services"

function EmulatorImages() {
  const { emulatorId } = useParams()
  const postImage = `emulators/${emulatorId}/images?type=file`
  const urlPattern = new RegExp("^https?://.*", "i")
  const base64 = /^(data:image\/([a-zA-Z]*);base64,)/
  const theme = useTheme()
  const classes = useStyles()
  const commonTextFieldStyles = {
    width: { xs: "225px", sm: "400px", md: "600px" },
  }

  const [images, setImages] = useRecoilState(imagesState)
  const [index, setIndex] = useRecoilState(imageIndex)
  const setChipPosition = useSetRecoilState(chipPositionState)
  const setIsFetchingAnnotations = useSetRecoilState(fetchAnnotationsState)

  const [imageUrl, setImageUrl] = useState("")
  const [imageTarget, setImageTarget] = useState([])
  const [loader, setLoader] = useState(true)
  const [linearLoader, setLinearLoader] = useState(false)
  const [thumbnail, setThumbnail] = useState(index < 0 ? true : false)
  const [fileError, setFileError] = useState(false)
  const [nameArray, setNameArray] = useState([])

  useEffect(() => {
    setThumbnail(true)
  }, [])

  useEffect(() => {
    if (thumbnail) {
      getAllImages()
      setIndex(-1)
      setChipPosition([])
      setIsFetchingAnnotations(true)
    } else {
      setLoader(false)
    }
  }, [thumbnail])

  useEffect(() => {
    if (fileError) {
      const timeoutId = setTimeout(() => {
        setFileError(false)
      }, 3000)

      return () => clearTimeout(timeoutId)
    }
  }, [fileError])

  useEffect(() => {
    if (imageTarget.length > 0) {
      handleImageUpload(imageTarget, setImageTarget)
    }
  }, [imageTarget])

  const getAllImages = async () => {
    try {
      setNameArray(images?.map((img) => img.name))
      setLoader(false)
    } catch (error) {
      alert("Eror in Fetching images, please try again..")
      setLoader(false)
    }
  }

  const uploadFromFile = (e) => {
    const files = e.target.files
    commonUpload(files, setImageTarget)
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    commonUpload(files, setImageTarget)
  }

  const commonUpload = (files, setTarget) => {
    const filesArray = Array.from(files)
    const uploadedFiles = []

    filesArray.map((file) => {
      const fileName = file.name
      const fileType = file.type.split("/")[0]
      if (fileType !== "image") {
        setFileError(true)
      } else {
        if (nameArray?.includes(fileName)) {
          const modifiedName = modifyFileName(fileName)
          const newFile = new File([file], modifiedName, { type: file.type })
          uploadedFiles.push(newFile)
        } else {
          uploadedFiles.push(file)
        }
      }
    })
    setTarget((prevImg) => [...uploadedFiles, ...prevImg])
  }

  const modifyFileName = (name) => {
    const time = Math.floor(Math.random() * 90 + 10)
    const dateNow = new Date().toISOString()
    const modifiedName = `${name?.split(".")[0]}-${dateNow}${time}.${
      name?.split(".")[1]
    }`

    return modifiedName
  }

  const handleImageUpload = (imageUp, setImageUp) => {
    imageUp?.map((img) => {
      const imgName = img.name
      const formData = new FormData()
      formData.append("file", img)
      uploadImage(formData, setImageUp, imgName)
    })
  }

  const uploadFromUrl = (event) => {
    setImageUrl(event.target.value)
  }

  const handleImageUrlPaste = async () => {
    const correctHttpUrl = urlPattern.test(imageUrl)
    const correctBase64 = base64.test(imageUrl)
    if (correctBase64 || correctHttpUrl) {
      try {
        const response = await axios.get(imageUrl, {
          responseType: "arraybuffer",
        })
        const name = "image.jpg"
        const urlName = nameArray?.includes(name) ? modifyFileName(name) : name
        const imageBlob = new Blob([response.data], { type: "image/jpeg" })
        const imageFile = new File([imageBlob], urlName, {
          type: "image/jpeg",
        })
        const contentType = response.headers["content-type"]
        if (!contentType.startsWith("image/")) {
          setImageUrl("")
          setFileError(true)
        } else {
          const formData = new FormData()
          formData.append("file", imageFile)
          uploadImage(formData, setImageUrl, urlName, "url")
        }
      } catch (error) {
        console.error("Error converting image to FormData:", error)
        alert("Please Enter Correct Url")
      }
    } else {
      setFileError(true)
      setImageUrl("")
    }
  }

  const handlePaste = async (event) => {
    const clipboardData =
      event.clipboardData || event.originalEvent.clipboardData
    if (clipboardData) {
      const items = clipboardData.items
      if (items) {
        const newImageCopies = []
        for (const item of items) {
          const fileType = item.type.split("/")[0]
          if (fileType !== "image" && fileType !== "text") {
            setFileError(true)
          } else {
            if (item.type.indexOf("image") !== -1) {
              const blob = await item.getAsFile()
              const name = `screenshot-${Math.floor(
                Math.random() * 90 + 10
              )}.png`
              const urlName = nameArray?.includes(name)
                ? modifyFileName(name)
                : name
              if (blob) {
                const imageFile = new File([blob], urlName, {
                  type: blob.type,
                })
                newImageCopies.push(imageFile)
              }
            }
          }
        }
        setImageTarget([...newImageCopies])
      }
    }
  }

  const openPreview = (index) => {
    setThumbnail(false)
    setIndex(index)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const uploadImage = async (data, setState, name, url) => {
    try {
      setLinearLoader(true)

      const result = await files.EmulationLab.post(postImage, data)

      const newImage = {
        image: result.data.url || "",
        id: result.data.public_id,
        name: result.data.public_id?.split("/")[1] || "unknown",
        height: result.data.height,
        width: result.data.width,
      }

      setImages((prevImg) => [newImage, ...prevImg])
      setNameArray((prevNamesArray) => [...prevNamesArray, name])

      url ? setState("") : setState([])
      setLinearLoader(false)
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        "Some error occurred while uploading files. Please try again."
      alert(errorMessage)

      url ? setState("") : setState([])
      setLinearLoader(false)
    }
  }

  return (
    <>
      {loader ? (
        <files.Loader />
      ) : (
        <>
          <Stack alignItems="end">
            {!thumbnail && (
              <IconButton
                onClick={() => {
                  setThumbnail(true)
                  setChipPosition([])
                  setIndex(-1)
                }}
              >
                <ArrowBackIcon color="primary" />
              </IconButton>
            )}
          </Stack>
          {linearLoader && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              className="singlefileupload-grid"
            >
              <files.Loader linear="true" />
            </Grid>
          )}
          {thumbnail && (
            <files.Authorization processName="manageIO">
              <Grid
                container
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onPaste={handlePaste}
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className={classes.uploadButton}
                style={{ marginBottom: "0px" }}
              >
                <Button
                  id="choose-filebtn"
                  sx={{
                    color: theme.palette.common.black,
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                  component="label"
                  disableElevation
                >
                  <AddIcon
                    sx={{
                      color: theme.palette.grey.secondaryLight,
                      marginRight: "10px",
                    }}
                  />
                  <Box className="ml-display-flex ml-flex-dir-col ml-justify-center">
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      hidden
                      onChange={(e) => uploadFromFile(e)}
                    />
                    <Box>
                      <Typography
                        variant="body1"
                        color={theme.palette.grey.main}
                        sx={commonTextFieldStyles}
                      >
                        Drag 'n' drop some files here, or click to select files
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: theme.palette.grey.secondaryLight }}
                      >
                        Only images are allowed
                      </Typography>
                    </Box>
                  </Box>
                </Button>
                <Box>
                  <TextField
                    label="Paste image URL or screenshots here.."
                    value={imageUrl}
                    sx={commonTextFieldStyles}
                    onChange={(e) => uploadFromUrl(e)}
                    variant="outlined"
                    autoComplete="off"
                    InputProps={{
                      style: {
                        height: "40px",
                        paddingRight: "0px",
                      },
                      endAdornment: imageUrl && (
                        <InputAdornment position="end">
                          <Button
                            variant="contained"
                            sx={{ color: theme.palette.common.white }}
                            onClick={handleImageUrlPaste}
                          >
                            Upload
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                {fileError && (
                  <Typography
                    variant="body2"
                    color={theme.palette.status.danger}
                    marginTop="10px"
                  >
                    Invalid file type. Please upload only image files.
                  </Typography>
                )}
              </Grid>
            </files.Authorization>
          )}
          {thumbnail && (
            <Grid container spacing={1} alignItems="center">
              {images.length > 0 ? (
                images.map((image, index) => (
                  <EvaluateTooltip title={image.name} key={image.id}>
                    <Card className="proj-img-row__card">
                      <Box
                        className="proj-img-card-header"
                        onClick={() => openPreview(index)}
                      >
                        <Typography variant="body2">
                          {convertToUppercase(image?.name)}
                        </Typography>
                      </Box>
                      <Box
                        className={`${classes.colorWhiteIcon} proj-im-positions`}
                        onClick={() => openPreview(index)}
                      >
                        <img
                          className="proj-image_color_filter1"
                          height="100%"
                          width="100%"
                          src={image?.image}
                          alt={image?.name || "Failed to Load"}
                        ></img>
                      </Box>
                    </Card>
                  </EvaluateTooltip>
                ))
              ) : (
                <Box className="image-viewer">
                  <Typography variant="body1">
                    No images to show, Please upload the image to see!!...
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
        </>
      )}
      {images && !thumbnail && (
        <files.ImageViewer setThumbnail={setThumbnail} disable={false} />
      )}
    </>
  )
}

export default EmulatorImages
