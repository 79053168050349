import { useRef } from "react"
import { useRecoilValue } from "recoil"
import * as m from "@mui/material"
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined"
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { ReactComponent as BotAvatar } from "../../assets/chatboticons/bot-icon.svg"
import { ReactComponent as Erase } from "../../assets/chatboticons/erase-icon.svg"
import { ReactComponent as Lookup } from "../../assets/newIcons/search.svg"
import { ReactComponent as Calculate } from "../../assets/newIcons/calculator.svg"
import { ReactComponent as Emulate } from "../../assets/newIcons/Virus-Lab-Research-Test-Tube--Streamline-Ultimate.svg"
import { ReactComponent as Optimize } from "../../assets/newIcons/optimise.svg"
import {
  AlternateEmulatorBox,
  EmulationLab,
  ListViewCardConsumer,
  NoHistoryBox,
} from "../../GlobalFileContainer"
import { chatBotResponseReview } from "../../state/requests"
import {
  allLaunchCardsState,
  cardsApiResponse,
  getEmulatorData,
} from "../../state/projectState"
import { createIcon } from "../shared components/CommonStyles/CommonStyles"
import { convertToUppercase } from "../../state/services"

const handleSelectedText = (e, text, isText, message, setSelectedUserText) => {
  e.preventDefault()
  setSelectedUserText({
    launch_ids: isText === "launch" ? text : null,
    emulator_ids: isText === "emulate" ? text : null,
    text: isText === "text" ? text : null,
    data: message,
  })
}

const BotMessages = ({
  messages,
  returnLaunchObject,
  setLikedResponse,
  feedBackSTack,
  likedResponse,
  selectedUserText,
  setSelectedUserText,
}) => {
  const theme = m.useTheme()
  const contentRef = useRef(null)

  const cardState = useRecoilValue(cardsApiResponse)
  const emulatorData = useRecoilValue(getEmulatorData)
  const allLaunchCards = useRecoilValue(allLaunchCardsState)

  const returnCardObject = (id, launch) => {
    const cardData = launch ? allLaunchCards : cardState
    const filteredCard = cardData?.find((card) => card.id === id)

    return filteredCard && Object?.keys(filteredCard).length > 0
      ? filteredCard
      : emulatorData
  }

  const TextWithHtml = ({ text }) => {
    return <div dangerouslySetInnerHTML={{ __html: text }} />
  }

  const renderCardsForLaunch = (
    emulatorObject,
    data,
    launchId,
    alternateId,
    emulatorId,
    accordion
  ) => {
    return (
      <div
        style={{
          padding: accordion ? "5px" : "10px",
          backgroundColor: accordion ? "" : theme.palette.primary.light,
          margin: accordion ? "0px" : "10px 5px 10px 0px",
          width: accordion ? "100%" : "75%",
        }}
      >
        <ListViewCardConsumer
          width={680}
          emulator={emulatorObject}
          investigateRouteChange={(e) => {
            returnLaunchObject(launchId, data, alternateId, emulatorId)
            accordion &&
              handleSelectedText(
                e,
                emulatorId,
                "emulate",
                data,
                setSelectedUserText
              )
          }}
          contentRef={contentRef}
        />
      </div>
    )
  }

  const renderUserReviewBox = () => {
    const handleLikeResponse = () => {
      setLikedResponse(true)
    }

    const handlePositiveResponse = async () => {
      handleLikeResponse()
      try {
        await EmulationLab.post(chatBotResponseReview, feedBackSTack)
      } catch (err) {
        console.log("err", err)
      }
    }

    return (
      <m.Box className="ml-display-flex ml-flex-dir-row width-75">
        <m.Box
          className="ml-display-flex ml-align-center"
          sx={{
            border: `1px solid ${theme.palette.grey.main}`,
            borderRadius: "5px",
            margin: "5px auto",
            padding: "5px",
            gap: "12px",
            color: theme.palette.grey.main,
          }}
        >
          <m.Typography variant="body2" fontSize={12}>
            Is this conversation helpful so far?
          </m.Typography>
          <m.Box className="cursor-pointer" onClick={handlePositiveResponse}>
            <ThumbUpOutlinedIcon fontSize="small" />
          </m.Box>
          <m.Box className="cursor-pointer">
            <ThumbDownOffAltOutlinedIcon
              fontSize="small"
              onClick={handleLikeResponse}
            />
          </m.Box>
          <m.Divider orientation="vertical" flexItem />
          <m.Box className="cursor-pointer">
            <CloseOutlinedIcon fontSize="small" onClick={handleLikeResponse} />
          </m.Box>
        </m.Box>
      </m.Box>
    )
  }

  const createModeIcon = (IconComponent, title = "") => ({
    icon: createIcon(IconComponent, theme.palette.common.white),
    ...(title && { title }),
  })

  const modeIcons = {
    calculate: createModeIcon(Calculate),
    emulate: createModeIcon(Emulate),
    lookup: createModeIcon(Lookup, "Curated Lookup"),
    optimization: createModeIcon(Optimize, "Optimize"),
    optimize: createModeIcon(Optimize, "Optimize"),
  }

  const renderChooseOptionsSuggestion = () => (
    <m.Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        pl: 0,
        pr: 1,
        width: "100%",
      }}
    >
      <m.Box sx={{ mt: 1, ml: "-20px" }}>
        <BotAvatar />
      </m.Box>
      <m.Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <m.Box
          className="user-message"
          sx={{
            maxWidth: "75%",
            padding: "10px",
            wordBreak: "break-word",
            mt: 0,
          }}
        >
          <m.Typography variant="body2">
            Choose one of the following options:
          </m.Typography>
        </m.Box>
      </m.Box>
    </m.Box>
  )

  const renderOptions = (options, isText = true, message) => {
    if (
      typeof options === "string"
        ? options.trim() !== ""
        : Array.isArray(options) && options.length > 0
    ) {
      return (
        <>
          {renderChooseOptionsSuggestion()}
          <m.Box
            className="ml-display-flex ml-dir-row ml-space-around"
            sx={{
              maxWidth: "75%",
              minWidth: "75%",
              padding: "5px 0px",
              gap: 2,
              flexWrap: "wrap", // Allow wrapping
            }}
          >
            {options.map((item, i) => {
              if (isText) {
                return (
                  <m.Button
                    key={i}
                    onClick={(e) =>
                      handleSelectedText(
                        e,
                        item,
                        "text",
                        message,
                        setSelectedUserText
                      )
                    }
                    sx={{
                      background:
                        selectedUserText.text === item
                          ? theme.palette.secondary.main
                          : theme.palette.common.white,
                      flexGrow: 1,
                      flexBasis: "auto",
                      textAlign: "center",
                      padding: "8px 16px",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      maxWidth: "100%",
                      height: "auto",
                      transition: "height 0.3s ease",
                    }}
                    variant="outlined"
                  >
                    {item}
                  </m.Button>
                )
              } else {
                const mode = returnCardObject(item, true)?.mode
                return (
                  <m.Button
                    key={i}
                    onClick={(e) =>
                      handleSelectedText(
                        e,
                        item,
                        "launch",
                        message,
                        setSelectedUserText
                      )
                    }
                    sx={{
                      background:
                        selectedUserText.launch_ids === item
                          ? theme.palette.secondary.main
                          : theme.palette.primary.main,
                      width: "45.5%",
                      padding: "5px",
                      color:
                        selectedUserText === item
                          ? theme.palette.common.black
                          : theme.palette.common.white,
                      gap: 1,
                    }}
                    variant="contained"
                  >
                    {modeIcons[mode]?.icon} {convertToUppercase(mode)}
                  </m.Button>
                )
              }
            })}
          </m.Box>
        </>
      )
    }
    return null
  }

  return messages?.map((message, index) => {
    const isFirstMessageFromSender =
      index === 0 || messages[index - 1].sender !== message.sender
    const emulatorObject = returnCardObject(message?.emulator_id)
    const isSenderUser = message.sender === "user"

    return (
      <m.Box
        key={message.id}
        sx={{
          display: "flex",
          justifyContent: isSenderUser ? "flex-end" : "flex-start",
          alignItems: "flex-start",
          pl: isSenderUser
            ? 1
            : message.selection || messages[index - 1].selection
            ? "20px"
            : 0,
          pr: 1,
          width: "100%",
        }}
      >
        {!isSenderUser && isFirstMessageFromSender && (
          <m.Box sx={{ mt: 1 }}>
            <BotAvatar />
          </m.Box>
        )}
        <m.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: isSenderUser ? "flex-end" : "flex-start",
            width: "100%",
          }}
        >
          {message.content.length > 0 && (
            <m.Box
              className="user-message"
              sx={{
                maxWidth: "75%",
                backgroundColor: isSenderUser
                  ? theme.palette.grey.primaryLight
                  : theme.palette.primary.light,
                borderRadius: "6px",
                padding: "10px",
                margin: isSenderUser ? "1 0 1 auto" : "1 auto 1 0",
                wordBreak: "break-word",
              }}
            >
              <m.Typography variant="body2">
                <TextWithHtml text={message.content} />
              </m.Typography>
            </m.Box>
          )}

          {message?.emulator_id &&
            message?.launch_id &&
            renderCardsForLaunch(
              emulatorObject,
              message,
              message?.launch_id,
              false,
              message?.emulator_id
            )}
          {renderOptions(message?.options?.text, true, message)}
          {renderOptions(message?.options?.launch_ids, false, message)}
          {typeof message?.options?.emulator_ids === "string"
            ? message?.options?.emulator_ids.trim() !== ""
            : Array.isArray(message?.options?.emulator_ids) &&
              message?.options?.emulator_ids.length > 0 &&
              message?.options?.emulator_ids?.[0] !== "" && (
                <AlternateEmulatorBox
                  message={message}
                  returnCardObject={returnCardObject}
                  renderCardsForLaunch={renderCardsForLaunch}
                />
              )}
          {!likedResponse &&
            index === messages.length - 1 &&
            !isSenderUser &&
            !message.error &&
            renderUserReviewBox()}
          {message?.start_over && (
            <>
              <m.Divider
                sx={{
                  borderColor: theme.palette.primary.main,
                  margin: "5px 0px",
                  width: "100%",
                }}
              />
              {message?.start_over && !messages[index + 1] && (
                <m.Box
                  gap={1}
                  color={theme.palette.grey.main}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    pl: 0,
                    pr: 1,
                    width: "100%",
                  }}
                >
                  <Erase />
                  <m.Typography variant="body2">
                    Memory erased. A new chat has started.
                  </m.Typography>
                </m.Box>
              )}
              {message?.start_over && (
                <m.Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    pl: 0,
                    pr: 1,
                    width: "100%",
                  }}
                >
                  <m.Box sx={{ mt: 1, ml: "-20px" }}>
                    <BotAvatar />
                  </m.Box>

                  <m.Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    {message?.error ? (
                      <NoHistoryBox />
                    ) : (
                      <m.Box
                        className="user-message"
                        sx={{
                          maxWidth: "75%",
                          backgroundColor: theme.palette.primary.light,
                          borderRadius: "6px",
                          padding: "10px",
                          wordBreak: "break-word",
                        }}
                      >
                        <m.Typography variant="body2">
                          What else can I help you with today?
                        </m.Typography>
                      </m.Box>
                    )}
                  </m.Box>
                </m.Box>
              )}
            </>
          )}
        </m.Box>
        {isSenderUser && isFirstMessageFromSender && (
          <m.Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <m.Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "50%",
                marginTop: 1,
              }}
            />
          </m.Box>
        )}
      </m.Box>
    )
  })
}

export default BotMessages
