import { useState, useEffect, useRef } from "react"
import * as m from "@mui/material"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { useRecoilState, useRecoilValue } from "recoil"
import { useSearchParams } from "react-router-dom"
import {
  errorFieldsState,
  outputsParametersAndVariables,
  populateResultData,
} from "../../../state/projectState"
import { useWindowDimensions } from "../../../GlobalFileContainer"
import { ERROR_MESSAGE } from "../../../state/StatusState"
import { checkStatus, getOperator } from "../../../state/services"

const LimitedSpaceList = ({ list, unit }) => {
  const [visibleCount, setVisibleCount] = useState(4)

  const formatList = (list, visibleCount) => {
    if (list.length <= visibleCount) {
      return list.join(", ")
    }

    const start = list.slice(0, Math.floor(visibleCount / 2))
    const end = list.slice(-Math.ceil(visibleCount / 2))

    return `${start.join(", ")}...${end.join(", ")}`
  }

  useEffect(() => {
    const calculateVisibleCount = () => {
      const avgItemWidth = (list[0].length + 4) * 5
      const count = Math.floor(200 / avgItemWidth)
      setVisibleCount(count - 1)
    }

    calculateVisibleCount()
  }, [])

  const formattedList = formatList(list, visibleCount)

  return (
    <m.Typography className="ed-small" variant="body2" noWrap>
      {formattedList}&nbsp;{unit !== "none" ? unit : ""}
    </m.Typography>
  )
}

function DropDowns(props) {
  const theme = m.useTheme()
  const adornmentRef = useRef(null)
  const { height } = useWindowDimensions()
  const [modeSearchParam] = useSearchParams()
  const tryitMode = modeSearchParam.get("mode")

  const title = props.variable.Name
  const type = props.variable.Domain.Type
  const columnlabel = props.variable.ColumnLabel
  const description = props.variable.Description
  const optionsLength = props.options?.length
  const haveSingleOption = optionsLength === 1
  const lengthOfPostBody = Object.keys(props.postBody).length

  const errorFields = useRecoilValue(errorFieldsState)
  const [parametersAndVariablesState, setParametersAndVariablesState] =
    useRecoilState(outputsParametersAndVariables)
  const [populateResultDataState, setPopulateResultDataState] =
    useRecoilState(populateResultData)

  const [variables, setVariables] = useState({})
  const [parameters, setParameters] = useState({})
  const [dropdownError, setDropdownError] = useState(false)
  const [value, setValue] = useState([])
  const [selectedVariable, setSelectedVariable] = useState(false)

  const findVariable =
    parametersAndVariablesState?.variables.length > 0 &&
    parametersAndVariablesState?.variables.find(
      (val) => val.label === columnlabel
    )
  const findParameter =
    parametersAndVariablesState?.parameters.length > 0 &&
    parametersAndVariablesState?.parameters.find(
      (val) => val.label === columnlabel
    )
  const findVariableInInstruc = props.lookupInstruction?.userFilters?.find(
    (filter) => filter.label === columnlabel
  )
  const defaultInputPresent =
    props.variable.DefaultValue !== undefined &&
    props.variable.DefaultValue !== null

  const dropdownStyles = {
    commonStyle: {
      minWidth: 200,
      maxWidth: 250,
    },
    inputLabelStyle: {
      top: "-4px",
      left: adornmentRef ? adornmentRef?.current?.offsetWidth : 0,
    },
    displayPropsStyle: {
      padding:
        props.pageOptimize && selectedVariable
          ? "8px 10px 8px 8px"
          : "10px 15px 10px 0px",
    },
    paperStyle: {
      maxHeight: height - 400,
      overflow: "auto",
    },
  }

  useEffect(() => {
    if (!props.page) {
      if (props.pageOptimize) {
        if (findVariable) {
          const domainValues = findVariable?.domain?.values
          domainValues.length === optionsLength
            ? setValue(["all"])
            : setValue(domainValues)
          setSelectedVariable(true)
        }
      } else {
        if (findParameter) {
          setValue([findParameter?.value])
        }
      }
    }
  }, [props.pageOptimize])

  useEffect(() => {
    settingValuesForDefaultAndSingle()
  }, [])

  useEffect(() => {
    if (selectedVariable) {
      if (!findVariable) {
        setVariables({
          label: columnlabel,
          domain: {
            type: type,
            values: props.options,
          },
        })
        setValue(["all"])
      }
    }
  }, [selectedVariable])

  useEffect(() => {
    if (
      (!props.pageOptimize || haveSingleOption) &&
      !selectedVariable &&
      value.length !== 0 &&
      !findVariable
    ) {
      setParameters({
        label: columnlabel,
        value: value[0],
      })
    }
  }, [value])

  useEffect(() => {
    if (selectedVariable) {
      if (!findVariable) {
        setParametersAndVariablesState((prev) => ({
          ...prev,
          parameters: prev.parameters.filter(
            (value) => value.label !== columnlabel
          ),
          variables: [...prev.variables, variables],
        }))
      } else {
        setParametersAndVariablesState((prev) => ({
          ...prev,
          variables: prev.variables.map((v) =>
            v.label === columnlabel ? variables : v
          ),
        }))
      }
    }
  }, [variables])

  useEffect(() => {
    if (
      props.generateRandomInputs &&
      !selectedVariable &&
      (checkStatus(props.postBody)
        ? true
        : value?.length === 0 || value?.[0]?.[0] === "")
    ) {
      const randomIndex = Math.floor(Math.random() * optionsLength)
      setValue([props.options[randomIndex]])
      props.setGenerateRandomInputs(false)
    }
  }, [props.generateRandomInputs])

  useEffect(() => {
    if ((!props.pageOptimize || haveSingleOption) && value.length !== 0) {
      if (!findParameter) {
        setParametersAndVariablesState((prev) => ({
          ...prev,
          parameters: [...prev.parameters, parameters],
        }))
      }
    } else {
      setParametersAndVariablesState((prev) => ({
        ...prev,
        parameters: prev.parameters.filter((val) => val.label !== columnlabel),
      }))
    }
  }, [parameters])

  useEffect(() => {
    if (
      props.postBody &&
      props.page &&
      lengthOfPostBody > 0 &&
      populateResultDataState &&
      !haveSingleOption
    ) {
      let extractedData = props.postBody?.FeaturesData?.filter(
        (item) => item.Label === columnlabel
      )
      let getValue = extractedData[0]?.Data
      setValue([getValue])
      setPopulateResultDataState(false)
    }
  }, [props.postBody, populateResultDataState])

  useEffect(() => {
    if (
      value.length === 0 ||
      (Array.isArray(value[0]) && value[0].length === 1 && value[0][0] === "")
    ) {
      setDropdownError(errorFields)
    } else {
      setDropdownError(false)
    }
  }, [errorFields, value, dropdownError])

  useEffect(() => {
    if (
      !props.pageOptimize &&
      !selectedVariable &&
      ((lengthOfPostBody && value) || !lengthOfPostBody)
    ) {
      props.updateFeatureOutput(props.inputindex, columnlabel, value[0] ?? "")
    }
  }, [value, tryitMode])

  const handleSingleValueDropdown = (incomingValue) => {
    setValue([incomingValue])
  }

  const settingValuesForDefaultAndSingle = () => {
    if (haveSingleOption) {
      setValue(props.options)
    }
    if (defaultInputPresent) {
      setValue([props.variable.DefaultValue])
    }
  }

  const handleChange = (event) => {
    event.preventDefault()
    const incomingValue = event.target.value

    if (!Array.isArray(incomingValue)) {
      return handleSingleValueDropdown(incomingValue)
    }

    const latestSelectedValue =
      incomingValue.length > 0 ? incomingValue[incomingValue.length - 1] : null
    const checkValue = incomingValue.includes("all")
      ? latestSelectedValue === "all"
        ? ["all"]
        : incomingValue.filter((val) => val !== "all")
      : incomingValue

    if (checkValue.includes("all")) {
      if (value.length === optionsLength) {
        setValue([])
        setVariables({
          label: columnlabel,
          domain: {
            type: type,
            values: [],
          },
        })
      } else {
        setValue(["all"])
        setVariables({
          label: columnlabel,
          domain: {
            type: type,
            values: props.options,
          },
        })
      }
    } else {
      setValue(checkValue)
      setVariables({
        label: columnlabel,
        domain: {
          type: type,
          values: checkValue,
        },
      })
    }
  }

  const handleVariableSelection = () => {
    if (selectedVariable) {
      setParametersAndVariablesState((prev) => ({
        ...prev,
        variables: prev.variables.filter(
          (value) => value.label !== columnlabel
        ),
      }))
    }

    setSelectedVariable(!selectedVariable)
    setValue([])
  }

  return (
    <div
      style={{ gap: "2px" }}
      className="ml-display-flex ml-flex-dir-col"
      title={description}
    >
      <m.Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <m.Typography
          variant="body2"
          className="ml-display-flex ml-align-center"
        >
          {title}
        </m.Typography>
        {!props.page && selectedVariable && (
          <m.IconButton
            sx={{ m: 0, p: 0 }}
            onClick={props.pageOptimize ? handleVariableSelection : undefined}
          >
            {props.pageOptimize && (
              <DeleteOutlineOutlinedIcon
                color="primary"
                sx={{ fontSize: "20px", m: 0, p: 0 }}
              />
            )}
          </m.IconButton>
        )}
      </m.Box>
      <div className="calc-range-hover">
        <m.FormControl
          style={dropdownStyles.commonStyle}
          fullWidth
          error={dropdownError}
        >
          {dropdownError && (
            <m.InputLabel
              shrink={true}
              id="demo-simple-select-label"
              variant="filled"
              style={dropdownStyles.inputLabelStyle}
            >
              {dropdownError ? ERROR_MESSAGE.requiredField : ""}
            </m.InputLabel>
          )}
          <m.Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              textAlign: "center",
              ".MuiSelect-icon": { right: props.unit !== "none" ? "20%" : 7 },
              paddingLeft: 0,
            }}
            multiple={props.pageOptimize || selectedVariable}
            value={
              selectedVariable
                ? value
                : props.pageOptimize
                ? haveSingleOption
                  ? value
                  : []
                : value[0] ?? ""
            }
            disabled={
              props.disableInputs ||
              haveSingleOption ||
              (!props.pageOptimize && selectedVariable)
            }
            autoFocus={!props.pageOptimize && props.inputindex === 0}
            startAdornment={
              props.lookupInstruction &&
              Object.keys(props.lookupInstruction).length ? (
                <m.InputAdornment
                  ref={adornmentRef}
                  position="start"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    maxHeight: "41px",
                    height: "41px",
                    padding: "5px 8px",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                >
                  <m.Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: "100%",
                      gap: 1,
                    }}
                  >
                    <m.Typography
                      variant="caption"
                      lineHeight={"13px"}
                      sx={{
                        textWrap: "balance",
                        textTransform: "none",
                        minWidth: "60px",
                        maxWidth: "fit-content",
                      }}
                    >
                      {findVariableInInstruc.operator.replace(/_/g, " ")}
                    </m.Typography>
                    <m.Typography variant="h4">
                      {getOperator(findVariableInInstruc.operator)}
                    </m.Typography>
                  </m.Box>
                </m.InputAdornment>
              ) : (
                ""
              )
            }
            endAdornment={
              props.unit !== "none" && (
                <m.InputAdornment position="end">{props.unit}</m.InputAdornment>
              )
            }
            SelectDisplayProps={{
              style: dropdownStyles.displayPropsStyle,
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              PaperProps: {
                style: dropdownStyles.paperStyle,
              },
              getContentAnchorEl: null,
            }}
            onChange={handleChange}
          >
            {!props.page && selectedVariable && (
              <m.MenuItem value="all">
                <em>Select All</em>
              </m.MenuItem>
            )}
            {props.options.map((option, index) => {
              return (
                <m.MenuItem key={option + index} value={option}>
                  {option}
                </m.MenuItem>
              )
            })}
          </m.Select>
        </m.FormControl>
        {props.pageOptimize && !selectedVariable && (
          <m.Box
            sx={{
              backgroundColor: theme.palette.grey.light,
              cursor: "pointer",
              opacity: !props.pageOptimize && selectedVariable ? 1 : 0,
            }}
            className="selected-input-div"
            onClick={
              props.pageOptimize && !haveSingleOption
                ? handleVariableSelection
                : null
            }
          >
            <m.Typography>
              {props.pageOptimize
                ? selectedVariable
                  ? ""
                  : optionsLength > 1
                  ? "Set as variable"
                  : "Can't be selected!"
                : ""}
            </m.Typography>
          </m.Box>
        )}
      </div>
      <LimitedSpaceList list={props.options} unit={props.unit} />
    </div>
  )
}

export default DropDowns
