import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Divider, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import {
  emulatorLauncheGroupDataState,
  getEmulatorunch,
  outputsParametersAndVariables,
} from "../../../state/projectState"
import { a11yProps } from "../LaunchPageTabs"
import { ReactComponent as Lookup } from "../../../assets/newIcons/search.svg"
import { ReactComponent as Calculate } from "../../../assets/newIcons/calculator.svg"
import { ReactComponent as Emulate } from "../../../assets/newIcons/Virus-Lab-Research-Test-Tube--Streamline-Ultimate.svg"
import { ReactComponent as Optimize } from "../../../assets/newIcons/optimise.svg"
import { ReactComponent as DMTBadge } from "../../../assets/newIcons/badge-check.svg"
import { convertToUppercase } from "../../../state/services"
import { createIcon } from "../../shared components/CommonStyles/CommonStyles"

const TryitTabs = ({ children, selectedCardData, setSelectedCardData }) => {
  const theme = useTheme()

  const [searchParams, setSearchParams] = useSearchParams()

  const emulatorLaunch = useRecoilState(getEmulatorunch)
  const groupedData = useRecoilValue(emulatorLauncheGroupDataState)
  const setOutputsParametersAndVariables = useResetRecoilState(
    outputsParametersAndVariables
  )

  const selectedIndex = searchParams.get("index")
  const selectedMode = searchParams.get("mode")
  const launchID = searchParams.get("launchid")

  const selectedDataForTryit = groupedData[selectedIndex]
  const navLaunchOptions = selectedDataForTryit?.map((data) => ({
    launch: data?.id,
    title: data?.mode,
    icon: data?.validations?.length > 0,
  }))

  const selectedModeIndex = navLaunchOptions?.findIndex(
    (option) => option.launch === launchID
  )

  const [selectedNavIndex, setSelectedNavIndex] = useState(selectedModeIndex)

  useEffect(() => {
    setOutputsParametersAndVariables()
  }, [])

  useEffect(() => {
    if (
      emulatorLaunch?.length > 0 &&
      (!selectedCardData || selectedCardData.length === 0)
    ) {
      settingCardData()
    }
  }, [emulatorLaunch])

  useEffect(() => {
    settingCardData()
  }, [selectedMode, launchID])

  const settingCardData = () => {
    const launchData = emulatorLaunch[0]?.find(
      (launchObj) => launchObj.id === launchID
    )
    setSelectedCardData(launchData)
  }

  const handleChange = (event, newValue) => {
    const newParams = new URLSearchParams(searchParams)
    newParams.set("mode", navLaunchOptions[newValue]?.title)
    newParams.set("launchid", selectedDataForTryit[newValue]?.id)
    setSelectedNavIndex(newValue)
    setSearchParams(newParams)
  }

  const renderTabOptionWithIcons = {
    calculate: {
      icon: createIcon(Calculate),
    },
    emulate: {
      icon: createIcon(Emulate),
    },
    lookup: {
      icon: createIcon(Lookup),
      title: "Look Up Data",
    },
    optimize: {
      icon: createIcon(Optimize),
    },
    "custom project": {
      icon: createIcon(Optimize),
      title: "Optimize",
    },
  }

  const getModeIcon = (mode) => renderTabOptionWithIcons[mode] || {}

  return (
    <Grid container direction={"column"} paddingLeft={"8px"}>
      <Grid
        item
        xs={12}
        className="ml-display-flex ml-flex-dir-row ml-align-center"
      >
        <Tabs
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
          value={selectedNavIndex}
          onChange={handleChange}
        >
          {navLaunchOptions?.map((option, i) => (
            <Tab
              key={i}
              sx={{
                borderBottom:
                  selectedNavIndex === i ? "2px solid #39C2D7" : "none",
              }}
              label={
                <Grid container gap="10px" alignItems="center">
                  {getModeIcon(option.title)?.icon}

                  <Typography fontWeight={500} style={{ fontSize: "14px" }}>
                    {convertToUppercase(option?.title)}
                  </Typography>
                  {option?.icon && (
                    <div
                      style={{
                        display: "inline-flex",
                        borderRadius: "50%",
                        padding: "4px",
                        border: `1px solid ${theme.palette.status.success}`,
                      }}
                    >
                      <DMTBadge
                        height="16px"
                        width="16px"
                        stroke={theme.palette.status.success}
                      />
                    </div>
                  )}
                </Grid>
              }
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </Grid>
      <Divider />
      <Grid item xs={12} sx={{ paddingTop: "8px" }}>
        {children}
      </Grid>
    </Grid>
  )
}

export default TryitTabs
