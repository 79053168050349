import { useState, useEffect } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useParams, useSearchParams } from "react-router-dom"
import * as core from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js"
import * as m from "@mui/material"
import { CalculatorInput, EmulationLab } from "../../../GlobalFileContainer"
import * as prjState from "../../../state/projectState"
import useStyles from "../../card_style"
import {
  FULL_VIEW_EXIT_TEXT,
  RUN_TYPES,
  emulatorMode,
  emulatorSoftwareType,
  optimiseModesStrings,
} from "../../../state/StatusState"
import { ModeButton } from "./ModeButton"
import AlgorithmButton from "./AlgorithmButton"
import LookupDefaultFilterNSort from "../../consumer mode flow/consumer tryit/LookupDefaultFilterNSort"
import { lookupRequest } from "../../../state/requests"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import * as service from "../../../state/services"
import { alertStates } from "../../../state/vizState"

const EMULATOR_MODE = "emulator"
const HOSTED_MODE = "hosted_calculator"
const CALCULATOR_MODE = "calculator"

function CalculatorInputCard(props) {
  const appInsights = useAppInsightsContext()
  const classes = useStyles()
  const { emulatorId } = useParams()
  const [modeSearchParam] = useSearchParams()

  const emulatorConfig = useRecoilValue(prjState.getEmulatorData)
  const trueModes = useRecoilValue(prjState.findTrueModes)
  const setErrorFields = useSetRecoilState(prjState.errorFieldsState)
  const [selectedOutputs, setSelectedOutputs] = useRecoilState(
    prjState.outputsParametersAndVariables
  )
  const siteWideRoleState = useRecoilValue(prjState.siteWideRole)
  const [lookupObject, setLookupObject] = useRecoilState(
    prjState.lookupInstructionFinal
  )
  const setResult = useSetRecoilState(prjState.calculatorResult)
  const launchData = useRecoilValue(prjState.getEmulatorunch)
  const setAlertState = useSetRecoilState(alertStates)
  const setGlobalDialogOpen = useSetRecoilState(prjState.globalDialogBox)

  const [timeAndIterationValue, setTimeAndIterationValue] = useState({
    time: 120,
    iteration: 200,
  })
  const [error, setError] = useState({ max_iteration: false, max_time: false })
  const [errorMessage, setErrorMessage] = useState("")

  const rangeTextFieldStyles = {
    inputLabelPropsStyle: {
      fontSize: "10px",
      top: "-13px",
      letterSpacing: "0.5px",
    },
    inputPropsStyle: {
      maxWidth: "60px",
      padding:
        error.max_iteration || error.max_time ? "14px 7px 6px" : "10px 7px",
    },
    inputAdornmentStyle: {
      padding:
        error.max_iteration || error.max_time ? "14px 0px 6px" : "10px 0px",
      margin: "0px",
    },
    commonStyle: {
      fontSize: "12px",
      paddingRight: "5px",
    },
  }

  const [tryitMode, setTryItMode] = useState(
    modeSearchParam.get("mode") ?? selectedOutputs.mode
  )
  const [selectedAlgorithm, setSelectedAlgorithm] = useState(
    optimiseModesStrings.genetic
  )
  const [postBody, setPostBody] = useState({})
  const [limitforLookup, setLimitForLookup] = useState(1)
  const [checkingRangeWarning, setCheckingRangeWarning] = useState(
    Array(emulatorConfig?.calculator?.InputVariables.length).fill(false)
  )

  const sqlTableRef = modeSearchParam.get("index")
  const mode = modeSearchParam.get("mode")
  const launchID = modeSearchParam.get("launchid")

  const selectedCalcObject = launchData?.find(
    (launch) => launch.id === launchID
  )
  const lookupInstructionFromObj =
    mode === RUN_TYPES[1] ? selectedCalcObject?.package.instructions ?? {} : {}
  const lookupLength = Object.keys(lookupInstructionFromObj)?.length > 0
  const isDataValidState = service.isDataValid(postBody)
  const allValuesFilledState = service.allValuesFilled(checkingRangeWarning)
  const trackPostedInputs = useTrackEvent(appInsights, "PostedInputs", postBody)
  const compareVariableLength =
    emulatorConfig?.calculator?.InputVariables.length ===
    selectedOutputs?.variables.length
  const showHideBest = selectedOutputs?.bestResult
    ? props.setAsVariable
      ? compareVariableLength
      : props.pageOptimize
    : false
  const numberOfActiveAgents = props.socketMembers?.filter(
    (member) => member.type === "agent"
  )?.length
  const isSocketTestingLaunch = props.channelInstance ?? false

  useEffect(() => {
    setErrorFields(false)
    if (selectedOutputs?.max_iteration) {
      const timeString = selectedOutputs.max_time
      const [hours, minutes, seconds] = timeString.split(":").map(Number)
      const totalSeconds = hours * 3600 + minutes * 60 + seconds
      setTimeAndIterationValue({
        time: totalSeconds,
        iteration: selectedOutputs.max_iteration,
      })
    }
    if (lookupLength) {
      setLimitForLookup(lookupInstructionFromObj?.limit)
    }
  }, [])

  useEffect(() => {
    const mode = modeSearchParam.get("mode")
    if (mode !== tryitMode) {
      setTryItMode(mode)
    }
  }, [modeSearchParam, tryitMode])

  useEffect(() => {
    const errorState = error.max_iteration || error.max_time
    props.setValidateOptimizeButton &&
      props.setValidateOptimizeButton(errorState || !allValuesFilledState)
  }, [allValuesFilledState, error])

  useEffect(() => {
    if (props.pageOptimize) {
      setSelectedOutputs((prev) => ({
        ...prev,
        mode: tryitMode,
      }))
    }
  }, [trueModes, tryitMode])

  useEffect(() => {
    if (window.location.pathname.includes("tryit")) {
      const listOfInputs = {}
      postBody?.FeaturesData?.map((input) => {
        const changedLabel = input.Label?.replace("in ", "")
        listOfInputs[changedLabel] = input?.Data[0]
      })
      const existingParams = new URLSearchParams(modeSearchParam)
      Object.entries(listOfInputs).map(([key, value]) =>
        existingParams.set(key, value)
      )
      prjState.updateURLSearchParams(listOfInputs)
    }
  }, [postBody])

  useEffect(() => {
    if (tryitMode === RUN_TYPES[1] && lookupLength) {
      if (Object.keys(postBody).length && Object.keys(lookupObject).length) {
        setLookupObject((prev) => ({
          sql_table_ref: sqlTableRef,
          instructions: {
            ...prev.instructions,
            userFilters: prev.instructions?.userFilters?.map((filter) => {
              const correspondingNewFilter = postBody?.FeaturesData?.find(
                (newFilter) => newFilter.Label === filter.label
              )
              if (correspondingNewFilter?.Data[0]) {
                const value = Number.isNaN(
                  parseFloat(correspondingNewFilter?.Data[0])
                )
                  ? correspondingNewFilter?.Data[0]
                  : parseFloat(correspondingNewFilter?.Data[0])
                return {
                  ...filter,
                  value: value,
                }
              }
              return filter
            }),
          },
        }))
      } else {
        setLookupObject({
          sql_table_ref: sqlTableRef,
          instructions: lookupInstructionFromObj,
        })
      }
    }
  }, [lookupLength, postBody])

  const processClick = (e) => {
    e.preventDefault()

    //if launch id is changed, make change in postBody
    const updatedPostBody = service.generatePostBody(
      props.activeEmulatorId,
      postBody.FeaturesData,
      props.consumer
    )
    setPostBody(updatedPostBody)

    if (!isDataValidState) {
      setErrorFields(true)
    } else if (allValuesFilledState) {
      if (tryitMode === RUN_TYPES[1]) {
        processLookup(e)
      } else if (
        isSocketTestingLaunch &&
        tryitMode === emulatorMode.calculate
      ) {
        processSocketCalculate(updatedPostBody)
      } else {
        props.setCurrentIndex(0)
        trackPostedInputs()
        if (props.page === "optimize") {
          processOptimize()
        } else {
          props.setPostBody1(updatedPostBody)
        }
      }
    }
  }

  const processOptimize = () => {
    const updatedOptimise = {
      emulator_id: props.activeEmulatorId,
      instructions: {
        ...props.instructions,
        parameters:
          postBody.FeaturesData?.map((featureData) => ({
            label: featureData?.Label,
            value: featureData?.Data?.[0],
          })) || [],
      },
    }
    props.setPostBody1(updatedOptimise)
    setSelectedOutputs((prev) => ({
      ...prev,
      ...updatedOptimise,
    }))
  }

  const processSocketCalculate = (updatedPostBody) => {
    const { ref = "", supporting_refs = [] } = launchID
      ? selectedCalcObject.package.source
      : emulatorConfig.source

    const { dependency_set = "" } = launchID
      ? selectedCalcObject.package.source
      : emulatorConfig

    const correctedDependencySet = dependency_set.replace("-", "_")
    const isExcelNoneChannel = correctedDependencySet?.includes(
      emulatorSoftwareType.excel
    )
    const isPythonChannel = correctedDependencySet?.includes(
      emulatorSoftwareType.python
    )
    const key = launchID ? "launch_id" : "emulator_id"

    const ioBasedOnMode = launchID
      ? selectedCalcObject.package?.io
      : emulatorConfig?.calculator

    const inputVars = service.extractRequiredFields(
      ioBasedOnMode?.InputVariables,
      correctedDependencySet
    )
    const outputVars = service.extractRequiredFields(
      ioBasedOnMode?.OutputVariables,
      correctedDependencySet
    )
    const outputVarsForPythonOrGrasshopper = outputVars.map((output) => ({
      Ref: isPythonChannel ? output.PythonVariableName : output.ParamName,
    }))
    const inputsArrayWithPayLoadValues = inputVars?.map((input1) => {
      const match = postBody.FeaturesData?.find(
        (input2) => input2.Label === input1.ColumnLabel
      )

      return isExcelNoneChannel
        ? { ...input1, Value: match ? match.Data : null }
        : isPythonChannel
        ? { Ref: input1.PythonVariableName, Value: match ? match.Data : null }
        : { Ref: input1.ParamName, Value: match ? match.Data : null }
    })

    const socketPayload = {
      job_id: `socket-${uuidv4()}`,
      [key]: props.activeEmulatorId,
      ref: ref ?? "",
      supporting_refs: supporting_refs ?? [],
      dependency_set: correctedDependencySet ?? "",
      inputs: inputsArrayWithPayLoadValues,
      outputs: isExcelNoneChannel ? outputVars : outputVarsForPythonOrGrasshopper,
    }

    props.setSocketPostBody({
      payload: socketPayload,
      inputs: updatedPostBody,
    })
  }

  const showImportantNotes = (mode) => {
    const message =
      mode === EMULATOR_MODE
        ? "Please run the MLEvaluation to run the emulator."
        : mode === HOSTED_MODE
        ? "Please add Ref. Key in manage configs to run the calculator."
        : emulatorConfig?.ready_for_calcs
        ? "Please set Active Calculator file to run the calculator."
        : "The calculator is not ready for calculations."

    return (
      <core.Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <core.Typography variant="body2" className="ed-small">
          {message}
        </core.Typography>
      </core.Box>
    )
  }

  const getCalculatorWarning = () => {
    if (tryitMode === RUN_TYPES[0]) {
      return (
        <core.Typography
          color="secondary"
          variant="body2"
          className="ed-small ml-text-center"
        >
          Calculators are undergoing testing.
          <br /> Results may not always be available or correct.
        </core.Typography>
      )
    } else {
      return null
    }
  }

  const renderReasonForDisable = () => {
    switch (tryitMode) {
      case RUN_TYPES[2]:
        return emulatorConfig?.modes?.includes(RUN_TYPES[2])
          ? showImportantNotes(EMULATOR_MODE)
          : null
      case RUN_TYPES[0]:
        return emulatorConfig?.source?.type === HOSTED_MODE
          ? showImportantNotes(HOSTED_MODE)
          : emulatorConfig?.source?.type === CALCULATOR_MODE
          ? showImportantNotes()
          : null
      default:
        return null
    }
  }

  const convertToTimeFormat = (userInput) => {
    const totalSeconds = parseInt(userInput, 10)

    if (!isNaN(totalSeconds) && totalSeconds >= 0) {
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds % 3600) / 60)
      const seconds = totalSeconds % 60

      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      return formattedTime
    } else {
      return ""
    }
  }

  const handleInputChange = (e, label) => {
    const labelIsMaxIteration = label === "max_iteration"
    const value = e.target.value === "" ? "" : Number(e.target.value)
    const maxValue = labelIsMaxIteration ? 200 : 120
    const getConvertedTime = !labelIsMaxIteration && convertToTimeFormat(value)
    setTimeAndIterationValue((prev) => ({
      ...prev,
      [`${label.split("_")[1]}`]: value,
    }))
    if (
      (value > maxValue || value < 1 || value === "") &&
      siteWideRoleState !== "admin"
    ) {
      setError((prev) => ({
        ...prev,
        [label]: true,
      }))
      setErrorMessage(
        value < 1 || value === ""
          ? "Should be >0"
          : `Should not be >${maxValue}`
      )
    } else {
      setError((prev) => ({
        ...prev,
        [label]: false,
      }))
      setSelectedOutputs((prev) => ({
        ...prev,
        [label]: `${labelIsMaxIteration ? value : getConvertedTime}`,
      }))
    }
  }

  const handleLimitChange = (e) => {
    setLimitForLookup(e.target.value)
    setLookupObject((prev) => ({
      ...prev,
      instructions: {
        ...prev.instructions,
        limit: e.target.value,
      },
    }))
  }

  const openDialogBox = (e) => {
    e.preventDefault()
    setGlobalDialogOpen((prev) => ({
      ...prev,
      isOpen: true,
      content: onlineMembersList(),
      title: "Active Agents",
    }))
  }

  const processLookup = async (e) => {
    e.preventDefault()
    props.setLoader(true)
    try {
      const result = await EmulationLab.post(lookupRequest, lookupObject, {
        headers: {
          severity: SeverityLevel.Error,
        },
        params: { client: "TryIt", version: "beta" },
      })

      if (!result.data) {
        setResult([])
        props.setLoader(false)
      }
      const filterInputs = lookupObject.instructions?.defaultFilters?.filter(
        (defVariable) =>
          emulatorConfig?.calculator?.InputVariables.some(
            (inpvars) => inpvars.ColumnLabel === defVariable.label
          )
      )
      const constraint = [
        ...(lookupObject.instructions?.userFilters || []),
        ...(filterInputs || []),
      ]
      const featuresData = await service.convertConstraints(constraint, true)
      const outputvalues = await service.convertOutputVal(
        result.data,
        siteWideRoleState === "consumer"
          ? selectedCalcObject?.package?.io
          : emulatorConfig?.calculator
      )
      if (outputvalues) {
        const outputbody = {
          emulatorId: emulatorId,
          jobId: uuidv4(),
          inputValues: featuresData,
          outputValues: outputvalues,
          resultMode: "lookup",
          resultVersion: result.data[0]?.version ?? "",
          resultStatus: "complete",
        }
        service.saveLookupSession(outputbody)
        setResult((prev) => [outputbody, ...prev])
      }
      props.setFullView(FULL_VIEW_EXIT_TEXT)
    } catch (error) {
      console.log(error)
      setAlertState({
        boolState: true,
        message: "Some Error occured. Try again after some time!",
        severityState: "error",
      })
    } finally {
      props.setLoader(false)
    }
  }

  const onlineMembersList = () => (
    <m.Box sx={{ padding: "16px" }}>
      {props.socketMembers
        ?.filter((member) => member.type === "agent")
        ?.map((member, index) => (
          <m.Typography key={member.id} variant="body2">
            {index + 1}-&nbsp;<b>{member.id}</b>: {member.name}
          </m.Typography>
        ))}
    </m.Box>
  )

  const iterationTimeInputs = (label, value) => {
    const labelIsMaxIteration = label === "Max Generations"
    const labelValue = labelIsMaxIteration ? "max_iteration" : "max_time"
    return (
      <div className="ml-display-flex ml-flex-dir-col margin-left-20">
        <div>
          <m.Typography variant="subtitle2">{label}</m.Typography>
        </div>
        <m.TextField
          fullWidth
          autoComplete="off"
          type="number"
          InputLabelProps={{
            shrink: false,
            style: rangeTextFieldStyles.inputLabelPropsStyle,
          }}
          inputProps={{
            step: 1,
            style: rangeTextFieldStyles.inputPropsStyle,
          }}
          value={value}
          InputProps={{
            endAdornment: !labelIsMaxIteration && (
              <m.InputAdornment
                position="end"
                style={rangeTextFieldStyles.inputAdornmentStyle}
              >
                <m.Typography variant="subtitle2">sec</m.Typography>
              </m.InputAdornment>
            ),
            style: rangeTextFieldStyles.commonStyle,
          }}
          onChange={(e) => handleInputChange(e, labelValue)}
          focused={error[labelValue]}
          color={error[labelValue] ? "warning" : undefined}
          label={error[labelValue] && errorMessage}
        />
      </div>
    )
  }

  return (
    <core.Card style={{ padding: "16px" }}>
      <m.Grid container gap={"8px"}>
        <m.Grid
          item
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <m.Grid
            item
            className="ml-display-flex ml-justify-start ml-align-center ml-gap-1"
          >
            <m.Typography
              fontWeight={500}
              style={{ fontSize: "24px" }}
              color={"primary"}
            >
              Inputs
            </m.Typography>
            {props.randomizeBtn}
            {!props.pageOptimize && siteWideRoleState !== "consumer" && (
              <core.Button
                id="tryitbtn"
                title={"Get random set of inputs."}
                disabled={
                  props.page === "optimize"
                    ? props.disableInputs
                    : (!props.pageOptimize && tryitMode !== RUN_TYPES[1]
                        ? !trueModes?.includes(tryitMode)
                        : false) || props.loader
                }
                style={{
                  backgroundColor: "rgba(244,244,245,1)",
                  color: "black",
                }}
                variant="contained"
                onClick={() => props.setGenerateRandomInputs(true)}
              >
                <m.Typography variant="caption" margin="4px">
                  Randomize{" "}
                  {service.checkStatus(postBody) ? "Inputs" : "Missing Inputs"}
                </m.Typography>
              </core.Button>
            )}
          </m.Grid>
          <m.Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <m.Typography
              fontWeight={500}
              className={classes.colorGraySec}
              style={{ fontSize: "16px" }}
            >
              {props.calcConfig?.InputVariables?.length} Inputs
            </m.Typography>
            {isSocketTestingLaunch && (
              <m.Box sx={{ display: "flex", alignItems: "center" }}>
                <m.Typography
                  fontWeight={500}
                  className={classes.colorGraySec}
                  style={{ fontSize: "16px" }}
                >
                  {`${numberOfActiveAgents} Agents`}
                </m.Typography>
                <m.IconButton
                  disabled={numberOfActiveAgents === 0}
                  title="Agent Information"
                  onClick={openDialogBox}
                >
                  <InfoOutlinedIcon color="primary" fontSize="small" />
                </m.IconButton>
              </m.Box>
            )}
          </m.Box>
        </m.Grid>
        <m.Grid item xs={12}>
          <m.Divider />
        </m.Grid>

        <m.Grid item>
          <CalculatorInput
            calcId={props.activeEmulatorId}
            calcConfig={props.calcConfig}
            setPostBody2={setPostBody}
            setCheckingRangeWarning={setCheckingRangeWarning}
            checkingRangeWarning={checkingRangeWarning}
            postBody={postBody}
            generateRandomInputs={props.generateRandomInputs}
            setGenerateRandomInputs={props.setGenerateRandomInputs}
            pageOptimize={props.setAsVariable}
            page={props.page}
            instructions={props.instructions}
            consumer={props.consumer}
            lookupInstruction={lookupInstructionFromObj}
            disableInputs={props.disableInputs}
          />
        </m.Grid>

        <m.Divider />
        {(modeSearchParam.get("mode") === "lookup" ||
          modeSearchParam.get("mode") === "optimize") && (
          <m.Grid
            item
            xs={12}
            sx={{
              height: "100%",
            }}
          >
            <LookupDefaultFilterNSort />
          </m.Grid>
        )}
        <m.Grid
          item
          className={`${classes.calculatorBtnGroup} calc-toggle-mode`}
          style={{
            display: "flex",
            justifyContent: props.consumer ? "flex-end" : "space-between",
            width: "100%",
            padding: "16px 0",
            alignItems: "center",
          }}
        >
          {!props?.setAsVariable && !props.consumer && (
            <ModeButton
              tryitMode={tryitMode}
              setTryItMode={setTryItMode}
              pageOptimize={props.pageOptimize}
            />
          )}
          {props.pageOptimize && (
            <m.Grid
              item
              className="ml-display-flex width-100"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
              }}
            >
              <m.Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "start",
                }}
              >
                {!props?.setAsVariable && (
                  <>
                    <AlgorithmButton
                      selectedAlgorithm={selectedAlgorithm}
                      setSelectedAlgorithm={setSelectedAlgorithm}
                    />
                    {iterationTimeInputs(
                      "Max Generations",
                      timeAndIterationValue.iteration
                    )}
                    {iterationTimeInputs(
                      "Max Time",
                      timeAndIterationValue.time
                    )}
                  </>
                )}
              </m.Box>
              {!props.setAsVariable && (
                <m.Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  {showHideBest && (
                    <m.Typography variant="body2">
                      <b>{`Best Result (Historically):
                     ${service.roundValues(selectedOutputs?.bestResult)}`}</b>
                    </m.Typography>
                  )}
                </m.Box>
              )}
            </m.Grid>
          )}
          {!props.setAsVariable && (
            <m.Box className="ml-display-flex ml-flex-dir-col ml-align-center">
              <m.Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                {!props.pageOptimize && (
                  <core.Button
                    id="tryitbtn"
                    title={
                      !trueModes?.includes(tryitMode) &&
                      `${tryitMode} feature will be launched soon!`
                    }
                    disabled={
                      props.page === "optimize"
                        ? props.disableInputs
                        : tryitMode === RUN_TYPES[1]
                        ? !lookupLength
                        : !trueModes?.includes(tryitMode) ||
                          props.loader ||
                          (isSocketTestingLaunch && numberOfActiveAgents === 0)
                    }
                    color="primary"
                    variant="contained"
                    onClick={processClick}
                  >
                    <m.Typography variant="caption" margin="4px">
                      {tryitMode === RUN_TYPES[1]
                        ? `Do the ${RUN_TYPES[1]}`
                        : `Run the ${
                            tryitMode?.slice(0, -1) +
                            (tryitMode === emulatorMode.optimize ? "er" : "or")
                          }`}
                    </m.Typography>
                  </core.Button>
                )}
                {!props.pageOptimize && (
                  <>
                    {tryitMode === RUN_TYPES[1] && (
                      <m.Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: 1,
                        }}
                      >
                        <m.Typography variant="subHeading">
                          Select the number of results you would like to view:
                        </m.Typography>
                        <m.Select
                          sx={{
                            minWidth: "60px",
                          }}
                          disabled={!lookupLength}
                          autoComplete="off"
                          size="small"
                          value={limitforLookup}
                          onChange={handleLimitChange}
                        >
                          {Array.from({ length: 10 }, (_, index) => (
                            <m.MenuItem key={index + 1} value={index + 1}>
                              {index + 1}
                            </m.MenuItem>
                          ))}
                        </m.Select>
                      </m.Box>
                    )}
                  </>
                )}
              </m.Box>

              {isSocketTestingLaunch && numberOfActiveAgents === 0 && (
                <m.Typography variant="subHeading" className="ed-small">
                  No active agents to process job.
                </m.Typography>
              )}

              {!props.pageOptimize && !props.consumer && (
                <>
                  {!trueModes?.includes(tryitMode) && renderReasonForDisable()}
                  {getCalculatorWarning()}
                </>
              )}
            </m.Box>
          )}
        </m.Grid>
      </m.Grid>
    </core.Card>
  )
}

export default CalculatorInputCard
