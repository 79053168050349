import { Suspense, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Dialog, DialogContent, Button, Box } from "@material-ui/core"
import { createEmulator } from "../state/requests"
import * as files from "../GlobalFileContainer"
import { testJWTToken } from "../state/axios"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { JWTTokenState, mongoProfileState } from "../state/userState"
import {
  commonCategoryChangeHandler,
  dataSourceType,
  handleKeyDown,
  searchBlobData,
} from "../state/projectState"
import "./CreateNewEmulator.css"
import { alertStates } from "../state/vizState"
import { Divider, Typography, useTheme } from "@mui/material"
import { base64Regex, urlRegex } from "./project/Define/CalculatorConfig"
import { ReactComponent as DataSetIcon } from "../assets/newIcons/data-set.svg"
import { ReactComponent as CalcIcon } from "../assets/newIcons/calculator.svg"
import { blobData } from "../state/services"
import { emulatorMode } from "../state/StatusState"

function CreateNewEmulator({ closeCreate, trigger }) {
  const theme = useTheme()
  const formRef = useRef()
  const navigate = useNavigate()

  const setMongoProfile = useSetRecoilState(mongoProfileState)
  const setSearchBlob = useSetRecoilState(searchBlobData)
  const JWTToken = useRecoilValue(JWTTokenState)
  const setFiles = useSetRecoilState(dataSourceType)
  const setAlertState = useSetRecoilState(alertStates)

  const [newEmulator, setNewEmulator] = useState({
    name: "",
    description: "",
    image: "",
  })
  const [tagValue, setTagValue] = useState([])
  const [categoryValue, setCategoryValue] = useState("")
  const [tempCategoryAlert, setTempCategoryAlert] = useState("")
  const [textfieldError, setTextfieldError] = useState(false)
  const [fileType, setFileType] = useState("none")
  const [modes, setModes] = useState([])
  const [dataSource, setDataSource] = useState({
    type: "none",
    software: "",
    ref: "",
    method: "",
  })
  const [activeStep, setActiveStep] = useState(1)

  const handleInput = (e) => {
    const name = e.target.name
    const value = e.target.value
    setNewEmulator({ ...newEmulator, [name]: value })
    setTextfieldError(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    //taking the https://placeimg.com/400/400/animals as image url for each entry
    const emulatorName = e.target.name.value
    const email = localStorage.getItem("email")
    const description = e.target.description.value
    const image = e.target.image.value
    const mode = modes?.map((m) => m?.toLowerCase())
    const dataS =
      dataSource.type === "hosted_calculator"
        ? dataSource
        : {
            ...dataSource,
            type: dataSource.type,
            software: dataSource.software,
            ref: null,
            method: "",
          }
    var data = {}

    image
      ? (data = {
          owner_email: email,
          emulator_name: emulatorName,
          description: description,
          image_url: image,
          tags: tagValue,
          category: categoryValue,
          modes: mode,
          source: dataS,
        })
      : (data = {
          owner_email: email,
          emulator_name: emulatorName,
          description: description,
          tags: tagValue,
          category: categoryValue,
          modes: mode,
          source: dataS,
        })

    await SubmitManage(data)
  }

  const SubmitManage = async (data) => {
    if (newEmulator.name?.length === 0 || newEmulator.name?.trim() === "") {
      setTextfieldError({ name: true })
      return
    } else if (
      newEmulator.description?.length === 0 ||
      newEmulator.description?.trim() === ""
    ) {
      setTextfieldError({ description: true })
      return
    } else if (categoryValue?.length === 0) {
      setTextfieldError({
        category: true,
      })
      return
    } else if (
      newEmulator.image?.length > 0 &&
      !(urlRegex.test(newEmulator.image) || base64Regex.test(newEmulator.image))
    ) {
      setTextfieldError({ image: true })
      return
    }
    try {
      const response = await files.EmulationLab.post(createEmulator, data)
      setAlertState({
        boolState: true,
        message: "Emulator created Successfully!",
        severityState: "success",
      })
      closeCreate(false)
      testJWTToken(setMongoProfile, JWTToken)

      setTimeout(() => {
        const path = `/em/${response.data}/manage_configs`
        navigate(path)
      }, 2000)
      blobData(setSearchBlob)
      setFiles(fileType)
    } catch (error) {
      setAlertState({
        boolState: true,
        message:
          error.response.status === 409
            ? "Failed! Emulator name already in use. Try different name!"
            : "Some error occured in creating new emulator. Please try after some time.",
        severityState: "error",
      })
      closeCreate(false)
      console.error(error.response)
    }
  }

  const handleTagChange = (event, value, reason) => {
    setTagValue(value)
    setTextfieldError(false)
  }

  const handleModeChange = (value) => {
    if (modes[0] !== value) {
      setDataSource({
        type: "none",
        software: "",
        ref: "",
        method: "",
      })
    }
    setModes([value])
  }

  const handleCategoryChange = (e) => {
    const category = e.target.value
    setTempCategoryAlert(commonCategoryChangeHandler(category))
    setCategoryValue(e.target.value)
    setTextfieldError(false)
  }

  const sharedStrings = {
    title: "Tell us more about your function.",
    detail: (
      <form ref={formRef} onSubmit={handleSubmit}>
        <Box sx={{ flexGrow: 1 }}>
          <files.CreateEmulator
            newEmulator={newEmulator}
            handleInput={handleInput}
            handleCategoryChange={handleCategoryChange}
            handleTagChange={handleTagChange}
            tagValue={tagValue}
            categoryValue={categoryValue}
            tempCategoryAlert={tempCategoryAlert}
            textfieldError={textfieldError}
            source={dataSource}
            fileType={fileType}
            setFileType={setFileType}
            setDataSource={setDataSource}
            modes={modes}
            handleModeChange={handleModeChange}
            setTextfieldError={setTextfieldError}
            createEm
          />
        </Box>
      </form>
    ),
  }

  const dataFirstStrings = {
    2: { ...sharedStrings },
  }

  const functionFirstStrings = {
    3: { ...sharedStrings },
    2: {
      title: "Please select your function.",
      detail:
        "The Emulation Lab currently supports Excel files, Python functions, and Grasshopper definitions. To finalize your requirements, such as necessary libraries and add-ons, for hosting the calculators, please reach out to the team when you're ready to test your function.",
    },
  }

  const triggerFormSubmit = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    )
  }

  const dataFunstionString = {
    data: emulatorMode.emulate,
    functions: emulatorMode.calculate,
  }

  const selectedMode = modes?.[0]
  const isDataSelected = selectedMode === dataFunstionString.data
  const isFunctionSelected = selectedMode === dataFunstionString.functions

  const isCreateEmFormOpen = isDataSelected
    ? activeStep === 2
    : activeStep === 3

  const newEmOptionStrings = {
    selectingFunctionDetail: `If you’ve already created partial data and want Emulation Lab to host your 
                     function to generate more, start with 'I have a function.' You can add your 
                    existing data later.`,
    dataSet: {
      title: "The dataset is ready!",
      detail: `I’ve created the data, and it’s in CSV or other acceptable
                  formats. I want to use it for lookups or training machine
                  learning models.`,
    },
    functionSet: {
      title: "I have a function!",
      detail: ` I have an Excel file, Python function, or Grasshopper
                  definition, and I want to create an Emulator for calculation
                  purposes.`,
    },
  }

  return (
    <Dialog
      onKeyDown={(e) => handleKeyDown(e, closeCreate)}
      open={trigger}
      fullWidth={true}
      margin={"5px"}
      onClose={closeCreate}
      PaperProps={{
        style: {
          background: theme.palette.common.white,
          minWidth: "40vw",
        },
      }}
    >
      <files.AddDialogTitle
        closeCreate={closeCreate}
        title={"Add New Emulator"}
        createEm
      />
      <DialogContent dividers>
        <Suspense fallback={<files.Loader />}>
          <files.StepperStyleCreateEm
            activeStep={activeStep}
            isCreateEmFormOpen={isCreateEmFormOpen}
            title={
              activeStep === 1
                ? "Please select one of these options."
                : isDataSelected
                ? dataFirstStrings[activeStep]?.title
                : functionFirstStrings[activeStep]?.title
            }
            detail={
              activeStep === 1
                ? newEmOptionStrings.selectingFunctionDetail
                : (isDataSelected
                    ? dataFirstStrings[activeStep]?.detail
                    : functionFirstStrings[activeStep]?.detail) || ""
            }
          />
          {activeStep === 1 && (
            <Box
              display="flex"
              justifyContent="space-around"
              flexDirection={{ xs: "column", md: "row" }}
              p={4}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  className="cursor-pointer"
                  onClick={() => handleModeChange(dataFunstionString.data)}
                  sx={{
                    width: "200px",
                    height: "150px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    backgroundColor: isDataSelected
                      ? theme.palette.secondary.main
                      : theme.palette.grey.light,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <DataSetIcon width="100px" height="100px" />
                  <Typography variant="body1" textAlign="center">
                    {newEmOptionStrings.dataSet.title}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  mt={1}
                  textAlign="center"
                  width={"200px"}
                >
                  {newEmOptionStrings.dataSet.detail}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  className="cursor-pointer"
                  onClick={() => handleModeChange(dataFunstionString.functions)}
                  sx={{
                    width: "200px",
                    height: "150px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    backgroundColor: isFunctionSelected
                      ? theme.palette.secondary.main
                      : theme.palette.grey.light,
                  }}
                >
                  <Box
                    className="ml-display-flex ml-justify-center ml-align-end"
                    sx={{
                      height: "115px",
                    }}
                  >
                    <CalcIcon width="60px" height="80px" stroke="black" />
                  </Box>
                  <Typography variant="body1" textAlign="center">
                    {newEmOptionStrings.functionSet.title}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  mt={1}
                  textAlign="center"
                  width={"200px"}
                >
                  {newEmOptionStrings.functionSet.detail}
                </Typography>
              </Box>
            </Box>
          )}
          {activeStep === 2 && isFunctionSelected && (
            <files.SoftwareSelectionCards
              dataSource={dataSource}
              setDataSource={setDataSource}
              setFileType={setFileType}
            />
          )}
          <Divider />
          <Box className="ml-display-flex ml-space-between" mt={2}>
            <Button
              disabled={activeStep === 1}
              color="primary"
              variant="contained"
              onClick={() => setActiveStep(activeStep - 1)}
              style={{ color: theme.palette.common.white }}
            >
              Back
            </Button>
            <Box>
              <Button
                disabled={
                  (activeStep === 1 && !selectedMode) ||
                  (isFunctionSelected &&
                    activeStep === 2 &&
                    !dataSource.software)
                }
                color="primary"
                variant="contained"
                style={{ color: theme.palette.common.white }}
                onClick={() =>
                  isCreateEmFormOpen
                    ? triggerFormSubmit()
                    : setActiveStep(activeStep + 1)
                }
              >
                {(isCreateEmFormOpen && "Create my Emulator") || "Next"}
              </Button>
            </Box>
          </Box>
        </Suspense>
      </DialogContent>
    </Dialog>
  )
}

export default CreateNewEmulator
