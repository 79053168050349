import { useState, useEffect, Suspense } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import WarningIcon from "@mui/icons-material/Warning"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import * as core from "@material-ui/core"
import { Typography, useTheme } from "@mui/material"
import * as files from "../GlobalFileContainer"
import "./UploadData.css"
import { uploadEmFiles } from "../state/projectState"
import useStyles from "./card_style"
import {
  errorStrings,
  failedValidationNote,
  inputOutputLabels,
} from "../state/StatusState"

function UploadData() {
  const { emulatorId } = useParams()
  const navigate = useNavigate()
  const classes = useStyles()
  const theme = useTheme()

  const getEmulatorFiles = `/emulators/${emulatorId}/data/files`
  const badReq = `/emulators/${emulatorId}/data/validate?verbose=False`

  const [uploadEmFilesState, setUploadEmFilesState] =
    useRecoilState(uploadEmFiles)

  const [fileList, setFileList] = useState([])
  const [columnValidation, setColumnValidation] = useState("")
  const [row, setRow] = useState("")
  const [badData, setBadData] = useState("")
  const [visible, setVisible] = useState(false)
  const [showHeader, setShowHeader] = useState(false)
  const [showRow, setShowRow] = useState(false)
  const [data, setData] = useState({})
  const [count, setCount] = useState(10)
  const [loadMore, setLoadMore] = useState(true)

  const columnRow_data = (showHeader && columnValidation) || (showRow && row)

  useEffect(() => {
    setUploadEmFilesState((prevState) => ({
      ...prevState,
      updateState: false,
    }))
  }, [])

  useEffect(() => {
    getFiles()
  }, [setFileList])

  useEffect(() => {
    if (fileList.length > 0) {
      setVisible(true)
    }
  }, [fileList])

  useEffect(() => {
    const getData = () => {
      var t_data = {}
      var t_count = 0
      if (columnRow_data) {
        setLoadMore(false)
        if (Object.keys(columnRow_data)?.length > 1) {
          Object.keys(columnRow_data).map((key) => {
            if (
              columnRow_data[key].length > 0 &&
              columnRow_data[key]?.find((ob) =>
                errorStrings.includes(ob.severity)
              )
            ) {
              if (t_count < count) {
                t_data[key] = columnRow_data[key]
                t_count = t_count + columnRow_data[key].length
              } else {
                setLoadMore(true)
                return true
              }
            }
          })
        }
      }
      setData(t_data)
    }
    getData()
  }, [columnRow_data, count])

  const onReset = () => {
    setCount(10)
    setRow("")
    setColumnValidation("")
  }

  const getFiles = async () => {
    !uploadEmFilesState.loaderState &&
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        loaderState: true,
        loaderMsg: "Loading Data...",
      }))
    try {
      const response = await files.EmulationLab.get(getEmulatorFiles)
      setFileList(
        response.data.map((v) => ({
          ...v,
          head: v.head || "",
          row: v.row || "",
          validateData: v.validateData || "",
        }))
      )
      if (response.status === 200) {
        setUploadEmFilesState((prevState) => ({
          ...prevState,
          listStatus: true,
          loaderState: false,
        }))

        if (fileList.length > 0) {
          setVisible(true)
        }
      }
    } catch (error) {
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        listStatus: false,
        loaderState: false,
      }))

      alert("Failed to load the files, please try again..")
    }
  }

  const convertObjForHover = (thisObj) => {
    try {
      delete thisObj[inputOutputLabels.input]
      delete thisObj[inputOutputLabels.output]
      // filter out empty values, and arrays
      var objArray = Object.entries(thisObj).filter((k) => {
        const v = k[1]

        if (Array.isArray(v)) {
          return v.length > 0
        } else {
          return Boolean(v)
        }
      })
      // build hover string
      const hoverTextArray = objArray.map((k) => {
        const v = k[1]

        if (Array.isArray(v)) {
          return `${k}: ${v.slice(0, 5).join(", ")}`
        } else {
          return `${k}: ${v}`
        }
      })
      return hoverTextArray
    } catch {
      return thisObj
    }
  }

  const handleBadReq = (e) => {
    e.preventDefault()
    setShowHeader(false)
    setShowRow(false)
    fileList.map((f) => {
      try {
        setUploadEmFilesState((prevState) => ({
          ...prevState,
          loaderState: true,
          loaderMsg: "Processing...",
        }))

        files.EmulationLab.post(badReq, {
          key: `${f.key}`,
        })
          .then((validData) => {
            setUploadEmFilesState((prevState) => ({
              ...prevState,
              updateState: true,
              loaderState: false,
            })),
              (f["validateData"] = validData?.data),
              (f["head"] = {
                err: f?.validateData?.columns?.error_count || 0,
                warn: f?.validateData?.columns?.warning_count || 0,
                data_for_hover: convertObjForHover(f?.validateData?.columns),
              }),
              (f["row"] = {
                err: f?.validateData?.rows?.error_count || 0,
                warn: f?.validateData?.rows?.warning_count || 0,
                data_for_hover: convertObjForHover(f?.validateData?.rows),
              })
            setBadData(fileList?.map((v) => v))
          })
          .catch((err) => {
            console.error(err)
            setUploadEmFilesState((prevState) => ({
              ...prevState,
              updateState: true,
              loaderState: false,
            })),
              (f["head"] = {
                err: failedValidationNote,
                warn: failedValidationNote,
              }),
              (f["row"] = {
                err: failedValidationNote,
                warn: failedValidationNote,
              })
            setBadData(fileList?.map((v) => v))
          })
      } catch (error) {
        setUploadEmFilesState((prevState) => ({
          ...prevState,
          loaderState: false,
        }))
      }
    })
  }

  const handleRouteConfig = () => {
    navigate(`/em/${emulatorId}/manage_configs`)
  }

  const validBtn = () => {
    return (
      <core.Box id="btnvalidatewrap">
        <core.Button
          variant="contained"
          color="primary"
          id="btnvalidate"
          style={{ color: theme.palette.common.white }}
          classes={{ label: classes.buttonLabel }}
          onClick={handleRouteConfig}
          disabled={!visible}
        >
          <Typography variant="caption" margin={"4px"}>
            Generate Config
          </Typography>
        </core.Button>
        <core.Button
          variant="contained"
          color="primary"
          id="btnvalidate"
          style={{ color: theme.palette.common.white }}
          classes={{ label: classes.buttonLabel }}
          onClick={handleBadReq}
          disabled={!visible}
        >
          <Typography variant="caption" margin={"4px"}>
            Validate Data
          </Typography>
        </core.Button>
        <files.UpdateStatus />
      </core.Box>
    )
  }

  const LoadMoreBtn = () => {
    return (
      <core.Box display="flex" justifyContent="center" marginBottom="10px">
        <core.Button
          variant="contained"
          color="primary"
          id="btnvalidate"
          style={{ color: theme.palette.common.white }}
          onClick={() => setCount(count + 10)}
          disabled={!loadMore}
        >
          <Typography variant="caption" margin={"4px"}>
            Load More
          </Typography>
        </core.Button>
      </core.Box>
    )
  }

  const showFileName = () => {
    return `Validation Data for "${uploadEmFilesState.fileName}".`
  }

  const showErrorsDescription = (key, data, icon) => (
    <core.Box key={key}>
      <core.Box className="icondiv">
        <core.Typography variant="h6" id="badheaderheading">
          {icon ? (
            <CancelOutlinedIcon
              color={theme.palette.status.danger}
              className="ml-p-top-10"
            />
          ) : (
            <WarningIcon className={`${classes.colorSecondary} ml-p-top-10`} />
          )}
          {key}
        </core.Typography>
      </core.Box>
      {data[key].map((m, i) => (
        <ul key={`${m.category}-${i}`} className="ulchild">
          <li>
            <Typography variant="body1">{m.issue}</Typography>
          </li>
        </ul>
      ))}
    </core.Box>
  )

  return (
    <div className="ml-p-5">
      {uploadEmFilesState.loaderState && (
        <files.Loader
          linear={true}
          message={uploadEmFilesState.loaderMsg ?? "Loading Data..."}
        />
      )}
      <files.UploadEmulatorFiles
        fileType="data"
        isTeamMember={true}
        getFiles={getFiles}
        fileList={uploadEmFilesState.updateState ? badData : fileList}
        setFileList={setFileList}
        setShowHeader={setShowHeader}
        setShowRow={setShowRow}
        validBtn={validBtn}
        onReset={onReset}
        setRow={setRow}
        setColumn={setColumnValidation}
      />
      {(columnValidation !== "" || row !== "") && (
        <Suspense fallback={<files.Loader />}>
          <core.Box className="validationbox">
            {(showHeader && columnValidation !== "" && (
              <core.Box className="filenamewrap">
                <core.Typography
                  gutterBottom
                  variant="h6"
                  style={{ fontWeight: 700 }}
                  className="validateheadings"
                >
                  {showFileName()}
                </core.Typography>
              </core.Box>
            )) ||
              (showRow && row !== "" && (
                <core.Box className="filenamewrap">
                  <core.Typography gutterBottom variant="h6">
                    {showFileName()}
                  </core.Typography>
                </core.Box>
              ))}
            {(showHeader || data) &&
              Object.keys(data)?.length > 0 &&
              Object.keys(data)?.map(
                (key) =>
                  data[key].length > 0 &&
                  data[key]?.find((ob) => ob.severity === "warning") &&
                  showErrorsDescription(key, data)
              )}
            {Object.keys(data)?.length > 0 &&
              (showHeader || showRow) &&
              Object.keys(data).map(
                (key) =>
                  data[key].length > 0 &&
                  data[key]?.find((ob) => ob.severity === "error") &&
                  showErrorsDescription(key, data, "data")
              )}
            {((showHeader && columnValidation !== "") ||
              (showRow && row !== "")) && <LoadMoreBtn />}
          </core.Box>
        </Suspense>
      )}
    </div>
  )
}

export default UploadData
