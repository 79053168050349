import { useState, useEffect } from "react"
import * as router from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import * as file from "../GlobalFileContainer"
import * as storedStates from "../state/projectState"
import { fetchStableCards } from "../state/requests"
import { mongoProfileState } from "../state/userState"
import { isUserTeamMember } from "../state/services"

const groupByVersion = (data) => {
  return data.reduce((acc, item) => {
    const normalizedVersion = item?.sql_table_ref

    if (!acc[normalizedVersion]) {
      acc[normalizedVersion] = []
    }
    acc[normalizedVersion].push(item)
    return acc
  }, {})
}

const ConsumerFlowEndPoint = () => {
  const { emulatorId } = router.useParams()

  const setEmulatorConfig = useSetRecoilState(storedStates.getEmulatorData)
  const [emulatorLaunch, setEmulatorLaunch] = useRecoilState(
    storedStates.getEmulatorunch
  )
  const setGroupData = useSetRecoilState(
    storedStates.emulatorLauncheGroupDataState
  )
  const [cards, setCards] = useRecoilState(storedStates.cardsApiResponse)
  const openBot = useRecoilValue(storedStates.openBotState)
  const setOpenSidebar = useSetRecoilState(storedStates.showSideBarState)
  const mongoProfile = useRecoilValue(mongoProfileState)

  const [loader, setLoader] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedCardData, setSelectedCardData] = useState([])

  useEffect(() => {
    if (!openBot) {
      setOpenSidebar(true)
    }
  }, [location.pathname, location.search])

  const initializeRecoilStates = async () => {
    setLoader(true)
    try {
      const emulatorURL = `/emulators/${emulatorId}`

      const { data, status } = await file.EmulationLab.get(emulatorURL)
      if (status === 200) {
        setEmulatorConfig(data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoader(false)
    }
  }

  const initializeLaunchData = async () => {
    try {
      const launchURL = `/emulators/${emulatorId}/launches`
      const { data, status } = await file.EmulationLab.get(launchURL)
      if (status === 200) {
        setEmulatorLaunch(data)
        setSelectedOptions(
          Array.from(
            new Set(
              data?.map((launchDta) => launchDta?.package?.source?.software)
            )
          )
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  const initializeCardStates = async () => {
    try {
      const { data, status } = await file.EmulationLab.get(fetchStableCards)
      if (status === 200) {
        setCards(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    initializeRecoilStates()
    initializeLaunchData()
    if (cards.length === 0) {
      initializeCardStates()
    }
  }, [emulatorId])

  useEffect(() => {
    const filteredDataBasedOnSelectedFileType = emulatorLaunch.filter(
      (cardData) => {
        const isSoftwareIncluded = selectedOptions?.includes(
          cardData?.package?.source?.software
        )
        const isUserInTeam = isUserTeamMember(mongoProfile, cardData)

        return (
          isSoftwareIncluded &&
          (cardData?.step === "launched" ||
            mongoProfile.active_user_mode !== "consumer" ||
            isUserInTeam)
        )
      }
    )
    const data = groupByVersion(filteredDataBasedOnSelectedFileType)
    setGroupData(data)
  }, [selectedOptions, emulatorLaunch])

  return (
    <>
      {loader && <file.Loader />}
      {!loader && (
        <>
          <file.MetaTags />
          <file.CommonPageLayout
            componentSidebar={
              <file.ConsumerFlowLaunchSidebar
                selectedCardData={selectedCardData}
              />
            }
            mainPageContent={
              <file.LaunchSidebarRoutes
                selectedOptions={selectedOptions}
                selectedCardData={selectedCardData}
                setSelectedOptions={setSelectedOptions}
                setSelectedCardData={setSelectedCardData}
              />
            }
          />
        </>
      )}
    </>
  )
}

export default ConsumerFlowEndPoint
