import axios from "axios"
import { atom, selector, selectorFamily } from "recoil"
import EmulationLab, { cleanReturnJson } from "./axios"
import * as requests from "./requests"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import {
  EMPTY_STATES,
  allStatus,
  excel,
  grasshopper,
  mathcad,
  RUN_TYPES,
  TRAINING_ARRAY,
  optimiseModesStrings,
} from "./StatusState"
import { mongoProfileState } from "./userState"

const mode = "PROD"
const devUrl = "http://127.0.0.1:5000"
const prodUrl = "https://emulation-lab-services.azurewebsites.net:80" //http://emulator-factory-dev.us-east-2.elasticbeanstalk.com
const rootUrl = mode === "DEV" ? devUrl : prodUrl

export async function fetchEmulator(activeEmulatorId) {
  if (activeEmulatorId) {
    const request = await EmulationLab.get(
      requests.fetchEmulatorConfig + activeEmulatorId,
      {
        headers: {
          severity: SeverityLevel.Error,
        },
      }
    )

    return cleanReturnJson(request.data)
  } else {
    return { data: { InputVariables: [], OutputVariables: [] } }
  }
}

export async function fetchUnits() {
  const request = await EmulationLab.get(requests.unitUrls, {
    headers: {
      severity: SeverityLevel.Warning,
    },
  })

  return cleanReturnJson(request.data)
}

export async function fetchLaunches() {
  const response = await EmulationLab.get(requests.launchRequest, {
    headers: { severity: SeverityLevel.Warning },
  })
  return response.data
}

export async function fetchDataNotes() {
  const response = await EmulationLab.get(requests.fetchNotification, {
    headers: { severity: SeverityLevel.Warning },
  })
  return response.data
}

export async function updateTheStatus(emulator_id, status) {
  const url = `/emulators/${emulator_id}/status`
  const response = await EmulationLab.post(url, { status: status })
  return response.data
}

export async function getAgentHealth() {
  const response = await EmulationLab.get(requests.agentHealthURL)
  return response.data
}

export async function getAgentQueue() {
  const response = await EmulationLab.get(requests.agentQueueURL)
  return response.data
}

export async function getCurrentJob() {
  const response = await EmulationLab.get(requests.currentJobURL)
  return response.data
}

export async function getRecentJob() {
  const response = await EmulationLab.get(requests.recentJobURL)
  return response.data
}

export async function getAgentCapability() {
  const response = await EmulationLab.get(requests.agentCapabilityURL)
  return response.data
}

export const getSingleEmulator = selector({
  key: "getSingleEmulator",
  get: ({ get }) => fetchEmulator(get(getSingleEmulatorId)),
})

export const getAllEmulatorLaunches = selector({
  key: "getAllEmulatorLaunches",
  get: ({ get }) => fetchLaunches(get(getSingleEmulatorId)),
})

export const getSingleEmulatorName = selector({
  key: "getSingleEmulatorName",
  get: ({ get }) => get(getSingleEmulator).name,
})

export const getSingleEmulatorLatestV = selector({
  key: "getSingleEmulatorLatestV",
  get: ({ get }) => get(getSingleEmulator)?.versions?.latest_stable,
})

export const getSingleEmulatorBeta = selector({
  key: "getSingleEmulatorBeta",
  get: ({ get }) => get(getSingleEmulator)?.versions?.beta,
})

export const getBeta = atom({
  key: "getBeta",
  default: getSingleEmulatorBeta,
})

export const getLatestV = atom({
  key: "getLatestV",
  default: getSingleEmulatorLatestV,
})

export const getSingleEmulatorId = atom({
  key: "getSingleEmulatorId",
  default: "",
})

export const getEmulatorData = atom({
  key: "getEmulatorData",
  default: getSingleEmulator,
})

export const getEmulatorunch = atom({
  key: "getEmulatorunch",
  default: [],
})

export const cardFilterSearchStringState = atom({
  key: "cardFilterSearchStringState",
  default: "",
})

export const searchedJobIdState = atom({
  key: "searchedJobIdState",
  default: "",
})

export const InputSuggestionsList = atom({
  key: "InputSuggestionsList",
  default: [],
})

export const dataSet = atom({
  key: "dataSet",
  default: 0,
})

export const searchBlobData = atom({
  key: "searchBlobData",
  default: [],
})

export const emulatorStatus = atom({
  key: "emulatorStatus",
  default: "",
})

export const apiNotificationData = atom({
  key: "apiNotificationData",
  default: [],
})

export const uploadEmFiles = atom({
  key: "uploadEmFiles",
  default: {
    listStatus: false,
    updateState: false,
    loaderState: false,
    loaderMsg: "",
    fileName: "",
  },
})

export const manageConfigsNames = atom({
  key: "manageConfigsNames",
  default: {
    names: [],
    nickNames: [],
    columnLabels: [],
  },
})

export const appLayout = atom({
  key: "appLayout",
  default: "grid",
})

export const cardsApiResponse = atom({
  key: "cardsApiResponse",
  default: [],
})

export const manageConfigsSameValues = atom({
  key: "manageConfigsSameValues",
  default: {
    isSameName: false,
    isSameNameError: false,
    isSameNickName: false,
    isSameNickNameError: false,
    isSamecolumnLabel: false,
    isSameColumnLabelError: false,
  },
})

export const calculatorResult = atom({
  key: "calculatorResult",
  default: [],
})

export const calculatorResultLoader = atom({
  key: "calculatorResultLoader",
  default: false,
})

export const imagesState = atom({
  key: "imagesState",
  default: [],
})

export const imageIndex = atom({
  key: "imageIndex",
  default: -1,
})

export const imageLoader = atom({
  key: "imageLoader",
  default: true,
})

export const fetchAnnotationsState = atom({
  key: "fetchAnnotationsState",
  default: true,
})

export const galleryDialogModeTitle = atom({
  key: "galleryDialogModeTitle",
  default: "",
})

export const sidebarWidth = atom({
  key: "sidebarWidth",
  default: true,
})

export const finalTestObject = atom({
  key: "finalTestObject",
  default: {},
})

export const exploreImagesState = atom({
  key: "exploreImagesState",
  default: [],
})

export const temporaryCategoryAlert = [
  {
    type: "temporary",
    message: "Emulators with temporary category may get removed",
  },
  {
    type: "other",
    message: "For new categories, use other and then contact admin",
  },
]

export const authorization = {
  editMetadataOrManageCollaborator: ["admin", "director", "manager"],
  manageIO: ["admin", "director", "manager", "technician"],
  uploadEmFilesOrTrain: [
    "admin",
    "ML Expert",
    "director",
    "manager",
    "technician",
  ],
  uploadDownloadPipelines: ["admin", "ML Expert"],
  deleteEmulator: ["admin", "director"],
}

export const resultJobIdState = atom({
  key: "resultJobIdState",
  default: "",
})

export const openNewOptimiseState = atom({
  key: "openNewOptimiseState",
  default: false,
})

export const editAlertBoxState = atom({
  key: "editAlertBox",
  default: {},
})

export const updateVar = atom({
  key: "updateVar",
  default: false,
})

export const addData = atom({
  key: "addData",
  default: false,
})

export const dataSourceType = atom({
  key: "dataSourceType",
  default: "excel",
})

export const featureSelectionReload = atom({
  key: "featureSelectionReload",
  default: false,
})

export const chipPositionState = atom({
  key: "chipPositionState",
  default: [],
})

export const versionName = atom({
  key: "versionName",
  default: getLatestV === "None" ? "(Beta)" : "(Released)",
})

export const selectedVersion = atom({
  key: "selectedVersion",
  default: getLatestV === "None" ? getBeta : getLatestV,
})

export const errorFieldsState = atom({
  key: "errorFieldsState",
  default: false,
})

export const filesFromDropzone = atom({
  key: "filesFromDropzone",
  default: [],
})

export const getGraphResult = atom({
  key: "getGraphResult",
  default: {},
})

export const agentHealthData = atom({
  key: "agentHealthData",
  default: {},
})

export const agentQueueData = atom({
  key: "agentQueueData",
  default: {
    queuedData: [],
    currentJobData: [],
    recentData: [],
    newlyAddedRows: [],
    updatedProgressCell: [],
  },
})

export const menuItemState = atom({
  key: "menuItemState",
  default: "",
})

export const agentQuedLoader = atom({
  key: "agentQuedLoader",
  default: true,
})

export const agentCapabilityData = atom({
  key: "agentCapabilityData",
  default: {},
})

export const agentSearchedData = atom({
  key: "agentSearchedData",
  default: [],
})

export const agentDataFilter = atom({
  key: "agentDataFilter",
  default: {
    status: [],
    capability: [],
    capabilityData: [],
  },
})

export const refreshButtonPollingStates = atom({
  key: "refreshButtonPollingStates",
  default: {
    progress: 0,
    pollingState: false,
    currentPollingState: false,
    recentPollingState: false,
    time: 10,
  },
})

export const outputsParametersAndVariables = atom({
  key: "outputsParametersAndVariables",
  default: {
    objectives: [],
    parameters: [],
    variables: [],
    max_time: "00:02:00",
    max_iteration: 200,
    constraints: [],
    bestResult: "",
    mode: "calculate",
    solver: optimiseModesStrings.genetic,
  },
})

export const jsonDialogOpenClose = atom({
  key: "jsonDialogOpenClose",
  default: {
    state: false,
    obj: {},
  },
})

export const globalDialogBox = atom({
  key: "globalDialogBox",
  default: {
    isOpen: false,
    content: null,
    title: "",
    style: {},
    maxWidth: "md",
    actions: null,
  },
})

export const defaultTestStatus = atom({
  key: "defaultTestStatus",
  default: {
    name: "",
    status: "",
  },
})

export const startGeneratingImage = atom({
  key: "startGeneratingImage",
  default: false,
})

export const estimatedTimeState = atom({
  key: "estimatedTimeState",
  default: {},
})

export const lookupInstructionFinal = atom({
  key: "lookupInstructionFinal",
  default: {},
})

export const projectSummaryState = selectorFamily({
  key: "projectSummary",
  get: (emulatorId) => async () => {
    if (mode === "DEV") {
      return
    }
    const projectSummary = await EmulationLab.get(
      `/emulators/${emulatorId}/data/summary`
    ).then((res) => res.json())
    return projectSummary
  },
})

export const getValidatedSubStatus = async (emConfig, emFiles) => {
  if (emConfig?.progress) {
    const { calculator, modes, status, data, training } = emConfig
    const { ref, type } = emConfig.source
    const allCalculatorExtension = [...excel, ...mathcad, ...grasshopper]
    const inpVarLength = calculator["InputVariables"]?.length || 0
    const outVarLength = calculator["OutputVariables"]?.length || 0
    const hasConfigs = inpVarLength > 0 && outVarLength > 0
    const isSynthesizer =
      (emFiles && emFiles[0]?.name.includes("synthesizer")) || false
    const findSubStatus = () => {
      let statusObject = {
        [allStatus[0]]: true,
        [allStatus[1]]: false,
        [allStatus[2]]: false,
        [allStatus[3]]: false, //release
        [allStatus[4]]: false, //upload
        [allStatus[5]]: false, //reference
        [allStatus[6]]: false, //synt
        [allStatus[7]]: false,
        [allStatus[8]]: false,
        [allStatus[9]]: false,
        [allStatus[10]]: false,
        [allStatus[11]]: false,
      }
      const modeEmulateOrDataFirst = () => {
        if (modes.includes("emulate")) {
          if (data.total_row_count > 0) {
            statusObject[allStatus[4]] = true
            statusObject[allStatus[7]] = true
          } else if (isSynthesizer) {
            statusObject[allStatus[6]] = true
            statusObject[allStatus[7]] = true
          }

          if (statusObject[allStatus[7]] && training) {
            const hasActiveAlgo = Object.values(training).some(
              (item) =>
                "active_algorithm" in item &&
                !EMPTY_STATES.includes(item?.active_algorithm)
            )
            statusObject[allStatus[9]] = true
            if (hasActiveAlgo) {
              statusObject[allStatus[10]] = true
              if (status === "deployed") {
                statusObject[allStatus[11]] = true
              }
            }
          }
        }
      }

      if (hasConfigs) {
        statusObject[allStatus[1]] = true
        if (
          modes.includes("calculate") &&
          ((type === "calculator" &&
            allCalculatorExtension?.includes(
              ref?.split(".")[1]?.toLocaleLowerCase()
            )) ||
            (type === "hosted_calculator" && ref))
        ) {
          statusObject[allStatus[2]] = true
          if (status === "deployed") {
            statusObject[allStatus[3]] = true
          }
        }
        modeEmulateOrDataFirst()
      } else {
        modeEmulateOrDataFirst()
      }
      return statusObject
    }

    const subStatus = findSubStatus()
    return subStatus
  } else return {}
}

export const updateProgressInConfig = async (
  keysWithTrueValue,
  emulatorConfig,
  setEmulatorProgress
) => {
  const configProgress = emulatorConfig?.progress || {}
  let emProgress = {
    initialized: {
      ideaInitiated: false,
      functionDefined: false,
    },
    calculate: {
      calculatorTesting: false,
      calculatorReleased: false,
    },
    data: {
      dataUpload: false,
      dataSynthesis: false,
      dataReference: false,
      dataAvailable: false,
      lookupReleased: false,
    },
    emulate: {
      trainingInProgress: false,
      emulatorTesting: false,
      emulatorReleased: false,
    },
  }

  for (const key in emProgress) {
    for (const subKey in emProgress[key]) {
      keysWithTrueValue.forEach((value) => {
        const lowercaseString = value.replace(/\s/g, "").toLowerCase()
        if (lowercaseString === subKey.toLowerCase()) {
          emProgress[key][subKey] = true
        }
      })
    }
  }

  if (JSON.stringify(configProgress) !== JSON.stringify(emProgress)) {
    try {
      let emulatorId = emulatorConfig.id
      let url = `/emulators/${emulatorId}/progress`
      const response = await EmulationLab.post(url, { progress: emProgress })
      setEmulatorProgress(emProgress)
      response && fetchEmulator(emulatorConfig.id)
    } catch (error) {
      console.log(error)
    }
  }
}

export const getDataFiles = async (emulatorId) => {
  try {
    let url = `/emulators/${emulatorId}/data/files`
    const res = await EmulationLab.get(url)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const emulatorActiveSubStatus = selector({
  key: "emulatorActiveSubStatus",
  get: async ({ get }) => {
    const emConfig = get(getEmulatorData)
    const initialSubStatus = await getValidatedSubStatus(emConfig, [])
    const keysWithTrueValue = Object.entries(initialSubStatus)
      .filter(([, value]) => value === true)
      .map(([key]) => key)
    let returnValue = ""

    if (keysWithTrueValue.length > 0) {
      returnValue = keysWithTrueValue[keysWithTrueValue.length - 1]
    }

    return returnValue
  },
})

export const capabilityDependencyData = selector({
  key: "capabilityDependencyData",
  get: async ({ get }) => {
    const groupedDependencyData = {}
    const agentHealthDataState = get(agentHealthData)
    Object.keys(agentHealthDataState).length > 0 &&
      agentHealthDataState?.table?.forEach(
        ({ agent_id, capability, dependency_sets }) => {
          if (!groupedDependencyData[agent_id]) {
            groupedDependencyData[agent_id] = []
          }
          dependency_sets &&
            dependency_sets?.length > 0 &&
            dependency_sets?.map((dp) =>
              groupedDependencyData[agent_id].push(`${capability}: ${dp}`)
            )
        }
      )

    return groupedDependencyData
  },
})

export const emulatorProgressStore = atom({
  key: "emulatorProgressStore",
  default: emulatorActiveSubStatus,
})

export const siteWideRole = selector({
  key: "siteWideRole",
  get: async ({ get }) => {
    const activeMode =
      get(mongoProfileState).active_user_mode ??
      get(mongoProfileState).useModes[0]
    return activeMode
  },
})

export const emulatorSpecificRole = selector({
  key: "emulatorSpecificRole",
  get: async ({ get }) => {
    const emTeam = get(getEmulatorData).team
    const siteWide = get(siteWideRole)
    const director = emTeam && emTeam.director
    const members = emTeam && emTeam.members
    const userEmail = localStorage.getItem("email")
    if (siteWide === "consumer") {
      return "em"
    }
    if (director && director.email === userEmail) {
      return "director"
    } else {
      const foundEmailAtIndex =
        members && members.findIndex((obj) => obj.email === userEmail) + 1
      const role =
        (await foundEmailAtIndex) &&
        foundEmailAtIndex !== 0 &&
        members[foundEmailAtIndex - 1].approved_role
          ? members[foundEmailAtIndex - 1].approved_role
          : "em"
      return role
    }
  },
})

export const projectDatasetState = selectorFamily({
  key: "projectDataset",
  get: (param) => async () => {
    const dataset = EmulationLab.get(param)
      .then((res) => cleanReturnJson(res.data))
      .catch((err) => {
        console.error(err)
        return []
      })
    return dataset
  },
})

export const projectDatasetTruncatedState = selectorFamily({
  key: "projectDatasetTruncated",
  get: (emulatorId) => async () => {
    const projectDataset = await axios(
      `${rootUrl}/emulators/${emulatorId}/data/0/100`
    ).then((res) => res.json())
    return projectDataset
  },
})

export const emulatorQuery = selector({
  key: "Emulators",
  get: async () => {
    try {
      const res = await axios("")
      return res.data || []
    } catch (error) {
      console.error(error)
    }
  },
})

export const projectCorrelationState = selectorFamily({
  key: "projectCorrelation",
  get: (emulatorId) => async () => {
    const corr = await EmulationLab.get(
      `/emulators/${emulatorId}/data/charts/correlation`
    )
      .then((res) => {
        return cleanReturnJson(res.data)
      })
      .catch((err) => {
        console.error(err)
        return []
      })
    return corr
  },
})

export const projectScatterplotMatrixState = selectorFamily({
  key: "projectScatterplotMatrix",
  get: (emulatorId) => async () => {
    if (emulatorId !== "none" || emulatorId !== undefined) {
      const matrix = await fetch(
        `${rootUrl}/emulators/${emulatorId}/data/charts/scatterplot-matrix`
      ).then((res) => res.json())
      return matrix
    }
  },
})

export const returnMetadataValue = selector({
  key: "returnMetadataValue",
  get: async () => {
    const response = await EmulationLab.get(requests.getMetadata)
      .then((res) => res.data)
      .catch((err) => {
        console.error(err)
        return {}
      })
    return response
  },
})

export const returnUserList = selector({
  key: "returnUserList",
  get: async () => {
    try {
      const response = await EmulationLab.get(requests.getUserList)
      return response.data
    } catch (error) {
      console.error(error)
      throw new Error("Failed to fetch user list")
    }
  },
})

export const findTrueModes = selector({
  key: "findTrueModes",
  get: async ({ get }) => {
    const emulatorConfig = get(getEmulatorData)
    const HOSTED_MODE = "hosted_calculator"
    const CALCULATOR_MODE = "calculator"

    const { training, data, modes, status, ready_for_calcs } = emulatorConfig
    const { ref, type } = emulatorConfig.source

    const trueModes = []
    if (
      modes.includes(RUN_TYPES[2]) &&
      training &&
      !TRAINING_ARRAY.includes(status)
    ) {
      const hasActiveData = Object.values(training).some(
        (item) => "active_algorithm" in item && item.active_algorithm !== ""
      )
      if (hasActiveData && data?.total_row_count > 0) {
        trueModes.push(RUN_TYPES[2])
      }
    }
    if (modes.includes(RUN_TYPES[0])) {
      if (
        (type === CALCULATOR_MODE || type === HOSTED_MODE) &&
        ref &&
        ready_for_calcs
      ) {
        trueModes.push(RUN_TYPES[0])
      }
    }
    return trueModes
  },
})

export const collections = {
  a: {
    name: "Archineering",
    emulators: [
      "ColumnBuckling",
      "CompositeBeamSizing",
      "CompositeGirderSizing",
      "ConcreteColumn",
      "OneWaySlab",
      "OneWayBeam",
      "SquareSpreadFooting",
      "TwoWayFlatPlate",
    ],
  },
  b: { name: "Blast", emulators: ["MooreWep"] },
  c: { name: "ETFE Cushions", emulators: [] },
}

export const commonCategoryChangeHandler = (category) => {
  const warning = temporaryCategoryAlert.find((item) => item.type === category)
  return warning ? warning.message : ""
}

export const updateURLSearchParams = (params) => {
  const searchParams = new URLSearchParams(window.location.search)
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      searchParams.set(key, value)
    } else {
      searchParams.delete(key)
    }
  })

  const newURL = `${window.location.pathname}?${searchParams.toString()}`

  window.history.replaceState({}, "", newURL)
}

export const notificationState = atom({
  key: "notificationState",
  default: false,
})

export const isEditMeta = atom({
  key: "isEditMeta",
  default: false,
})

export const textFieldState = atom({
  key: "textFieldState",
  default: false,
})

export const saveBtnState = atom({
  key: "saveBtnState",
  default: false,
})

export const allLaunchCardsState = atom({
  key: "activeModeState",
  default: getAllEmulatorLaunches,
})

export const populateResultData = atom({
  key: "populateResultData",
  default: true,
})

export const releaseIOState = selector({
  key: "releaseIOState",
  get: async ({ get }) => {
    const emId = get(getEmulatorData).id
    const releasedVersions = get(getEmulatorData).versions?.releases[0]
    const latestVers = get(getLatestV)
    const betaVers = get(getBeta)
    const selectedVer = get(selectedVersion)
    const body = {
      emulator_id: emId,
      mode: "emulate",
      version: selectedVer,
    }

    if (
      !selectedVer ||
      (releasedVersions
        ? !releasedVersions.includes(selectedVer) && selectedVer !== latestVers
        : latestVers === "None"
        ? selectedVer === betaVers
        : false)
    ) {
      return {}
    }

    try {
      const response = await EmulationLab.post(requests.releasesDataUrl, body)
      return response.data
    } catch (error) {
      console.log(error)
      return {}
    }
  },
})

export const getUnitList = selector({
  key: "getUnitList",
  get: async () => {
    let unique = new Set()
    const unitSuggestions = await fetchUnits()
    unitSuggestions
      ?.map((obj) => {
        return {
          alternative_names: obj.alternative_names,
          symbol: obj.symbol,
          name: obj.name,
          mathcad_symbol: obj.mathcad_symbol,
          alternative_symbols: obj.alternative_symbols,
        }
      })
      .forEach((h) => {
        Object.keys(h).forEach((k) => {
          if (h[k]?.length > 0) {
            if (k === "alternative_symbols") {
              if (Array.isArray(h[k])) {
                h[k].forEach((as) => {
                  unique.add(h.symbol + "-" + as)
                })
              }
            } else {
              unique.add(h.symbol + "-" + h[k])
            }
          }
        })
      })
    const uniqueList = Array?.from(unique)

    return uniqueList
  },
})

export const extractModelPosition = (emData) => {
  const allOutputs = emData?.calculator?.OutputVariables
  const modelPosition =
    allOutputs
      ?.filter((item) => item?.orientation !== undefined) // Filter out items without "ModalVisual" key
      .map((item) => item?.orientation) || []
  return modelPosition[0]
}

export const sendModelWithRotation = (modelPosition, orientation, data) => {
  let orientationValues = {
    x: 0,
    y: 0,
    z: 0,
  }

  if (modelPosition === "Front") {
    orientationValues = {
      x: 0,
      y: 0,
      z: 0,
    }
  } else if (modelPosition === "Back") {
    orientationValues = {
      x: -3,
      y: 0,
      z: 0,
    }
  } else if (modelPosition === "Left") {
    orientationValues = {
      x: -1.57,
      y: -1.57,
      z: 0,
    }
  } else if (modelPosition === "Right") {
    orientationValues = {
      x: -1.57,
      y: 1.57,
      z: 0,
    }
  } else if (modelPosition === "Top") {
    orientationValues = {
      x: -1.5,
      y: 0,
      z: 0,
    }
  } else if (modelPosition === "Bottom") {
    orientationValues = {
      x: 1.5,
      y: 0,
      z: 0,
    }
  } else {
    orientationValues = orientation
  }
  const updatedstateValue = {
    ModelVisual: modelPosition ? modelPosition : "Custom Position",
    orientation: orientationValues,
    Type: "text",
    Units: "none",
    ParamName: "ML: VisualizationOut",
    NickName: "Viz Out",
    Name: "Visualization",
    ColumnLabel: "out Viz Out",
    Description: "Viz Out",
  }
  const updatingOutput = {
    ...data,
    ModelVisual: modelPosition ? modelPosition : "Custom Position",
    orientation: orientationValues,
  }
  const retainFullObject = data ? updatingOutput : updatedstateValue
  return retainFullObject
}

export const handleKeyDown = (event, closeDialog) => {
  if (event.key === "Escape") {
    closeDialog(false)
  }
}

export const commonDateFormat = {
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
}

export const formatTimeBasedOnLocal = (time, timeFormat) => {
  const value = `${time?.split(" ")[0]}T${time?.split(" ")[1]}Z`
  const utcDate = new Date(value)
  const userLocale = navigator.language

  const localDateString = new Intl.DateTimeFormat(
    userLocale,
    timeFormat
  ).format(utcDate)

  return localDateString
}

export const matchUnit = (input, uniqueList) => {
  const lowerInput = input.toLowerCase()
  const matchedUnit = uniqueList.find((unit) => {
    const secondPart = unit.split("-")[1]
    const lowerSecondPart = secondPart.toLowerCase()
    return lowerSecondPart === lowerInput
  })

  if (matchedUnit) {
    return matchedUnit.split("-")[0]
  }

  return input
}

export const emulatorLauncheGroupDataState = atom({
  key: "emulatorLauncheGroupDataState",
  default: {},
})

export const showSideBarState = atom({
  key: "showSideBarState",
  default: true,
})

export const openBotState = atom({
  key: "openBotState",
  default: false,
})
