import { IconButton } from "@mui/material"
import * as core from "@material-ui/core"
import CloseIcon from "@mui/icons-material/Close"
import useStyles from "../../card_style"
import ShareIconButton from "../IconButtons/ShareIconButton"

const AddDialogTitle = ({
  calcModeTitle,
  callFunction,
  closeCreate,
  title,
  shareButton,
  exportButton,
  createEm,
}) => {
  const classes = useStyles()

  const handleClose = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (callFunction) {
      callFunction()
    } else {
      closeCreate(false)
    }
  }

  return (
    <core.DialogTitle
      className={`member-manage-title ${
        createEm
          ? ``
          : `${classes.backgroundPrimaryMain} ${classes.colorWhiteIcon} img-title-block`
      }`}
    >
      <div className="ml-space-between ml-display-flex ml-align-center">
        <div
          className={
            createEm
              ? `${classes.backgroundPrimaryMain} ${classes.colorWhiteIcon} img-title-block1`
              : "critheading"
          }
        >
          <core.Typography variant="h6">{title}</core.Typography>
        </div>
        <div className="ml-display-flex ml-flex-dir-row">
          {calcModeTitle && (
            <div className="critheading">
              <core.Typography variant="h6">{calcModeTitle}</core.Typography>
            </div>
          )}
          {shareButton && (
            <div className="createEm-close-btn">
              <ShareIconButton />
            </div>
          )}
          {exportButton && exportButton()}
          <IconButton
            aria-label="close"
            id="commonbtncss"
            className={
              createEm ? classes.bgMainGreyIcon : classes.colorPrimaryMain
            }
            onClick={(e) => handleClose(e)}
          >
            <CloseIcon className={!createEm && classes.colorWhiteIcon} />
          </IconButton>
        </div>
      </div>
    </core.DialogTitle>
  )
}

export default AddDialogTitle
