import * as m from "@mui/material"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useNavigate, useParams } from "react-router-dom"
import * as files from "../../GlobalFileContainer"
import { resultJobIdState, siteWideRole } from "../../state/projectState"
import { convertToUppercase } from "../../state/services"
import { JobLogsCalculate } from "../shared components/CommonStyles/CommonStyles"

const LookupOptimizationMain = ({
  isFullView,
  inputCrads,
  name,
  OutputVariableCards,
  progressCard,
  mode,
  outputLog,
}) => {
  const navigate = useNavigate()
  const { emulatorId } = useParams()

  const setJobId = useSetRecoilState(resultJobIdState)
  const siteWideRoleState = useRecoilValue(siteWideRole)

  const borderCss = {
    border: "1px solid rgba(228,228,231,1)",
    borderRadius: "8px",
  }

  const cardStyle = {
    padding: "8px",
    width: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  }

  return (
    <m.Grid container direction={"column"} style={{ width: "100%", gap: 8 }}>
      <m.Grid item xs={12} sx={borderCss}>
        <files.ErrorBoundary>{inputCrads}</files.ErrorBoundary>
      </m.Grid>
      {isFullView && (
        <files.AlternateEmulatorBox mode={mode}>
          <m.Box className="width-100" style={cardStyle}>
            <m.Grid item xs={12} sx={borderCss}>
              <m.Card style={cardStyle}>
                <m.Typography
                  mr={mode === "calculate" ? 1 : 0}
                  variant="subHeading"
                  fontWeight={500}
                >
                  {mode === "calculate"
                    ? "Want to try advanced optimization?"
                    : `Is the current curated ${name} not working for you?`}
                </m.Typography>
                <m.Button
                  id="tryitbtn"
                  color="primary"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={() =>
                    mode === "calculate"
                      ? navigate(`/new/${emulatorId}/optimization`)
                      : (() => {
                          setJobId("")
                          navigate(`/new/${emulatorId}/${name}`)
                        })()
                  }
                >
                  <m.Typography variant="subHeading">
                    {mode === "calculate"
                      ? "Build a Custom Optimization"
                      : `Build a Custom ${convertToUppercase(name)}`}
                  </m.Typography>
                </m.Button>
              </m.Card>
            </m.Grid>
          </m.Box>
        </files.AlternateEmulatorBox>
      )}
      <m.Grid item xs={12} sx={{ ...borderCss, height: "100%" }}>
        <files.ErrorBoundary>{OutputVariableCards}</files.ErrorBoundary>
      </m.Grid>
      {outputLog && <JobLogsCalculate
        siteWideRoleState={siteWideRoleState}
        outputLog={outputLog}
      />}
      {isFullView && progressCard ? progressCard : null}
    </m.Grid>
  )
}

export default LookupOptimizationMain
