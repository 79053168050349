import React, { useState } from "react"
import * as requests from "../state/requests"
import { EmulationLab, ShareIconButton } from "../GlobalFileContainer"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { alertStates } from "../state/vizState"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { testJWTToken } from "../state/axios"
import * as m from "@mui/material"
import { ReactComponent as HeartIcon } from "../assets/newIcons/Heart.svg"
import { mongoProfileState } from "../state/userState"
import { getEmulatorData } from "../state/projectState"

const getFavoriteDefault = (emulator, mongoProfile, sidebar) => {
  const id = sidebar ? emulator[0]?.emulator_id : emulator.id
  try {
    return mongoProfile.library.consumer.includes(id)
  } catch {
    return false
  }
}

const FavouriteAndShareIcon = ({
  emulator,
  styleProp,
  iconPadding,
  placeHolder,
  consumer,
  sidebar,
}) => {
  const setAlertState = useSetRecoilState(alertStates)
  const emulatorConfig = useRecoilValue(getEmulatorData)
  const [mongoProfile, setMongoProfile] = useRecoilState(mongoProfileState)

  const [favorite, setFavorite] = useState(
    getFavoriteDefault(emulator, mongoProfile, sidebar)
  )

  const extractEmulatorName = Array.isArray(emulator)
    ? emulator[0]?.package?.documentation?.emulator_name
    : undefined

  const setFavoriteState = (response) => {
    const direction = favorite ? "removed from" : "added to"
    if (response.status === 200) {
      setAlertState({
        boolState: true,
        message: `Emulator ${
          sidebar ? extractEmulatorName ?? emulatorConfig?.name : emulator.name
        } ${direction} Saved Emulators list.`,
        severityState: "success",
      })
      setFavorite((val) => !val)
    } else {
      setAlertState({
        boolState: true,
        message: "Emulator unable to modify favorites",
        severityState: "error",
      })
    }
  }

  const clickFavoriteHandler = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let body = { id: emulator[0]?.emulator_id || emulator.id }
    let url = requests.fetchUserLibraryFavorites
    if (!favorite) {
      EmulationLab.put(url, body, {
        headers: {
          severity: SeverityLevel.Warning,
        },
      }).then((response) => {
        setFavoriteState(response)
        testJWTToken(setMongoProfile)
      })
    } else {
      EmulationLab.delete(url, {
        data: body,
        headers: {
          severity: SeverityLevel.Warning,
        },
      }).then((response) => {
        setFavoriteState(response)
        testJWTToken(setMongoProfile)
      })
    }
  }

  return (
    <m.Box sx={styleProp}>
      {!placeHolder && (
        <m.IconButton
          title={"Save the Emulator."}
          onClick={placeHolder ? null : clickFavoriteHandler}
          size="small"
          sx={iconPadding ? { ...iconPadding } : {}}
          className="zoom-hover-10"
        >
          <HeartIcon height={"20px"} fill={favorite ? "black" : "none"} />
        </m.IconButton>
      )}
      <ShareIconButton
        id={emulator?.id || emulator[0]?.emulator_id}
        iconPadding={iconPadding}
        consumer={consumer}
      />
    </m.Box>
  )
}

export default FavouriteAndShareIcon
