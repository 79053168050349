import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import * as m from "@mui/material"
import { AddDialogTitle, EmulationLab } from "../../../GlobalFileContainer"
import { alertStates } from "../../../state/vizState"
import { handleKeyDown } from "../../../state/projectState"

const DeleteEmulator = ({
  openDeleteDialog,
  setOpenDeleteDialog,
  name,
  emulatorId,
  fetchData,
  navigatePage,
}) => {
  const navigate = useNavigate()
  const deleteEmulator = `/emulators/${emulatorId}`

  const setAlertState = useSetRecoilState(alertStates)

  const [userInput, setUserInput] = useState("")
  const [incorrectName, setIncorrectName] = useState(false)

  const handleDeleteDialogClose = () => {
    setIncorrectName(false)
    setUserInput("")
    setOpenDeleteDialog(false)
  }

  const deleteEmulatorFunc = async () => {
    try {
      await EmulationLab.delete(deleteEmulator)
      setOpenDeleteDialog(false)
      setAlertState({
        boolState: true,
        message: "Emulator deleted successfully!",
        severityState: "success",
      })
      if (navigatePage && !window.location.pathname.includes("my")) {
        setTimeout(() => {
          navigate("/")
        }, 2000)
      } else {
        fetchData()
      }
    } catch (error) {
      console.error(error.response)
      setOpenDeleteDialog(false)
    }
  }

  const handleInputChange = (e) => {
    setIncorrectName(false)
    setUserInput(e.target.value)
  }

  const testUserInput = () => {
    name === userInput ? deleteEmulatorFunc() : setIncorrectName(true)
  }

  return (
    <m.Dialog
      onKeyDown={(e) => handleKeyDown(e, handleDeleteDialogClose)}
      open={openDeleteDialog}
      onClose={handleDeleteDialogClose}
    >
      <AddDialogTitle
        callFunction={handleDeleteDialogClose}
        title={"Delete Emulator"}
      />
      <m.DialogContent>
        <m.DialogContentText sx={{ padding: "10px 0" }}>
          <m.Typography variant="body1">
            <b>Are you sure you want to delete this emulator?</b>
          </m.Typography>
        </m.DialogContentText>
        <m.TextField
          error={incorrectName}
          fullWidth
          variant="outlined"
          autoComplete="off"
          placeholder="Enter the exact name of the emulator you wish to delete."
          value={userInput}
          helperText={incorrectName && "Incorrect name!"}
          onChange={handleInputChange}
        />
        <m.DialogContentText className="delete-dialog-note">
          <m.Typography variant="body2">
            **Note- This action is irreversable! All data will be deleted.
          </m.Typography>
        </m.DialogContentText>
      </m.DialogContent>
      <m.DialogActions sp>
        <m.Button
          variant="contained"
          color="primary"
          onClick={testUserInput}
          className="actions-delete"
        >
          <m.Typography variant="caption"> Yes</m.Typography>
        </m.Button>
        <m.Button
          variant="contained"
          color="primary"
          onClick={handleDeleteDialogClose}
          className="actions-delete"
          autoFocus
        >
          <m.Typography variant="caption"> No</m.Typography>
        </m.Button>
      </m.DialogActions>
    </m.Dialog>
  )
}

export default DeleteEmulator
