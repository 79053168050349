import * as m from "@mui/material"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { getSingleEmulatorId } from "../../../state/projectState"
import { alertStates } from "../../../state/vizState"
import { ReactComponent as ShareIcon } from "../../../assets/newIcons/Share.svg"

const ShareIconButton = ({ id, iconPadding, consumer }) => {
  const emulatorId = useRecoilValue(getSingleEmulatorId)
  const setAlertState = useSetRecoilState(alertStates)

  const getEnvString = (url) => {
    if (url.includes("emulationlab.ai")) {
      return "/share"
    } else {
      return "/share/staging"
    }
  }

  const getBaseURL = (lastSegment, isEmulatorPage, isConsumerPage) => {
    let currentURLString = window.location.href
    let envString = getEnvString(currentURLString)
    if (isConsumerPage || consumer) {
      return `${envString}?emulator_id=${id ?? emulatorId}&launch=true&route=${
        consumer ? "launches" : lastSegment
      }`
    } else if (isEmulatorPage) {
      return `${envString}?emulator_id=${id ?? emulatorId}&route=${
        id ? "summary" : lastSegment
      }`
    } else {
      return envString
    }
  }

  const shareEmulator = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const pageURL = window.location
    let pathnameSegmentsArray = pageURL.pathname.split("/")
    let lastSegment = pathnameSegmentsArray.slice(3).join("/")
    let isConsumerPage = pageURL.pathname.includes("new")
    let isEmulatorPage = pageURL.pathname.includes("em") || id
    let shareRoute = getBaseURL(lastSegment, isEmulatorPage, isConsumerPage)
    let baseURL = process.env.REACT_APP_API_URL + shareRoute
    let url =
      (isEmulatorPage || isConsumerPage) &&
      pageURL.search &&
      pageURL.search?.size !== 0
        ? `${baseURL}&${pageURL.search.split("?")[1]}`
        : baseURL
    await navigator.clipboard.writeText(url)
    setAlertState({
      message: "Emulator link copied to clipboard!",
      severityState: "info",
      boolState: true,
    })
  }
  return (
    <m.IconButton
      title={"Share the Emulator."}
      size="small"
      sx={iconPadding ? iconPadding : {}}
      className="zoom-hover-10"
      onClick={shareEmulator}
    >
      <ShareIcon height="20px" style={{ stroke: id ? "#1E1E1E" : "white" }} />
    </m.IconButton>
  )
}

export default ShareIconButton
