import { useNavigate } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import * as m from "@mui/material"
import { agentDashboardNavOpt } from "./AdminDashboardMain"
import useStyles from "../card_style"
import { commonCss } from "../result-optimization/OptimizationHistory"
import {
  agentQueueData,
  agentCapabilityData,
  menuItemState,
} from "../../state/projectState"
import { currentJob, recentJob } from "./AgentQueueMain"

export const getUniqueFilters = (data) => {
  return [...(new Set(data?.table?.map((tab) => tab.capability)) || [])]
}

const AgentSummaryMain = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const agentQueueDataState = useRecoilValue(agentQueueData)
  const agentCapabilityDataState = useRecoilValue(agentCapabilityData)
  const setMenuItem = useSetRecoilState(menuItemState)

  const navigateToDetail = (route, filter) => {
    navigate(route)
    setMenuItem(filter)
  }

  const renderViewDataButton = (route, title) => (
    <m.Box className="ml-display-flex" sx={{ justifyContent: "flex-end" }}>
      <m.Link
        component="button"
        variant="body2"
        onClick={() => navigateToDetail(route, title)}
      >
        <m.Box sx={commonCss}>
          <m.Typography variant="body2">View All</m.Typography>
        </m.Box>
      </m.Link>
    </m.Box>
  )

  return (
    <m.Box>
      <m.Box className="gutter-top ml-display-flex ml-gap-1 ml-flex-dir-col">
        {agentDashboardNavOpt.map((agent, id) => {
          const isAgentCapblity = agent.title === "Agent Capability"
          const isJobQueue = agent.title === "Job Queue"

          return (
            agent.title !== "Summary" && (
              <m.Box key={`${agent.title}-${id}`}>
                <m.Card sx={{ minHeight: "210px" }}>
                  <m.CardContent className={classes.calcMainCard}>
                    <m.Typography
                      variant="h5"
                      className={classes.calculatorTypo}
                    >
                      {isJobQueue &&
                      agentQueueDataState.queuedData?.length === 0 &&
                      (agentQueueDataState.currentJobData?.length > 0 ||
                        agentQueueDataState.recentData?.length > 0)
                        ? agentQueueDataState.currentJobData?.length > 0
                          ? currentJob
                          : recentJob
                        : agent.title}
                    </m.Typography>
                    <m.Box>
                      {agent.renderComponent}
                      {isJobQueue &&
                        (agentQueueDataState.queuedData?.length > 0 ||
                          agentQueueDataState.currentJobData?.length > 0 ||
                          agentQueueDataState.recentData?.length > 0) &&
                        renderViewDataButton(agent.to, agent.title)}
                      {isAgentCapblity &&
                        Object.keys(agentCapabilityDataState)?.length > 0 &&
                        renderViewDataButton(agent.to, agent.title)}
                    </m.Box>
                  </m.CardContent>
                </m.Card>
              </m.Box>
            )
          )
        })}
      </m.Box>
    </m.Box>
  )
}

export default AgentSummaryMain
