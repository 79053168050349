import { Grid } from "@mui/material"
import { useRecoilValue } from "recoil"
import { openBotState } from "../../../state/projectState"

const CommonPageLayout = ({ componentSidebar, mainPageContent }) => {
  const showSideBar = useRecoilValue(openBotState)

  return (
    <Grid container direction="row" spacing={1} height={"100%"}>
      {!showSideBar && componentSidebar}
      <Grid item xs={12} md={showSideBar ? 12 : 9} direction="column">
        {showSideBar && componentSidebar}
        {mainPageContent}
      </Grid>
    </Grid>
  )
}

export default CommonPageLayout
