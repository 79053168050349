import * as m from "@mui/material"
import * as statusState from "../../state/StatusState"
import {
  convertToUppercase,
  pickStatusWithSoftwareIcons,
  returnAgentStatusIcon,
  seperateSoftwareAndSubType,
} from "../../state/services"

export const LegendHelper = (classes, theme) => (
  <m.Box className="ml-display-flex ml-flex-dir-col ml-p-top-10 ml-space-between">
    <m.Box className="eval-m-1-4-0 ml-p-5">
      <m.Grid
        container
        alignItems={"center"}
        rowSpacing={1}
        columnSpacing={2}
        direction={"row"}
      >
        {statusState.allSoftwareWithDependency?.map((sd, index1) => {
          const [softwareType, softwareSubType, softwareStatus] =
            seperateSoftwareAndSubType(sd)
          return (
            <m.Grid item xs="auto" key={`${sd}-${index1}`}>
              <m.Typography
                className="ed-small margin-right-10"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {pickStatusWithSoftwareIcons(
                  softwareType,
                  softwareSubType,
                  softwareStatus,
                  "25px",
                  sd
                )}
                &nbsp;
                {convertToUppercase(sd)}
              </m.Typography>
            </m.Grid>
          )
        })}
        {statusState.uniqueListofStatus.map(
          (status, index1) =>
            status !== "None" &&
            status !== "others" && (
              <m.Grid item xs="auto" key={`${status}-${index1}`}>
                <m.Typography
                  className="ed-small margin-right-10"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {returnAgentStatusIcon(status, theme, classes, "small")}
                  &nbsp;
                  {convertToUppercase(status)}
                </m.Typography>
              </m.Grid>
            )
        )}
      </m.Grid>
    </m.Box>
  </m.Box>
)
