import { Box } from "@material-ui/core"
import { Card, Typography, useTheme } from "@mui/material"
import { convertToUppercase } from "../state/services"
import excel from "../assets/Agent Health Icons - v1/Excel/7.png"
import python from "../assets/Agent Health Icons - v1/Python/9.png"
import grasshopper from "../assets/Agent Health Icons - v1/Grasshopper/7.png"
import { flexCenterProps } from "./admin-dashboard/AdminSummaryOptions"
import { calc, emulatorSoftwareType } from "../state/StatusState"

const SoftwareSelectionCards = ({ dataSource, setDataSource, setFileType }) => {
  const theme = useTheme()

  const softwareStrings = [
    emulatorSoftwareType.excel,
    emulatorSoftwareType.python,
    emulatorSoftwareType.grasshopper,
  ]

  const handleChange = (value) => {
    const isHosted = value === "hosted_calculator"
    setFileType(value)
    setDataSource(
      value === calc[0]
        ? {
            ...dataSource,
            type: "calculator",
            software: value,
            method: "",
          }
        : {
            type: calc?.includes(value) ? "calculator" : value,
            software:
              (calc?.includes(value) && value) || (isHosted && "python") || "",
            method: isHosted ? "GET_WITH_PARAMS" : "",
          }
    )
  }

  const softwareIcons = {
    excel,
    python,
    grasshopper,
  }

  const getSoftwareIcon = (software) => softwareIcons[software] || null

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection={{ xs: "column", md: "row" }}
      sx={{ gap: "10px" }}
      mb={10}
    >
      {softwareStrings?.map((software, i) => {
        const isSelected = dataSource.software === software
        return (
          <Card
            key={`${software}_${i}`}
            sx={{
              backgroundColor: isSelected
                ? theme.palette.secondary.main
                : theme.palette[software].light,
              ...flexCenterProps,
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <Box
              className="cursor-pointer"
              onClick={() => handleChange(software)}
              sx={{
                width: "150px",
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={getSoftwareIcon(software)}
                width={"40px"}
                alt={software}
              />
              <Typography variant="body2" textAlign="center">
                {convertToUppercase(software)}
              </Typography>
            </Box>
          </Card>
        )
      })}
    </Box>
  )
}

export default SoftwareSelectionCards
