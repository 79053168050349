import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil"
import * as m from "@mui/material"
import {
  calculatorResult,
  errorFieldsState,
  getAllEmulatorLaunches,
  populateResultData,
} from "../../state/projectState"
import {
  generateLookupResultFormat,
  getParamString,
} from "../../state/services"
import {
  ChatbotFooter,
  ChatbotHeader,
  ChatbotMessages,
} from "../../GlobalFileContainer"

const latestChatResponseKey = "latestChatStack"
const allbotHistory = "eml_bot's_chat"

const CustomChatbotApp = ({ embedded }) => {
  const theme = m.useTheme()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const launchState = useRecoilValue(getAllEmulatorLaunches)
  const setPopulateResultDataState = useSetRecoilState(populateResultData)
  const resetcalculatorResult = useResetRecoilState(calculatorResult)
  const setErrorFields = useSetRecoilState(errorFieldsState)
  const [results, setResults] = useRecoilState(calculatorResult)

  const [messages, setMessages] = useState(() => {
    const storedMessages = localStorage.getItem(allbotHistory)
    return storedMessages ? JSON.parse(storedMessages) : []
  })
  const [feedBackSTack, setFeedbackStack] = useState(() => {
    const storedMessages = localStorage.getItem(latestChatResponseKey)
    return storedMessages ? JSON.parse(storedMessages) : []
  })

  const [loader, setLoader] = useState(false)
  const [typingDots, setTypingDots] = useState("")
  const [likedResponse, setLikedResponse] = useState(false)
  const [selectedUserText, setSelectedUserText] = useState({})

  const isAgentPage = window.location.pathname.includes("/agent")

  const handleDeleteChat = () => {
    setMessages([])
    setFeedbackStack([])
  }

  const checkIfAllValuesFilled = (array) => {
    return array.every(
      (item) => typeof item.value === "string" && item.value.trim().length > 0
    )
  }

  const returnLaunchObject = async (
    launchID,
    data,
    emId,
    emulatorId,
    updateLocalStorage
  ) => {
    const filteredCard = launchState?.find((card) => card.id === launchID) ?? {}

    const {
      id: launchId = null,
      sql_table_ref = null,
      package: pkg = {},
      mode = null,
    } = filteredCard

    const newParams = new URLSearchParams()

    if (mode) newParams.set("mode", mode)
    if (launchId) newParams.set("launchid", launchId)
    if (sql_table_ref) newParams.set("index", sql_table_ref)

    const isModeOptimize = mode === "optimize"

    const searchParamsInputString = data?.inputs
      ? getParamString(data?.inputs, true)
      : getParamString(pkg?.io?.InputVariables, isModeOptimize)

    const navigateTo = (path) => {
      navigate(path)
    }
    if (data?.outputs && updateLocalStorage) {
      const existingData =
        JSON.parse(localStorage.getItem("lookupHistory")) || []
      const updatedData = [
        generateLookupResultFormat(data, "lookup"),
        ...existingData,
      ]
      localStorage.setItem("lookupHistory", JSON.stringify(updatedData))
    }

    if (!emId) {
      const currentMode = searchParams.get("mode")
      const currentLaunchId = searchParams.get("launchid")
      const currentIndex = searchParams.get("index")

      const areParamsSame =
        currentMode === mode &&
        currentLaunchId === launchId?.toString() &&
        currentIndex === sql_table_ref?.toString()
      if (!areParamsSame) {
        resetcalculatorResult()
      } else if (updateLocalStorage && data?.outputs) {
        setResults([generateLookupResultFormat(data, "lookup"), ...results])
      }
      if (launchID) {
        const searchString = newParams.toString()
        const targetUrl = `/new/${emulatorId}/tryit?${searchString}&${searchParamsInputString}`

        navigateTo(targetUrl)

        await new Promise((resolve) => setTimeout(resolve, 1000))
        setPopulateResultDataState(true)

        if (data?.inputs) {
          const allValuesFilled = checkIfAllValuesFilled(data?.inputs)
          setErrorFields(!allValuesFilled)
        }
      }
    } else if (data?.emulator_id) {
      const targetUrl = `/new/${emulatorId}/launches`
      navigateTo(targetUrl)
    }
  }

  useEffect(() => {
    localStorage.setItem(allbotHistory, JSON.stringify(messages))
  }, [messages])

  useEffect(() => {
    localStorage.setItem(latestChatResponseKey, JSON.stringify(feedBackSTack))
  }, [feedBackSTack])

  useEffect(() => {
    if (loader) {
      const interval = setInterval(() => {
        setTypingDots((prev) => (prev.length < 5 ? prev + "•" : ""))
      }, 500)

      return () => clearInterval(interval)
    }
  }, [loader])

  return (
    <m.Box
      className="ml-display-flex ml-flex-dir-col ml-gap-1 width-100"
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: "10px",
        boxShadow: `0 2px 4px ${theme.palette.grey.main}`,
        height: embedded ? (isAgentPage ? "75vh" : "90vh") : "100vh",
        overflow: "hidden",
      }}
    >
      <ChatbotHeader handleDeleteChat={handleDeleteChat} messages={messages} />
      <m.Divider />
      <ChatbotMessages
        returnLaunchObject={returnLaunchObject}
        setLikedResponse={setLikedResponse}
        feedBackSTack={feedBackSTack}
        messages={messages}
        likedResponse={likedResponse}
        loader={loader}
        typingDots={typingDots}
        selectedUserText={selectedUserText}
        setSelectedUserText={setSelectedUserText}
      />
      <ChatbotFooter
        setLikedResponse={setLikedResponse}
        messages={messages}
        setMessages={setMessages}
        setFeedbackStack={setFeedbackStack}
        loader={loader}
        setLoader={setLoader}
        returnLaunchObject={returnLaunchObject}
        feedBackSTack={feedBackSTack}
        selectedUserText={selectedUserText}
        setSelectedUserText={setSelectedUserText}
      />
    </m.Box>
  )
}

export default CustomChatbotApp
