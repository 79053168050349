import { useState } from "react"
import * as files from "../../GlobalFileContainer"
import "./GenerateData.css"

function GenerateData({ time, setTime, timerInterval, setTimer }) {
  const [viewResult, setViewResult] = useState(false)
  const [refKey, setRefKey] = useState("")

  const closeResult = () => {
    setViewResult(!viewResult)
  }

  return (
    <>
      <files.ErrorBoundary>
        <files.GenerateTimerColumn
          setViewResult={setViewResult}
          viewResult={viewResult}
          setRefKey={setRefKey}
          time={time}
          setTime={setTime}
          timerInterval={timerInterval}
          setTimer={setTimer}
        />
      </files.ErrorBoundary>

      <files.ShowResult
        refKey={refKey}
        ViewResult={viewResult}
        closeResult={closeResult}
      />
    </>
  )
}

export default GenerateData
