import { useCallback, useEffect, useState } from "react"
import { Grid, useTheme } from "@material-ui/core"
import AddIcon from "@mui/icons-material/Add"
import { useRecoilState, useSetRecoilState } from "recoil"
import * as file from "../../GlobalFileContainer"
import "./UploadFiles.css"
import {
  uploadEmFiles,
  featureSelectionReload,
  filesFromDropzone,
} from "../../state/projectState"
import useStyles from "../card_style"
import { Typography } from "@mui/material"
import { uploadEmFilesOrTrain } from "../../state/StatusState"
import useCustomDropzone from "../../hooks/useCustomDropzone"

const MultipleFileUpload = ({
  getEmulatorFiles,
  allowedFileExtensions,
  count,
  updateEmulatorState,
  setFileList,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const setUploadEmFilesState = useSetRecoilState(uploadEmFiles)
  const setReloadProject = useSetRecoilState(featureSelectionReload)
  const [files, setFiles] = useRecoilState(filesFromDropzone)

  const [updates, setUpdates] = useState(false)

  useEffect(() => {
    if (updates && updateEmulatorState) {
      updateEmulatorState()
      setUpdates(false)
    }
  }, [updates])

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const mappedAccepted = acceptedFiles.map((file) => ({ file, errors: [] }))
    setFiles((curr) => [...curr, ...mappedAccepted, ...rejectedFiles])
  }, [])

  const { getRootProps, getInputProps } = useCustomDropzone(
    onDrop,
    allowedFileExtensions
  )

  const onDelete = (file) => {
    setFiles((curr) => {
      return curr.filter((fwrap) => fwrap.file !== file)
    })
  }

  const onUpload = async () => {
    try {
      const response = await file.EmulationLab.get(getEmulatorFiles)
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        listStatus: true,
      }))

      var newData = response.data.map((v) => ({
        ...v,
        head: v.head || "",
        row: v.row || "",
        validateData: v.validateData || "",
      }))
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        updateState: false,
      }))
      setFileList(count ? newData : response.data)
      setUpdates(true)
      setReloadProject(true)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <file.Authorization processName={uploadEmFilesOrTrain}>
      <>
        <Grid container style={{ marginBottom: "10px" }}>
          {files?.map((fileWrapper, ind) =>
            fileWrapper.errors.length ? (
              <file.UploadError
                key={fileWrapper.file}
                file={fileWrapper.file}
                errors={fileWrapper.errors}
                onDelete={onDelete}
                allowedFileExtensions={allowedFileExtensions}
              />
            ) : (
              <file.SingleFileUpload
                key={ind}
                onDelete={onDelete}
                onUpload={onUpload}
                file={fileWrapper.file}
                getEmulatorFiles={getEmulatorFiles}
              />
            )
          )}
        </Grid>
        <Typography variant="h5" fontWeight={"600"}>
          Upload your files here:
        </Typography>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={`${classes.uploadButton} drop-nonselect`}
        >
          <div {...getRootProps()} className="ml-display-flex">
            <input {...getInputProps()} />
            <div style={{ padding: "10px" }}>
              <AddIcon sx={{ color: theme.palette.grey.secondaryLight }} />
            </div>
            <div>
              <Typography variant="body1" color={theme.palette.grey.main}>
                Drag 'n' drop some files here, or click to select files
              </Typography>
              <Typography
                variant="body1"
                color={theme.palette.grey.secondaryLight}
              >
                Only {`${allowedFileExtensions}`} files allowed
              </Typography>
            </div>
          </div>
        </Grid>
      </>
    </file.Authorization>
  )
}

export default MultipleFileUpload
