import { useEffect, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { Chip, Typography } from "@mui/material"
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import { getEmulatorData, globalDialogBox } from "../../state/projectState"
import { mongoProfileState } from "../../state/userState"
import CollaboratorDialog from "../CollaboratorDialog"
import { getCollabDefault } from "../shared components/IconButtons/HeartnContribute"
import { EmulationLab } from "../../GlobalFileContainer"
import { alertStates } from "../../state/vizState"
import { convertToUppercase } from "../../state/services"

const MemberCollaboratorRole = ({ selectedCalcObject, style }) => {
  const emulatorConfig = useRecoilValue(getEmulatorData)
  const mongoProfile = useRecoilValue(mongoProfileState)
  const setGlobalDialogOpen = useSetRecoilState(globalDialogBox)
  const setAlertState = useSetRecoilState(alertStates)

  const [collaboratorRole, setCollaboratorRole] = useState("")
  const [requestedCollaborator, setRequestedCollaborator] = useState(
    getCollabDefault(emulatorConfig, mongoProfile).requested
  )

  const team = selectedCalcObject
    ? selectedCalcObject?.team
    : emulatorConfig?.team

  const director = team && Object.keys(team).length !== 0 && team.director
  const members = team && Object.keys(team).length !== 0 && team.members

  const isMemberDirector = director && director.email === mongoProfile.email
  const approvedRole = isMemberDirector
    ? "director"
    : members &&
      members.find((member) => member.email === mongoProfile.email)
        ?.approved_role
  const activeCollaborator = getCollabDefault(
    emulatorConfig,
    mongoProfile
  )?.active

  useEffect(() => {
    collaboratorRole !== "" && clickCollaborateHandler()
  }, [collaboratorRole])

  const clickCollaborateHandler = async () => {
    if (mongoProfile) {
      let TeamBody = {
        email: mongoProfile.email,
        requested_role: collaboratorRole,
      }
      let TeamUrl = `/emulators/${emulatorConfig.id}/team/members`
      if (!activeCollaborator.active && !requestedCollaborator.requested) {
        try {
          await EmulationLab.put(TeamUrl, TeamBody, {
            headers: {
              severity: SeverityLevel.Warning,
            },
          })

          setAlertState({
            boolState: true,
            message: `Request to join has been added to Emulator ${emulatorConfig.name}`,
            severityState: "info",
          })
          setRequestedCollaborator(true)
          setCollaboratorRole("")
        } catch (err) {
          setAlertState({
            boolState: true,
            message: err.response.data.message.includes(
              "already has a pending request"
            )
              ? "Member already has pending request"
              : "Emulator unable to send join request",
            severityState: "error",
          })
        }
      }
    }
  }

  return (
    <Chip
      variant="outlined"
      sx={style}
      size="small"
      icon={
        approvedRole ? (
          <PermIdentityOutlinedIcon fontSize="small" />
        ) : (
          <GroupAddIcon fontSize="small" />
        )
      }
      label={
        <Typography fontWeight={500} style={{ fontSize: "12px" }}>
          {approvedRole ? convertToUppercase(approvedRole) : "Become A Member"}
        </Typography>
      }
      onClick={
        !approvedRole
          ? () =>
              setGlobalDialogOpen((prev) => ({
                ...prev,
                isOpen: true,
                content: (
                  <CollaboratorDialog
                    setShowCollaboratorDialog={setGlobalDialogOpen}
                    setCollaboratorRole={setCollaboratorRole}
                  />
                ),
                title: "Manager/Technician Permissions",
                maxWidth: "md",
              }))
          : undefined
      }
    />
  )
}

export default MemberCollaboratorRole
