import * as m from "@mui/material"
import { ReactComponent as NoResult } from "../../assets/newIcons/noresultTriangle.svg"

const NoResultNote = ({ title }) => {
  return (
    <m.Grid
      container
      padding={"8px 0px"}
      alignItems={"center"}
      direction={"row"}
    >
      <NoResult width={"16px"} height={"16px"} />
      <m.Typography
        fontWeight={500}
        style={{ fontSize: "16px", paddingLeft: "8px" }}
        color={"primary"}
      >
        No {title} Yet.
      </m.Typography>
    </m.Grid>
  )
}

export default NoResultNote
