import { Suspense, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors"
import FileCopySharpIcon from "@mui/icons-material/FileCopySharp"
import InputIcon from "@mui/icons-material/Input"
import OutputIcon from "@mui/icons-material/Output"
import GradingIcon from "@mui/icons-material/Grading"
import { BsFillCalculatorFill } from "react-icons/bs"
import {
  defaultTestStatus,
  estimatedTimeState,
  getEmulatorData,
  outputsParametersAndVariables,
} from "../../state/projectState"
import useStyles from "../card_style"
import * as file from "../../GlobalFileContainer"
import * as statusState from "../../state/StatusState"
import { mongoProfileState } from "../../state/userState"
import {
  useGetCalcShowHideStatus,
  useGetEmulatorUsageStatus,
} from "../../hooks/useGetCalcShowHideStatus"
import { configInpOut } from "../../hooks/useCalculatorConfig"
import { returnDisableTestingVal } from "../sidebar/EmulatorSidebar"
import { randomizeInputUrl, updateCalcMode } from "../../state/requests"
import { callCalculateToRun } from "../testing-calculator/AddTestForm"
import { alertStates } from "../../state/vizState"
import useCalculatorPolling from "../../hooks/useCalculatorPolling"
import { getIconBasedOnStatus } from "../testing-calculator/MainTest"
import { checkAuthorization, getParamString } from "../../state/services"

export const CalculatorCard = ({ children, image, showDescription }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { emulatorId } = useParams()

  const handleClick = (e) => {
    e.preventDefault()
    showDescription && navigate(`/em/${emulatorId}/manage_configs`)
  }

  return (
    <core.Card className={classes.card} onClick={handleClick}>
      {image && (
        <core.CardMedia
          className={`${classes.cardMedia} ${classes.cardPaddingTop} image_color_filter1`}
          image={image}
          title="Image title"
        />
      )}
      <core.CardContent className={classes.cardContent}>
        <Suspense fallback={<file.Loader />}>{children}</Suspense>
      </core.CardContent>
    </core.Card>
  )
}

const getDomain = (variable) => {
  if (variable.Domain.Type === "continuous") {
    return `${variable.Domain.Start} to ${variable.Domain.End} `
  } else {
    return `${variable.Domain.Options.join("\n")}`
  }
}

export const OutputsCard = ({
  emulatorConfig,
  showDescription,
  handleDragStart,
  chipPosition,
  disableComponent,
  clickFunction,
  lookup,
}) => {
  const config = emulatorConfig.calculator
  const training = emulatorConfig?.training
  const outputVariables = lookup
    ? [...config.InputVariables, ...config.OutputVariables]
    : config.OutputVariables
  const classes = useStyles()
  const { width } = file.useWindowDimensions()
  const theme = m.useTheme()
  const selectedOutputs = useRecoilValue(outputsParametersAndVariables)
  const extractedLabel = selectedOutputs?.objectives?.map((obj) => obj.label)

  const commonChipRender = (isLabelPresent, variable, chip) =>
    !variable?.ModelVisual && (
      <core.Chip
        draggable={!disableComponent && !showDescription && !isLabelPresent}
        size={width > 1110 && showDescription ? "small" : "medium"}
        className={`hover-styling ${classes.chipStyle}`}
        icon={ChipIcon(variable.Type)}
        label={variable.Name}
        color={isLabelPresent || chip ? "default" : "secondary"}
        style={{ fontFamily: theme.palette.typography.fontFamily }}
        onDragStart={(e) => !showDescription && handleDragStart(e)}
      />
    )

  return (
    <core.Typography>
      {!clickFunction && (
        <core.Typography
          className="ml-display-flex ml-align-center"
          gutterBottom
          variant="h5"
          component="h2"
        >
          <OutputIcon sx={{ fontSize: "1.7rem" }} />
          Outputs:
        </core.Typography>
      )}
      <core.Box>
        {outputVariables &&
          Object.entries(outputVariables).map(([id, variable]) => {
            const isLabelPresent = chipPosition?.some(
              (item) => item.content === variable.Name && item.type === "output"
            )
            return (
              <core.Box key={`outputVar` + id + variable}>
                {clickFunction ? (
                  <m.Button
                    title="Click to Select an Output"
                    onClick={() => clickFunction(variable)}
                  >
                    {commonChipRender(
                      isLabelPresent,
                      variable,
                      extractedLabel?.includes(variable?.ColumnLabel)
                    )}
                  </m.Button>
                ) : (
                  commonChipRender(isLabelPresent, variable)
                )}
                {showDescription && !clickFunction && (
                  <core.Box className="outputsynopsis ed-small">
                    <m.Typography variant="body2">
                      {training &&
                      training[variable.ColumnLabel]?.active_algorithm &&
                      !statusState.EMPTY_STATES.includes(
                        training[variable.ColumnLabel]?.active_algorithm
                      )
                        ? training[variable.ColumnLabel]?.evaluation &&
                          training[variable.ColumnLabel]?.evaluation[
                            training[variable.ColumnLabel]?.active_algorithm
                          ]?.synopsis
                        : ""}
                    </m.Typography>
                  </core.Box>
                )}
                <br />
              </core.Box>
            )
          })}
      </core.Box>
    </core.Typography>
  )
}

export const InputsCard = ({
  emulatorConfig,
  showDescription,
  handleDragStart,
  chipPosition,
  disableComponent,
}) => {
  const config = emulatorConfig.calculator
  const classes = useStyles()
  const { width } = file.useWindowDimensions()
  const theme = m.useTheme()

  return (
    <core.Typography>
      <core.Typography
        className="ml-display-flex ml-align-center"
        gutterBottom
        variant="h5"
      >
        <InputIcon />
        Inputs:
      </core.Typography>
      <core.Box>
        {config.InputVariables &&
          Object.entries(config.InputVariables).map(([id, variable]) => {
            const isLabelPresent = chipPosition?.some(
              (item) => item.content === variable.Name && item.type === "input"
            )

            return (
              <m.Typography variant="body2" key={`inputVar_${variable}` + id}>
                <core.Chip
                  draggable={
                    !disableComponent && !showDescription && !isLabelPresent
                  }
                  size={width > 1110 && showDescription ? "small" : "medium"}
                  className={`hover-styling ${classes.chipStyle}`}
                  icon={ChipIcon(variable.Type)}
                  label={variable.Name}
                  title={getDomain(variable)}
                  color={isLabelPresent ? "default" : "primary"}
                  style={{ fontFamily: theme.palette.typography.fontFamily }}
                  onDragStart={(e) => {
                    !showDescription && handleDragStart(e)
                  }}
                />

                <br />
              </m.Typography>
            )
          })}
      </core.Box>
    </core.Typography>
  )
}

export function ChipIcon(type) {
  switch (type) {
    case "text":
      return <TextIcon />
    case "categorical":
      return <TextIcon />
    case "quantity":
      return <QuantityIcon />
    case "numerical":
      return <NumberIcon />
    case "number":
      return <NumberIcon />
    default:
      return <MissingIcon />
  }
}

const TextIcon = () => {
  return (
    <core.SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="-15 -15 130 130"
        enableBackground="new 0 0 100 100"
        xmlSpace="preserve"
      >
        <switch>
          <foreignObject
            requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
            x="0"
            y="0"
            width="1"
            height="1"
          />
          <g>
            <path
              d="M50,5C25.3,5,5.2,25.1,5,49.7c-0.1,12,4.5,23.4,13,31.9c8.4,8.6,19.7,13.3,31.7,13.4c0,0,0.3,0,0.3,0
                c24.7,0,44.8-20.1,45-44.7C95.1,25.5,75.1,5.2,50,5z M71.1,38.1c0,1.6-1.3,2.9-2.9,2.9s-2.9-1.3-2.9-2.9v-3.6H52.9V70h3.6
                c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9h-13c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9h3.6V34.5H34.7v3.6
                c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9v-6.5c0-1.6,1.3-2.9,2.9-2.9h36.4c1.6,0,2.9,1.3,2.9,2.9V38.1z"
            />
          </g>
        </switch>
      </svg>
    </core.SvgIcon>
  )
}

const NumberIcon = () => {
  return (
    <core.SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="-1 -3 24 30"
        version="1.1"
        x="0px"
        y="0px"
      >
        <title>icon/circle-number-solid</title>
        <desc>Created with Sketch.</desc>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M17,9 L15.62,9 L15.98,7.196 L14.02,6.804 L13.58,9 L11.62,9 L11.98,7.196 L10.02,6.804 L9.58,9 L7,9 L7,11 L9.181,11 L8.78,13 L7,13 L7,15 L8.38,15 L8.02,16.804 L9.98,17.196 L10.42,15 L12.38,15 L12.02,16.804 L13.98,17.196 L14.42,15 L17,15 L17,13 L14.819,13 L15.22,11 L17,11 L17,9 Z M22,12 C22,17.522 17.522,22 12,22 C6.478,22 2,17.522 2,12 C2,6.478 6.478,2 12,2 C17.522,2 22,6.478 22,12 L22,12 Z M11.22,11 L13.181,11 L12.78,13 L10.819,13 L11.22,11 Z"
            fill="#000000"
          />
        </g>
      </svg>
    </core.SvgIcon>
  )
}

const QuantityIcon = () => {
  return (
    <core.SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-4 -8 41 52"
        x="0px"
        y="0px"
      >
        <path d="M18,0A18,18,0,1,0,36,18,18,18,0,0,0,18,0ZM28.83,14.39,14.39,28.84A.57.57,0,0,1,14,29a.59.59,0,0,1-.41-.16L7.17,22.42a.56.56,0,0,1,0-.81l2-2,.8.8,2.81,2.81a.58.58,0,0,0,.4.17.54.54,0,0,0,.4-.17.55.55,0,0,0,0-.8l-2.8-2.81-.81-.8,1.61-1.61,1.62,1.62a.56.56,0,0,0,.41.17.5.5,0,0,0,.39-.17.55.55,0,0,0,0-.8l-.82-.82-.8-.8L14,14.79,17.6,18.4a.56.56,0,0,0,.8,0,.57.57,0,0,0,0-.8l-2.81-2.81-.8-.8,1.61-1.61.8.8L18,14a.58.58,0,0,0,.4.17.56.56,0,0,0,.4-.17.58.58,0,0,0,0-.81L18,12.38l-.8-.8L18.81,10l3.61,3.61a.54.54,0,0,0,.4.16.57.57,0,0,0,.4-.16.58.58,0,0,0,0-.81L20.41,10l-.8-.8,2-2a.56.56,0,0,1,.81,0l6.41,6.42A.55.55,0,0,1,28.83,14.39Z" />
      </svg>
    </core.SvgIcon>
  )
}

const MissingIcon = () => {
  return (
    <core.SvgIcon>
      <svg
        height="100px"
        width="100px"
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 100 100"
        xmlSpace="preserve"
      >
        <path
          fill="#000000"
          d="M50,1C22.938,1,1,22.938,1,50c0,27.062,21.938,49,49,49c27.062,0,49-21.938,49-49C99,22.938,77.062,1,50,1z
                M50,83.709c-4.164,0-7.54-3.376-7.54-7.54s3.376-7.54,7.54-7.54c4.164,0,7.54,3.376,7.54,7.54S54.164,83.709,50,83.709z
                M57.54,55.297c0,4.164-3.376,7.541-7.54,7.541c-4.164,0-7.54-3.377-7.54-7.541V23.831c0-4.164,3.376-7.54,7.54-7.54
                c4.164,0,7.54,3.376,7.54,7.54V55.297z"
        ></path>
      </svg>
    </core.SvgIcon>
  )
}

export const runDefaultTest = async (
  emulatorConfig,
  setAlertState,
  setDefaultTestStatusState,
  forAll,
  estimateData,
  setAllStatus
) => {
  let jobResponses = {}
  const pollingIntervalTime = 1000
  const timeoutDuration = 30000
  const randomizeBody = {
    emulator_id: emulatorConfig.id,
  }
  const readyForCalcBody = {
    emulator_id: emulatorConfig.id,
    ready_for_calcs: false,
  }

  const failTest = (postAlert, resultStatus, jobID) => {
    setDefaultTestStatusState((prev) => ({
      ...prev,
      status: resultStatus === "pass" ? resultStatus : "calculation fail",
    }))

    jobID &&
      forAll === "all" &&
      setAllStatus((prevArr) => {
        return prevArr.map((item) =>
          item.jobId === jobID
            ? {
                ...item,
                status:
                  resultStatus === "pass" ? resultStatus : "calculation fail",
              }
            : item
        )
      })

    forAll === "single" &&
      setAlertState({
        boolState: true,
        message: postAlert.message,
        severityState: postAlert.state,
      })
  }

  const notReadyForCalc = async () => {
    if (emulatorConfig.ready_for_calcs) {
      const res = await file.EmulationLab.post(updateCalcMode, readyForCalcBody)
      if (res) {
        setTimeout(() => {
          failTest(
            { message: "Updated ready_for_calcs to false.", state: "success" },
            "fail"
          )
        }, 3000)
        return true
      }
    }
  }

  try {
    setDefaultTestStatusState({
      name: emulatorConfig.name,
      status: "draft",
    })

    const randomizeInputs = await file.EmulationLab.post(
      randomizeInputUrl,
      randomizeBody
    )
    const postBody = randomizeInputs.data
    if (!postBody) {
      failTest(
        {
          message: "Couldn't run test! Recheck your config and calc file.",
          state: "error",
        },
        "fail"
      )
      notReadyForCalc()
      return true
    }

    const data = await callCalculateToRun(postBody)
    forAll === "single" &&
      setAlertState({
        boolState: true,
        message: "Executing default test.",
        severityState: "success",
      })

    forAll === "all" &&
      setAllStatus((prev) => [
        ...prev,
        {
          jobId: data,
          name: emulatorConfig.name,
          status: "draft",
        },
      ])
    const jobObject = [
      {
        emulatorId: postBody.emulator_id,
        jobid: data,
        inputs: postBody.FeaturesData,
      },
    ]

    let pollingInterval = setInterval(async () => {
      const { updatedResponses, allJobsCompleteorFailed } =
        await useCalculatorPolling(
          jobResponses,
          jobObject,
          "calculate",
          estimateData,
          emulatorConfig.calculator
        )
      setDefaultTestStatusState((prev) => ({
        ...prev,
        status: prev.status !== "calculation fail" ? "running" : prev.status,
      }))

      forAll === "all" &&
        setAllStatus((prevArr) => {
          return prevArr.map((item) =>
            item.jobId === Object.keys(updatedResponses)[0]
              ? {
                  ...item,
                  status: !["calculation fail", "pass"].includes(item.status)
                    ? "running"
                    : item.status,
                }
              : item
          )
        })

      const lastKey = Object.keys(updatedResponses).pop()
      const lastKeyStatus = updatedResponses[lastKey]?.status || ""

      if (
        (allJobsCompleteorFailed &&
          Object.keys(updatedResponses).length === 0) ||
        ["canceled", "failed"].includes(lastKeyStatus)
      ) {
        clearInterval(pollingInterval)
        pollingInterval = null
        failTest(
          {
            message: "Failed to process previous request!",
            state: "error",
          },
          "fail",
          Object.keys(updatedResponses)[0]
        )
        return true
      }

      if (lastKeyStatus === "complete") {
        const calcBody = {
          ...readyForCalcBody,
          ready_for_calcs: true,
        }
        clearInterval(pollingInterval)
        pollingInterval = null
        failTest(
          { message: "Default test successful.", state: "success" },
          "pass",
          Object.keys(updatedResponses)[0]
        )

        if (!emulatorConfig.ready_for_calcs) {
          const res = await file.EmulationLab.post(updateCalcMode, calcBody)
          if (res) {
            failTest(
              { message: "Updated ready_for_calcs to true.", state: "success" },
              "pass"
            )
          }
        }
        return true
      }

      setTimeout(() => {
        if (
          (!updatedResponses || updatedResponses[0]?.status !== "complete") &&
          pollingInterval
        ) {
          clearInterval(pollingInterval)
          pollingInterval = null
          failTest(
            {
              message: "Some issue with the agents in processing test.",
              state: "warning",
            },
            "fail",
            Object.keys(updatedResponses)[0]
          )
          return true
        }
      }, timeoutDuration)
    }, pollingIntervalTime)

    return () => clearInterval(pollingInterval)
  } catch (error) {
    console.error("Error-", error)
    failTest(
      {
        message: "Couldn't run test! Recheck your config and calc file.",
        state: "error",
      },
      "fail"
    )
    notReadyForCalc()
    return true
  }
}

export const ActionsCard = () => {
  let { emulatorId } = useParams()
  const navigate = useNavigate()
  const classes = useStyles()
  const { width } = file.useWindowDimensions()
  const { inpVar, outVar } = configInpOut()

  const emulatorConfig = useRecoilValue(getEmulatorData)
  const mongoProfile = useRecoilValue(mongoProfileState)
  const setAlertState = useSetRecoilState(alertStates)
  const [defaultTestStatusState, setDefaultTestStatusState] =
    useRecoilState(defaultTestStatus)
  const estimateData = useRecoilValue(estimatedTimeState)

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [editMeta, setEditMeta] = useState(false)

  const emulatorCalcInputConfig = emulatorConfig?.calculator?.InputVariables
  const emulatorStatus = emulatorConfig?.status
  const noOfReleases = Object.keys(emulatorConfig.versions.releases).length
  const showCalculator = useGetCalcShowHideStatus(emulatorConfig, noOfReleases)
  const useEmulator = useGetEmulatorUsageStatus(emulatorConfig)
  const noConfigs = inpVar === 0 || outVar === 0
  const isCalculateMode = emulatorConfig?.modes?.includes(
    statusState.emulatorMode.calculate
  )
  const disableCalc = !showCalculator || useEmulator
  const disableDefaultTest = returnDisableTestingVal(
    noConfigs,
    isCalculateMode,
    emulatorConfig,
    true
  )

  const handleDeleteDialogOpen = (e) => {
    e.preventDefault()
    setOpenDeleteDialog(true)
  }

  const tryItRouteChange = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const searchParamsString = getParamString(emulatorCalcInputConfig)
    let path = `/em/${emulatorId}/tryit?mode=calculate&${searchParamsString}`
    navigate(path)
    return false
  }

  const getCalculatorBadgeSymbol = () => {
    switch (emulatorStatus) {
      case statusState.allEmulatorStatus.deployed:
        return emulatorConfig.numUses
      case statusState.allEmulatorStatus.training:
        return <RunningWithErrorsIcon />
      case statusState.allEmulatorStatus.prepared:
        return <RunningWithErrorsIcon />
      default:
        return <RunningWithErrorsIcon />
    }
  }

  const cloneEmulator = () => {
    setEditMeta(true)
  }

  const renderIconButton = () => {
    return (
      <>
        <m.IconButton
          title={"Click to try the emulator engine"}
          className="zoom-hover-10"
          color="primary"
          onClick={tryItRouteChange}
        >
          <m.Badge badgeContent={getCalculatorBadgeSymbol()} color="secondary">
            <BsFillCalculatorFill
              style={{ fontSize: width > 400 ? "2rem" : "1rem" }}
            />
          </m.Badge>
        </m.IconButton>
      </>
    )
  }

  const renderDefaultTestIcon = () => {
    const gradingIcon = () => <GradingIcon fontSize="large" />

    return (
      <m.IconButton
        title="Run Default Test"
        disabled={
          disableDefaultTest ||
          ["running", "draft"].includes(defaultTestStatusState.status)
        }
        className="zoom-hover-10"
        color="primary"
        onClick={() =>
          runDefaultTest(
            emulatorConfig,
            setAlertState,
            setDefaultTestStatusState,
            "single",
            estimateData
          )
        }
      >
        {defaultTestStatusState ? (
          <m.Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              top: "-2px",
              right: "3px",
              border: "2px solid white",
              "& .MuiBadge-badge": {
                right: "9px",
                bottom: "7px",
                backgroundColor: "white",
                height: "20px",
                width: "20px",
              },
            }}
            badgeContent={getIconBasedOnStatus(
              "test",
              defaultTestStatusState?.status,
              false,
              classes
            )}
          >
            {gradingIcon()}
          </m.Badge>
        ) : (
          gradingIcon()
        )}
      </m.IconButton>
    )
  }

  return (
    <>
      <core.Card className="ml-height">
        <core.CardContent>
          <core.Box className={`${classes.colorPrimaryMain}`}>
            <m.Typography variant="h6">Actions</m.Typography>
          </core.Box>
          <br />
          <br />
          <m.Grid container alignItems={"center"}>
            <file.Authorization processName="deleteEmulator">
              <m.Grid item xs={"auto"}>
                <m.IconButton
                  title={"DELETES the EMULATOR! CAUTION"}
                  className="zoom-hover-10"
                  color="primary"
                  onClick={handleDeleteDialogOpen}
                >
                  <DeleteOutlineOutlinedIcon
                    style={{ fontSize: "2.5rem" }}
                    color="primary"
                  />
                </m.IconButton>
              </m.Grid>
            </file.Authorization>
            <m.Grid item xs={"auto"}>
              {!disableCalc &&
                checkAuthorization(
                  emulatorConfig,
                  mongoProfile,
                  noOfReleases,
                  renderIconButton
                )}
            </m.Grid>
            <file.Authorization processName="uploadEmFilesOrTrain">
              <m.Grid item xs={"auto"}>
                <m.IconButton
                  title={"Clone the emulator?"}
                  className="zoom-hover-10"
                  color="primary"
                  onClick={cloneEmulator}
                >
                  <FileCopySharpIcon
                    className="summryiconsize"
                    color="primary"
                  />
                </m.IconButton>
              </m.Grid>
            </file.Authorization>
            <file.Authorization processName="manageIO">
              <m.Grid item xs={"auto"}>
                {renderDefaultTestIcon()}
              </m.Grid>
            </file.Authorization>
          </m.Grid>
          <file.DeleteEmulator
            openDeleteDialog={openDeleteDialog}
            setOpenDeleteDialog={setOpenDeleteDialog}
            name={emulatorConfig.name}
            emulatorId={emulatorConfig.id}
            navigatePage={true}
          />
        </core.CardContent>
      </core.Card>
      <file.CloneEmulator
        open={editMeta}
        setEditMeta={setEditMeta}
        emulator={emulatorConfig}
      />
    </>
  )
}
