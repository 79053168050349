import { useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import * as m from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { ReactComponent as Lookup } from "../../assets/newIcons/search.svg"
import { ReactComponent as Calculate } from "../../assets/newIcons/calculator.svg"
import { ReactComponent as Emulate } from "../../assets/newIcons/Virus-Lab-Research-Test-Tube--Streamline-Ultimate.svg"
import { ReactComponent as Optimize } from "../../assets/newIcons/optimise.svg"
import * as files from "../../GlobalFileContainer"
import {
  getEmulatorData,
  outputsParametersAndVariables,
  resultJobIdState,
} from "../../state/projectState"
import {
  pickStatusWithSoftwareIcons,
  getParamString,
  convertToUppercase,
  formatStringWithChips,
} from "../../state/services"
import { createIcon } from "../shared components/CommonStyles/CommonStyles"

const ChipWithIconForVariables = ({
  emulatorConfigs,
  color,
  fontColor,
  type,
}) => {
  const [openConfig, setOpenConfig] = useState(false)

  const changeConfig =
    emulatorConfigs &&
    (openConfig
      ? Object.entries(emulatorConfigs)
      : Object.entries(emulatorConfigs).slice(0, 5))

  return (
    <m.Grid container gap={"4px"} alignItems={"center"}>
      <m.Typography
        style={{
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: 800,
        }}
      >
        {type}:
      </m.Typography>
      {changeConfig?.map(([id, variable]) => (
        <m.Chip
          size={"medium"}
          key={`outputVar` + id + variable}
          className="ml-display-flex ml-align-center"
          label={variable.Name}
          color={color}
          style={{
            fontFamily: "Inter, Helvetica",
            padding: "4px 8px",
            fontSize: "10px",
            gap: "3.6px",
            color: fontColor,
          }}
        />
      ))}
      {Object.entries(emulatorConfigs).length > 5 && (
        <m.IconButton onClick={() => setOpenConfig(!openConfig)}>
          {openConfig ? <ChevronLeftIcon /> : <MoreHorizIcon />}
        </m.IconButton>
      )}
    </m.Grid>
  )
}

const IndividualLaunchCard = ({
  cardsLaunchedData,
  i,
  emulatorGroup,
  index,
  setSelectedCardData,
}) => {
  const theme = m.useTheme()
  const navigate = useNavigate()
  const { emulatorId } = useParams()

  const themePalatte = theme.palette

  const greyTheme = themePalatte.grey
  const primaryGrey = greyTheme.primaryLight

  const commonTheme = themePalatte.common

  const [searchParams, setSearchParams] = useSearchParams()
  const setSelectedOutputs = useSetRecoilState(outputsParametersAndVariables)
  const setJobId = useSetRecoilState(resultJobIdState)

  const emulator = useRecoilValue(getEmulatorData)

  const {
    validations,
    mode,
    description,
    version,
    package: packages,
  } = cardsLaunchedData || {}
  const launchDataSource = packages?.source
  const launchSourceSoftware = launchDataSource?.software
  const launchSourceType = launchDataSource?.type
  const inputVars = packages?.io?.InputVariables
  const outputVars = packages?.io?.OutputVariables

  const formattedString = formatStringWithChips(packages?.instructions?.method)
  const hasOptimizeMode = emulatorGroup.some((emu) => emu.mode === "optimize")

  const updateModeSearchParam = (cardsLaunchedData) => {
    setSelectedCardData(cardsLaunchedData)

    const {
      mode,
      id,
      sql_table_ref,
      package: packages,
    } = cardsLaunchedData || {}

    const storedData = localStorage.getItem("Consumer Optimize")
    const parsedData = JSON.parse(storedData)
    const isModeOptimize = mode === "optimize"

    const modifiedData =
      parsedData?.length > 0
        ? parsedData?.find((item) => item.emulator_id === emulatorId)
        : []
    const emulatorInputVarConfig = isModeOptimize
      ? modifiedData
        ? modifiedData?.instructions?.parameters
        : packages?.instructions?.parameters
      : packages?.io?.InputVariables

    const newParams = new URLSearchParams(searchParams)
    newParams.set("mode", mode)
    newParams.set("launchid", id)
    newParams.set("index", sql_table_ref)
    setSearchParams(newParams)

    const searchParamsInputString = getParamString(
      emulatorInputVarConfig,
      isModeOptimize
    )
    const searchString = newParams.toString()

    if (isModeOptimize) {
      setSelectedOutputs(modifiedData?.instructions)
      setJobId(modifiedData?.jobId)
    }
    navigate(
      `/new/${cardsLaunchedData?.emulator_id}/tryit?${searchString}&${searchParamsInputString}`
    )
  }

  const createModeIcon = (IconComponent, title = "") => ({
    icon: createIcon(IconComponent, commonTheme.white),
    ...(title && { title }),
  })

  const modeIcons = {
    calculate: createModeIcon(Calculate),
    emulate: createModeIcon(Emulate),
    lookup: createModeIcon(Lookup, "Curated Lookup"),
    optimization: createModeIcon(Optimize, "Optimize"),
    optimize: createModeIcon(Optimize, "Optimize"),
  }

  const getModeIcon = (mode) => modeIcons[mode] || {}

  return (
    <>
      <m.Grid container sx={{ padding: "16px 12px", gap: "12px" }}>
        <m.Grid container justifyContent="space-between">
          <m.Grid
            className="ml-display-flex ml-align-center"
            sx={{ gap: "8px" }}
          >
            {launchSourceType === "calculator" && (
              <m.Grid
                container
                sx={{
                  padding: "4px",
                  border: `1px solid ${primaryGrey}`,
                  width: "32px",
                  height: "32px",
                  borderRadius: "6px",
                }}
              >
                {pickStatusWithSoftwareIcons(
                  launchSourceSoftware,
                  "none",
                  "none",
                  "22px",
                  launchSourceSoftware
                )}
              </m.Grid>
            )}
            <m.Typography variant="h4" fontFamily={"Roboto"}>
              {convertToUppercase(mode)}
            </m.Typography>
            <m.Grid
              sx={{
                borderRadius: "5999.4px",
                padding: "4px 8px",
                gap: "3.6px",
                backgroundColor: primaryGrey,
              }}
            >
              <m.Typography variant="body2">Version {version}</m.Typography>
            </m.Grid>
          </m.Grid>
          <m.Grid
            className="ml-display-flex ml-align-center"
            sx={{ gap: "12px" }}
          >
            {(validations?.[0]?.length > 0 ||
              cardsLaunchedData?.step !== "launched") && (
              <files.CommonDMTBadge
                validations={
                  validations?.length > 0
                    ? validations
                    : cardsLaunchedData?.step
                }
                style={{
                  padding: "6.4px 12.8px",
                  gap: "4.8px",
                }}
                size={"19.2px"}
                fontSize={"16px"}
              />
            )}
            <files.FavouriteAndShareIcon
              emulator={emulator}
              styleProp={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
              }}
              placeHolder={true}
            />
          </m.Grid>
        </m.Grid>
        <m.Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <m.Grid item xs={12} md={8}>
            <m.Typography
              fontSize={"14px"}
              fontWeight={500}
              style={{ color: greyTheme.main }}
            >
              {description}
            </m.Typography>
          </m.Grid>
          <m.Grid
            item
            sx={{ display: "flex", gap: "4px", justifyContent: "end" }}
            xs={12}
            md={4}
          >
            <m.Button
              variant="contained"
              sx={{
                color: commonTheme.white,
                padding: "4px 12px",
                borderRadius: "8px",
                gap: "4px",
              }}
              onClick={() => updateModeSearchParam(cardsLaunchedData, index)}
              startIcon={getModeIcon(mode).icon}
            >
              <m.Typography fontFamily={"Roboto"} fontWeight={500}>
                {convertToUppercase(getModeIcon(mode)?.title ?? mode)}
              </m.Typography>
            </m.Button>
          </m.Grid>
        </m.Grid>
      </m.Grid>
      <m.Divider />
      {(i === emulatorGroup?.length - 1 || hasOptimizeMode) && (
        <m.Grid container sx={{ padding: "16px 12px" }} gap={"8px"}>
          {mode !== "optimize" ? (
            <m.Grid container gap={"10px"}>
              <ChipWithIconForVariables
                emulatorConfigs={inputVars}
                color={"primary"}
                fontColor={commonTheme.white}
                type={"Inputs"}
              />
              <ChipWithIconForVariables
                emulatorConfigs={outputVars}
                color={"secondary"}
                fontColor={commonTheme.black}
                type={"Outputs"}
              />
            </m.Grid>
          ) : (
            <>
              {formattedString && (
                <m.Box display="flex" flexWrap="wrap" alignItems="center">
                  {formattedString.map((str) => (
                    <>
                      {typeof str === "string" ? str : str}
                      &nbsp;
                    </>
                  ))}
                </m.Box>
              )}
            </>
          )}
        </m.Grid>
      )}
      {i !== emulatorGroup?.length - 1 && hasOptimizeMode && <m.Divider />}
    </>
  )
}

export default IndividualLaunchCard
