import React from "react"
import { useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { Stack } from "@mui/system"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import { TiLightbulb, TiCogOutline } from "react-icons/ti"
import { GoDatabase } from "react-icons/go"
import { RiFileSearchLine } from "react-icons/ri"
import { FaScroll, FaCalculator } from "react-icons/fa"
import { SlCalculator, SlCloudUpload, SlChemistry } from "react-icons/sl"
import { ImTable } from "react-icons/im"
import { TbCloudComputing, TbTestPipe } from "react-icons/tb"
import { getEmulatorData, globalDialogBox } from "../../state/projectState"
import {
  ActionsCard,
  CalculatorCard,
  InputsCard,
  OutputsCard,
} from "./CalculatorCard"
import * as files from "../../GlobalFileContainer"
import { allStatus, emulatorSoftwareType } from "../../state/StatusState"
import { NewStatusInfo } from "./NewStatusInfo"
import {
  sourceType,
  sourceRef,
  latestVersion,
  convertToUppercase,
  betaVersion,
} from "../../state/services"

const EmulatorSummary = ({ fileList }) => {
  return <SummaryGrid fileList={fileList} />
}

function SummaryGrid({ fileList }) {
  const { width } = files.useWindowDimensions()
  let { emulatorId } = useParams()

  const emulatorConfig = useRecoilValue(getEmulatorData)
  const setGlobalDialogOpen = useSetRecoilState(globalDialogBox)
  const noneString = "none"

  const openStatusHelp = () => {
    setGlobalDialogOpen((prev) => ({
      ...prev,
      isOpen: true,
      content: (
        <core.Box>
          <m.List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              padding: "0",
            }}
          >
            {" "}
            {allStatus.map((status) => displayStatusList(status))}
          </m.List>
        </core.Box>
      ),
      title: "Emulator Status Information",
      maxWidth: "lg",
    }))
  }

  function getUsageSummary() {
    return (
      <>
        <files.VariableInfoLine varKey={"Current Status"} summary={"yes"}>
          <m.Typography variant="body2">
            {convertToUppercase(emulatorConfig?.status)}
          </m.Typography>
        </files.VariableInfoLine>
        <files.VariableInfoLine varKey={"Deployed Apps"} summary={"yes"}>
          <m.Typography variant="body2">
            {emulatorConfig.appsUsing?.map((apUse, ind) => (
              <span key={`${apUse}-${ind}`}>{convertToUppercase(apUse)}</span>
            ))}
          </m.Typography>
        </files.VariableInfoLine>
        <files.VariableInfoLine varKey={"Total Uses"} summary={"yes"}>
          <m.Typography variant="body2">{emulatorConfig.numUses}</m.Typography>
        </files.VariableInfoLine>
        {emulatorConfig.usage && (
          <files.VariableInfoLine varKey={"Usage Details"} summary={"yes"}>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {Object.entries(emulatorConfig?.usage)?.map(([key, value]) => (
                <li key={key + value}>
                  <m.Typography variant="body2">
                    {convertToUppercase(key)}: {value}
                  </m.Typography>
                </li>
              ))}
            </ul>
          </files.VariableInfoLine>
        )}
      </>
    )
  }

  const getSummaryMainCardDetails = () => {
    const emulatorCreateDate = emulatorConfig?.createdAt
    const emulatorDeployDate = emulatorConfig?.deployedAt
    const latestVersionState = latestVersion(emulatorConfig)
    const betaVersionState = `${betaVersion(emulatorConfig)}*`
    const activeCalcFile = sourceType(emulatorConfig)
    const activeFileRef = sourceRef(emulatorConfig)

    const isHostedCalc = activeCalcFile === emulatorSoftwareType.hosted

    const getCurrentVersion = () => {
      if (latestVersionState === "None") {
        return betaVersionState
      } else {
        return latestVersionState
      }
    }

    return (
      <core.Grid container direction="column" justifyContent="space-between">
        <core.Grid item>
          <core.Grid
            item
            className="ml-display-flex ml-flex-dir-row ml-space-between ml-align-start"
          >
            <core.Typography variant="h3">
              {emulatorConfig.name}
            </core.Typography>
            <core.Grid item className="ml-display-flex ml-flex-dir-row">
              <files.HeartnContribute
                emulator={emulatorConfig}
                fontSize={"smaller"}
              />
            </core.Grid>
          </core.Grid>
          <core.Typography variant="caption" display="block" gutterBottom>
            {emulatorCreateDate && (
              <>Date Created:&nbsp;{emulatorCreateDate?.split(" ")[0]}</>
            )}
            <br />
            {latestVersionState && <>Current Version: {getCurrentVersion()}</>}
            <br />
            {emulatorDeployDate && (
              <>Date Deployed:&nbsp;{emulatorDeployDate?.split(" ")[0]}</>
            )}
          </core.Typography>
          {activeCalcFile !== emulatorSoftwareType.upload && (
            <core.Typography variant="caption" display="block" gutterBottom>
              {`${isHostedCalc ? "Ref. Url" : "Active Calc File"}: ${
                isHostedCalc ? activeFileRef : activeFileRef?.split("/")[2]
              }`}
            </core.Typography>
          )}
          <core.Typography variant="body1" style={{ paddingBottom: "10px" }}>
            {emulatorConfig.description}
          </core.Typography>
        </core.Grid>
      </core.Grid>
    )
  }

  const getDataExplorationSummary = () => {
    const trainedRows = emulatorConfig?.data.clean_row_count
    const totalDataPoints = emulatorConfig?.data.total_row_count
    const ignoredPoint = totalDataPoints - trainedRows

    return totalDataPoints ? (
      <>
        <files.VariableInfoLine varKey={"Rows Trained"} sum={"yes"}>
          <m.Typography variant="body2">{trainedRows?.toString()}</m.Typography>
        </files.VariableInfoLine>
        <files.VariableInfoLine varKey={"Total Datapoints"} sum={"yes"}>
          <m.Typography variant="body2">
            {totalDataPoints?.toString()}
          </m.Typography>
        </files.VariableInfoLine>
        <files.VariableInfoLine varKey={"Ignored Datapoints"} sum={"yes"}>
          <m.Typography variant="body2">
            {ignoredPoint?.toString()}
          </m.Typography>
        </files.VariableInfoLine>
        <files.VariableInfoLine varKey={"Uploaded Data Files"} sum={"yes"}>
          <m.Typography variant="body2">
            {fileList?.length?.toString()}
          </m.Typography>
        </files.VariableInfoLine>
      </>
    ) : (
      <m.Typography variant="body1" padding={"0 10px"}>
        Data needs to be uploaded or generated for this emulator.
      </m.Typography>
    )
  }

  if (!emulatorConfig && emulatorConfig?.id !== emulatorId) {
    return ""
  }
  const displayStatusList = (status) => {
    const iconId = "statusicons"
    const iconSize = "40px"

    const icons = {
      [allStatus[0]]: <TiLightbulb id={iconId} size={iconSize} />,
      [allStatus[1]]: <FaScroll id={iconId} size={iconSize} />,
      [allStatus[2]]: <SlCalculator id={iconId} size={iconSize} />,
      [allStatus[3]]: <FaCalculator id={iconId} size={iconSize} />,
      [allStatus[4]]: <SlCloudUpload id={iconId} size={iconSize} />,
      [allStatus[5]]: <GoDatabase id={iconId} size={iconSize} />,
      [allStatus[6]]: <TiCogOutline id={iconId} size={iconSize} />,
      [allStatus[7]]: <ImTable id={iconId} size={iconSize} />,
      [allStatus[8]]: <RiFileSearchLine id={iconId} size={iconSize} />,
      [allStatus[9]]: <TbCloudComputing id={iconId} size={iconSize} />,
      [allStatus[10]]: <TbTestPipe id={iconId} size={iconSize} />,
      [allStatus[11]]: <SlChemistry id={iconId} size={iconSize} />,
    }

    return (
      <>
        <m.ListItem alignItems="flex-start">
          <m.ListItemAvatar>{icons[status]}</m.ListItemAvatar>
          <m.ListItemText
            primary={
              <m.Typography variant="body1" fontWeight={650}>
                {status}
              </m.Typography>
            }
            secondary={
              <React.Fragment>
                <core.Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body1"
                >
                  {NewStatusInfo[status]?.info}
                </core.Typography>
              </React.Fragment>
            }
          />
        </m.ListItem>
        {status !== allStatus[11] && (
          <m.Divider variant="inset" component="li" />
        )}
      </>
    )
  }

  return (
    <files.ErrorBoundary>
      <core.Grid
        id="mainSummaryCont"
        container
        spacing={1}
        alignItems="stretch"
      >
        <core.Grid item xs>
          <core.Grid container spacing={1} alignItems="stretch">
            <core.Grid item xs={12} md={9}>
              <files.SummaryElementCard image={emulatorConfig.image}>
                {getSummaryMainCardDetails()}
              </files.SummaryElementCard>
            </core.Grid>
            <core.Grid item xs={12} md={3}>
              <files.ErrorBoundary>
                <files.ErrorBoundary>
                  <ActionsCard emName={emulatorConfig.name} />
                </files.ErrorBoundary>
              </files.ErrorBoundary>
            </core.Grid>
            <core.Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              className={width > 1535 ? "ml-flex-dir-col" : "noid"}
            >
              <files.ErrorBoundary>
                <files.SummaryElementCard
                  title="Status"
                  openStatusHelp={openStatusHelp}
                >
                  <files.EmulatorStatus emulator={emulatorConfig} />
                </files.SummaryElementCard>
              </files.ErrorBoundary>
            </core.Grid>
            <core.Grid
              item
              key={`${noneString}_Explore`}
              xs={12}
              sm={6}
              md={12}
              lg={6}
              xl={6}
            >
              <files.ErrorBoundary>
                <files.SummaryElementCard
                  title="Data"
                  desc={getDataExplorationSummary()}
                />
              </files.ErrorBoundary>
            </core.Grid>
            <core.Grid
              item
              key={`${noneString}_deploy`}
              xs={12}
              sm={6}
              md={12}
              lg={6}
              xl={6}
            >
              <files.ErrorBoundary>
                <files.SummaryElementCard
                  title="Usage"
                  desc={getUsageSummary()}
                ></files.SummaryElementCard>
              </files.ErrorBoundary>
            </core.Grid>
          </core.Grid>
        </core.Grid>
        <core.Grid
          className="hover-styling"
          item
          key={`${noneString}_OutputsCard`}
          xs={12}
          lg={"auto"}
        >
          <CalculatorCard showDescription={true}>
            <files.ErrorBoundary>
              <Stack direction={{ xs: "row", lg: "column" }} spacing={4}>
                <InputsCard
                  emulatorConfig={emulatorConfig}
                  showDescription={true}
                />
                <OutputsCard
                  emulatorConfig={emulatorConfig}
                  showDescription={true}
                />
              </Stack>
            </files.ErrorBoundary>
          </CalculatorCard>
        </core.Grid>
      </core.Grid>
    </files.ErrorBoundary>
  )
}

export default EmulatorSummary
