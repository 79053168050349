import { useEffect, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useNavigate } from "react-router"
import * as m from "@mui/material"
import * as projState from "../../state/projectState"
import AgentSummarySkeleton from "./AgentSummarySkeleton"
import {
  convertToUppercase,
  pickStatusWithSoftwareIcons,
  seperateSoftwareAndSubType,
} from "../../state/services"

export const flexCenterProps = {
  display: "flex",
  alignItems: "center",
}

export const breakpointCss = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row" },
}

export const marginPaddingForBreaks = {
  padding: { xs: "10px 5px", md: "10px 15px" },
}

export const cumulativeDataFunc = (data) => {
  const cumulativeData = Object.values(data).reduce((cumulative, current) => {
    for (const [key, value] of Object.entries(current)) {
      cumulative[key] = (cumulative[key] || 0) + value
    }
    return cumulative
  }, {})

  const result = { all: cumulativeData, ...data }
  return result
}

const RenderAnimatedCards = ({ id, software, type }) => {
  const theme = m.useTheme()
  const navigate = useNavigate()

  const agentHealthDataState = useRecoilValue(projState.agentHealthData)
  const agentCapabilityDataState = useRecoilValue(projState.agentCapabilityData)
  const setAgentHealthFilter = useSetRecoilState(projState.agentDataFilter)
  const setMenuItem = useSetRecoilState(projState.menuItemState)

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true)
    }, id * 200)
    return () => clearTimeout(timeout)
  }, [software])

  const isHealthData = type === "health"
  const renderCardFor = isHealthData
    ? cumulativeDataFunc(agentHealthDataState?.summary)
    : agentCapabilityDataState?.summary?.agents
  const [softwareType, softwareSubType, softwareStatus] =
    seperateSoftwareAndSubType(software)
  const softwareKeysHasBroken = Object.keys(renderCardFor[software])?.includes(
    "broken"
  )

  const handleFilteredState = (e, software, all) => {
    e.stopPropagation()
    if (all) {
      setAgentHealthFilter((prev) => ({
        ...prev,
        status: [],
        capability: [],
      }))
    } else {
      setAgentHealthFilter((prev) => ({ ...prev, capability: [software] }))
    }
    navigate("/agent/health")
    setMenuItem("Agent Health")
  }

  return (
    <m.Card
      onClick={(e) =>
        isHealthData
          ? handleFilteredState(e, softwareType, software === "all")
          : ""
      }
      sx={{
        minHeight: "130px",
        backgroundColor: theme.palette[softwareType].light,
        ...flexCenterProps,
        cursor: "pointer",
      }}
      className={`animate-card width-100 ${
        type === "health" ? "hover-styling" : ""
      } ${isVisible ? "visible" : ""}`}
    >
      <m.Box className="ml-display-flex ml-flex-dir-row ml-align-center ml-height ml-p-5 width-100">
        <m.Grid item xs={6}>
          <m.Box className="ml-display-flex ml-flex-dir-col ml-align-center">
            {!softwareSubType
              ? pickStatusWithSoftwareIcons(
                  softwareType,
                  "none",
                  "none",
                  "50px",
                  software
                )
              : pickStatusWithSoftwareIcons(
                  softwareType,
                  softwareSubType,
                  softwareStatus,
                  "60px",
                  software
                )}
            <m.Typography
              variant="caption"
              sx={{ fontWeight: "bold", padding: "5px" }}
            >
              {software}
            </m.Typography>
          </m.Box>
        </m.Grid>
        <m.Divider
          orientation="vertical"
          flexItem
          sx={{ margin: "0px 10px" }}
        />
        <m.Grid item sx={{ marginLeft: { xs: "10px", md: "0px" } }} xs={6}>
          {Object.keys(renderCardFor[software])?.map((key, index2) => (
            <m.Typography
              key={`${key}-${index2}`}
              style={{
                ...flexCenterProps,
                flexWrap: "wrap",
              }}
            >
              <b style={{ minWidth: softwareKeysHasBroken ? "60px" : "45px" }}>
                {convertToUppercase(key)}
              </b>
              :&nbsp;
              <span>{renderCardFor[software][key]}</span>
            </m.Typography>
          ))}
        </m.Grid>
      </m.Box>
    </m.Card>
  )
}

export const AgentHealth = () => {
  const agentHealthDataState = useRecoilValue(projState.agentHealthData)

  return (
    <m.Grid
      container
      sx={{
        ...breakpointCss,
        ...marginPaddingForBreaks,
        gridTemplateColumns: "repeat(5, 1fr)",
        display: "grid",
        gap: 2,
      }}
    >
      {Object.keys(agentHealthDataState).length
        ? Object.keys(cumulativeDataFunc(agentHealthDataState?.summary))?.map(
            (software, index1) => (
              <m.Box
                className="ml-display-flex width-100"
                key={`${software}-${index1}`}
              >
                <RenderAnimatedCards
                  id={index1}
                  software={software}
                  type="health"
                />
              </m.Box>
            )
          )
        : [...Array(5)].map((e, i) => (
            <m.Box className="ml-display-flex width-100" key={`${e}_${i}`}>
              <AgentSummarySkeleton />
            </m.Box>
          ))}
    </m.Grid>
  )
}

export const AgentCapability = () => {
  const agentCapabilityDataState = useRecoilValue(projState.agentCapabilityData)
  const { emulators = {}, agents = {} } =
    agentCapabilityDataState?.summary || {}

  return (
    <m.Grid container direction={"column"} spacing={2}>
      {Object.keys(emulators).length && Object.keys(agents).length ? (
        <>
          <m.Grid item sx={marginPaddingForBreaks}>
            <m.Card>
              <m.CardContent>
                <m.Typography padding={"5px 0px"}>
                  <b>Emulators</b>
                </m.Typography>
                <m.Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  {Object.keys(emulators)?.map((software, index) => (
                    <m.Grid
                      key={`${software}-${index}`}
                      item
                      xs={4}
                      sm={3}
                      md={1.5}
                    >
                      <m.Typography>
                        {convertToUppercase(software)}:&nbsp;
                        {emulators[software]}&nbsp;
                      </m.Typography>
                    </m.Grid>
                  ))}
                </m.Grid>
              </m.CardContent>
            </m.Card>
          </m.Grid>
          <m.Grid item>
            <m.Card>
              <m.CardContent>
                <m.Typography padding={"5px 0px"}>
                  <b>Agents</b>
                </m.Typography>
                <m.Grid
                  container
                  sx={{
                    ...breakpointCss,
                    gridTemplateColumns: "repeat(4, 1fr)",
                    display: "grid",
                    gap: 2,
                  }}
                >
                  {Object.keys(agents)?.map((agent, index) => (
                    <m.Box
                      className="ml-display-flex width-100"
                      key={`${agent}-${index}`}
                    >
                      <RenderAnimatedCards
                        id={index}
                        software={agent}
                        type="capability"
                      />
                    </m.Box>
                  ))}
                </m.Grid>
              </m.CardContent>
            </m.Card>
          </m.Grid>
        </>
      ) : (
        <m.Grid
          container
          sx={{
            ...breakpointCss,
            gridTemplateColumns: "repeat(5, 1fr)",
            display: "grid",
            gap: 2,
          }}
        >
          {[...Array(5)].map((e, i) => (
            <m.Box className="ml-display-flex width-100" key={`${e}_${i}`}>
              <AgentSummarySkeleton />
            </m.Box>
          ))}
        </m.Grid>
      )}
    </m.Grid>
  )
}
