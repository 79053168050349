import { EmulationLab } from "../GlobalFileContainer"
import { ALGO_STATUS } from "../state/StatusState"

const useCalculatorPolling = async (
  jobResponses,
  jobData,
  pollType,
  estimateData,
  io
) => {
  const updatedResponses = { ...jobResponses }

  // Handle missing InputVariables or OutputVariables
  const inputVariables = io?.InputVariables || []
  const outputVariables = io?.OutputVariables || []
  const inputoutputVars = [...inputVariables, ...outputVariables]

  let allJobsCompleteorFailed = true

  const changeEstimateDataFormat = async (estimateData, status) => {
    //change labels for estimate data
    const updatedEstimateData = {
      "Number of Active Agents": estimateData?.agentCount ?? "no data",
      "Expected Time": estimateData?.wait ?? "no data",
      Status: status,
    }

    //converting estimate data to accepted output type
    const estimateOutputArray = Object.keys(updatedEstimateData).map((key) => ({
      name: key,
      value: [updatedEstimateData[key]],
    }))

    return estimateOutputArray
  }

  const pollJobData = async (jobId) => {
    const url = `/calc/job/${jobId}`
    try {
      const job = await EmulationLab.get(url)
      return job.data
    } catch (error) {
      console.error(error)
      return {}
    }
  }

  for (const job of jobData) {
    const dataId = pollType === "test" ? job.testId : job.jobid

    if (
      dataId in jobResponses &&
      (jobResponses[dataId].status === ALGO_STATUS.complete ||
        jobResponses[dataId].status === ALGO_STATUS.failed ||
        jobResponses[dataId].status === ALGO_STATUS.canceled ||
        jobResponses[dataId].status === ALGO_STATUS.timedOut)
    ) {
      continue
    }

    const getJobData = await pollJobData(job.jobid)

    if (!updatedResponses[dataId]) {
      updatedResponses[dataId] = {}
    }

    updatedResponses[dataId] = Object.assign({}, updatedResponses[dataId], {
      status: getJobData.status,
      job: getJobData.id === dataId ? getJobData : {},
    })

    if (pollType === "test") {
      updatedResponses[dataId].jobid = job.jobid
    }

    const estimateOutputArray = await changeEstimateDataFormat(
      estimateData,
      getJobData.status
    )

    updatedResponses[dataId].estimate = estimateOutputArray

    if (getJobData.status !== ALGO_STATUS.complete) {
      allJobsCompleteorFailed = false
    } else {
      const filteredResult = getJobData.results?.filter((var1) =>
        inputoutputVars.some((var2) => var2?.ColumnLabel === var1.Ref.Label)
      )
      updatedResponses[dataId].estimate = filteredResult ?? getJobData.results
    }
  }
  return { updatedResponses, allJobsCompleteorFailed }
}

export default useCalculatorPolling
