import { useEffect, useRef } from "react"
import { Route, Routes } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import * as m from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { fontWeight650 } from "../../state/StatusState"
import { AgentCapability, AgentHealth } from "./AdminSummaryOptions"
import AgentSummaryMain from "./AgentSummaryMain"
import AgentHealthMain from "./AgentHealthMain"
import AgentQueueMain, { hasData } from "./AgentQueueMain"
import * as projState from "../../state/projectState"
import CapabilityMain from "./CapabilityMain"
import {
  MetaTags,
  ErrorBoundary,
  useWindowDimensions,
} from "../../GlobalFileContainer"
import useStyles from "../card_style"
import { LegendHelper } from "./LegendHelper"
import CommonMobileNav from "../navContent/CommonMobileNav"
import RefreshButtonStyling from "../shared components/RefreshStyling/RefreshButtonStyling"

const requests = [
  { type: "health", requestTo: projState.getAgentHealth },
  { type: "queue", requestTo: projState.getAgentQueue },
  { type: "currentJob", requestTo: projState.getCurrentJob },
  { type: "recentJob", requestTo: projState.getRecentJob },
  { type: "capability", requestTo: projState.getAgentCapability },
]

const titleInfo = {
  title: "Agent Dashboard",
  description:
    "Know your agents health & status before beginning the calculations",
}

export const agentDashboardNavOpt = [
  {
    title: "Summary",
    to: "/agent/summary",
    routerPath: "summary",
    renderMain: <AgentSummaryMain />,
  },
  {
    title: "Agent Health",
    to: "/agent/health",
    routerPath: "health",
    renderComponent: <AgentHealth />,
    renderMain: <AgentHealthMain />,
  },
  {
    title: "Job Queue",
    to: "/agent/job/queue",
    routerPath: "job/queue",
    renderComponent: <AgentQueueMain summary={true} />,
    renderMain: <AgentQueueMain />,
  },
  {
    title: "Agent Capability",
    to: "/agent/capability",
    routerPath: "capability",
    renderComponent: <AgentCapability />,
    renderMain: <CapabilityMain />,
  },
]

export const fetchJobsData = async (
  getUrl,
  setAgentQueueDataState,
  queuedData,
  setRefreshPolling,
  pollingState,
  progress,
  time,
  setQueuedLoader,
  emulatorId
) => {
  const locationUrl = window.location?.pathname?.includes("generate/data")

  try {
    const queueData = await getUrl()
    if (queuedData === "currentJobData") {
      setAgentQueueDataState((prevQueue) => ({
        ...prevQueue,
        [queuedData]: queueData,
        newlyAddedRows: queueData
          .filter(
            (newRow) =>
              !prevQueue.currentJobData.some(
                (row) => row.job_id === newRow.job_id
              )
          )
          .map((row) => row.job_id),
        updatedProgressCell: queueData
          .filter((newRow) => {
            const prevRow = prevQueue.currentJobData.find(
              (row) => row.job_id === newRow.job_id
            )
            return prevRow && newRow.progress !== prevRow.progress
          })
          .reduce((acc, row) => {
            acc[row.job_id] = row.progress
            return acc
          }, {}),
      }))
    } else {
      setAgentQueueDataState((prevQueue) => ({
        ...prevQueue,
        [queuedData]: queueData,
      }))
    }

    setRefreshPolling((prev) => ({
      ...prev,
      [pollingState]: locationUrl
        ? queueData?.filter((queData) => queData?.emulator_id === emulatorId)
            ?.length > 0
        : queueData?.length > 0,
      progress:
        locationUrl &&
        queueData?.filter((queData) => queData?.emulator_id === emulatorId)
          ?.length === 0
          ? 0
          : progress,
      time: locationUrl ? 10 : time,
    }))
    setQueuedLoader(false)
  } catch (error) {
    setRefreshPolling((prev) => ({
      ...prev,
      [pollingState]: false,
    }))
    console.error("Error while getting agent queue data: " + error)
  }
  setQueuedLoader(false)
}

const AdminDashboardMain = () => {
  const theme = m.useTheme()
  const classes = useStyles()
  const buttonRef = useRef(null)
  const { width } = useWindowDimensions()

  const setAgentHealthDataState = useSetRecoilState(projState.agentHealthData)
  const setAgentHealthFilter = useSetRecoilState(projState.agentDataFilter)
  const [agentQueueDataState, setAgentQueueDataState] = useRecoilState(
    projState.agentQueueData
  )
  const setAgentCapabilityDataState = useSetRecoilState(
    projState.agentCapabilityData
  )
  const setQueuedLoader = useSetRecoilState(projState.agentQuedLoader)
  const setSearchedAgent = useSetRecoilState(projState.agentSearchedData)
  const [menuItem, setMenuItem] = useRecoilState(projState.menuItemState)
  const [refreshPolling, setRefreshPolling] = useRecoilState(
    projState.refreshButtonPollingStates
  )
  const queuedLoader = useRecoilValue(projState.agentQuedLoader)
  const setGlobalDialogOpen = useSetRecoilState(projState.globalDialogBox)

  const hasQueuedData = hasData(agentQueueDataState.queuedData)
  const hasCurrentData = hasData(agentQueueDataState.currentJobData)

  useEffect(() => {
    setMenuItem(
      agentDashboardNavOpt?.find((navOpt) => navOpt.to === location.pathname)
        ?.title ?? "Summary"
    )
  }, [])

  useEffect(() => {
    requests.forEach((reqData) => {
      fetchDataAndUpdateState(reqData.type, reqData.requestTo)
    })
  }, [])

  const fetchDataAndUpdateState = (type, request) => {
    try {
      request()
        .then((data) => {
          if (type === requests[0].type) {
            const updatedData = data?.table.sort((a, b) => {
              // Handle negative values
              const secondsA =
                typeof a["seconds since last reported"] === "number"
                  ? a["seconds since last reported"]
                  : Number.MAX_SAFE_INTEGER
              const secondsB =
                typeof b["seconds since last reported"] === "number"
                  ? b["seconds since last reported"]
                  : Number.MAX_SAFE_INTEGER

              if (isNaN(secondsA) || isNaN(secondsB)) {
                return isNaN(secondsA) ? (isNaN(secondsB) ? 0 : 1) : -1
              }
              return secondsA - secondsB
            })
            const updatedHealthData = {
              ...data,
              table: updatedData,
            }
            setAgentHealthDataState(updatedHealthData)
            setSearchedAgent(updatedData)
          }
          if (type === requests[1].type) {
            setAgentQueueDataState((prev) => ({
              ...prev,
              queuedData: data,
            }))

            setQueuedLoader(false)
          }
          if (type === requests[2].type) {
            setAgentQueueDataState((prev) => ({
              ...prev,
              currentJobData: data,
            }))

            setQueuedLoader(false)
          }
          if (type === requests[3].type) {
            setAgentQueueDataState((prev) => ({
              ...prev,
              recentData: data,
            }))

            setQueuedLoader(false)
          }
          if (type === requests[4].type) {
            setAgentCapabilityDataState(data)
            setAgentHealthFilter((prev) => ({
              ...prev,
              capabilityData: ["busy", "idle"],
            }))
          }
        })
        .catch((error) => {
          console.error(`Error while fetching ${type}:` + error)
          return null
        })
    } catch (error) {
      console.error("Error while getting data: " + error)
      setQueuedLoader(false)
    }
  }

  const currentQueueRefresh = (e) => {
    e.stopPropagation()
    e.preventDefault()
    fetchJobsData(
      projState.getAgentQueue,
      setAgentQueueDataState,
      "queuedData",
      setRefreshPolling,
      "pollingState",
      0,
      10,
      setQueuedLoader
    )
    fetchJobsData(
      projState.getCurrentJob,
      setAgentQueueDataState,
      "currentJobData",
      setRefreshPolling,
      "currentPollingState",
      refreshPolling.progress,
      refreshPolling.time,
      setQueuedLoader
    )
    fetchJobsData(
      projState.getRecentJob,
      setAgentQueueDataState,
      "recentData",
      setRefreshPolling,
      "recentPollingState",
      refreshPolling.progress,
      refreshPolling.time,
      setQueuedLoader
    )
  }

  const getButtonStyle = (refreshButton) => {
    if (refreshButton) {
      return {
        position: "absolute",
        mr: 0,
        boxShadow: "0px 0px 1px 1px #0000001a",
      }
    } else {
      return {
        position: "relative",
        mr: 1,
      }
    }
  }
  const btnStyle = getButtonStyle(
    true && (refreshPolling.currentPollingState || refreshPolling.pollingState)
  )

  return (
    <>
      <MetaTags />
      <m.Paper
        elevation={0}
        className="App-paper"
        style={{ backgroundColor: theme.palette.grey.light }}
      >
        <div className="ml-display-flex ml-flex-dir-col">
          <div
            className="explore-topnavbar"
            style={{ backgroundColor: theme.palette.grey.light, width: width }}
          >
            <div className="explore-title text-center">
              <div>
                <m.Typography variant="h4" fontWeight={fontWeight650}>
                  {titleInfo.title}
                </m.Typography>
                <m.Typography variant="body1">
                  {titleInfo.description}
                </m.Typography>
              </div>
            </div>
            <br />
            <div className="top-area">
              <CommonMobileNav
                navOptions={agentDashboardNavOpt}
                menuItem={menuItem}
                setMenuItem={setMenuItem}
              />
              {location.pathname === "/agent/job/queue" && !queuedLoader && (
                <m.Box className="ml-display-flex ml-justify-end ml-align-center">
                  {refreshPolling.pollingState ||
                  refreshPolling.currentPollingState ? (
                    <RefreshButtonStyling
                      progress={refreshPolling.progress}
                      strokeDasharray={
                        buttonRef?.current?.getBoundingClientRect()?.width +
                          220 || 335
                      }
                      height={
                        buttonRef?.current?.getBoundingClientRect()?.height +
                          16 || 40
                      }
                      width={
                        buttonRef?.current?.getBoundingClientRect()?.width +
                          16 || 130
                      }
                      isPolling={hasQueuedData || hasCurrentData}
                    >
                      <m.Button
                        variant="contained"
                        color="primary"
                        margin="0"
                        ref={buttonRef}
                        onClick={(e) => currentQueueRefresh(e)}
                        className={
                          true &&
                          (hasQueuedData || hasCurrentData) &&
                          (refreshPolling.time < 2 ||
                            refreshPolling.time === 30)
                            ? "pulse"
                            : ""
                        }
                        sx={btnStyle}
                      >
                        <m.Typography
                          variant="caption"
                          margin="4px"
                          color={theme.palette.common.white}
                        >
                          Refresh&nbsp;Page
                        </m.Typography>
                      </m.Button>
                    </RefreshButtonStyling>
                  ) : (
                    <m.Button
                      variant="contained"
                      color="primary"
                      margin="0"
                      onClick={(e) => currentQueueRefresh(e)}
                      sx={btnStyle}
                    >
                      <m.Typography
                        variant="caption"
                        margin="4px"
                        color={theme.palette.common.white}
                      >
                        Refresh&nbsp;Page
                      </m.Typography>
                    </m.Button>
                  )}
                </m.Box>
              )}
              <m.Box
                sx={{
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                <m.IconButton
                  title="Icon Help?"
                  onClick={() =>
                    setGlobalDialogOpen((prev) => ({
                      ...prev,
                      isOpen: true,
                      content: LegendHelper(classes, theme),
                      title: "Legends",
                      maxWidth: "md",
                    }))
                  }
                >
                  <HelpOutlineIcon fontSize="large" color="primary" />
                </m.IconButton>
              </m.Box>
            </div>
          </div>
          <div className="explore-allcards">
            <Routes>
              {agentDashboardNavOpt.map((agent, id) => {
                return (
                  <Route
                    key={`${agent.title}-${id}`}
                    path={agent.routerPath}
                    exact
                    element={<ErrorBoundary>{agent.renderMain}</ErrorBoundary>}
                  />
                )
              })}
            </Routes>
          </div>
        </div>
      </m.Paper>
    </>
  )
}

export default AdminDashboardMain
