import { useEffect, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { calculatorResult, selectedVersion } from "../state/projectState"
import { mongoProfileState } from "../state/userState"
import Pusher from "pusher-js"
import { emulatorMode, emulatorSoftwareType } from "../state/StatusState"
import { alertStates } from "../state/vizState"

const useCalculatorSocketConnection = (
  launch,
  setPostBody1,
  isSocketTestingLaunch,
  setLoader,
  selectedID,
  selectedCalcObject,
  socketPostBody,
  setSocketMembers,
  setOutputLog
) => {
  const setResult = useSetRecoilState(calculatorResult)
  const selectedVersions = useRecoilValue(selectedVersion)
  const mongoProfile = useRecoilValue(mongoProfileState)
  const setAlertState = useSetRecoilState(alertStates)

  const JWTToken = localStorage.getItem("JWTToken")
  const isCalculateMode = launch
    ? selectedCalcObject?.mode === emulatorMode.calculate
    : selectedCalcObject?.modes.includes(emulatorMode.calculate)

  const [channelInstance, setChannelInstance] = useState("")
  const [completedJobResult, setCompletedJobResult] = useState([])

  const updateSocketMembers = (channel) => {
    setSocketMembers(
      Object.entries(channel.members.members).map(([key, value]) => {
        return {
          id: value.type === "agent" ? value.device : key,
          name: value.name,
          type: value.type,
        }
      })
    )
  }

  const updateOutputLogs = (text) => {
    setOutputLog((prev) => [...prev, text])
  }

  useEffect(() => {
    if (Object.keys(socketPostBody.payload).length > 0) {
      if (channelInstance !== "") {
        setLoader(true)
        channelInstance.trigger("client-calc-request", socketPostBody.payload)
        updateOutputLogs(
          `You announced a new job with jobId-(${socketPostBody.payload.job_id})!`
        )
      } else {
        console.log("Lost channel instance!")
      }
    }
  }, [socketPostBody])

  useEffect(() => {
    if (completedJobResult.length > 0) {
      const resultBody = {
        emulatorId: selectedID,
        jobId: socketPostBody.payload.job_id,
        inputValues: socketPostBody.inputs.FeaturesData,
        outputValues: completedJobResult,
        resultMode: "calculate",
        resultVersion: launch ? selectedCalcObject?.version : selectedVersions,
        resultStatus: "complete",
      }
      setResult((prev) => [resultBody, ...prev])

      //save result in local storage
      const storedData = localStorage.getItem("socketJobs")
      const parsedData = JSON.parse(storedData) || []
      try {
        if (Array.isArray(parsedData)) {
          if (parsedData.length >= 15) {
            parsedData.pop()
          }
          parsedData.push(resultBody)
          localStorage.setItem("socketJobs", JSON.stringify(parsedData))
        }
      } catch (error) {
        console.error("Error parsing or updating existing data:", error)
      }

      setPostBody1({})
      setLoader(false)
    }
  }, [completedJobResult])

  useEffect(() => {
    if (isSocketTestingLaunch) {
      //log pusher results
      Pusher.logToConsole = true

      // Initialize Pusher
      const pusher = new Pusher("9f7aabf0718d8389f400", {
        cluster: "us2",
        channelAuthorization: {
          endpoint:
            "https://emulation-lab-services-staging.azurewebsites.net/sockets/pusher/auth",
          transport: "ajax",
          headers: {
            Authorization: `Bearer ${JWTToken}`, // Replace with your token
          },
        },
      })

      // Subscribe to the emulator dependency, agent channel
      const channel = pusher.subscribe(
        `presence-agents-${isSocketTestingLaunch ?? "excel-none"}`
      )

      //storing channel instance
      setChannelInstance(channel)

      //pusher inbuilt events
      channel.bind("pusher:subscription_succeeded", (member) => {
        updateSocketMembers(channel)
        updateOutputLogs(`You joined the channel- ${channel.name}`)
        console.log("Subscribed!!", member)
      })

      channel.bind("pusher:member_added", (member) => {
        console.log("New member added:", member)
        updateSocketMembers(channel)
        updateOutputLogs(`${member.info?.name} joined the channel!`)
      })

      channel.bind("pusher:member_removed", (member) => {
        console.log("Member removed:", member)
        updateSocketMembers(channel)
        updateOutputLogs(`${member.info?.name} left the channel!`)
      })

      //client events
      channel.bind("client-calc-request", function (data) {
        console.log("client-calc-request", data)
        updateOutputLogs(
          `A channel member announced a new job with jobId-${data.job_id}.`
        )
      })

      // code will be used later

      // channel.bind("client-job_announcement", function (data) {
      //   currentResultLog = {
      //     id: data.job_id,
      //     eventsDone: ["client-job_announcement"],
      //     logs: [
      //       `Job announced by userId: ${data.user_id} on the channel with jobId: ${data.job_id}`,
      //     ],
      //   }
      //   console.log("currentresultLog", currentResultLog)
      //   setOutputLog((prev) => [...prev, currentResultLog])
      //   setSocketJobId(data.job_id)
      //   console.log("client-job_announcement", data)
      // })

      // console.log("current", currentResultLog)
      // channel.bind("client-job_receipt", function (data) {
      //   const logAlreadyPresent =
      //     currentResultLog.eventsDone?.includes("client-job_receipt")

      //   if (!logAlreadyPresent) {
      //     currentResultLog = {
      //       ...currentResultLog,
      //       eventsDone: [...currentResultLog.eventsDone, "client-job_receipt"],
      //       logs: [
      //         ...currentResultLog.logs,
      //         "Job is assigned to one of our agents!",
      //       ],
      //     }
      //     setOutputLog((prev) =>
      //       prev.map((item) =>
      //         item.id === data.job_id ? currentResultLog : item
      //       )
      //     )
      //     console.log("client-job_receipt", data)
      //   }
      // })

      channel.bind("client-job_progress", function (data) {
        updateOutputLogs(
          `Job progress: ${data.payload?.progress}%, status: ${data.payload?.status}`
        )
        console.log("client-job_progress", data)
      })

      channel.bind("client-job_failure", function (data) {
        updateOutputLogs(
          `Job ${data.job_id} failed! - ${data.payload?.exception_message}`
        )
        setCompletedJobResult([
          { name: "Result", value: data.payload?.exception_message },
        ])
        setLoader(false)
        console.log("client-job_failed", data)
      })

      channel.bind("client-job_complete", function (data) {
        const results = data.payload?.outputs
        const resultBody = results?.map((result) => {
          return {
            name:
              isSocketTestingLaunch?.includes(emulatorSoftwareType.excel)
                ? result.ColumnLabel
                : result.Ref,
            value: result.Value[0],
          }
        })
        updateOutputLogs(`Job ${data.job_id} is complete`)
        if (data.user_id === mongoProfile.id) {
          setCompletedJobResult(resultBody)
        }
        console.log("client-job_complete", data)
      })

      // Cleanup function to unsubscribe
      return () => {
        channel.unbind_all()
        channel.unsubscribe()
        console.log("Unsubscribed-", channel.name)
        updateOutputLogs(`You unsubscribed channel- ${channel.name}`)
        setChannelInstance("")
      }
    } else if (selectedCalcObject && isCalculateMode) {
      //alert user if socket channel not subscribed
      launch
        ? setAlertState({
            boolState: true,
            message:
              "Unable to create socket connection, check dependency_set!",
            severityState: "error",
          })
        : updateOutputLogs(
            "dependency_set is either null or not mentioned in config for this emulator. Hence, unable to make connection to socket."
          )
    } else null
  }, [selectedCalcObject])
}

export default useCalculatorSocketConnection
