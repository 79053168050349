import * as m from "@mui/material"

export const renderFormLabel = (value, classes, minValue) => {
  //commmon category(name, expected run modes, units...) Labels
  //for forms like createEmulator editVariables
  return (
    <m.Typography
      variant="body1"
      fontWeight={"650"}
      className={`${classes.colorBlack} boldLabel ml-display-flex ml-flex-dir-row`}
    >
      {value}&nbsp;
      {minValue && (
        <m.Typography
          variant="body2"
          fontWeight={200}
          className={`${classes.colorSecondary} ml-display-flex ml-flex-dir-row`}
        >
          ({value === "Start" ? "Min" : "Max"}. Value: {minValue})
        </m.Typography>
      )}
      <m.Typography variant="h5" className={classes.colorPrimaryMain}>
        {value ? "*" : ""}
      </m.Typography>
    </m.Typography>
  )
}

export const showTimer = (time) => (
  //refresh timer related informative text
  <m.Typography variant="body1" className="mismatchlabel">
    Page will refresh in: {`0:${time}`}
  </m.Typography>
)

export const createIcon = (IconComponent, color) => (
  //generate custom icon with custom color
  <IconComponent
    height="24px"
    width="24px"
    stroke={color ?? "rgba(10,10,11,0.5)"}
  />
)

export const commonResultNumbering = (index) => {
  return (
    <m.Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        border: "1px solid black",
      }}
    >
      <m.Typography style={{ fontSize: "12px" }}>{index}</m.Typography>
    </m.Box>
  )
}

export const ClampedTypography = m.styled(m.Typography)(() => ({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 2,
}))

export const JobLogsCalculate = ({ outputLog, siteWideRoleState }) => {
  return (
    outputLog.length > 0 &&
    siteWideRoleState !== "consumer" && (
      <m.Grid container direction="row">
        <m.Card
          sx={{
            padding: "16px",
            marginTop: "10px",
            maxHeight: "40vh",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <m.Typography
            fontWeight={500}
            style={{ fontSize: "24px" }}
            color={"primary"}
            gutterBottom
          >
            Job Logs
          </m.Typography>
          <m.Divider />
          <m.Box
            sx={{
              padding: "8px 0 0 8px",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            {outputLog.map((output, index) => (
              <m.Box key={index}>
                <m.Typography key={index} variant="body2">
                  {`--->  ${output}`}
                </m.Typography>
              </m.Box>
            ))}
          </m.Box>
        </m.Card>
      </m.Grid>
    )
  )
}
