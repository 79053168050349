import { useEffect, useRef, useState } from "react"
import * as m from "@mui/material"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined"
import {
  chipPositionState,
  commonDateFormat,
  emulatorLauncheGroupDataState,
  fetchAnnotationsState,
  formatTimeBasedOnLocal,
  getEmulatorData,
  getEmulatorunch,
  imageIndex,
  imagesState,
  openBotState,
  siteWideRole,
} from "../../state/projectState"
import * as files from "../../GlobalFileContainer"
import { ReactComponent as ZoomIcon } from "../../assets/newIcons/zoomicon.svg"

const DescriptionAndLastUpdatedInfo = ({
  selectedCalcObject,
  emulatorLaunch,
  isOptimiseOrCalcPage,
  isLaunchPage,
}) => {
  function formatTimestamp(seconds) {
    const date = new Date(seconds * 1000)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const secondsPart = String(date.getSeconds()).padStart(2, "0")
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0")

    return `${year}-${month}-${day} ${hours}:${minutes}:${secondsPart}.${milliseconds}`
  }

  return (
    <m.Box
      className={`ml-display-flex ml-space-between ${
        emulatorLaunch ? "ml-flex-dir-col" : "ml-flex-dir-row"
      }`}
      sx={{
        gap: emulatorLaunch ? 1 : 2,
      }}
    >
      <m.Typography
        textAlign={"justify"}
        className="ml-display-flex"
        fontWeight={400}
        style={{ fontSize: "14px" }}
      >
        {isOptimiseOrCalcPage
          ? selectedCalcObject?.description
          : emulatorLaunch
          ? emulatorLaunch[0]?.package?.documentation?.description
          : ""}
      </m.Typography>
      {selectedCalcObject && (
        <m.Box
          className="ml-display-flex ml-flex-dir-col ml-justify-end"
          sx={{
            gap: 0.5,
          }}
        >
          <m.Box
            sx={{
              px: emulatorLaunch ? 0 : 1,
              display: "flex",
              alignItems: emulatorLaunch ? "left" : "center",
              flexDirection: emulatorLaunch ? "column" : "row",
              justifyContent: emulatorLaunch ? "left" : "space-between",
              gap: emulatorLaunch ? 1 : 0.5,
            }}
          >
            <m.Typography
              textAlign={"justify"}
              className="ml-display-flex ml-align-center"
              fontWeight={400}
              style={{ fontSize: "14px" }}
              noWrap
            >
              Last Updated:
              {formatTimeBasedOnLocal(
                formatTimestamp(selectedCalcObject?.last_updated),
                {
                  commonDateFormat,
                }
              )}
            </m.Typography>
            <m.Typography
              noWrap
              textAlign={"justify"}
              className="ml-display-flex ml-align-center"
              fontWeight={400}
              style={{ fontSize: "14px" }}
            >
              Version Available:
              {selectedCalcObject?.version}
            </m.Typography>
          </m.Box>
          {!emulatorLaunch && (
            <m.Box className="ml-display-flex ml-flex-dir-row ml-gap-1">
              <MemberAndDMTTag
                selectedCalcObject={selectedCalcObject}
                isLaunchPage={isLaunchPage}
                width="100%"
              />
            </m.Box>
          )}
        </m.Box>
      )}
    </m.Box>
  )
}

const MemberAndDMTTag = ({ selectedCalcObject, isLaunchPage, width }) => {
  const { validations } = selectedCalcObject || {}
  const chipPadding = {
    padding: "5px 8px",
    width: width ?? "auto",
  }

  return (
    <>
      <files.MemberCollaboratorRole
        selectedCalcObject={selectedCalcObject}
        style={chipPadding}
      />
      {!isLaunchPage &&
        selectedCalcObject &&
        (validations?.[0]?.length > 0 ||
          selectedCalcObject?.step !== "launched") && (
          <files.CommonDMTBadge
            validations={
              validations?.length > 0 ? validations : selectedCalcObject?.step
            }
            style={chipPadding}
            size={"16px"}
            fontSize={"12px"}
          />
        )}
    </>
  )
}

const ConsumerFlowLaunchSidebar = ({ selectedCardData }) => {
  const theme = m.useTheme()
  const { emulatorId } = useParams()
  const navigate = useNavigate()
  const contentRef = useRef(null)

  const pathname = window.location.pathname
  const isLaunchPage = pathname?.includes("launches")
  const isTryitPage = pathname?.includes("tryit")
  const isOptimisePage = pathname?.includes("optimization")
  const showCalcFile =
    !pathname?.includes("teams") && selectedCardData?.package?.source

  const [searchParams, setSearchParams] = useSearchParams()

  const selectedIndex = searchParams.get("index")
  const selectedID = searchParams.get("launchid")

  const emulatorConfig = useRecoilValue(getEmulatorData)
  const setChipPosition = useSetRecoilState(chipPositionState)
  const setIsFetchingAnnotations = useSetRecoilState(fetchAnnotationsState)
  const setIndex = useSetRecoilState(imageIndex)
  const groupedData = useRecoilValue(emulatorLauncheGroupDataState)
  const emulatorLaunch = useRecoilValue(getEmulatorunch)
  const images = useRecoilValue(imagesState)
  const showSideBar = useRecoilValue(openBotState)
  const siteWideRoleState = useRecoilValue(siteWideRole)

  const isRoleConsumer = siteWideRoleState === "consumer"

  const selectedDataForTryit = groupedData[selectedIndex]
  const selectedCalcObject = selectedDataForTryit?.find(
    (obj) => obj.id === selectedID
  )

  const [openImageDialog, setOpenImageDialog] = useState(false)

  useEffect(() => {
    setAssociatedStates()
  }, [])

  const setAssociatedStates = () => {
    setChipPosition([])
    setIsFetchingAnnotations(true)
    setIndex(0)
    setOpenImageDialog(false)
  }

  const renderCommonButton = (zoom) => (
    <m.Button
      variant="contained"
      style={{
        width: "32px",
        height: "32px",
        borderRadius: "8px",
        padding: "6.4px 12.8px",
        gap: "6.4px",
      }}
      onClick={() => setOpenImageDialog(true)}
    >
      {zoom ? (
        <ZoomIcon width="12.8px" height="12.8px" />
      ) : (
        <NoteAltOutlinedIcon
          style={{
            width: "12.8px",
            height: "12.8px",
            color: theme.palette.common.white,
          }}
        />
      )}
    </m.Button>
  )

  const hideBorderForBox = {
    width: "100%",
    height: "350px",
    gap: "4px",
  }

  const showDefaultImage = () => (
    <img
      width="100%"
      height="100%"
      style={{
        boxSizing: "border-box",
        borderRadius: "6px",
        objectFit: "scale-down",
      }}
      src={emulatorConfig?.image}
      alt={emulatorConfig?.name}
      onError={({ target }) => {
        target.src = "/assets/images/default_em_image.jpg"
      }}
    />
  )

  const navigateToLaunch = () => {
    setSearchParams({})
    navigate(`/new/${emulatorId}/launches`)
  }

  const navigateToSummary = () => {
    navigate(`/em/${emulatorId}/summary`)
  }

  const handleRouteToMain = () => {
    navigate("/")
  }

  const handleBackArrow = isLaunchPage
    ? isRoleConsumer
      ? handleRouteToMain
      : navigateToSummary
    : navigateToLaunch

  return showSideBar ? (
    <m.Paper
      className="ml-display-flex ml-align-center ml-gap-1 member-manage-title"
      sx={{
        marginLeft: "8px",
      }}
    >
      <m.IconButton
        title={`Go Back ${isLaunchPage ? "To Discovery" : null}`}
        onClick={handleBackArrow}
      >
        <ArrowBackIcon fontSize="small" />
      </m.IconButton>
      <m.Box className="ml-display-flex ml-flex-dir-col ml-gap-1 width-100">
        <files.ListViewCardConsumer
          imageHeight={"100%"}
          emulator={emulatorConfig}
          contentRef={contentRef}
        />
        <DescriptionAndLastUpdatedInfo
          selectedCalcObject={selectedCalcObject}
          isOptimiseOrCalcPage={isOptimisePage || isTryitPage}
          isLaunchPage={isLaunchPage}
        />
        <m.Box className={"ml-display-flex ml-flex-dir-row, ml-gap-1"}>
          {isRoleConsumer && (
            <files.ContributorModeHandler showSideBar={showSideBar} />
          )}
          <files.MemberAndCalcFileButton
            selectedCardData={selectedCardData}
            isLaunchPage={isLaunchPage}
            showCalcFile={showCalcFile}
          />
        </m.Box>
      </m.Box>
    </m.Paper>
  ) : (
    <m.Grid item xs={12} md={3} direction="column">
      <m.Grid
        className="ml-display-flex ml-align-center cursor-point"
        sx={{
          padding: "16px 32px",
          gap: "4px",
          boxSizing: "border-box",
          border: `1px solid ${theme.palette.grey.primaryLight}`,
          backgroundColor: theme.palette.common.white,
        }}
        onClick={handleBackArrow}
      >
        <ArrowBackIcon fontSize="small" />
        <m.Typography fontWeight={400} style={{ fontSize: "16px" }}>
          Go Back{" "}
          {isLaunchPage
            ? isRoleConsumer
              ? "To Discovery"
              : "To Summary Page"
            : "Launch Page"}
        </m.Typography>
      </m.Grid>
      {isRoleConsumer && <files.ContributorModeHandler />}
      <m.Grid
        className="ml-display-flex"
        alignItems="baseline"
        sx={{
          gap: "8px",
          boxSizing: "border-box",
          border: `1px solid ${theme.palette.grey.primaryLight}`,
          backgroundColor: theme.palette.common.white,
        }}
      >
        <m.Grid
          container
          sx={{
            padding: "16px 32px 16px 24px",
            gap: "16px",
            marginTop: "0px",
          }}
        >
          <m.Grid
            container
            direction="column"
            sx={{
              gap: "4px",
            }}
          >
            <files.EmulatorTitleAndFavourite
              emulator={emulatorLaunch}
              styleProp={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: "5px",
              }}
              sidebar={true}
            />
            <m.Grid item>
              <m.Box display={"flex"} gap={0.5}>
                <MemberAndDMTTag
                  selectedCalcObject={selectedCalcObject}
                  isLaunchPage={isLaunchPage}
                />
              </m.Box>
            </m.Grid>
          </m.Grid>
          <m.Grid
            className="ml-display-flex ml-justify-center ml-align-center"
            style={{
              ...hideBorderForBox,
              ...(isLaunchPage || images?.length === 0
                ? {}
                : {
                    borderRadius: "6px",
                    border: "1px solid rgba(228,228,231,1)",
                    position: "relative",
                  }),
            }}
          >
            {isLaunchPage || images?.length === 0 ? (
              showDefaultImage()
            ) : (
              <files.ErrorBoundary>
                <files.KonvaImageStage
                  launch={0.95}
                  mainLoader={false}
                  displayIn="calc"
                  handleClick={() => setOpenImageDialog(true)}
                />
              </files.ErrorBoundary>
            )}

            {!isLaunchPage && images?.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  bottom: "8px",
                  right: "8px",
                  display: "flex",
                  gap: "6px",
                }}
              >
                {renderCommonButton(true)}
                {renderCommonButton()}
              </div>
            )}
          </m.Grid>
          <files.MemberAndCalcFileButton
            isLaunchPage={isLaunchPage}
            showCalcFile={showCalcFile}
            selectedCardData={selectedCardData}
          />
          <DescriptionAndLastUpdatedInfo
            selectedCalcObject={selectedCalcObject}
            emulatorLaunch={emulatorLaunch}
            isOptimiseOrCalcPage={isOptimisePage || isTryitPage}
            isLaunchPage={isLaunchPage}
          />
          <files.GalleryDialogBox
            setOpenImageDialog={setOpenImageDialog}
            disable={true}
            openImageDialog={openImageDialog}
            setAssociatedStates={setAssociatedStates}
          />
        </m.Grid>
      </m.Grid>
    </m.Grid>
  )
}

export default ConsumerFlowLaunchSidebar
