import { Box, LinearProgress, Typography, useTheme } from "@mui/material"

const CapsuleProgressBar = ({ value }) => {
  const theme = useTheme()
  return (
    value && (
      <Box
        margin="0px 8px"
        position="relative"
        display="flex"
        minWidth="100px"
        height="30px"
      >
        <Box
          sx={{
            width: "100%",
            bgcolor: "#e0e0e0",
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <LinearProgress
            variant="determinate"
            value={value}
            sx={{
              height: 30,
              bgcolor: "transparent",
              "& .MuiLinearProgress-bar": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          />
        </Box>
        <Box
          className="ml-display-flex ml-align-center ml-justify-center"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
        >
          <Typography
            variant="caption"
            component="div"
            color={theme.palette.common.white}
            fontWeight="bold"
          >
            {`${value}%`}
          </Typography>
        </Box>
      </Box>
    )
  )
}

export default CapsuleProgressBar
