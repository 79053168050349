import OptFrameIcon from "./Frame 2608986.svg"
import SettingIcon from "./settings.svg"
import LookupIcon from "./search.svg"
import HeartIcon from "./Heart.svg"
import ShareIcon from "./Share.svg"
import calculate from "./calculator.svg"
import emulate from "./Virus-Lab-Research-Test-Tube--Streamline-Ultimate.svg"
import layoutGrid from "./layout-grid.svg"
import layoutList from "./layout-list.svg"
import wpmLogo from "./wpm_logo.png"

const newIcons = {
  OptFrameIcon: OptFrameIcon,
  SettingIcon: SettingIcon,
  LookupIcon: LookupIcon,
  HeartIcon: HeartIcon,
  ShareIcon: ShareIcon,
  calculate: calculate,
  emulate: emulate,
  layoutGrid: layoutGrid,
  layoutList: layoutList,
  wpmLogo: wpmLogo
}

export default newIcons
