import * as m from "@mui/material"
import _ from "lodash"
import FavouriteAndShareIcon from "./FavouriteAndShareIcon"
import { ClampedTypography } from "./shared components/CommonStyles/CommonStyles"
import { convertToUppercase } from "../state/services"
import { useRecoilValue } from "recoil"
import { getEmulatorData } from "../state/projectState"

const EmulatorTitleAndFavourite = ({
  emulator,
  styleProp,
  iconPadding,
  sidebar,
  consumer,
}) => {
  const emulatorConfig = useRecoilValue(getEmulatorData)

  const string = []
  const searchParamsString = window.location.search
  const queryParams = new URLSearchParams(searchParamsString)
  const tryitMode = queryParams.get("mode")
  const sqlTableRef = queryParams.get("index")
  const findModeLaunchObj =
    sqlTableRef && Array.isArray(emulator)
      ? emulator.find((emu) => emu.sql_table_ref === sqlTableRef)
      : undefined

  const extractEmulatorName = Array.isArray(emulator)
    ? emulator[0]?.package?.documentation?.emulator_name
    : undefined
  const title = sidebar
    ? emulator && extractEmulatorName
      ? extractEmulatorName
      : emulatorConfig?.name
    : emulator?.name

  string.push(title)
  const launchVersion = findModeLaunchObj?.version
  const substring = searchParamsString.length
    ? `[${convertToUppercase(tryitMode)} (Version-${
        launchVersion === 0 ? String(launchVersion) : launchVersion
      })]`
    : ""
  string.push(substring)

  return (
    <m.Grid container alignItems={"baseline"} justifyContent={"space-between"}>
      <m.Grid item xs={9}>
        <ClampedTypography
          variant={"cardHeading"}
          fontFamily={"Roboto"}
          fontWeight={sidebar ? 500 : 700}
        >
          {string[0]}
        </ClampedTypography>
        <ClampedTypography
          variant={"body1"}
          className="ed-small"
          fontFamily={"Roboto"}
        >
          {string[1]}
        </ClampedTypography>
      </m.Grid>
      <m.Grid item xs={3}>
        <FavouriteAndShareIcon
          emulator={emulator}
          styleProp={styleProp}
          iconPadding={iconPadding}
          consumer={consumer}
          sidebar={sidebar}
        />
      </m.Grid>
    </m.Grid>
  )
}

export default EmulatorTitleAndFavourite
