import { useEffect } from "react"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { allEmulatorStatus, emulatorMode } from "../state/StatusState"
import { EmulationLab } from "../GlobalFileContainer"
import { useSetRecoilState } from "recoil"
import { calculatorResult } from "../state/projectState"

export const useTryitModeEffect = (
  postBody,
  tryitMode,
  setOutput,
  setPostBody1,
  setLoader,
  launch,
  selectedID,
  isSocketTestingLaunch
) => {
  const setResult = useSetRecoilState(calculatorResult)
  const params = launch
    ? { client: "TryIt" }
    : { client: "TryIt", version: "beta" }

  async function fetchData(postBody, callbackFunction) {
    try {
      setLoader(true)

      const finalEndPoint = allEmulatorStatus.predict
      const api_url = `/engine/${finalEndPoint}`

      const response = await EmulationLab.post(api_url, postBody, {
        headers: {
          severity: SeverityLevel.Error,
        },
        params,
      })

      const data = response.data
      callbackFunction(data)
      setLoader(false)
    } catch (err) {
      console.error("Error fetching data:", err)

      callbackFunction({ error: `Unable to fetch ${err}` })
      setLoader(false)
      setPostBody1({})
    }
  }

  useEffect(() => {
    if (Object.keys(postBody).length !== 0) {
      if (tryitMode === emulatorMode.emulate) {
        fetchData(postBody, setOutput)
      } else {
        setPostBody1({})
      }
    }
  }, [postBody])

  useEffect(() => {
    if (!isSocketTestingLaunch) {
      return
    }
    const storedData = localStorage.getItem("socketJobs")
    const parsedData = JSON.parse(storedData)

    if (parsedData?.length > 0) {
      const modifiedData = parsedData.filter(
        (item) => item.emulatorId === selectedID
      )
      setResult(modifiedData.reverse())
    }
  }, [tryitMode, selectedID])
}
