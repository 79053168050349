import { useEffect, useRef, useState } from "react"
import * as m from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import _ from "lodash"
import { appLayout, emulatorStatus, siteWideRole } from "../state/projectState"
import * as files from "../GlobalFileContainer"
import {
  getEmulatorModes,
  getModeIcon,
  modeValidationCombo,
} from "../state/services"
import { ClampedTypography } from "./shared components/CommonStyles/CommonStyles"

const EmulatorSelectionCardConsumer = ({ emulator, searchResultKeys }) => {
  const contentRef = useRef(null)
  const navigate = useNavigate()
  const theme = m.useTheme()
  const { width } = files.useWindowDimensions()

  const setEmulatorStatusState = useSetRecoilState(emulatorStatus)
  const layout = useRecoilValue(appLayout)
  const siteWideRoleState = useRecoilValue(siteWideRole)

  const modes = getEmulatorModes(emulator?.modes)

  const [imageHeight, setImageHeight] = useState("100%")

  const iconPadding = {
    paddingRight: width < 680 ? "3px" : "6px",
  }

  useEffect(() => {
    const updateImageHeight = () => {
      if (contentRef.current) {
        const containerHeight = contentRef.current.offsetHeight
        const fixedHeight = layout === "grid" ? 65 : 0
        setImageHeight(`${containerHeight - fixedHeight}px`)
      }
    }

    updateImageHeight()
    window.addEventListener("resize", updateImageHeight)

    return () => {
      window.removeEventListener("resize", updateImageHeight)
    }
  }, [layout])

  const investigateRouteChange = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let path = `/em/${emulator.id}/summary`
    setEmulatorStatusState(emulator.status)
    if (siteWideRoleState === "consumer") {
      path = `/new/${emulator?.id}/launches`
    }
    navigate(path)
    return false
  }

  return (
    <>
      {_.some(searchResultKeys, (value) => _.includes(value, emulator?.id)) &&
        (layout === "grid" ? (
          <m.Card
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
            }}
            className="emu-row__card cursor-point"
            onClick={investigateRouteChange}
          >
            <m.CardHeader
              sx={{ padding: "16px 12px" }}
              title={
                <>
                  <files.EmulatorTitleAndFavourite
                    emulator={emulator}
                    consumer
                    styleProp={{ display: "flex", justifyContent: "end" }}
                  />
                  <m.Grid sx={{ pt: "16px" }} container spacing={1}>
                    <m.Grid item xs={12}>
                      <ClampedTypography variant="body2">
                        {emulator.description}
                      </ClampedTypography>
                    </m.Grid>
                  </m.Grid>
                </>
              }
            />
            <div style={{ height: "100%" }} ref={contentRef}>
              <img
                height={imageHeight}
                width="100%"
                src={emulator.image}
                alt={emulator.name}
                onError={({ target }) => {
                  target.src = "/assets/images/default_em_image.jpg"
                }}
              />
              <m.CardActions
                sx={{
                  padding: "14px 8px",
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                }}
              >
                {modes?.map((mode) => (
                  <m.Box
                    key={mode}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: modeValidationCombo(emulator, modes)?.[mode]
                        ? `1px ${theme.palette.status.success} solid`
                        : `none`,
                      borderRadius: "6px",
                      padding: "2px",
                    }}
                  >
                    {getModeIcon(
                      mode,
                      modeValidationCombo(emulator, modes)?.[mode]
                        ? theme.palette.status.success
                        : "black",
                      width
                    )}
                    {modeValidationCombo(emulator, modes)?.[mode] && (
                      <m.Typography
                        variant="caption"
                        fontWeight={500}
                        color={theme.palette.status.success}
                        sx={{ ...iconPadding }}
                      >
                        {modeValidationCombo(emulator, modes)?.[mode]}
                      </m.Typography>
                    )}
                  </m.Box>
                ))}
              </m.CardActions>
            </div>
          </m.Card>
        ) : (
          <files.ListViewCardConsumer
            width={width}
            imageHeight={imageHeight}
            emulator={emulator}
            investigateRouteChange={investigateRouteChange}
            contentRef={contentRef}
          />
        ))}
    </>
  )
}

export default EmulatorSelectionCardConsumer
