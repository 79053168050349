import { useState, useEffect } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import * as m from "@mui/material"
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid"
import Identicon from "react-identicons"
import {
  agentHealthData,
  agentDataFilter,
  agentSearchedData,
} from "../../state/projectState"
import * as files from "../../GlobalFileContainer"
import { renderFilterButton } from "../navContent/ExploreNav"
import { isAppliedFilter } from "../result-optimization/OptimizationHistory"
import { commonRowStyle, handleReadyForCalcToggle } from "./CapabilityMain"
import useStyles from "../card_style"
import { alertStates } from "../../state/vizState"
import { mongoProfileState } from "../../state/userState"
import {
  handleContextCopy,
  pickStatusWithSoftwareIcons,
  returnAgentStatusIcon,
  seperateSoftwareAndSubType,
  convertToUppercase,
} from "../../state/services"

export const boldTitleHeader = {
  ".MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold !important",
  },
}

export const stringOperators = getGridSingleSelectOperators().filter((op) => {
  return ["isAnyOf"].includes(op?.value)
})

export const renderIdenticalIcon = (
  value,
  setAlertState,
  id,
  isCapabilityTable,
  params,
  statusIcon
) => {
  const fullId = value
  const lastFiveDigits = fullId ? fullId?.substr(-4) : ""

  return (
    <div onClick={() => handleContextCopy(value, setAlertState, id)}>
      <m.Box
        title={`Click to copy ${id}`}
        className="ml-display-flex ml-align-center"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
          height: isCapabilityTable ? "40px" : "auto",
          width: isCapabilityTable ? "40px" : "auto",
        }}
      >
        {isCapabilityTable ? (
          <m.Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{ top: "-2px", right: "3px", border: "2px solid white" }}
            badgeContent={statusIcon}
          >
            <Identicon string={fullId} size={25} />
          </m.Badge>
        ) : !params.isCalcResult ? (
          <Identicon string={fullId} size={20} />
        ) : null}
        &nbsp;
        {!isCapabilityTable && (
          <span>
            {params && params.colDef.width > 230
              ? fullId
              : lastFiveDigits
              ? `...${lastFiveDigits}`
              : ""}
          </span>
        )}
      </m.Box>
    </div>
  )
}

export const uniqueFiltersOwner = (serachedAgent, category) => {
  return Array.from(new Set(serachedAgent?.map((row) => row[category]))) ?? []
}

export const multiUniqueFilter = (
  serachedAgent,
  category1,
  category2,
  owner
) => {
  if (owner) {
    return (
      Array.from(
        new Set(
          serachedAgent?.map((row) => `${row[category1]}/${row[category2]}`)
        )
      ) ?? []
    )
  } else {
    return (
      Array.from(
        new Set(
          serachedAgent?.map(
            (row) => `${row[category1]}: ${JSON.stringify(row[category2])}`
          )
        )
      ) ?? []
    )
  }
}
export const SECONDS_IN_ONEHALF_MIN = 90
export const SECONDS_IN_MINUTE = 60

const AgentHealthMain = () => {
  const theme = m.useTheme()
  const classes = useStyles()

  const mongoProfile = useRecoilValue(mongoProfileState)
  const [agentHealthDataState, setAgentHealthDataState] =
    useRecoilState(agentHealthData)
  const [slectedFilter, setSelectedFilter] = useRecoilState(agentDataFilter)
  const [serachedAgent, setSearchedAgent] = useRecoilState(agentSearchedData)
  const setAlertState = useSetRecoilState(alertStates)

  const [openFilter, setOpenFilter] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const isAdmin = mongoProfile.useModes.includes("admin")

  useEffect(() => {
    const filteredData = agentHealthDataState?.table?.filter(
      (item) =>
        (slectedFilter.status.length === 0 ||
          slectedFilter.status.includes(item.status)) &&
        (slectedFilter.capability.length === 0 ||
          slectedFilter.capability.includes(item.capability))
    )
    setSearchedAgent(filteredData)
  }, [slectedFilter, agentHealthDataState])

  const columnsForNonAdmins = [
    {
      field: "agent_id",
      headerName: "Agent ID",
      flex: 1,
      minWidth: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        renderIdenticalIcon(
          params.value,
          setAlertState,
          "Agent ID",
          false,
          params
        ),
    },
    {
      field: "deviceOwner",
      headerName: "Device/Owner",
      minWidth: 240,
      flex: 1,
      sortable: false,
      filterable: true,
      renderCell: (params) => (
        <m.Box title={params.value}>{params.value?.split("@")[0]}</m.Box>
      ),
      type: "singleSelect",
      filterOperators: stringOperators,
      valueOptions: multiUniqueFilter(
        serachedAgent,
        "device",
        "owner",
        true
      )?.map((vl) => {
        return { value: vl, label: vl?.split("@")[0] }
      }),
    },
    {
      field: "software_dependency",
      headerName: "Dependencies",
      minWidth: 130,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: true,
      type: "singleSelect",
      filterOperators: stringOperators,
      valueOptions: multiUniqueFilter(
        serachedAgent,
        "capability",
        "dependency_sets"
      ),
      renderCell: (params) => {
        const rowValue = params.row.software_dependency
        const capabilityData = String(rowValue.split(":")[0])
        const dependencySet = JSON.parse(rowValue.split(": ")[1])

        return (
          <div
            className="ml-display-flex ml-justify-center ml-align-center"
            style={{
              height: "100%",
            }}
          >
            {dependencySet?.map((dpnset, i) => {
              const [softwareType, softwareSubType, softwareStatus] =
                seperateSoftwareAndSubType(`${capabilityData}: ${dpnset}`)

              return (
                <div key={`${dpnset}-${i}`} style={commonRowStyle}>
                  {pickStatusWithSoftwareIcons(
                    softwareType,
                    softwareSubType,
                    softwareStatus,
                    "30px",
                    `${capabilityData}: ${dpnset}`
                  )}
                </div>
              )
            })}
            {capabilityData && dependencySet === null && (
              <div
                key={`${capabilityData}-${dependencySet}`}
                style={commonRowStyle}
              >
                {pickStatusWithSoftwareIcons(
                  capabilityData,
                  "null",
                  "none",
                  "30px",
                  `${capabilityData}: ${dependencySet}`
                )}
              </div>
            )}
          </div>
        )
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={commonRowStyle}>
          {returnAgentStatusIcon(params.row.status, theme, classes)}
        </div>
      ),
      sortable: false,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value.value,
      getOptionLabel: (value) => value.value,
      filterOperators: stringOperators,
      valueOptions: uniqueFiltersOwner(serachedAgent, "status")?.map((vl) => {
        return { value: vl, label: returnAgentStatusIcon(vl, theme, classes) }
      }),
    },
    {
      field: "job_count",
      headerName: "Job Count",
      minWidth: 100,
      flex: 1,
      align: "center",
      headerAlign: "center",
      filterable: false,
    },
    {
      field: "iteration_count",
      headerName: "#Runs",
      minWidth: 100,
      flex: 1,
      align: "center",
      headerAlign: "center",
      filterable: false,
    },
    {
      field: "progress",
      headerName: "Progress",
      align: "center",
      headerAlign: "center",
      minWidth: 90,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: "last_pulse",
      headerName: "Last Seen",
      minWidth: 150,
      flex: 1,
      filterable: false,
      renderCell: (params) =>
        showComputedTime(params.row.last_seen, params.value),
    },
  ]

  const mixedModeColumn = serachedAgent && {
    field: "mixed_mode",
    headerName: "Mix Mode Status",
    headerClassName: "bold-header",
    align: "center",
    width: 100,
    headerAlign: "center",
    sortable: false,
    type: "boolean",
    valueOptions: isAdmin && uniqueFiltersOwner(serachedAgent, "mixed_mode"),
    renderCell: (params) => {
      const agentId = params.row.agent_id
      const mixedMode = params.row.mixed_mode
      const dependencyRowValue = params.row.software_dependency
      const capability = String(dependencyRowValue.split(":")[0])
      const updateMixMode = `/calc/agents/${agentId}/${capability}/update_mixed_mode`

      return (
        <div style={commonRowStyle}>
          <m.Switch
            checked={mixedMode}
            onChange={(e) =>
              handleReadyForCalcToggle(
                e,
                agentId,
                updateMixMode,
                "mix",
                setAlertState,
                agentHealthDataState,
                setAgentHealthDataState,
                capability
              )
            }
          />
        </div>
      )
    },
  }

  const columns = isAdmin
    ? [...columnsForNonAdmins, mixedModeColumn]
    : columnsForNonAdmins

  const rows =
    Object.keys(agentHealthDataState).length > 0 &&
    serachedAgent?.map(
      (
        {
          agent_id,
          owner,
          device,
          capability,
          status,
          dependency_sets,
          job_count,
          iteration_count,
          progress,
          last_seen,
          "seconds since last reported": last_pulse,
          mixed_mode_on,
        },
        i
      ) => ({
        id: `${agent_id}-${i}`,
        agent_id,
        owner,
        device,
        software_dependency: `${capability}: ${JSON.stringify(
          dependency_sets
        )}`,
        status,
        job_count,
        iteration_count,
        progress,
        last_seen,
        last_pulse,
        deviceOwner: `${device}/${owner}`,
        mixed_mode: mixed_mode_on
          ? mixed_mode_on !== "UNKNOWN" && mixed_mode_on !== null
          : false,
      })
    )

  const showComputedTime = (note, time) => {
    if (time > SECONDS_IN_ONEHALF_MIN) {
      return (
        <m.Chip
          size="small"
          color="error"
          label={
            <m.Typography variant="body2" color={theme.palette.common.white}>
              {convertToUppercase(note)}
            </m.Typography>
          }
        />
      )
    } else if (time > SECONDS_IN_MINUTE) {
      return (
        <m.Typography variant="body2" className={classes.colorSecondary}>
          {convertToUppercase(note)}
        </m.Typography>
      )
    } else {
      return (
        <m.Typography variant="body2" color={theme.palette.status.success}>
          {convertToUppercase(note)}
        </m.Typography>
      )
    }
  }

  const handleOpenFilters = (e) => {
    setAnchorEl(e.currentTarget)
    setOpenFilter(true)
  }

  const resetFilter = () => {
    setSelectedFilter({
      ...slectedFilter,
      status: [],
      capability: [],
    })
  }

  return (
    <>
      {Object.keys(agentHealthDataState).length > 0 ? (
        <m.Paper className="para-1">
          <files.ErrorBoundary>
            <files.CommonHeader title={"Agent Health"}>
              <m.Box className="ml-display-flex ml-justify-end">
                {renderFilterButton(
                  handleOpenFilters,
                  isAppliedFilter({ ...slectedFilter, capabilityData: [] })
                )}
              </m.Box>
            </files.CommonHeader>
          </files.ErrorBoundary>
          <div className="margin-0-10 adminmuigridtable">
            <files.ErrorBoundary>
              <DataGrid
                rows={rows}
                columns={columns}
                hideFooterPagination
                sx={boldTitleHeader}
                density="compact"
                className="capability-table"
                autoHeight
                disableRowSelectionOnClick
                hideFooter
              />
            </files.ErrorBoundary>
          </div>
          <files.ErrorBoundary>
            <files.AdvanceSearchFilter
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              appliedFilters={slectedFilter}
              setAppliedFilters={setSelectedFilter}
              anchorEl={anchorEl}
              isAppliedFilter={isAppliedFilter({
                ...slectedFilter,
                capabilityData: [],
              })}
              resetFilter={resetFilter}
              cards={agentHealthDataState?.table}
              filterOptions={["Capability", "Status"]}
              health={true}
            />
          </files.ErrorBoundary>
        </m.Paper>
      ) : (
        <files.Loader />
      )}
    </>
  )
}

export default AgentHealthMain
