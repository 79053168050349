export const fetchTrending = `/discover/trending/cards`
export const fetchEmulatorConfig = `/emulators/`
export const fetchMostRecent = "/discover/newest/cards"
export const fetchStableCards = "/discover/stable/cards"
export const fetchAll = "/discover/all/cards"
export const fetchMostUsed = `/discover/most_used/cards`
export const fetchStructures = "/discover/category/structures"
export const fetchSecureDesign = "/discover/category/secure-design"
export const fetchEnclosures = "/discover/category/enclosures"
export const postSubmitIssue = "/users/me/issues"
export const topRated = "/discover/top_rated/cards"
export const fetchSearchBlobAPI = "/emulators/blobs"
export const fetchNotification = "/users/me/requests"
export const estimateUrl = "/engine/calculate/estimate"
export const updateMetadata = "/admin/mongo/precompute/metadata"
export const synthesizerJobUrl = `/engine/synthesize?client=generate&version=beta`
export const optimizeUrl = `/engine/optimize?client=optimize&version=beta`
export const randomizeInputUrl = "/engine/example?version=beta"
export const releasesDataUrl = "/launch/io"
export const lookupRequest = "/engine/lookup/instructed"

//launches
export const launchRequest = "/launch"
export const feedbackRequest = "/engine/feedback"

//chatbot
export const chatBotRequest = "/engine/chatbot"
export const chatBotResponseReview = "/engine/chat_archive?version=beta0.3"

//agents
export const agentCapabilityURL = "/calc/agents/capabilities"
export const agentHealthURL = "/calc/agents/health2"
export const agentQueueURL = "/calc/job/queue"
export const currentJobURL = "/calc/job/current"
export const recentJobURL = "/calc/job/recent"

//ready_for_calcs
export const updateCalcMode = "/emulators/update_calc_mode"
export const updateSynMode = "/emulators/update_synthesis_mode"

//activemode
export const getUserActiveMode = "/users/me/use_modes/active"

//getmetadata
export const getMetadata = "/emulators/metadata"
export const getUserList = "/users/names"

//request for create new emulator
export const createEmulator = "/emulators/new"

//
export const fetchUserLibrary = `/users/me/library`
//started
export const fetchUserAsDirector = `users/me/library/director`
//hearted
export const fetchUserLibraryFavorites = `/users/me/library/favorites`
//joined as collaborator
export const fetchUserCollaborations = `/users/me/library/collaborator`

//upload files
export const uploadEmulatorFilesRequests = (
  emulatorId,
  fileType,
  request,
  consumer
) => {
  if (request === "get/delete") {
    return `/emulators/${emulatorId}/${fileType}/files`
  } else {
    return `/emulators/${consumer || emulatorId}/${fileType}/files/download`
  }
}
export const unitUrls = `/engine/units`

export const metadataPost = (id) => {
  return `/emulators/${id}/metadata`
}
