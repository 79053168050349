import { useEffect, useLayoutEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import * as m from "@mui/material"
import _ from "lodash"
import { includes } from "lodash"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { commonCss } from "./OptimizationHistory"
import {
  getGraphResult,
  handleKeyDown,
  openNewOptimiseState,
  outputsParametersAndVariables,
  resultJobIdState,
  searchedJobIdState,
} from "../../state/projectState"
import * as files from "../../GlobalFileContainer"
import "./OptimizationMain.css"
import { ALGO_STATUS, optimiseModesStrings } from "../../state/StatusState"

const OptimizationMain = ({ setTime, polling, setPolling, consumer }) => {
  let { emulatorId } = useParams()
  const navigate = useNavigate()

  const historyUrl = `/calc/${emulatorId}/optimization/history/v1`

  const [openOpt, setOpenOpt] = useState(false)
  const [allOptHistory, setAllOptHistory] = useState({})
  const [loader, setLoader] = useState(false)
  const [searchedOptHistory, setSearchedOptHistory] = useState(allOptHistory)

  const [jobId, setJobId] = useRecoilState(resultJobIdState)
  const setSelectedOutputs = useSetRecoilState(outputsParametersAndVariables)
  const setGraph = useSetRecoilState(getGraphResult)
  const [newOptimiseOpen, setNewOptimiseOpen] =
    useRecoilState(openNewOptimiseState)
  const searchdJobId = useRecoilValue(searchedJobIdState)

  useLayoutEffect(() => {
    getAllHistory(setLoader)
    if (jobId?.length > 0) {
      setOpenOpt(true)
    }
  }, [])

  useEffect(() => {
    if (polling) {
      const timerInterval = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 9))
      }, 1000)
      return () => clearInterval(timerInterval)
    }
  }, [polling])

  useEffect(() => {
    if (setPolling) {
      if (Object.keys(allOptHistory)?.length > 0) {
        const pollingID = setInterval(async () => {
          const jobStatuses = allOptHistory?.table.map((item) => item.status)
          const hasQueuedOrReceivedIncompJobs = agentStatus(jobStatuses)
          if (!hasQueuedOrReceivedIncompJobs) {
            clearInterval(pollingID)
            setPolling(hasQueuedOrReceivedIncompJobs)
            return
          }
          await getAllHistory()
        }, 10000)

        return () => {
          clearInterval(pollingID)
        }
      }
    }
  }, [allOptHistory])

  useEffect(() => {
    if (newOptimiseOpen) {
      handleOpenOptimisation()
    }
  }, [newOptimiseOpen])

  useEffect(() => {
    if (searchdJobId.length > 0) {
      const foundJobs = allOptHistory?.table?.filter((tb) =>
        includes(tb.job_id, searchdJobId)
      )

      if (foundJobs.length > 0) {
        const filteredContexts = foundJobs.reduce((acc, foundJob) => {
          acc[foundJob.context] = allOptHistory.contexts[foundJob.context]
          return acc
        }, {})
        setSearchedOptHistory({
          table: foundJobs,
          contexts: filteredContexts,
        })
      } else {
        setSearchedOptHistory({
          table: [],
          contexts: {},
        })
      }
    } else {
      setSearchedOptHistory(allOptHistory)
    }
  }, [searchdJobId, allOptHistory])

  const agentStatus = (jobStatuses) => {
    const hasQueuedOrReceivedJobs =
      jobStatuses.includes(ALGO_STATUS.queued) ||
      jobStatuses.includes(ALGO_STATUS.received) ||
      jobStatuses.includes("incomplete")
    return hasQueuedOrReceivedJobs
  }

  const getAllHistory = async (setLoader) => {
    setLoader && setLoader(true)
    try {
      const optHistory = await files.EmulationLab.get(historyUrl, {
        headers: {
          severity: SeverityLevel.Error,
        },
      })
      if (optHistory.status === 200) {
        setAllOptHistory(optHistory.data)
        const jobStatuses = optHistory.data?.table.map((item) => item.status)
        const hasQueuedOrReceivedIncompJobs = agentStatus(jobStatuses)
        setPolling(hasQueuedOrReceivedIncompJobs)
        setLoader && setLoader(false)
      }
    } catch (err) {
      setLoader && setLoader(false)
      console.error("some error occured", err)
    }
  }

  const resetState = () => {
    setJobId("")
    setGraph({})
    setSelectedOutputs({
      objectives: [],
      parameters: [],
      variables: [],
      max_time: "00:02:00",
      max_iteration: 200,
      constraints: [],
      bestResult: "",
      mode: "calculate",
      solver: optimiseModesStrings.genetic,
    })
  }

  const handleOpenOptimisation = () => {
    resetState()
    setOpenOpt(true)
  }

  const closeOptimisationDialog = () => {
    resetState()
    if (!consumer) {
      navigate(`/em/${emulatorId}/optimization`)
    }
    setOpenOpt(false)
    setNewOptimiseOpen(false)
  }

  return loader ? (
    <files.Loader />
  ) : (
    <m.Box sx={{ width: "100%", height: "100%" }}>
      <files.ErrorBoundary>
        {allOptHistory?.contexts &&
        Object.keys(searchedOptHistory?.contexts)?.length > 0 ? (
          <files.OptimizationHistory
            allOptHistory={searchedOptHistory}
            setOpenOpt={setOpenOpt}
          />
        ) : (
          <m.Box sx={commonCss}>
            <m.Typography variant="body1">
              No historical job present.
            </m.Typography>
          </m.Box>
        )}
      </files.ErrorBoundary>
      <m.Dialog
        onKeyDown={(e) => handleKeyDown(e, closeOptimisationDialog)}
        open={openOpt}
        fullWidth={true}
        maxWidth={"xl"}
        sx={{ height: "100%" }}
      >
        <files.AddDialogTitle
          callFunction={closeOptimisationDialog}
          title={`${jobId?.length ? "View" : "Add"} Optimization`}
          shareButton={jobId !== ""}
        />
        <m.DialogContent dividers>
          <m.Card className="ml-p-5" sx={{ margin: "5px" }}>
            <files.ErrorBoundary>
              <files.OptimizationStepper getAllHistory={getAllHistory} />
            </files.ErrorBoundary>
          </m.Card>
        </m.DialogContent>
      </m.Dialog>
    </m.Box>
  )
}

export default OptimizationMain
