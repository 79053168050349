import React from "react"
import * as m from "@mui/material"
import ErrorBoundary from "../../ErrorBoundary"
import Navbar from "../../Navbar"

const MainAppLayout = ({ children }) => {
  return (
    <>
      <ErrorBoundary>
        <m.Box sx={{ height: "4rem" }}>
          <Navbar />
        </m.Box>
      </ErrorBoundary>
      {children}
    </>
  )
}

export default MainAppLayout
