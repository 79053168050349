import { useEffect, useState } from "react"
import * as m from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  apiNotificationData,
  getAllEmulatorLaunches,
  getEmulatorData,
  imagesState,
} from "../state/projectState"
import { useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { mongoProfileState } from "../state/userState"
import { EmulationLab } from "../GlobalFileContainer"
import { getUserActiveMode, updateMetadata } from "../state/requests"
import { SignOutButton } from "./SignOutButton"
import { convertToUppercase } from "../state/services"
import { alertStates } from "../state/vizState"

const UserModeBox = ({ open, anchorEl, handleClose }) => {
  const theme = m.useTheme()
  const navigate = useNavigate()
  const notificationCount = useRecoilValue(apiNotificationData)
  const [mongoProfile, setMongoProfile] = useRecoilState(mongoProfileState)
  const setAlertState = useSetRecoilState(alertStates)
  const setImages = useSetRecoilState(imagesState)
  const emData = useRecoilValue(getEmulatorData)
  const allLaunches = useRecoilValue(getAllEmulatorLaunches)

  const [activeMode, setActiveMode] = useState("")
  const [selectedIndex, setSelectedIndex] = useState()

  useEffect(() => {
    fetchActiveMode()
  }, [mongoProfile])

  const fetchActiveMode = async () => {
    try {
      const res = await EmulationLab.get(getUserActiveMode)
      setActiveMode(res.data)
    } catch (error) {
      console.error(error)
    }
  }

  const changeActiveMode = async (e) => {
    const emulatorId = emData.id
    setImages([])
    const isNewPath = window.location.pathname.includes("/new")
    const isEmPath = window.location.pathname.includes("/em")

    handleClose()
    const body = {
      mode: e.target.value,
    }

    try {
      await EmulationLab.post(getUserActiveMode, body)

      const myData = await EmulationLab.get("/users/me")
      setMongoProfile(myData.data)
      const profile = myData.data.useModes.includes("admin")
      if (profile) {
        EmulationLab.get(updateMetadata)
      }
      setActiveMode(e.target.value)
      
      if (activeMode === "consumer") {
        navigate(isNewPath ? `/em/${emulatorId}/summary` : "/")
      } else if (isEmPath) {
        const hasMatchingLaunch = allLaunches.some(
          (launch) => launch.emulator_id === emulatorId
        )
        navigate(hasMatchingLaunch ? `/new/${emulatorId}/launches` : "/")
      } else {
        navigate("/")
      }

      setAlertState({
        boolState: true,
        message: `Switched to ${e.target.value} mode!`,
        severityState: "success",
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleMenuItemClick = (event, index, id, name) => {
    if (activeMode === "consumer") {
      changeActiveMode({ target: { value: "contributor" } })
    }
    setSelectedIndex(index)
    const navigation = `/em/${id}/members`
    handleClose()
    navigate(navigation)
  }

  const accordionDetails = [
    {
      label: "User Modes",
      content: (
        <m.FormControl sx={{ mt: 1 }} size="small" fullWidth>
          <m.Select value={activeMode} onChange={changeActiveMode}>
            {mongoProfile.useModes.map((user) => (
              <m.MenuItem key={user} value={user}>
                {convertToUppercase(user, false)}
              </m.MenuItem>
            ))}
          </m.Select>
        </m.FormControl>
      ),
    },
    {
      label: "Notifications",
      content:
        notificationCount.length > 0 ? (
          <m.MenuList id="split-button-menu" autoFocusItem>
            {notificationCount.map((option, index) => (
              <m.MenuItem
                key={option.emulator_id}
                selected={index === selectedIndex}
                onClick={(event) =>
                  handleMenuItemClick(
                    event,
                    index,
                    option.emulator_id,
                    option.emulator_name
                  )
                }
                className="nav-notifications"
              >
                <m.Typography variant="body2">
                  {option.emulator_name}
                </m.Typography>
                <m.Typography variant="body2" className="nav-notifications">
                  <m.Badge
                    style={{
                      color: theme.palette.grey.main,
                      marginLeft: "1rem",
                    }}
                    badgeContent={option.request_count}
                  ></m.Badge>
                </m.Typography>
              </m.MenuItem>
            ))}
          </m.MenuList>
        ) : (
          <m.Typography variant="body2">No new notifications</m.Typography>
        ),
    },
  ]

  return (
    <m.Menu
      sx={{ boxShadow: "none" }}
      anchorEl={anchorEl}
      open={open}
      PaperProps={{
        sx: {
          "& .MuiMenu-list": {
            padding: "0px",
          },
        },
      }}
      onClose={() => handleClose()}
      className="search-filter"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <m.MenuList sx={{ padding: "0px" }} disablePadding>
        {accordionDetails.map((accordion, index) => (
          <div key={accordion.label}>
            <m.ListItem disablePadding>
              <m.Accordion defaultExpanded={index === 0} sx={{ width: "100%" }}>
                <m.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <m.Typography variant="body1">{accordion.label}</m.Typography>
                </m.AccordionSummary>
                <m.AccordionDetails>{accordion.content}</m.AccordionDetails>
              </m.Accordion>
            </m.ListItem>
            <m.Divider light />
          </div>
        ))}
        <m.Box sx={{ padding: "8px" }}>
          <m.ListItem sx={{ justifyContent: "center" }}>
            <SignOutButton />
          </m.ListItem>
        </m.Box>
      </m.MenuList>
    </m.Menu>
  )
}

export default UserModeBox
