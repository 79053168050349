import { useEffect, useState } from "react"
import { ReactComponent as ChatSendAvatar } from "../../assets/chatboticons/chatsend-icon.svg"
import * as m from "@mui/material"
import { EmulationLab } from "../../GlobalFileContainer"
import { chatBotRequest } from "../../state/requests"
import {
  allLaunchCardsState,
  cardsApiResponse,
  getEmulatorData,
  showSideBarState,
} from "../../state/projectState"
import { useRecoilValue, useSetRecoilState } from "recoil"

const ChatbotFooter = ({
  loader,
  setLoader,
  setLikedResponse,
  messages,
  setMessages,
  returnLaunchObject,
  setFeedbackStack,
  feedBackSTack,
  selectedUserText,
  setSelectedUserText,
}) => {
  const theme = m.useTheme()
  const setShowSideBar = useSetRecoilState(showSideBarState)
  const emulatorData = useRecoilValue(getEmulatorData)
  const allLaunchCards = useRecoilValue(allLaunchCardsState)
  const cardState = useRecoilValue(cardsApiResponse)

  const [inputValue, setInputValue] = useState("")

  const makeCommonObject = (sender, content) => {
    const commonObject = {
      id: new Date().toISOString(),
      sender: sender,
      content: content,
    }
    return commonObject
  }

  const handleSave = async (userSelection) => {
    if (!userSelection) {
      if (!inputValue?.trim()) {
        alert("Please enter a message before saving.")
        return
      }
    }

    const newMessage = !userSelection && makeCommonObject("user", inputValue)
    setLoader(true)
    setInputValue("")
    setLikedResponse(false)

    if (newMessage) {
      const updatedMessages = [...messages, newMessage]
      setMessages(updatedMessages)
    }

    const lastBotMessage = messages
      .filter((item) => item.sender === "bot")
      .pop()

    if (lastBotMessage?.start_over && !userSelection) {
      setFeedbackStack([])
    }

    const cleanedData = lastBotMessage
      ? (({ id, sender, content, previous_prompt, ...rest }) => rest)(
          lastBotMessage
        )
      : null

    try {
      const historyData =
        (lastBotMessage && !lastBotMessage.start_over) || userSelection
          ? {
              previous_prompt: feedBackSTack.at(-1)?.prompt,
              ...cleanedData,
              ...(selectedUserText
                ? {
                    user_selection: {
                      launch_ids: selectedUserText.launch_ids,
                      emulator_ids: selectedUserText.emulator_ids,
                      text: selectedUserText.text,
                    },
                  }
                : null),
            }
          : {}

      const requestData = {
        history: historyData,
        prompt: inputValue,
      }

      const response = await EmulationLab.post(chatBotRequest, requestData)

      if (response.status === 200) {
        setLoader(false)
        setSelectedUserText({})
        const { data } = response

        const newFeedback = {
          prompt: newMessage.content,
          response: data.response,
        }
        setFeedbackStack((prevStack) =>
          lastBotMessage?.start_over
            ? [newFeedback]
            : [...prevStack, newFeedback]
        )

        const botMessage = {
          ...data,
          ...makeCommonObject("bot", data.response),
        }
        setMessages((prevMessages) => [...prevMessages, botMessage])

        if (data.redirect) {
          if (data.launch_id || userSelection) {
            setShowSideBar(false)
          }
          const launchIds =
            userSelection && selectedUserText?.launch_ids
              ? selectedUserText.launch_ids
              : data.launch_id
          const isLaunchIdMissing =
            userSelection && selectedUserText?.launch_ids
              ? !selectedUserText.launch_ids
              : !data.launch_id
          const emulatorId = userSelection
            ? data.emulator_id?.length > 0
              ? data.emulator_id
              : selectedUserText?.data?.emulator_id
            : data.emulator_id

          returnLaunchObject(
            launchIds,
            data,
            isLaunchIdMissing,
            emulatorId,
            true
          )
        }
      }
    } catch (error) {
      setLoader(false)
      console.error("Error in sending message:", error)
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          start_over: true,
          error: true,
          content:
            "Oops! That doesn't seem to be a valid input. Please type something I can help you with.",
        },
      ])
      setFeedbackStack([])
      setSelectedUserText({})
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      handleSave()
    }
  }

  useEffect(() => {
    if (selectedUserText && Object.keys(selectedUserText)?.length > 0) {
      const cardData = selectedUserText?.launch_ids ? allLaunchCards : cardState
      const findData =
        selectedUserText?.launch_ids ?? selectedUserText?.emulator_ids
      const filteredCard = cardData?.find((card) => card.id === findData)

      const filteredData =
        filteredCard && Object?.keys(filteredCard).length > 0
          ? filteredCard
          : emulatorData
      const botMessage = makeCommonObject(
        "bot",
        selectedUserText.text
          ? `You selected "${selectedUserText.text}"`
          : selectedUserText.launch_ids
          ? `You selected "${filteredData?.mode}"`
          : `You selected "${filteredData?.name}"`
      )
      setMessages((prev) => [...prev, { ...botMessage, selection: true }])
      handleSave(true)
    }
  }, [selectedUserText])

  return (
    <m.Box
      sx={{
        position: "sticky",
        bottom: 0,
        zIndex: 10,
        padding: "8px 16px",
      }}
    >
      <m.Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: "6px",
          padding: "4px 12px",
          gap: "8px",
          pointerEvents: loader ? "none" : "auto",
          cursor: loader ? "not-allowed" : "text",
        }}
      >
        {/* Input Field */}
        <m.TextField
          multiline
          rows={1}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter a question or response..."
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            flex: 1,
            "& .MuiInputBase-input": {
              padding: "8px 0",
            },
          }}
        />
        <m.Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <m.Divider
            orientation="vertical"
            sx={{ height: "36px", backgroundColor: "rgba(0, 0, 0, 0.12)" }}
          />
          {/* Send Button */}
          <m.IconButton
            onClick={() => handleSave()}
            disabled={!inputValue}
            color="primary"
            className="ml-display-flex ml-align-center ml-justify-center"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              width: "36px",
              height: "36px",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
              "&.Mui-disabled": {
                backgroundColor: theme.palette.grey[400],
                color: theme.palette.common.white,
                pointerEvents: "none",
              },
            }}
          >
            <ChatSendAvatar style={{ fontSize: "20px" }} />
          </m.IconButton>
        </m.Box>
      </m.Box>

      <m.Box
        className="ml-display-flex ml-space-between"
        sx={{
          bgcolor: theme.palette.grey.light,
          borderRadius: "0 0 10px 10px",
          borderTop: `1px solid ${theme.palette.grey.primaryLight}`,
          marginTop: "8px",
        }}
      >
        <m.Box>
          <m.Typography className="bot-footer" variant="body2">
            Think critically. Always verify important info.
          </m.Typography>
        </m.Box>
        <m.Box>
          <m.Typography className="bot-footer" variant="body2">
            V beta 0.3
          </m.Typography>
        </m.Box>
      </m.Box>
    </m.Box>
  )
}

export default ChatbotFooter
