import { useEffect, useState } from "react"
import * as m from "@mui/material"
import { Grid } from "@material-ui/core"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import LockIcon from "@mui/icons-material/Lock"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useWindowDimensions } from "../GlobalFileContainer"
import useStyles from "./card_style"
import { breadCrumbsStrings } from "../state/StatusState"
import {
  emulatorSpecificRole,
  getBeta,
  getEmulatorData,
  getLatestV,
  selectedVersion,
  versionName,
} from "../state/projectState"
import { betaVersion, latestVersion } from "../state/services"

function EmulatorBreadCrumbs(props) {
  const { width } = useWindowDimensions()
  const classes = useStyles()

  const emulatorSpecificRoleState = useRecoilValue(emulatorSpecificRole)
  const activeEmulator = useRecoilValue(getEmulatorData)
  const setVersName = useSetRecoilState(versionName)
  const [latestVersions, setLatestVersions] = useRecoilState(getLatestV)
  const [betaVersions, setBetaVersions] = useRecoilState(getBeta)
  const emData = useRecoilValue(getEmulatorData)
  const setSelectedVersion = useSetRecoilState(selectedVersion)

  const specificRole = emulatorSpecificRoleState !== breadCrumbsStrings.role
  const latestStableVersion = latestVersion(emData)
  const configBetaVersion = betaVersion(emData)

  const [versionDetail, setVersionDetail] = useState(
    specificRole || latestVersions === breadCrumbsStrings.none
      ? `${betaVersions} ${breadCrumbsStrings.beta} `
      : `${latestVersions} ${breadCrumbsStrings.released} `
  )

  const hrefPath = props.launch
    ? `/new/${activeEmulator.id}/launches`
    : `/em/${activeEmulator.id}/summary`

  useEffect(() => {
    const updatedSpecificRole =
      specificRole || latestStableVersion === breadCrumbsStrings.none
    setSelectedVersion(
      updatedSpecificRole ? configBetaVersion : latestStableVersion
    )
    setVersName(
      updatedSpecificRole
        ? breadCrumbsStrings.beta
        : breadCrumbsStrings.released
    )
    setLatestVersions(latestStableVersion)
    setBetaVersions(configBetaVersion)
    setVersionDetail(
      updatedSpecificRole
        ? `${configBetaVersion} ${breadCrumbsStrings.beta} `
        : `${latestStableVersion} ${breadCrumbsStrings.released} `
    )
  }, [])

  const newReleaseVersion = Object.values(emData?.versions?.releases || {})
    .flat()
    ?.filter((rlvalue) => rlvalue !== latestVersions)
    ?.reverse()

  const allReleasesVersion = [...newReleaseVersion]

  const versionarray =
    allReleasesVersion &&
    [
      ...allReleasesVersion.map((v) => `${v} ${breadCrumbsStrings.archived} `),
    ]?.filter((f) => f !== "")

  const selectListVersion = (v) => {
    setSelectedVersion(v?.split(" ")[0])
    setVersName(v?.split(" ")[1])
    setVersionDetail(v)
  }

  const breadcrumbs = [
    width < 600 && props.version ? (
      <m.Box
        className="padding-top"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <m.Link
          className="boldLabel breadcrumb-font"
          underline="hover"
          key="2"
          color="grey"
          href={hrefPath}
          to={hrefPath}
        >
          <m.Typography variant="body1" fontWeight={650}>
            {props.name || activeEmulator.name}
          </m.Typography>
        </m.Link>
        <m.Box sx={{ display: "flex", justifyContent: "end" }}>
          <m.Typography variant="body1" key="3" color="primary">
            {props.msg}
          </m.Typography>
        </m.Box>
      </m.Box>
    ) : (
      <m.Link
        className="ml-display-flex boldLabel breadcrumb-font"
        underline="hover"
        key="2"
        color="grey"
        href={hrefPath}
        to={hrefPath}
      >
        <m.Typography variant="body1" fontWeight={650}>
          {props.name || activeEmulator.name}
        </m.Typography>
      </m.Link>
    ),
    props.version &&
      allReleasesVersion &&
      !versionDetail?.includes("undefined") && (
        <m.Box>
          <m.FormControl variant="standard" className="breadcrumb-font">
            <m.Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              classes={{ select: classes.menuStyling }}
              MenuProps={{
                classes: { paper: classes.menuStyling },
              }}
              value={versionDetail}
              renderValue={() => (
                <m.Box
                  display="flex"
                  sx={{ maxWidth: { xs: "100px", md: "none" } }}
                >
                  {versionDetail}
                  {versionDetail?.includes(breadCrumbsStrings.beta) ? (
                    ""
                  ) : (
                    <LockIcon fontSize="small" />
                  )}
                </m.Box>
              )}
              onChange={(e) => selectListVersion(e.target.value)}
            >
              {betaVersions && (
                <m.MenuItem
                  value={`${betaVersions} ${breadCrumbsStrings.beta} `}
                >{`${betaVersions} ${breadCrumbsStrings.beta} `}</m.MenuItem>
              )}
              {latestVersions && latestVersions !== breadCrumbsStrings.none && (
                <m.MenuItem
                  value={`${latestVersions} ${breadCrumbsStrings.released} `}
                >{`${latestVersions} ${breadCrumbsStrings.released} `}</m.MenuItem>
              )}
              {versionarray?.map((version, i) => (
                <m.MenuItem key={`${version + i}`} value={version}>
                  {version}
                </m.MenuItem>
              ))}
            </m.Select>
          </m.FormControl>
        </m.Box>
      ),
    (props.imgName && (
      <m.Link
        className={(width > 600 && "breadcrumb-font padding-top") || "noclass"}
        underline="hover"
        key="2"
        color="grey"
        onClick={() => props.setThumbnail(true)}
      >
        <m.Typography className="breadcrumb-font" key="3">
          {props.msg}
        </m.Typography>
      </m.Link>
    )) ||
      ((width > 600 || !props.version) && (
        <m.Typography
          variant="body1"
          className={`breadcrumb-font padding-top`}
          key="3"
          color="primary"
        >
          {props.msg}
        </m.Typography>
      )),
    props.imgName && (
      <m.Typography
        variant="body1"
        className="breadcrumb-font"
        key="3"
        color="primary"
      >
        Image Preview
      </m.Typography>
    ),
  ]

  return (
    <m.Stack spacing={2} paddingBottom={props.padding ? 0 : 2}>
      <m.Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Grid item xs={props.children ? 10 : 12}>
          <m.Breadcrumbs
            separator={
              <NavigateNextIcon className="padding-top" fontSize="small" />
            }
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </m.Breadcrumbs>
        </Grid>
        {props.children}
      </m.Stack>
    </m.Stack>
  )
}

export default EmulatorBreadCrumbs
