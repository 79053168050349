import { getUserActiveMode } from "../state/requests"
import { EmulationLab } from "../GlobalFileContainer"

const useModesCheck = (path, setMongoProfile, siteWideRoleState) => {
  const changeActiveMode = async (mode) => {
    try {
      await EmulationLab.post(getUserActiveMode, { mode })
      const myData = await EmulationLab.get("/users/me")
      setMongoProfile(myData.data)
    } catch (error) {
      console.error(error)
    }
  }
  if (path?.includes("redirect")) {
    if (siteWideRoleState === "contributor" && path?.includes("launch")) {
      changeActiveMode("consumer")
    } else if (siteWideRoleState === "consumer" && !path?.includes("launch")) {
      changeActiveMode("contributor")
    }
  }

  return true
}

export default useModesCheck
