import { useState, useEffect } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import { useParams } from "react-router-dom"
import { Grid } from "@material-ui/core"
import { appInsightsReactPlugin } from "../../../errorManagement/AppInsights"
import { withAITracking } from "@microsoft/applicationinsights-react-js"
import * as files from "../../../GlobalFileContainer"
import {
  calculatorResult,
  getBeta,
  getEmulatorData,
  handleKeyDown,
  imagesState,
  releaseIOState,
  selectedVersion,
  siteWideRole,
  versionName,
} from "../../../state/projectState"
import {
  breadCrumbsStrings,
  FULL_VIEW_OPEN_TEXT,
} from "../../../state/StatusState"
import { Dialog, DialogContent } from "@mui/material"
import {
  betaVersion,
  latestVersion,
  isModalObjectBoolean,
} from "../../../state/services"
import { useTryitModeEffect } from "../../../hooks/useTryitModeEffect"
import useCalculatorSocketConnection from "../../../hooks/useCalculatorSocketConnection"
import { JobLogsCalculate } from "../../shared components/CommonStyles/CommonStyles"

function TryIt() {
  let { emulatorId } = useParams()
  let searchParams = window.location.search
  const tryitMode = searchParams?.split("?")[1].split("&")[0].split("=")[1]

  const emData = useRecoilValue(getEmulatorData)
  const betaVers = useRecoilValue(getBeta)
  const images = useRecoilValue(imagesState)
  const [versName, setVersName] = useRecoilState(versionName)
  const [selectedVersions, setSelectedVersions] =
    useRecoilState(selectedVersion)
  const result = useRecoilValue(calculatorResult)
  const releaseWithIo = useRecoilValue(releaseIOState)
  const siteWideRoleState = useRecoilValue(siteWideRole)

  const isModalObject = isModalObjectBoolean(result)
  const { dependency_set } = emData ?? "none"
  const isSocketTestingLaunch =
    dependency_set !== "none" ? dependency_set?.replace("-", "_") : false

  const [emulatorConfigs, setEmulatorConfig] = useState([
    emData?.calculator,
    emData,
  ])
  const [outputResponse, setOutput] = useState([])
  const [postBody, setPostBody1] = useState({})
  const [loader, setLoader] = useState(false)
  const [mainLoader, setMainLoader] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [showModal, setShowModal] = useState(isModalObject)
  const [openThreeModal, setOpenThreeModal] = useState(false)
  const [generateRandomInputs, setGenerateRandomInputs] = useState(false)
  const [disableKeys, setDisableKeys] = useState(false)
  const [fullView, setFullView] = useState(FULL_VIEW_OPEN_TEXT)
  const [outputLog, setOutputLog] = useState([])
  const [socketMembers, setSocketMembers] = useState([])
  const [socketPostBody, setSocketPostBody] = useState({
    payload: {},
    inputs: {},
  })

  const emulatorConfig = emulatorConfigs[0]
  const emulatorConfig1 = emulatorConfigs[1]
  const latest = latestVersion(emData)
  const beta = betaVersion(emData)
  const select = latest === breadCrumbsStrings.none ? beta : latest

  useEffect(() => {
    setShowModal(isModalObject)
    setDisableKeys(isModalObject)
  }, [isModalObject])

  useEffect(() => {
    setSelectedVersions(select)
    setVersName(
      latest === breadCrumbsStrings.none
        ? breadCrumbsStrings.beta
        : breadCrumbsStrings.released
    )
    setTimeout(() => {
      setMainLoader(false)
    }, [1000])
  }, [])

  useEffect(() => {
    if (!mainLoader) {
      let calci
      if (
        selectedVersions === betaVers &&
        versName === breadCrumbsStrings.beta
      ) {
        calci = emulatorConfig1?.calculator
      } else {
        calci = releaseWithIo ?? []
      }

      setEmulatorConfig([calci, emulatorConfig1])
    }
  }, [selectedVersions])

  useTryitModeEffect(
    postBody,
    tryitMode,
    setOutput,
    setPostBody1,
    setLoader,
    false,
    emulatorId,
    isSocketTestingLaunch
  )

  useCalculatorSocketConnection(
    false,
    setPostBody1,
    isSocketTestingLaunch,
    setLoader,
    emulatorId,
    emData,
    socketPostBody,
    setSocketMembers,
    setOutputLog
  )

  const closeDialog = () => {
    setDisableKeys(true)
    setOpenThreeModal(false)
    setShowModal(true)
  }

  if (Object.keys(emulatorConfig).length === 0) {
    return <div></div>
  } else {
    return (
      <div>
        <files.CommonerrorPageHandle title="Calculator">
          <Grid container>
            <Grid item xs={12}>
              <files.ErrorBoundary>
                <files.CalculatorInputCard
                  activeEmulatorId={emulatorId}
                  calcConfig={emulatorConfig}
                  setPostBody1={setPostBody1}
                  loader={loader}
                  generateRandomInputs={generateRandomInputs}
                  setGenerateRandomInputs={setGenerateRandomInputs}
                  page="tryit"
                  setAsVariable={false}
                  setCurrentIndex={setCurrentIndex}
                  channelInstance={isSocketTestingLaunch}
                  socketMembers={socketMembers}
                  setSocketPostBody={setSocketPostBody}
                />
              </files.ErrorBoundary>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <files.ErrorBoundary sideComp>
                {(images.length !== 0 || (isModalObject && showModal)) && (
                  <files.CalculatorGallery
                    mainLoader={mainLoader}
                    isModalObject={isModalObject}
                    showModal={showModal}
                    result={result}
                    currentIndex={currentIndex}
                    setOpenThreeModal={setOpenThreeModal}
                    openThreeModal={openThreeModal}
                    emData={emData}
                    imageLength={images.length}
                    setDisableKeys={setDisableKeys}
                    disableKeys={disableKeys}
                    loader={loader}
                  />
                )}
              </files.ErrorBoundary>
            </Grid>
            <Grid
              item
              xs={12}
              md={images.length !== 0 || isModalObject ? 9 : 12}
            >
              <files.ErrorBoundary>
                <files.CalculatorOutput
                  outputResponse={outputResponse}
                  inputResponse={postBody}
                  loader={loader}
                  setLoader={setLoader}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  isModalObject={isModalObject}
                  setDisableKeys={setDisableKeys}
                  disableKeys={disableKeys}
                  fullView={fullView}
                  setFullView={setFullView}
                  emulatorConfig={emulatorConfig1}
                  channelInstance={isSocketTestingLaunch}
                />
              </files.ErrorBoundary>
            </Grid>
          </Grid>
          <JobLogsCalculate
            siteWideRoleState={siteWideRoleState}
            outputLog={outputLog}
          />
        </files.CommonerrorPageHandle>
        <Dialog
          onKeyDown={(e) => handleKeyDown(e, closeDialog)}
          open={openThreeModal}
          fullWidth
          maxWidth={"100%"}
        >
          <files.AddDialogTitle title={"3D Model"} closeCreate={closeDialog} />
          <DialogContent dividers sx={{ padding: "0px" }}>
            <Grid container className="gallery-dialog">
              <Grid item xs={12} md={3}>
                <files.ErrorBoundary>
                  <files.CalculatorOutput
                    outputResponse={outputResponse}
                    inputResponse={postBody}
                    loader={loader}
                    setLoader={setLoader}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    threeRes={true}
                    isModalObject={isModalObject}
                    setDisableKeys={setDisableKeys}
                    disableKeys={disableKeys}
                    fullView={fullView}
                    setFullView={setFullView}
                  />
                </files.ErrorBoundary>
              </Grid>
              <Grid item xs={12} md={9}>
                <files.ErrorBoundary>
                  <files.ThreeDCanvas
                    result={result}
                    currentIndex={currentIndex}
                    setDisableKeys={setDisableKeys}
                    disableKeys={disableKeys}
                    loader={loader}
                  />
                </files.ErrorBoundary>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

export default withAITracking(appInsightsReactPlugin, TryIt)
