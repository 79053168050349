import React, { useEffect, useState } from "react"
import * as m from "@mui/material"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import FavoriteIcon from "@mui/icons-material/Favorite"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import Diversity3Icon from "@mui/icons-material/Diversity3"
import { Divider } from "@material-ui/core"
import * as requests from "../../../state/requests"
import * as files from "../../../GlobalFileContainer"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { testJWTToken } from "../../../state/axios"
import { JWTTokenState, mongoProfileState } from "../../../state/userState"
import useStyles from "../../card_style"
import { alertStates } from "../../../state/vizState"
import { globalDialogBox } from "../../../state/projectState"
import { ALGO_STATUS } from "../../../state/StatusState"

const getFavoriteDefault = (emulator, mongoProfile) => {
  try {
    return mongoProfile.library.consumer.includes(emulator.id)
  } catch {
    return false
  }
}

export const getCollabDefault = (emulator, mongoProfile) => {
  let team = []
  try {
    team = emulator.team.members
  } catch (err) {
    console.error("getCollabDefault: Error:", err)
    return { active: false, requested: false }
  }

  const requestedMemberNames = team
    .filter(
      (member) =>
        member.request_status && member.request_status === ALGO_STATUS.pending
    )
    .map((member) => member.name)
  var approvedMemberNames = team
    .filter((member) => member.approved_role)
    .map((member) => member.name)
  approvedMemberNames.push(emulator.team.director.name)

  return {
    active: approvedMemberNames.includes(mongoProfile.name),
    requested: requestedMemberNames.includes(mongoProfile.name),
    numberOfApproved: approvedMemberNames.length,
  }
}

const getCollaboratorHover = (
  activeCollaborator,
  requestedCollaborator,
  emulator
) => {
  const initiator = `${emulator?.team.director.name}(Initiator)`
  const collaborators = emulator?.team?.members
    .filter((member) => member.approved_role)
    .map((member) => member.name)
  const groupText = collaborators?.concat(initiator).join("\n")
  if (activeCollaborator) {
    return `Unjoin as Collaborator\n\n${groupText}`
  } else if (requestedCollaborator) {
    return `Awaiting Response on Collaboration Request\n\n${groupText}`
  } else {
    return `Become Collaborator\n\n${groupText}`
  }
}

const HeartnContribute = ({ emulator, fontSize }) => {
  const classes = useStyles()
  const { width } = files.useWindowDimensions()
  const JWTToken = useRecoilValue(JWTTokenState)
  const setAlertState = useSetRecoilState(alertStates)
  const setGlobalDialogOpen = useSetRecoilState(globalDialogBox)
  const [mongoProfile, setMongoProfile] = useRecoilState(mongoProfileState)

  const [favorite, setFavorite] = useState(
    getFavoriteDefault(emulator, mongoProfile)
  )
  const [requestedCollaborator, setRequestedCollaborator] = useState(
    getCollabDefault(emulator, mongoProfile).requested
  )
  const [collaboratorRole, setCollaboratorRole] = useState("")
  const activeCollaborator = getCollabDefault(emulator, mongoProfile)?.active
  const noOfActiveCollaborator = getCollabDefault(
    emulator,
    mongoProfile
  )?.numberOfApproved

  useEffect(() => {
    collaboratorRole !== "" && clickCollaborateHandler()
  }, [collaboratorRole])

  const setFavoriteState = (response) => {
    const direction = favorite ? "removed from" : "added to"
    if (response.status === 200) {
      setAlertState({
        boolState: true,
        message: `Emulator ${emulator.name} ${direction} Saved Emulators list.`,
        severityState: "success",
      })
      setFavorite((val) => !val)
    } else {
      setAlertState({
        boolState: true,
        message: "Emulator unable to modify favorites",
        severityState: "error",
      })
    }
  }

  const clickFavoriteHandler = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let body = { id: emulator.id }
    let url = requests.fetchUserLibraryFavorites
    if (!favorite) {
      files.EmulationLab.put(url, body, {
        headers: {
          severity: SeverityLevel.Warning,
        },
      }).then((response) => {
        setFavoriteState(response)
        testJWTToken(setMongoProfile, JWTToken)
      })
    } else {
      files.EmulationLab.delete(url, {
        data: body,
        headers: {
          severity: SeverityLevel.Warning,
        },
      }).then((response) => {
        setFavoriteState(response)
        testJWTToken(setMongoProfile, JWTToken)
      })
    }
  }

  const clickCollaborateHandler = async () => {
    if (mongoProfile) {
      let TeamBody = {
        email: mongoProfile.email,
        requested_role: collaboratorRole,
      }
      let TeamUrl = `/emulators/${emulator.id}/team/members`
      if (!activeCollaborator.active && !requestedCollaborator.requested) {
        try {
          await files.EmulationLab.put(TeamUrl, TeamBody, {
            headers: {
              severity: SeverityLevel.Warning,
            },
          })

          setAlertState({
            boolState: true,
            message: `Request to join has been added to Emulator ${emulator.name}`,
            severityState: "info",
          })
          setRequestedCollaborator(true)
          setCollaboratorRole("")
        } catch (err) {
          setAlertState({
            boolState: true,
            message: err.response.data.message.includes(
              "already has a pending request"
            )
              ? "Member already has pending request"
              : "Emulator unable to send join request",
            severityState: "error",
          })
        }
      }
    }
  }

  const getCollaboratorIcon = (activeCollaborator, requestedCollaborator) => {
    if (activeCollaborator) {
      return (
        <Diversity3Icon
          sx={{
            fontSize: fontSize ?? "1.5rem",
            margin: { xs: "0", md: "3px 0" },
            color: "green",
          }}
        />
      )
    } else if (requestedCollaborator) {
      return (
        <Diversity3Icon
          sx={{
            fontSize: fontSize ?? "1.5rem",
            margin: { xs: "0", md: "3px 0" },
            color: "orange",
          }}
        />
      )
    } else {
      return (
        <GroupAddIcon
          sx={{
            fontSize: fontSize ?? "1.5rem",
            margin: { xs: "0", md: "3px 0" },
          }}
        />
      )
    }
  }

  return (
    <>
      <m.IconButton
        className="grid-child-score2 zoom-hover-10"
        aria-label={
          favorite
            ? "Remove from Favorites Library"
            : "Add to Favorites Library"
        }
        title={
          favorite
            ? "Remove from Favorites Library"
            : "Add to Favorites Library"
        }
        onClick={clickFavoriteHandler}
      >
        <m.Stack direction={width > 480 ? "row" : "column"}>
          {favorite ? (
            <FavoriteIcon
              sx={{
                fontSize: fontSize ?? "1.5rem",
                margin: { xs: "0", md: "3px 0" },
                color: "red",
              }}
            />
          ) : (
            <FavoriteBorderIcon
              sx={{
                fontSize: fontSize ?? "1.5rem",
                margin: { xs: "0", md: "3px 0" },
              }}
            />
          )}
          <m.Typography variant="caption" className={`${classes.themeTypo}`}>
            {emulator?.numSaves ? emulator.numSaves : ""}
          </m.Typography>
        </m.Stack>
      </m.IconButton>
      <Divider orientation="vertical" flexItem className="emu-divider" />
      <m.IconButton
        className="grid-child-score2 zoom-hover-10"
        sx={{
          "&.Mui-disabled": {
            pointerEvents: "auto",
            cursor: "pointer",
          },
        }}
        title={getCollaboratorHover(
          activeCollaborator,
          requestedCollaborator,
          emulator
        )}
        disabled={activeCollaborator}
        onClick={() =>
          setGlobalDialogOpen((prev) => ({
            ...prev,
            isOpen: true,
            content: (
              <files.CollaboratorDialog
                setShowCollaboratorDialog={setGlobalDialogOpen}
                setCollaboratorRole={setCollaboratorRole}
              />
            ),
            title: "Manager/Technician Permissions",
            maxWidth: "md",
          }))
        }
      >
        <m.Stack direction={width > 480 ? "row" : "column"}>
          {getCollaboratorIcon(activeCollaborator, requestedCollaborator)}

          <m.Typography variant="caption" className={`${classes.themeTypo}`}>
            {noOfActiveCollaborator ? noOfActiveCollaborator : ""}
          </m.Typography>
        </m.Stack>
      </m.IconButton>
    </>
  )
}

export default HeartnContribute
