import { Suspense, useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import * as core from "@material-ui/core"
import { useRecoilValue } from "recoil"
import { getEmulatorData, projectDatasetState } from "../../state/projectState"
import * as files from "../../GlobalFileContainer"
import CommonerrorPageHandle from "../CommonerrorPageHandle"

var minMaxValues = {}

const ExploreTable = ({ breadCrumbs, refKey, optimise }) => {
  let { emulatorId } = useParams()
  const exploreUrl = `/emulators/${emulatorId}/data`
  const resultUrl = exploreUrl + `?key=${refKey}`
  const finalProjectValue =
    breadCrumbs || optimise
      ? projectDatasetState(exploreUrl)
      : projectDatasetState(resultUrl)

  let data = useRecoilValue(finalProjectValue)
  const activeEmulatorConfig = useRecoilValue(getEmulatorData)

  const calculator = activeEmulatorConfig?.calculator

  return (
    <CommonerrorPageHandle title="Explore table">
      <TableCard data={data} calculator={calculator} optimise={optimise} />
    </CommonerrorPageHandle>
  )
}

const TableCard = ({ data, calculator, optimise }) => {
  data = data.map((dataRow, i) => ({ ...dataRow, id: i }))

  const [selectedCols, setSelectedCols] = useState([])
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    minMaxValues = {}
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 800)
  }, [loader])

  return (
    <Suspense fallback={<files.Loader />}>
      <core.TableContainer component={core.Paper}>
        <files.ErrorBoundary>
          <files.ParallelCoordinatesNivoFiltered
            calculator={calculator}
            setSelectedColumn={setSelectedCols}
            setLoader={setLoader}
          />
        </files.ErrorBoundary>
        <files.ErrorBoundary>
          {loader ? (
            <files.Loader margin={"3%"} marginBot={"3%"} />
          ) : (
            <files.TabulatorCID
              data={data}
              selectedCols={selectedCols}
              calculator={calculator}
              optimise={optimise}
              minMaxValues={minMaxValues}
            />
          )}
        </files.ErrorBoundary>
      </core.TableContainer>
    </Suspense>
  )
}

export default ExploreTable
