import { Suspense, useState, useEffect } from "react"
import * as router from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { Paper } from "@mui/material"
import { useTheme } from "@material-ui/core"
import MetaTags from "../components/MetaTags"
import * as file from "../GlobalFileContainer"
import * as storedStates from "../state/projectState"
import * as storedStatus from "../state/StatusState"
import EmulatorPagesLayout from "../components/shared components/Layouts/EmulatorPagesLayout"
import { getAllImages, getParamString, sourceRef } from "../state/services"

function EmulatorEndpoint() {
  const { emulatorId } = router.useParams()
  const navigate = router.useNavigate()
  const theme = useTheme()
  const [modeSearchParam, setModeSearchParam] = router.useSearchParams()
  const getEmulatorFiles = `/emulators/${emulatorId}/calculator/files`
  const pathname = window.location.pathname

  const [result, setResult] = useRecoilState(storedStates.calculatorResult)
  const setActiveEmulatorId = useSetRecoilState(
    storedStates.getSingleEmulatorId
  )
  const setEmulatorConfig = useSetRecoilState(storedStates.getEmulatorData)
  const setEmulatorData = useSetRecoilState(storedStates.dataSet)
  const setEmulatorStatusState = useSetRecoilState(storedStates.emulatorStatus)
  const setDataSourceType = useSetRecoilState(storedStates.dataSourceType)
  const setLatestV = useSetRecoilState(storedStates.getLatestV)
  const setBeta = useSetRecoilState(storedStates.getBeta)
  const setVersName = useSetRecoilState(storedStates.versionName)
  const setSelectedVersions = useSetRecoilState(storedStates.selectedVersion)
  const setImages = useSetRecoilState(storedStates.imagesState)
  const setUploadEmFilesState = useSetRecoilState(storedStates.uploadEmFiles)
  const setOutputsParametersAndVariables = useSetRecoilState(
    storedStates.outputsParametersAndVariables
  )
  const setChipPosition = useSetRecoilState(storedStates.chipPositionState)
  const setFinalTestObjectState = useSetRecoilState(
    storedStates.finalTestObject
  )
  const setShowNotification = useSetRecoilState(storedStates.notificationState)
  const setJobId = useSetRecoilState(storedStates.resultJobIdState)
  const setGraphData = useSetRecoilState(storedStates.getGraphResult)
  const setDefaultTestStatusState = useSetRecoilState(
    storedStates.defaultTestStatus
  )
  const openBot = useRecoilValue(storedStates.openBotState)

  const [fileList, setFileList] = useState([])
  const [renderPage, setRenderPage] = useState(false)
  const [fetchedML, setFetchedML] = useState(false)
  const [time, setTime] = useState(10)
  const [polling, setPolling] = useState(true)
  const [optTime, setOptTime] = useState(10)

  const emulatorSpecificResult =
    result.length > 0
      ? result?.filter((resultItem) => resultItem.emulatorId === emulatorId)
      : []

  useEffect(() => {
    setResult(emulatorSpecificResult)
    initializeRecoilStates()
    getAllImages(emulatorId, setImages)
    setChipPosition([])
    setFinalTestObjectState({})
    setOutputsParametersAndVariables({
      objectives: [],
      parameters: [],
      variables: [],
      max_time: "00:02:00",
      max_iteration: 200,
      constraints: [],
      bestResult: "",
      mode: "calculate",
      solver: storedStatus.optimiseModesStrings.genetic,
    })
  }, [emulatorId])

  useEffect(() => {
    if (modeSearchParam.get("jobId")?.length) {
      addOptimiseObjectInRecoilState()
    }

    setGraphData({})
    setJobId(modeSearchParam.get("jobId") ?? "")
  }, [emulatorId, pathname])

  const getInstructions = async () => {
    let instructionUrl = `/calc/job/${modeSearchParam.get("jobId")}`
    try {
      const res = await file.EmulationLab.get(instructionUrl)
      const { ...instructions } = res.data.instructions
      return { ...instructions, bestResult: res.data.best }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const addOptimiseObjectInRecoilState = async () => {
    const optimizeInstructions = await getInstructions()
    if (modeSearchParam.get("jobId")) {
      setOutputsParametersAndVariables({ ...optimizeInstructions })
    }
  }

  const setParamsInUrl = (emulatorConfig) => {
    const emulatorInputVarConfig = emulatorConfig?.calculator?.InputVariables
    const searchParamsString = getParamString(emulatorInputVarConfig)
    const existingParams = new URLSearchParams(modeSearchParam)
    const newSearchString = `${existingParams.toString()}&${searchParamsString}`

    modeSearchParam.size === 1 &&
      pathname.includes("tryit") &&
      setModeSearchParam(newSearchString)
  }

  const initializeRecoilStates = async () => {
    try {
      var emulatorURL = `/emulators/${emulatorId}`
      const fetchData = await file.EmulationLab.get(emulatorURL)

      const response = fetchData.data
      const latest = response?.versions?.latest_stable
      const beta = response?.versions?.beta

      setParamsInUrl(response)
      setEmulatorConfig(response)
      Object.keys(response).length !== 0 && setRenderPage(true)
      setEmulatorData(response?.data?.total_row_count)
      setActiveEmulatorId(response?.id)
      setDefaultTestStatusState({
        name: "",
        status: "",
      })

      setEmulatorStatusState(response?.status)
      setDataSourceType(
        response?.source?.type === "calculator"
          ? response?.source?.software
          : response?.source?.type
      )
      setLatestV(latest)
      setBeta(beta)
      setSelectedVersions(latest === beta || latest === "None" ? beta : latest)
      setVersName(
        latest === beta || latest === "None" ? "(Beta)" : "(Released)"
      )
      allFiles(response)
    } catch (error) {
      navigate("/")
      console.error(error)
    }
  }

  const allFiles = async (data) => {
    try {
      const response = await file.EmulationLab.get(getEmulatorFiles)
      const updatedFileList = response.data.map((item) => {
        return {
          ...item,
          ref: item.name === sourceRef(data)?.split("/")[2],
        }
      })
      setFileList(updatedFileList)
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        listStatus: true,
      }))
    } catch (error) {
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        listStatus: true,
      }))
      setShowNotification(true)
      console.error(error.response)
    }
  }

  const commonPageProps = {
    maxWidth: "100%",
    minWidth: "fit-content",
  }

  if (renderPage) {
    return (
      <Suspense fallback={<file.Loader />}>
        <MetaTags />
        <EmulatorPagesLayout>
          <Paper
            sx={{
              marginRight: "10px",
              backgroundColor: theme.palette.grey.light,
            }}
            elevation={0}
          >
            <router.Routes>
              <router.Route
                exact
                path="summary"
                element={<file.EmulatorSummary fileList={fileList} />}
              />
              <router.Route
                exact
                path="timeline"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      name="Event Logs"
                      component={<file.EventLogs />}
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="members"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      name="Emulator Members"
                      component={<file.Team />}
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="manage_configs"
                element={
                  <file.ErrorBoundary>
                    <file.DefineCalcualtorConfig
                      fileList={fileList}
                      setFileList={setFileList}
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="explore/table"
                Explore
                Table
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      name="Explore Table"
                      helper={"exploreTable"}
                      component={<file.ExploreTable breadCrumbs={"true"} />}
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="explore/parallel"
                element={<file.ExploreParallel />}
              />
              <router.Route
                exact
                path="explore/matrix"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      name="Explore Matrix"
                      helper={"exploreMatrix"}
                      navigateButton={true}
                      component={<file.ExploreScatterplotMatrix />}
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="feature-selection"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      name="Feature Selection"
                      helper={"featureSelection"}
                      component={<file.FeatureSelection />}
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                path="tryit/*"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      helper={"tryIt"}
                      name="Calculator"
                      version={"true"}
                      component={<file.TryIt />}
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="uploaddata"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      helper={"uploadData"}
                      name="Manage Data"
                      component={<file.UploadData />}
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="generate/data"
                element={
                  <file.ErrorBoundary>
                    <file.GenerateData />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="mlevaluation"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      fetched={fetchedML}
                      time={time}
                      name="ML Evaluation"
                      helper={"MLEvaluation"}
                      pageCss={!openBot && commonPageProps}
                      component={
                        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
                          <file.MLEvaluation
                            fetched={fetchedML}
                            setFetched={setFetchedML}
                            setTime={setTime}
                          />
                        </div>
                      }
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="/emulator/filemanager"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      helper={"EmulatorFileManager"}
                      name="Emulator File Manager"
                      component={<file.MLFileManager />}
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="tests"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      name="Calculator Tests"
                      component={<file.MainTest />}
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="optimization"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      name="Optimization"
                      gnTime={optTime}
                      timerInterval={polling}
                      component={
                        <file.OptimizationMain
                          setTime={setOptTime}
                          polling={polling}
                          setPolling={setPolling}
                        />
                      }
                    />
                  </file.ErrorBoundary>
                }
              />
              <router.Route
                exact
                path="lookup"
                element={
                  <file.ErrorBoundary>
                    <file.MemberManagement
                      name="Lookup Results"
                      component={<file.LookupMain />}
                    />
                  </file.ErrorBoundary>
                }
              />
            </router.Routes>
          </Paper>
        </EmulatorPagesLayout>
      </Suspense>
    )
  }
}

export default EmulatorEndpoint
