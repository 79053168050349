import { useEffect } from "react"
import { useRecoilValue, useResetRecoilState } from "recoil"
import { Grid, useTheme } from "@mui/material"
import {
  calculatorResult,
  emulatorLauncheGroupDataState,
} from "../../state/projectState"
import { IndividualLaunchCard } from "../../GlobalFileContainer"

const ConsumerFlowAllPages = ({ setSelectedCardData }) => {
  const theme = useTheme()

  const themePalatte = theme.palette

  const greyTheme = themePalatte.grey
  const primaryGrey = greyTheme.primaryLight

  const commonTheme = themePalatte.common
  const resetcalculatorResult = useResetRecoilState(calculatorResult)
  const groupedData = useRecoilValue(emulatorLauncheGroupDataState)

  useEffect(() => {
    resetcalculatorResult()
  }, [])

  return (
    <Grid container sx={{ gap: "12px", padding: "12px" }}>
      {Object.values(groupedData)?.map((emulatorGroup, index) => (
        <Grid
          key={index}
          sx={{
            borderRadius: "6px",
            border: `1px solid ${primaryGrey}`,
            backgroundColor: commonTheme.white,
            width: "100%",
          }}
        >
          {emulatorGroup?.map((emulator, i) => (
            <IndividualLaunchCard
              key={`${emulator.id}_${i}`}
              cardsLaunchedData={emulator}
              i={i}
              emulatorGroup={emulatorGroup}
              index={index}
              setSelectedCardData={setSelectedCardData}
            />
          ))}
        </Grid>
      ))}
    </Grid>
  )
}

export default ConsumerFlowAllPages
