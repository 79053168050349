import * as m from "@mui/material"
import * as files from "../GlobalFileContainer"
import {
  getEmulatorModes,
  getModeIcon,
  modeValidationCombo,
} from "../state/services"
import { ClampedTypography } from "./shared components/CommonStyles/CommonStyles"

const ListViewCardConsumer = ({
  width,
  imageHeight,
  emulator,
  investigateRouteChange,
  contentRef,
}) => {
  const theme = m.useTheme()

  const modes = getEmulatorModes(emulator?.modes)

  const iconPadding = {
    paddingRight: width < 680 ? "3px" : "6px",
  }
  const isTryitPage = window.location.pathname?.includes("tryit")

  return (
    <m.Card
      sx={{ display: "flex", flexDirection: "row" }}
      className={width ? "emu-col__card cursor-point" : ""}
      onClick={investigateRouteChange}
    >
      <m.Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3px",
          width: "100%",
        }}
      >
        <m.Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{ height: "100%" }} ref={contentRef}>
            <img
              style={{ borderRadius: 6 }}
              height={imageHeight ?? "100%"}
              width="70px"
              src={emulator.image}
              alt={emulator.name}
              onError={({ target }) => {
                target.src = "/assets/images/default_em_image.jpg"
              }}
            />
          </div>
          <m.Box
            sx={{
              display: "inline-flex",
              flexDirection: "column",
            }}
          >
            <ClampedTypography
              variant="body1"
              fontFamily={"Roboto"}
              fontWeight={600}
            >
              {emulator.name}{" "}
            </ClampedTypography>
            {!isTryitPage && (
              <ClampedTypography variant="subHeading" fontWeight={500}>
                {emulator.description}
              </ClampedTypography>
            )}
          </m.Box>
        </m.Box>
        <m.Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: width < 680 ? 0 : 2,
          }}
        >
          {width &&
            modes?.map((mode) => (
              <m.Box
                key={mode}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: modeValidationCombo(emulator, modes)?.[mode]
                    ? `1px ${theme.palette?.status?.success ?? "#579441"} solid`
                    : `none`,
                  borderRadius: "6px",
                  width: "max-content",
                  padding: "2px",
                }}
              >
                {getModeIcon(
                  mode,
                  modeValidationCombo(emulator, modes)?.[mode]
                    ? theme.palette?.status?.success ?? "#579441"
                    : "black",
                  width
                )}
                {modeValidationCombo(emulator, modes)?.[mode] && (
                  <m.Typography
                    variant="caption"
                    fontWeight={500}
                    color={theme.palette?.status?.success ?? "#579441"}
                    sx={{ ...iconPadding }}
                  >
                    {modeValidationCombo(emulator, modes)?.[mode]}
                  </m.Typography>
                )}
              </m.Box>
            ))}
          {imageHeight && <m.Divider orientation="vertical" flexItem />}
          {imageHeight && (
            <files.FavouriteAndShareIcon
              styleProp={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
              emulator={emulator}
              consumer
              iconPadding={iconPadding}
            />
          )}
        </m.Box>
      </m.Box>
    </m.Card>
  )
}

export default ListViewCardConsumer
