import { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import * as m from "@mui/material"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import {
  errorFieldsState,
  outputsParametersAndVariables,
  populateResultData,
} from "../../../state/projectState"
import { ERROR_MESSAGE } from "../../../state/StatusState"
import { checkStatus, getOperator } from "../../../state/services"

const domainGetMin = (domain) => {
  return Number(getValue(domain.Start))
}
const domainGetMax = (domain) => {
  return Number(getValue(domain.End))
}
const getValue = (qty) => {
  if (typeof qty === "number") {
    return qty
  }

  return qty.includes(" ") ? qty.split(" ")[0] : qty
}

function RangeInput({
  variable,
  unit,
  updateFeatureOutput,
  inputindex,
  setCheckingRangeWarning,
  checkingRangeWarning,
  postBody,
  generateRandomInputs,
  setGenerateRandomInputs,
  pageOptimize,
  page,
  lookupInstruction,
  disableInputs,
}) {
  const theme = m.useTheme()
  const adornmentRef = useRef(null)
  const [modeSearchParam] = useSearchParams()
  const tryitMode = modeSearchParam.get("mode")

  const description = variable?.Description
  let min = variable.Domain ? domainGetMin(variable.Domain) : -Infinity
  let max = variable.Domain ? domainGetMax(variable.Domain) : Infinity
  const unitLabel = unit !== "none" ? unit : ""
  const lengthOfPostBody = Object.keys(postBody).length

  const errorFields = useRecoilValue(errorFieldsState)
  const [parametersAndVariablesState, setParametersAndVariablesState] =
    useRecoilState(outputsParametersAndVariables)
  const [populateResultDataState, setPopulateResultDataState] =
    useRecoilState(populateResultData)

  const [variables, setVariables] = useState({})
  const [rangeVal, setRangeVal] = useState("")
  const [rangeError, setRangeError] = useState({
    range: false,
    rangeWarning: false,
  })
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedVariable, setSelectedVariable] = useState(false)
  const findVariable =
    parametersAndVariablesState?.variables.length > 0 &&
    parametersAndVariablesState?.variables.find(
      (val) => val.label === variable.ColumnLabel
    )
  const findParameter =
    parametersAndVariablesState?.parameters.length > 0 &&
    parametersAndVariablesState?.parameters.find(
      (val) => val.label === variable.ColumnLabel
    )
  const defaultInputPresent =
    variable.DefaultValue !== undefined && variable.DefaultValue !== null

  const [sliderValue, setSliderValue] = useState([
    findVariable?.domain?.start ?? min,
    findVariable?.domain?.end ?? max,
  ])

  const findVariableInInstruc = lookupInstruction?.userFilters?.find(
    (filter) => filter.label === variable.ColumnLabel
  )

  const rangeTextFieldStyles = {
    inputLabelPropsStyle: {
      fontSize: "11.5px",
      top: "-14px",
      letterSpacing: "0.5px",
      left: adornmentRef ? adornmentRef?.current?.offsetWidth : 0,
    },
    inputPropsStyle: {
      padding:
        rangeError.range || rangeError.rangeWarning
          ? "14px 14px 6px"
          : "10px 5px 10px 14px",
    },
    inputAdornmentStyle: {
      padding:
        rangeError.range || rangeError.rangeWarning
          ? "14px 0px 6px"
          : "10px 0px",
      marginLeft: 0,
    },
    commonStyle: {
      minWidth: 200,
    },
  }

  useEffect(() => {
    if (!page) {
      setVariables({
        label: variable.ColumnLabel,
        domain: {
          type: variable.Domain.Type,
          start: findVariable?.domain?.start ?? min,
          end: findVariable?.domain?.end ?? max,
        },
      })
      findVariable && setSelectedVariable(true)
    }
    if (findParameter && window.location.pathname.includes("optimization")) {
      setRangeVal(findParameter?.value)
    }
    if (
      !window.location.pathname.includes("optimization") &&
      defaultInputPresent
    ) {
      setRangeVal(Number(variable.DefaultValue))
    }
  }, [])

  useEffect(() => {
    if (rangeVal !== "") {
      if (!selectedVariable) {
        if (!findParameter) {
          setParametersAndVariablesState((prev) => ({
            ...prev,
            parameters: [
              ...prev.parameters,
              { label: variable.ColumnLabel, value: rangeVal.toString() },
            ],
          }))
        }
      }
    }
  }, [rangeVal])

  useEffect(() => {
    if (selectedVariable) {
      if (!findVariable) {
        setParametersAndVariablesState((prev) => ({
          ...prev,
          parameters: prev.parameters.filter(
            (value) => value.label !== variable.ColumnLabel
          ),
          variables: [...prev.variables, variables],
        }))
      } else {
        setParametersAndVariablesState((prev) => ({
          ...prev,
          variables: prev.variables.map((v) =>
            v.label === variable.ColumnLabel ? variables : v
          ),
        }))
      }
    }
  }, [variables, selectedVariable])

  useEffect(() => {
    if (
      generateRandomInputs &&
      !selectedVariable &&
      (checkStatus(postBody) ? true : rangeVal === "")
    ) {
      const randomNumber =
        min !== -Infinity
          ? Math.floor(Math.random() * (max - min + 1)) + min
          : ""
      setRangeVal(randomNumber)
      setGenerateRandomInputs(false)
    }
  }, [generateRandomInputs])

  useEffect(() => {
    if (
      postBody &&
      lengthOfPostBody > 0 &&
      populateResultDataState &&
      !(pageOptimize || selectedVariable)
    ) {
      let extractedData = postBody?.FeaturesData?.filter(
        (item) => item.Label === variable.ColumnLabel
      )
      let getValue = extractedData[0]?.Data[0]
      setRangeVal(getValue === "" ? "" : Number(getValue))
      setPopulateResultDataState(false)
    }
  }, [postBody, populateResultDataState])

  useEffect(() => {
    if (!rangeVal) {
      setErrorMessage(ERROR_MESSAGE.requiredField)
      setRangeError({ range: errorFields, rangeWarning: false })
    } else if (rangeVal > min || rangeVal < max) {
      setRangeError({ range: false, rangeWarning: false })
      setErrorMessage("")
    }
  }, [errorFields, rangeVal])

  useEffect(() => {
    if ((lengthOfPostBody && rangeVal) || !lengthOfPostBody) {
      updateFeatureOutput(
        inputindex,
        variable.ColumnLabel,
        rangeVal === "" ? true : rangeVal.toString()
      )
    }
  }, [rangeVal, tryitMode])

  const handleInputChange = ({ target: { value } }) => {
    setParametersAndVariablesState((prev) => ({
      ...prev,
      parameters: prev.parameters.map((param) =>
        param.label === variable.ColumnLabel
          ? { ...param, value: value }
          : param
      ),
    }))
    setRangeVal(value === "" ? "" : Number(value))

    if (value === "") {
      setParametersAndVariablesState((prev) => ({
        ...prev,
        parameters: prev.parameters.filter(
          (value) => value.label !== variable.ColumnLabel
        ),
      }))
    }
  }

  const handleBlur = () => {
    if (rangeVal !== "" && (rangeVal < min || rangeVal > max)) {
      let updatedWarning = [...checkingRangeWarning]
      updatedWarning[inputindex] = true
      setCheckingRangeWarning(updatedWarning)
      setErrorMessage(ERROR_MESSAGE.outOfRange)
      setRangeError({ range: false, rangeWarning: true })
    } else {
      let updatedWarning = [...checkingRangeWarning]
      updatedWarning[inputindex] = false
      setCheckingRangeWarning(updatedWarning)
    }
  }

  const handleVariableSelection = () => {
    selectedVariable &&
      setParametersAndVariablesState((prev) => ({
        ...prev,
        variables: prev.variables.filter(
          (value) => value.label !== variable.ColumnLabel
        ),
      }))
    setSelectedVariable(!selectedVariable)
    setRangeVal("")
  }

  const handleChangeRange = (event, newValue) => {
    setSliderValue(newValue)
    setVariables({
      label: variable.ColumnLabel,
      domain: {
        type: variable.Domain.Type,
        start: newValue[0],
        end: newValue[1],
      },
    })
  }

  const commonInput = () => (
    <m.TextField
      fullWidth
      disabled={disableInputs}
      value={pageOptimize ? "" : rangeVal}
      autoComplete="off"
      autoFocus={!pageOptimize && inputindex === 0}
      onChange={!pageOptimize && handleInputChange}
      onBlur={handleBlur}
      type="number"
      InputLabelProps={{
        shrink: false,
        style: rangeTextFieldStyles.inputLabelPropsStyle,
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          paddingLeft: 0,
        },
      }}
      inputProps={{
        min: min,
        max: max,
        step: 1,
        style: rangeTextFieldStyles.inputPropsStyle,
      }}
      InputProps={{
        startAdornment:
          lookupInstruction && Object.keys(lookupInstruction).length ? (
            <m.InputAdornment
              ref={adornmentRef}
              position="start"
              sx={{
                backgroundColor: theme.palette.primary.main,
                maxHeight: "41px",
                height: "41px",
                padding: "5px 8px",
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
              }}
            >
              <m.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "100%",
                  gap: 1,
                }}
              >
                <m.Typography
                  variant="caption"
                  lineHeight={"13px"}
                  sx={{ textWrap: "balance", textTransform: "none" }}
                >
                  {findVariableInInstruc.operator.replace(/_/g, " ")}
                </m.Typography>
                <m.Typography variant="h4">
                  {getOperator(findVariableInInstruc.operator)}
                </m.Typography>
              </m.Box>
            </m.InputAdornment>
          ) : (
            ""
          ),
        endAdornment: unitLabel && (
          <m.InputAdornment
            position="end"
            style={rangeTextFieldStyles.inputAdornmentStyle}
          >
            {unitLabel}
          </m.InputAdornment>
        ),
        style: rangeTextFieldStyles.commonStyle,
      }}
      focused={rangeError.rangeWarning || rangeError.range}
      color={rangeError.rangeWarning ? "warning" : undefined}
      error={rangeError.range}
      label={(rangeError.range || rangeError.rangeWarning) && errorMessage}
    />
  )

  const calculateStep = (min, max) => {
    const range = Math.abs(max - min)

    if (range <= 1) return 0.01
    if (range <= 10) return 0.1
    if (range <= 100) return 1
    if (range <= 500) return 10

    return 50
  }

  return (
    <>
      <div
        style={{ gap: "2px" }}
        className="ml-display-flex ml-flex-dir-col"
        title={description}
      >
        <m.Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <m.Typography
            variant="body2"
            className="tryit-label ml-display-flex ml-align-center"
          >
            {variable.Name}
          </m.Typography>
          {!page && selectedVariable && (
            <m.IconButton
              sx={{ m: 0, p: 0 }}
              onClick={pageOptimize ? handleVariableSelection : undefined}
            >
              {pageOptimize && (
                <DeleteOutlineOutlinedIcon
                  color="primary"
                  sx={{ fontSize: "20px", m: 0, p: 0 }}
                />
              )}
            </m.IconButton>
          )}
        </m.Box>
        {page && commonInput()}
        {!selectedVariable && !page ? (
          <div className="ml-display-flex ml-flex-dir-row calc-range-hover">
            {commonInput()}
            {(pageOptimize || selectedVariable) && (
              <m.Box
                sx={{
                  backgroundColor: theme.palette.grey.light,
                  cursor: "pointer",
                  opacity: !pageOptimize && selectedVariable ? 1 : 0,
                }}
                className="selected-input-div"
                onClick={pageOptimize && handleVariableSelection}
              >
                <m.Typography>
                  {pageOptimize
                    ? selectedVariable
                      ? "Unselect"
                      : "Set as variable"
                    : selectedVariable
                    ? "Selected as variable"
                    : ""}
                </m.Typography>
              </m.Box>
            )}
          </div>
        ) : (
          !page && (
            <m.Box
              sx={{
                mt: 1.15,
                px: 1,
                minWidth: 200,
              }}
            >
              <m.Slider
                min={min}
                max={max}
                step={calculateStep(min, max)}
                disabled={!pageOptimize}
                value={sliderValue}
                getAriaLabel={() => "Temperature range"}
                valueLabelDisplay="auto"
                onChange={handleChangeRange}
              />
            </m.Box>
          )
        )}
        {min !== -Infinity && (
          <m.Typography
            variant="body2"
            id="input-slider"
            sx={{ mb: selectedVariable ? 0.7 : undefined }}
          >
            <p className="ed-small">{`Range: ${min + unitLabel}-${
              max + unitLabel
            }`}</p>
          </m.Typography>
        )}
      </div>
    </>
  )
}

export default RangeInput
