import { atom } from "recoil"

export const alertStates = atom({
  key: "alertStates",
  default: {
    boolState: false,
    message: "",
    severityState: "info",
  },
})

export const darkModeState = atom({
  key: "darkModeState",
  default: "light",
})
