import { useRef } from "react"
import * as m from "@mui/material"
import { ReactComponent as BotAvatar } from "../../assets/chatboticons/bot-icon.svg"
import { NoHistoryBox, useScrollToBottom } from "../../GlobalFileContainer"
import BotMessages from "./BotMessages"

const ChatbotMessages = ({
  returnLaunchObject,
  setLikedResponse,
  feedBackSTack,
  messages,
  likedResponse,
  loader,
  typingDots,
  selectedUserText,
  setSelectedUserText,
}) => {
  const theme = m.useTheme()
  const messagesBoxRef = useRef(null)

  useScrollToBottom(messagesBoxRef, 3000, [location.pathname])
  useScrollToBottom(messagesBoxRef, 100, [messages])

  return (
    <m.Box
      sx={{
        flex: 1,
        overflowY: "auto",
        height: "400px",
        padding: 1,
        position: "relative",
      }}
      ref={messagesBoxRef}
    >
      <m.Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          pl: 0,
          pr: 1,
          width: "100%",
        }}
      >
        <m.Box sx={{ mt: 1 }}>
          <BotAvatar />
        </m.Box>
        <m.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <NoHistoryBox />
        </m.Box>
      </m.Box>
      <BotMessages
        likedResponse={likedResponse}
        messages={messages}
        returnLaunchObject={returnLaunchObject}
        setLikedResponse={setLikedResponse}
        feedBackSTack={feedBackSTack}
        selectedUserText={selectedUserText}
        setSelectedUserText={setSelectedUserText}
      />
      {loader && (
        <div
          className="typing-indicator"
          style={{
            fontStyle: "italic",
            color: theme.palette.primary.main,
            marginTop: "10px",
          }}
        >
          Typing{typingDots}
        </div>
      )}
    </m.Box>
  )
}

export default ChatbotMessages
