import * as router from "react-router-dom"
import * as m from "@mui/material"
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded"
import { convertToUppercase } from "../../state/services"

const CustomLookupOpt = ({ name, children }) => {
  const navigate = router.useNavigate()

  return (
    <m.Grid container direction={"column"} padding={1} gap={2}>
      <m.Grid item xs={12}>
        <m.Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <m.IconButton
            onClick={() => navigate(-1)}
            title={`Back to curated ${name}`}
          >
            <ArrowBackRoundedIcon />
          </m.IconButton>
          <m.Typography
            fontWeight={500}
            style={{ fontSize: "24px" }}
            color={"primary"}
          >
            Custom {convertToUppercase(name)}
          </m.Typography>
        </m.Box>
      </m.Grid>
      <m.Grid item xs={12}>
        {children}
      </m.Grid>
    </m.Grid>
  )
}

export default CustomLookupOpt
