import "react-tabulator/lib/styles.css"
import { ReactTabulator } from "react-tabulator"
import "./tabulator.css"
import "react-tabulator/lib/css/tabulator.min.css"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useEmulatorVariablesByColumnLabel } from "../../GlobalFileContainer"
import { tabulatorFilteredDataState } from "../../state/dataState"
import { EMPTY_STATES, textCatArray } from "../../state/StatusState"
import {
  getUnitList,
  matchUnit,
  outputsParametersAndVariables,
} from "../../state/projectState"

export const showNameWithUnits = (emVarsDict, colLabel, table, uniqueList) => {
  const { NickName, Units } = emVarsDict[colLabel] || {}
  const shortHandUnit = matchUnit(Units ?? "none", uniqueList)
  const unitString = shortHandUnit ? `(${shortHandUnit})` : ""
  const isTextCategory =
    textCatArray?.includes(emVarsDict[colLabel].Type) ||
    EMPTY_STATES?.includes(emVarsDict[colLabel].Units)
  if (isTextCategory) {
    return NickName
  } else if (table) {
    return `<div class="column-title"> <div>${NickName}</div> <div>${unitString}</div> </div>`
  } else {
    return `${NickName} ${unitString}`
  }
}

export const getSOrtedVarList = (label, column) => {
  const labelPositions = {}
  const inputLabels = label.map((input) => input.ColumnLabel)

  inputLabels.forEach((label, index) => {
    labelPositions[label] = index
  })

  const variableColumn = column
  const labelSort = column
    ? variableColumn?.sort((a, b) => {
        const fieldA = a.field
        const fieldB = b.field
        return labelPositions[fieldA] - labelPositions[fieldB]
      })
    : ""

  return labelSort
}

function TabulatorCID(props) {
  const setTableFilteredData = useSetRecoilState(
    tabulatorFilteredDataState
  )
  const [emVarsDict] = useEmulatorVariablesByColumnLabel(props?.calculator)
  const selectedOutputs = useRecoilValue(outputsParametersAndVariables)
  const uniqueList = useRecoilValue(getUnitList)

  const inputColumnLabel =
    props?.calculator?.InputVariables?.map((inp) => inp.ColumnLabel) || []
  const outputColumnLabel =
    props?.calculator?.OutputVariables?.map((out) => out.ColumnLabel) || []

  const minMaxFilterEditor = (
    cell,
    onRendered,
    success,
    cancel,
    editorParameter
  ) => {
    var end
    var container = document.createElement("span")
    container.setAttribute("display", "Min")

    var start = document.createElement("input")
    start.setAttribute("type", "number")
    start.setAttribute("placeholder", "Min")
    start.style.padding = "1px"
    start.style.width = "100%"

    if (props.minMaxValues[cell.getField()] === undefined) {
      start.value = editorParameter?.min ?? ""
    } else {
      if (props.minMaxValues[cell.getField()].start) {
        start.value = props.minMaxValues[cell.getField()].start
      }
    }

    const buildValues = (type) => {
      if (type) {
        if (props.minMaxValues[cell.getField()] === undefined) {
          props.minMaxValues[cell.getField()] = {}
        }
        props.minMaxValues[cell.getField()][type] =
          type === "start" ? start.value : end.value
      }

      success({
        start: start.value,
        end: end.value,
      })
    }

    const keypress = (e, type) => {
      if (e.keyCode == 13) {
        buildValues(type)
      }

      if (e.keyCode == 27) {
        cancel()
      }
    }

    end = document.createElement("input")
    end.setAttribute("type", "number")
    end.setAttribute("placeholder", "Max")
    end.style.padding = "1px"
    end.style.width = "100%"

    if (props.minMaxValues[cell.getField()] === undefined) {
      end.value = editorParameter?.max ?? ""
    } else {
      if (props.minMaxValues[cell.getField()].end) {
        end.value = props.minMaxValues[cell.getField()].end
      }
    }

    start.addEventListener("change", () => buildValues("start"))
    start.addEventListener("blur", () => buildValues("start"))
    start.addEventListener("keydown", (e) => keypress(e, "start"))

    end.addEventListener("change", () => buildValues("end"))
    end.addEventListener("blur", () => buildValues("end"))
    end.addEventListener("keydown", (e) => keypress(e, "end"))

    container.appendChild(start)
    container.appendChild(document.createElement("br"))
    container.appendChild(end)

    buildValues()

    return container
  }

  const minMaxFilterFunction = (headerValue, rowValue) => {
    if (rowValue) {
      if (headerValue.start != "") {
        if (headerValue.end != "") {
          return rowValue >= headerValue.start && rowValue <= headerValue.end
        } else {
          return rowValue >= headerValue.start
        }
      } else {
        if (headerValue.end != "") {
          return rowValue <= headerValue.end
        }
      }
    }

    return true
  }
  const minFilterEditor = (
    cell,
    onRendered,
    success,
    cancel,
    editorParameter
  ) => {
    var container = document.createElement("span")
    container.setAttribute("display", "Filter")

    var start = document.createElement("input")
    start.setAttribute("type", "string")
    start.setAttribute("placeholder", "Filter")
    start.style.padding = "1px"
    start.style.width = "100%"

    if (props.minMaxValues[cell.getField()] === undefined) {
      start.value = editorParameter.value
    } else {
      if (props.minMaxValues[cell.getField()].start) {
        start.value = props.minMaxValues[cell.getField()].start
      }
    }

    const buildValues = (type) => {
      if (type) {
        if (props.minMaxValues[cell.getField()] === undefined) {
          props.minMaxValues[cell.getField()] = {}
        }
        props.minMaxValues[cell.getField()][type] = start.value
      }

      success({
        start: start.value,
      })
    }

    const keypress = (e, type) => {
      if (e.keyCode == 13) {
        buildValues(type)
      }
      if (e.keyCode == 27) {
        cancel()
      }
    }

    start.addEventListener("change", () => buildValues("start"))
    start.addEventListener("blur", () => buildValues("start"))
    start.addEventListener("keydown", (e) => keypress(e, "start"))

    container.appendChild(start)
    buildValues()

    return container
  }

  const minFilterFunction = (headerValue, rowValue) => {
    if (rowValue) {
      if (headerValue.start != "") {
        return rowValue === headerValue.start
      }
    }
    return true
  }

  const filterEditor = (cell, success) => {
    var editor = document.createElement("input")
    editor.setAttribute("type", "text")

    if (props.minMaxValues[cell.getField()] === undefined) {
      editor.value = cell.getValue()
    } else {
      if (props.minMaxValues[cell.getField()].value) {
        editor.value = props.minMaxValues[cell.getField()].value
      }
    }

    const successFunc = (value) => {
      if (value) {
        if (props.minMaxValues[cell.getField()] === undefined) {
          props.minMaxValues[cell.getField()] = {}
        }
        props.minMaxValues[cell.getField()].value = editor.value
      }

      success(editor.value)
    }

    editor.addEventListener("change", () => successFunc("value"))
    editor.addEventListener("blur", () => successFunc("value"))

    setTimeout(() => {
      successFunc()
    }, 1000)

    return editor
  }

  const inputColumns =
    props?.data?.length > 0 &&
    Object.keys(props?.data[0])
      ?.filter((colLabel) => inputColumnLabel?.includes(colLabel))
      ?.filter((colLabel) =>
        props.selectedCols.includes(
          showNameWithUnits(emVarsDict, colLabel, false, uniqueList)
        )
      )
      ?.map((colLabel, i) => {
        try {
          emVarsDict[colLabel].Type
        } catch (e) {
          console.error(`Error creating table ${colLabel}`)
          return {
            title: `Error-${colLabel}`,
            field: colLabel,
            headerVertical: true,
          }
        }
        if (
          emVarsDict[colLabel].Type == "number" ||
          emVarsDict[colLabel].Type == "numerical" ||
          emVarsDict[colLabel].Type == "quantity"
        ) {
          return {
            title: showNameWithUnits(emVarsDict, colLabel, true, uniqueList),
            field: colLabel,
            responsive: 0,
            sorter: "number",
            headerFilter: minMaxFilterEditor,
            headerFilterParams: props.optimise
              ? {
                  min:
                    selectedOutputs?.variables?.find(
                      (outVar) =>
                        outVar?.label === emVarsDict[colLabel]?.ColumnLabel
                    )?.domain?.start ??
                    selectedOutputs?.parameters?.find(
                      (outVar) =>
                        outVar?.label === emVarsDict[colLabel]?.ColumnLabel
                    )?.value ??
                    "",
                  max:
                    selectedOutputs?.variables?.find(
                      (outVar) =>
                        outVar?.label === emVarsDict[colLabel]?.ColumnLabel
                    )?.domain?.end ??
                    selectedOutputs?.parameters?.find(
                      (outVar) =>
                        outVar?.label === emVarsDict[colLabel]?.ColumnLabel
                    )?.value ??
                    "",
                }
              : null,
            headerFilterFunc: minMaxFilterFunction,
            headerFilterLiveFilter: false,

            headerVertical: true,
          }
        } else if (
          emVarsDict[colLabel].Type == "categorical" ||
          emVarsDict[colLabel].Type == "discrete" ||
          emVarsDict[colLabel].Type == "text"
        ) {
          if (props.optimise) {
            return {
              title: showNameWithUnits(emVarsDict, colLabel, true, uniqueList),
              field: colLabel,
              sorter: "string",
              responsive: 0,
              headerFilter: minFilterEditor,
              headerFilterParams: {
                value:
                  selectedOutputs?.parameters?.find(
                    (outVar) =>
                      outVar?.label === emVarsDict[colLabel]?.ColumnLabel
                  )?.value ?? "",
              },
              headerFilterFunc: minFilterFunction,
              headerFilterLiveFilter: false,

              headerVertical: true,
            }
          } else {
            return {
              title: showNameWithUnits(emVarsDict, colLabel, true, uniqueList),
              field: colLabel,
              responsive: 0,
              headerFilter: filterEditor,
              headerFilterPlaceholder: "filter",
              headerVertical: true,
            }
          }
        } else {
          console.error("Input Column Label Mismatch Error", `${colLabel}`)
        }
      })

  const outputColumns =
    props?.data?.length > 0 &&
    Object.keys(props.data[0])
      ?.filter((colLabel) => outputColumnLabel?.includes(colLabel))
      ?.filter((colLabel) =>
        props.selectedCols.includes(
          showNameWithUnits(emVarsDict, colLabel, false, uniqueList)
        )
      )
      ?.map((colLabel) => {
        try {
          emVarsDict[colLabel]?.Type
        } catch (e) {
          console.error(`Error creating table ${colLabel}`)

          return {
            title: `Error-${colLabel}`,
            field: colLabel,
            headerVertical: true,
          }
        }
        if (
          emVarsDict[colLabel].Type == "number" ||
          emVarsDict[colLabel].Type == "numerical" ||
          emVarsDict[colLabel].Type == "quantity"
        ) {
          return {
            title: showNameWithUnits(emVarsDict, colLabel, true, uniqueList),
            field: colLabel,
            sorter: "number",
            responsive: 0,
            headerFilter: minMaxFilterEditor,
            headerFilterFunc: minMaxFilterFunction,
            headerFilterLiveFilter: false,

            headerVertical: true,
          }
        } else if (
          emVarsDict[colLabel].Type == "text" ||
          emVarsDict[colLabel].Type == "categorical"
        ) {
          return {
            title: showNameWithUnits(emVarsDict, colLabel, true, uniqueList),
            field: colLabel,
            responsive: 0,
            headerFilter: filterEditor,
            headerFilterPlaceholder: "filter",

            headerVertical: true,
          }
        }
      })

  const columnOptions = [
    {
      title: "Inputs",
      columns:
        getSOrtedVarList(props?.calculator?.InputVariables, inputColumns) ??
        inputColumns,
      resizable: false,
      headerVisible: false,
    },
    {
      title: "Outputs",
      columns:
        getSOrtedVarList(props?.calculator?.OutputVariables, outputColumns) ??
        outputColumns,
      resizable: false,
      headerVisible: false,
    },
  ]

  const options = {
    layout: "fitColumns",
    data: props.data,
    pagination: "local",
    paginationSize: 20,
    headerFilterLiveFilterDelay: 600,
    paginationSizeSelector: [20, 40, 80, true],
  }

  return (
    <div>
      <ReactTabulator
        data={props.data}
        columns={columnOptions}
        options={options}
        events={{
          dataFiltered: async function (e, tabulatordata) {
            const tabData = await tabulatordata.map(
              async (dataRow) => await dataRow.getData()
            )
            setTableFilteredData(tabData)
          },
        }}
        placeholder={
          !props.data
            ? props.optimise
              ? "No Data to show"
              : "Awaiting Data, Please Load File"
            : null
        }
      />
    </div>
  )
}

export default TabulatorCID
