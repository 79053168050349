import { useEffect, useState } from "react"
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil"
import { useParams } from "react-router-dom"
import {
  Stack,
  IconButton,
  Typography,
  Box,
  Dialog,
  Grid,
  Divider,
} from "@mui/material"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import HistoryIcon from "@mui/icons-material/History"
import { ReactComponent as DownloadIcon } from "../../../assets/newIcons/downloadIcon.svg"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import * as files from "../../../GlobalFileContainer"
import {
  calculatorResult,
  editAlertBoxState,
  handleKeyDown,
  selectedVersion,
} from "../../../state/projectState"
import {
  confirmDelete,
  emulatorMode,
  FULL_VIEW_EXIT_TEXT,
  FULL_VIEW_OPEN_TEXT,
} from "../../../state/StatusState"
import { Button } from "@material-ui/core"
import { alertStates } from "../../../state/vizState"
import NoResultNote from "../../consumer mode flow/NoResultNote"
import { handleExportData } from "../../../state/services"

function Results(props) {
  let { emulatorId } = useParams()
  const { width } = files.useWindowDimensions()
  const searchParams = new URLSearchParams(window.location.search)
  const tryItMode = searchParams.get("mode")

  const selectedVersions = useRecoilValue(selectedVersion)
  const [result, setResult] = useRecoilState(calculatorResult)
  const [editAlertBox, setEditAlertBox] = useRecoilState(editAlertBoxState)
  const setAlertState = useSetRecoilState(alertStates)

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [showInputs, setShowInputs] = useState(true)

  const storedData = localStorage.getItem("socketJobs")
  const parsedData = storedData ? JSON.parse(storedData) : []
  const updatedParsedData = parsedData?.filter((parse) => {
    if (props.consumer) {
      return props.channelInstance
        ? parse.emulatorId === props.activeEmulatorId
        : parse.launchID === props.activeEmulatorId
    } else {
      return parse.emulatorId === emulatorId
    }
  })

  useEffect(() => {
    openDeleteDialog &&
      setEditAlertBox({
        heading: confirmDelete,
        content: `Are you sure you want to delete whole result history?`,
      })
  }, [openDeleteDialog])

  useEffect(() => {
    if (props.optresults) {
      setResult(props.optresults)
    }
  }, [props.optresults])

  useEffect(() => {
    if (result?.length > 0) {
      props.setLoader(false)
      props.isModalObject && props.setDisableKeys(true)
    }
  }, [result])

  useEffect(() => {
    if (!props.optresults) {
      if (props.outputResponse.length > 0) {
        const emVSLaunch = props.consumer
          ? { emulatorId: props.inputResponse?.launchID }
          : { emulatorId: props.inputResponse?.emulator_id }
        const singleResult = {
          ...emVSLaunch,
          jobId: length,
          inputValues: props.inputResponse?.FeaturesData,
          outputValues: props.outputResponse,
          resultMode: tryItMode,
          resultVersion: selectedVersions,
          resultStatus: "complete",
        }

        setResult([singleResult, ...result])
      }
    }
  }, [props.outputResponse])

  const handlePrev = () => {
    props.isModalObject && props.setDisableKeys(true)
    props.setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + result.length) % result.length
    )
  }

  const handleNext = () => {
    props.isModalObject && props.setDisableKeys(true)
    props.setCurrentIndex((prevIndex) => (prevIndex + 1) % result.length)
  }

  const handleFullViewResult = (e, view) => {
    props.setFullView(
      view ?? props.fullView === FULL_VIEW_OPEN_TEXT
        ? FULL_VIEW_EXIT_TEXT
        : FULL_VIEW_OPEN_TEXT
    )
    setShowInputs(true)
  }

  const handleDialogClose = () => setOpenDeleteDialog(false)

  const handleDeleteHistory = () => {
    setResult([])
    localStorage.removeItem("socketJobs")
  }

  const renderCommonResultCard = (data) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: data ? "end" : "center",
      }}
    >
      {!props.loader && (
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={handlePrev}
            disabled={
              props.fullView === FULL_VIEW_EXIT_TEXT ||
              props.currentIndex === 0 ||
              props.disableKeys
            }
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Box mx={2}>
            <Typography variant="body2">
              {props.fullView === FULL_VIEW_OPEN_TEXT
                ? `${String(props.currentIndex + 1).padStart(
                    2,
                    "0"
                  )} of ${String(result.length).padStart(2, "0")}`
                : "all-results"}
            </Typography>
          </Box>
          <IconButton
            onClick={handleNext}
            disabled={
              props.fullView === FULL_VIEW_EXIT_TEXT ||
              props.currentIndex === result.length - 1 ||
              props.disableKeys
            }
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      )}
      {!data && (
        <>
          <Box
            display="flex"
            alignItems="center"
            title={"Clear result history"}
          >
            <IconButton
              onClick={() => {
                setOpenDeleteDialog(true)
              }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            title={`${props.fullView} list-view`}
          >
            <IconButton>
              <HistoryIcon onClick={handleFullViewResult} />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  )

  const commonShowHideButton = (title) => (
    <Button
      id="tryitbtn"
      style={{
        backgroundColor: "rgba(244,244,245,1)",
        color: "black",
      }}
      endIcon={<HistoryIcon sx={{ fontSize: "20px" }} />}
      onClick={handleFullViewResult}
      variant="contained"
    >
      <Typography lineHeight={"20px"} variant="subHeading" margin="2px">
        {title} History
      </Typography>
    </Button>
  )

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Grid container>
          <Grid container justifyContent="space-between" alignItems={"center"}>
            <Grid className="ml-display-flex ml-align-center">
              {props.fullView === FULL_VIEW_EXIT_TEXT && (
                <IconButton title="Hide history" onClick={handleFullViewResult}>
                  <ArrowBackIcon fontSize="small" />
                </IconButton>
              )}
              <Typography
                fontWeight={500}
                style={{ fontSize: "24px" }}
                color={"primary"}
              >
                {props.fullView === FULL_VIEW_EXIT_TEXT ? "History" : "Results"}
              </Typography>
            </Grid>
            {result?.length > 0 && (
              <Grid item gap={"8px"} sx={{ width: "fit-content" }}>
                {props.resultComponent}
              </Grid>
            )}
            {(result?.length > 0 || props.fullView === FULL_VIEW_EXIT_TEXT) && (
              <Grid item gap={"8px"}>
                <Button
                  id="tryitbtn"
                  disabled={props.loader || result.length === 0}
                  style={{
                    backgroundColor: "rgba(244,244,245,1)",
                    color: "black",
                  }}
                  endIcon={
                    <DownloadIcon height={20} width={20} stroke="black" />
                  }
                  variant="contained"
                  onClick={(e) => {
                    handleFullViewResult(e, "Exit")
                    setAlertState({
                      boolState: true,
                      message: "Generating PDF!",
                      severityState: "success",
                    })
                    setTimeout(() => {
                      handleExportData("#resultBlock", props.emulatorConfig)
                    }, 1800)
                  }}
                >
                  <Typography
                    lineHeight={"20px"}
                    variant="subHeading"
                    margin="2px"
                  >
                    Export History
                  </Typography>
                </Button>
                {commonShowHideButton(
                  props.fullView === FULL_VIEW_EXIT_TEXT ? "Hide" : "Show"
                )}
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ mb: 1 }} />
          </Grid>
        </Grid>

        {/* {updatedParsedData.length > 0 &&
          !props.threeRes &&
          renderCommonResultCard()} */}
      </Stack>
      <div className={width < 480 ? "" : "results-block"}>
        <div id="resultBlock">
          {props.loader ? (
            <files.Loader margin="0px" />
          ) : result.length > 0 ? (
            props.fullView === FULL_VIEW_OPEN_TEXT ? (
              <Box>
                <files.OutputsCard
                  finalRes={result}
                  fullView={props.fullView}
                  currentIndex={props.currentIndex}
                  threeRes={props.threeRes}
                  isModalObject={props.isModalObject}
                  handlePrev={handlePrev}
                  setResults={setResult}
                  handleNext={handleNext}
                  updatedParsedData={updatedParsedData}
                  consumer={props.consumer}
                  showInputs={showInputs}
                  setShowInputs={setShowInputs}
                  lookup={
                    tryItMode === emulatorMode.lookup ||
                    tryItMode === emulatorMode.optimize
                  }
                  setCurrentIndex={props.setCurrentIndex}
                  channelInstance={props.channelInstance}
                />
              </Box>
            ) : (
              <Stack gap={1}>
                <files.OutputsCard
                  finalRes={result}
                  fullView={props.fullView}
                  threeRes={props.threeRes}
                  isModalObject={props.isModalObject}
                  handlePrev={handlePrev}
                  setResults={setResult}
                  handleNext={handleNext}
                  updatedParsedData={updatedParsedData}
                  consumer={props.consumer}
                  showInputs={showInputs}
                  setShowInputs={setShowInputs}
                  setCurrentIndex={props.setCurrentIndex}
                  lookup={
                    tryItMode === emulatorMode.lookup ||
                    tryItMode === emulatorMode.optimize
                  }
                  channelInstance={props.channelInstance}
                />
              </Stack>
            )
          ) : (
            <NoResultNote title={"Results"} />
          )}
        </div>
      </div>
      <Dialog
        onKeyDown={(e) => handleKeyDown(e, handleDialogClose)}
        open={openDeleteDialog}
      >
        <files.AlertBox
          heading={editAlertBox.heading}
          content={editAlertBox.content}
          closeDialog={handleDialogClose}
          callFunction={handleDeleteHistory}
        />
      </Dialog>
      {result.length > 0 && props.threeRes && (
        <Box marginTop={"5px"}>{renderCommonResultCard(true)}</Box>
      )}
    </>
  )
}

export default Results
