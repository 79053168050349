import { useState } from "react"
import { useRecoilValue } from "recoil"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  Checkbox,
  Chip,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { getEmulatorunch } from "../../state/projectState"
import { convertToUppercase } from "../../state/services"

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const LaunchPageTabs = ({ children, selectedOptions, setSelectedOptions }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const { emulatorId } = useParams()

  const launchesData = useRecoilValue(getEmulatorunch)

  const [selectedNavIndex, setSelectedNavIndex] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)

  const fileTypeOptions = Array.from(
    new Set(
      launchesData?.map((launchDta) => launchDta?.package?.source?.software)
    )
  )

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navLaunchOptions = [
    { title: "All Launches" },
    //commented-may be used later
    // { title: "Liked Modes" },
    { title: "FileType", icon: true },
  ]

  const navigateToLaunch = () => {
    setSearchParams({})
    navigate(`/new/${emulatorId}/launches`)
  }

  const handleChange = (event, newValue) => {
    navigateToLaunch()
    setSelectedNavIndex(newValue)
  }

  const handleSelect = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    )
  }

  const handleResettingNavFilter = () => {
    setSelectedOptions(fileTypeOptions)
  }

  return (
    <>
      <Grid
        item
        xs={8}
        className="ml-display-flex ml-flex-dir-row"
        justifyContent="space-between"
        direction="row"
        sx={{ height: "60px", padding: "12px", gap: "10px" }}
      >
        <Grid className="ml-display-flex ml-flex-dir-row ml-align-center">
          <Tabs
            sx={{ borderBottom: "1px solid rgba(228, 228, 231, 1)" }}
            value={selectedNavIndex}
            onChange={handleChange}
            onClick={navigateToLaunch}
          >
            {navLaunchOptions.map((option, i) => (
              <Tab
                key={i}
                label={
                  <Typography fontWeight={500} style={{ fontSize: "14px" }}>
                    {option.title}
                  </Typography>
                }
                {...a11yProps(i)}
                icon={option?.icon ? <ExpandMoreIcon fontSize="small" /> : null}
                iconPosition="end"
                disabled={
                  !window.location.pathname.includes("/launches") && i !== 0
                }
                onClick={(e) =>
                  i === 1 ? handleMenuClick(e, 2) : handleResettingNavFilter()
                }
                sx={{
                  display:
                    option.title === "FileType" && fileTypeOptions?.length === 0
                      ? "none"
                      : "flex",
                  padding: "12px 16px",
                  minHeight: 48,
                  "&.Mui-selected": { color: "black" },
                }}
              />
            ))}
          </Tabs>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {fileTypeOptions.map((filterOption) => (
              <MenuItem
                key={filterOption}
                value={filterOption}
                style={{
                  display: "block",
                  whiteSpace: "normal",
                }}
                onClick={() => handleSelect(filterOption)}
              >
                <div className="ml-display-flex ml-flex-dir-row ml-align-center">
                  <div className="create-em-width">
                    <Checkbox
                      checked={selectedOptions.indexOf(filterOption) > -1}
                    />
                  </div>
                  <div>
                    <Typography variant="body1">
                      {convertToUppercase(filterOption)}
                    </Typography>
                  </div>
                </div>
              </MenuItem>
            ))}
          </Menu>
          {selectedNavIndex === 2 &&
            selectedOptions.map((category, index) => (
              <Grid key={index} item xs="auto">
                <Chip
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    cursor: "pointer",
                  }}
                  variant="outlined"
                  label={
                    <Typography variant="body2" fontWeight={600}>
                      {convertToUppercase(category)}
                    </Typography>
                  }
                  onDelete={() => handleSelect(category)}
                />
              </Grid>
            ))}
        </Grid>
        <Divider />
        <Grid item xs={12} md={3}>
          <TextField
            sx={{
              backgroundColor: theme.palette.common.white,
              width: "100%",
            }}
            name="name"
            variant="outlined"
            placeholder="Search for output..."
            size="small"
            spellCheck="false"
            autoComplete="off"
          />
        </Grid>
      </Grid>
      {children}
    </>
  )
}

export default LaunchPageTabs
