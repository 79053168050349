import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import * as core from "@material-ui/core"
import { styled, Badge, useTheme, Typography, Stack } from "@mui/material"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import ErrorIcon from "@mui/icons-material/Error"
import AnnouncementIcon from "@mui/icons-material/Announcement"
import Edit from "@mui/icons-material/Edit"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import useStyles from "../card_style"
import "./MainTest.css"
import * as files from "../../GlobalFileContainer"
import {
  ALGO_STATUS,
  EMPTY_STATES,
  TEST_LEGEND_DESC,
  TEST_STATUS,
  confirmDelete,
} from "../../state/StatusState"
import {
  editAlertBoxState,
  estimatedTimeState,
  finalTestObject,
  handleKeyDown,
} from "../../state/projectState"
import { callCalculateToRun, postNewTest } from "./AddTestForm"
import useCalculatorPolling from "../../hooks/useCalculatorPolling"
import { alertStates } from "../../state/vizState"
import {
  evaluateTestRule,
  roundValues,
  areObjectsEqual,
} from "../../state/services"

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 9,
    border: `1px solid white`,
    padding: "0 4px",
  },
}))

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(() => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "0px",
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "0 20px 0",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}))

export const CustomButton = ({ text, icon, callingFunction }) => {
  return (
    <core.Button
      style={{ textTransform: "none" }}
      startIcon={icon}
      onClick={callingFunction}
    >
      <core.Typography variant="caption" color="primary">
        {text}
      </core.Typography>
    </core.Button>
  )
}

export const processSubTest = async (subTest, data, jobid) => {
  const resultList = await Promise.all(
    subTest.map(async (tl) => {
      if (!data) {
        return {
          ...tl,
          status: "running",
          actualData: "",
          jobid: jobid,
        }
      }
      const secondData = data?.find(
        (secondObj) => secondObj?.Ref?.Label === tl.label
      )
      if (secondData) {
        const status = await evaluateTestRule(
          tl.mode,
          tl.value,
          tl.valueMin,
          tl.valueMax,
          tl.tolerance,
          secondData.Data
        )
        return {
          ...tl,
          actualData: secondData.Data,
          status: status,
          jobid: jobid,
        }
      } else {
        return {
          ...tl,
          status:
            data[3]?.value[0] === "canceled" ? "canceled" : "calculation fail",
          jobid: jobid,
        }
      }
    })
  )

  return resultList.filter(Boolean)
}

export const getIconBasedOnStatus = (
  displayLocation,
  status,
  count,
  classes
) => {
  const getClassName =
    displayLocation === "test"
      ? `${status === TEST_STATUS.running ? "mliconrunning" : ""} margin-0-10`
      : ""

  const renderDoneIcon = () => (
    <CheckCircleOutlineIcon
      fontSize="small"
      className={`${classes.colorSuccessIcon} mlicondone ${getClassName}`}
    />
  )
  switch (status) {
    case TEST_STATUS.pass:
      return count ? (
        <StyledBadge badgeContent={count} color="error">
          {renderDoneIcon()}
        </StyledBadge>
      ) : (
        renderDoneIcon()
      )
    case TEST_STATUS.fail:
      return (
        <CancelOutlinedIcon
          fontSize="small"
          className={`${classes.colorDangerIcon} ${getClassName}`}
        />
      )
    case TEST_STATUS.draft:
      return (
        <RemoveCircleOutlineIcon
          fontSize="small"
          className={`${classes.colorGraySec} ${getClassName}`}
        />
      )
    case TEST_STATUS.running:
      return (
        <DonutLargeOutlinedIcon
          className={`${classes.colorPrimaryMain} ${getClassName}`}
          fontSize="small"
        />
      )
    case TEST_STATUS.calculation_fail:
      return (
        <AnnouncementIcon
          className={`${classes.colorDangerIcon} ${getClassName}`}
          fontSize="small"
        />
      )
    case TEST_STATUS.canceled:
      return (
        <ErrorIcon
          className={`${classes.colorDangerIcon} ${getClassName}`}
          fontSize="small"
        />
      )
    default:
      return null // Return null for unsupported status values
  }
}

const MainTest = () => {
  const classes = useStyles()
  let { emulatorId } = useParams()
  const theme = useTheme()
  const getAllTestApi = `/emulators/${emulatorId}/tests`

  const [finalTestObjectState, setFinalTestObjectState] =
    useRecoilState(finalTestObject)
  const emulatorCalcConfig = files.useCalculatorConfig()
  const [editAlertBox, setEditAlertBox] = useRecoilState(editAlertBoxState)
  const setAlertState = useSetRecoilState(alertStates)
  const estimateData = useRecoilValue(estimatedTimeState)

  const [expanded, setExpanded] = useState("panel1")
  const [openNewTest, setOpenNewTest] = useState(false)
  const [index, setIndex] = useState()
  const [updateTest, setUpdateTest] = useState(false)
  const [addForm, setAddForm] = useState(false)
  const [postTest, setPostTest] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [jobData, setJobData] = useState([])
  const [jobResponses, setJobResponses] = useState({})

  useEffect(() => {
    openDeleteDialog &&
      setEditAlertBox({
        heading: confirmDelete,
        content: `Are you sure you want to delete ${
          !EMPTY_STATES.includes(deleteIndex) ? "this" : "all"
        } test result?`,
      })
  }, [openDeleteDialog])

  useEffect(() => {
    getAllTestData()
  }, [])

  useEffect(() => {
    const findRunningTests = finalTestObjectState?.tests?.filter((tests) =>
      tests.subTests.find((subtest) => subtest.status === "running")
    )

    if (findRunningTests) {
      let localArray = []
      finalTestObjectState?.tests?.map((tests, index) => {
        tests.subTests.map((subtest) => {
          let isPresent = localArray?.find((arr) => arr.testId === index)
          if (subtest.status === "running" && !isPresent) {
            const newObj = {
              emulator_id: finalTestObjectState?.emulator_id,
              jobid: subtest.jobid,
              testId: index,
            }
            localArray.push(newObj)
          }
        })
      })
      if (localArray?.length > 0) {
        setJobData(localArray)
      }
    } else {
      setJobData([])
    }
  }, [finalTestObjectState])

  useEffect(() => {
    if (jobData.length > 0) {
      const pollingInterval = setInterval(async () => {
        const { updatedResponses, allJobsCompleteorFailed } =
          await useCalculatorPolling(
            jobResponses,
            jobData,
            "test",
            estimateData,
            emulatorCalcConfig[0]
          )

        if (
          Object.keys(updatedResponses).length === 0 &&
          allJobsCompleteorFailed
        ) {
          setJobData([])
          setJobResponses({})
          clearInterval(pollingInterval)
        } else {
          setJobResponses((prev) => {
            if (!areObjectsEqual(prev, updatedResponses)) {
              return {
                ...prev,
                ...updatedResponses,
              }
            }
            return prev
          })
          if (allJobsCompleteorFailed) {
            clearInterval(pollingInterval)
          }
        }
      }, 1000)

      setTimeout(() => {
        clearInterval(pollingInterval)
        setJobData([])
        setAlertState({
          boolState: true,
          message: "Some issue with the agents in processing test.",
          severityState: "warning",
        })
        setJobResponses((prevState) => {
          const updatedState = { ...prevState }
          Object.keys(updatedState).forEach((key) => {
            updatedState[key].status = "failed"
          })
          return updatedState
        })
      }, 30000)

      return () => {
        clearInterval(pollingInterval)
      }
    }
  }, [jobData, jobResponses])

  useEffect(() => {
    const setFinalResults = async () => {
      for (const id in jobResponses) {
        if (
          jobResponses[id]?.status === ALGO_STATUS.failed ||
          jobResponses[id]?.status === ALGO_STATUS.complete ||
          jobResponses[id]?.status === ALGO_STATUS.canceled ||
          jobResponses[id]?.status === ALGO_STATUS.timedOut
        ) {
          const subTestResults = await processSubTest(
            finalTestObjectState?.tests[id]?.subTests,
            jobResponses[id].estimate,
            jobResponses[id].jobid
          )
          setFinalTestObjectState((prevState) => {
            const updatedTests = [...prevState.tests]
            updatedTests[id] = {
              ...updatedTests[id],
              subTests: subTestResults,
            }

            return {
              ...prevState,
              tests: updatedTests,
            }
          })
          setPostTest(true)
        }
      }
    }
    setFinalResults()
  }, [jobResponses])

  const getAllTestData = async () => {
    try {
      const fetchUnits = await files.EmulationLab.get(getAllTestApi, {
        headers: {
          severity: SeverityLevel.Warning,
        },
      })
      setFinalTestObjectState(fetchUnits.data)
    } catch (error) {
      alert("error in fetching data...")
    }
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const getTestHeader = (inputs) => {
    const inputsData = inputs?.map((input) => {
      const unit =
        emulatorCalcConfig[0]?.InputVariables?.find(
          (inputVariable) => inputVariable.ColumnLabel === input.Label
        )?.Units || ""
      return `${roundValues(input.Data[0])}${
        !EMPTY_STATES.includes(unit) ? unit : ""
      }`
    })

    const dataString = inputsData.join(", ")
    const headerString = `Given inputs as, ${dataString}`
    return headerString
  }

  const formatData = (data) => {
    if (!data || !data[0]) {
      return ""
    }

    const numericValue = parseFloat(data[0])

    if (!isNaN(numericValue)) {
      return Number.isInteger(numericValue)
        ? numericValue.toString()
        : numericValue.toFixed(2)
    }

    return data[0]
  }

  const subTestHeader = (output, rule) => {
    const variable = emulatorCalcConfig[0]?.OutputVariables?.find(
      (outputVariable) => outputVariable.ColumnLabel === output.label
    )
    const variableName = variable?.Name
    const variableUnit = !EMPTY_STATES.includes(variable?.Units)
      ? variable?.Units
      : ""
    const variableRule = output?.mode ? output.mode.replace(/_/g, " ") : ""
    const applyRule = getDataBasedOnRule(output, variableUnit, variableRule)

    let parsedData = ""
    let isObject = false

    if (output?.actualData) {
      try {
        parsedData = JSON.parse(output.actualData)
        isObject = typeof parsedData === "object"
      } catch (e) {
        parsedData = ""
        isObject = false
      }
    }
    const variableOutput = output?.actualData
    const isContainsRule = variableRule.includes("contains")
    const action = isContainsRule ? "" : "is"

    const subHeader = `Verify that, ${variableName} ${action} ${variableRule} ${applyRule}`
    const outputText = !isObject
      ? `Actual output- ${formatData(variableOutput)}${variableUnit}`
      : ""
    return rule === "main" ? subHeader : outputText
  }

  const getDataBasedOnRule = (output, unit, rule) => {
    const expectedData =
      output.mode === "between" ? output?.valueMin : output?.value
    const maxValue = output?.valueMax
    const tolerance = output?.tolerance

    switch (rule) {
      case "equal":
        return `to ${expectedData + unit} with tolerance ${tolerance}%`
      case "between":
        return expectedData + "-" + maxValue + unit
      default:
        return expectedData + unit
    }
  }

  const getTestIcon = (subTests) => {
    const statuses = subTests?.map((item) => item.status)
    const failedCount = subTests.filter(
      (item) => item.status === TEST_STATUS.fail
    ).length

    if (statuses.includes(TEST_STATUS.running)) {
      return getIconBasedOnStatus("test", TEST_STATUS.running, false, classes)
    } else if (statuses.includes(TEST_STATUS.calculation_fail)) {
      return getIconBasedOnStatus(
        "test",
        TEST_STATUS.calculation_fail,
        false,
        classes
      )
    } else if (statuses.includes(TEST_STATUS.canceled)) {
      return getIconBasedOnStatus("test", TEST_STATUS.canceled, false, classes)
    } else if (
      statuses.every((status) => status === TEST_STATUS.fail) ||
      (statuses.includes(TEST_STATUS.fail) &&
        statuses.includes(TEST_STATUS.draft) &&
        !statuses.includes(TEST_STATUS.pass))
    ) {
      return getIconBasedOnStatus("test", TEST_STATUS.fail, false, classes)
    } else if (
      statuses.every((status) => status === TEST_STATUS.pass) ||
      (statuses.includes(TEST_STATUS.pass) &&
        statuses.includes(TEST_STATUS.draft) &&
        !statuses.includes(TEST_STATUS.fail))
    ) {
      return getIconBasedOnStatus("test", TEST_STATUS.pass, false, classes)
    } else if (statuses.every((status) => status === TEST_STATUS.draft)) {
      return getIconBasedOnStatus("test", TEST_STATUS.draft, false, classes)
    } else if (
      statuses.includes(TEST_STATUS.pass) &&
      statuses.includes(TEST_STATUS.fail)
    ) {
      return getIconBasedOnStatus(
        "test",
        TEST_STATUS.pass,
        failedCount,
        classes
      )
    } else {
      return null
    }
  }

  const handleOpenTest = () => {
    setIndex(finalTestObjectState?.tests?.length)
    setOpenNewTest(true)
    setAddForm(true)
  }

  const openEditTestForm = (index) => {
    setIndex(index)
    setUpdateTest(true)
    setOpenNewTest(true)
    setAddForm(false)
    setJobData([])
    setJobResponses({})
  }

  const reRunSingleTest = async () => {
    setJobData([])
    setJobResponses({})
    return true
  }

  const runSingleTest = async (i, subTest, inputs) => {
    const postBody = {
      emulator_id: emulatorId,
      FeaturesData: inputs,
    }
    try {
      const data = await callCalculateToRun(postBody)
      const subTestResults = await processSubTest(subTest, false, data)
      setFinalTestObjectState((prevState) => {
        const updatedTests = [...prevState.tests]
        updatedTests[i] = {
          inputs: postBody.FeaturesData,
          subTests: subTestResults,
        }

        return {
          ...prevState,
          tests: updatedTests,
        }
      })
      setPostTest(true)
    } catch (error) {
      console.error("Error in callCalculateToRun:", error)
    }
  }

  const deleteSingleTest = async (index) => {
    let dummyTestObject = { ...finalTestObjectState }
    let dummyTestArray = !EMPTY_STATES.includes(index)
      ? dummyTestObject?.tests
        ? [...dummyTestObject.tests]
        : []
      : []

    if (!EMPTY_STATES.includes(index)) {
      dummyTestArray?.splice(index, 1)
    }

    const updatedFinalTestObject = {
      ...dummyTestObject,
      tests: dummyTestArray,
    }
    setFinalTestObjectState(updatedFinalTestObject)
    postNewTest(getAllTestApi, updatedFinalTestObject)
    setDeleteIndex(null)
    setJobResponses({})
    setJobData([])
  }

  const runAllTest = () => {
    finalTestObjectState?.tests?.forEach((ts, i) => {
      runSingleTest(i, ts.subTests, ts.inputs)
    })
  }

  const openDeleteDialogFunc = (index) => {
    setDeleteIndex(index)
    setOpenDeleteDialog(true)
  }

  const preventProgation = (e, i, prop1, prop2) => {
    e.stopPropagation()
    const saveState =
      prop1 && prop1[0]?.Status === TEST_STATUS.calculation_fail
        ? reRunSingleTest(i)
        : true
    saveState && prop2 ? runSingleTest(i, prop1, prop2) : openEditTestForm(i)
  }

  return (
    <files.CommonerrorPageHandle title="Tests">
      <core.Card className={classes.calcMainCard}>
        <core.Grid className="ml-display-flex ml-flex-dir-col">
          <core.Grid
            item
            className="ml-display-flex ml-flex-dir-row ml-justify-end ml-align-center"
          >
            <core.Box className="ml-display-flex ml-flex-dir-row ml-align-center">
              <core.Grid
                item
                className="ml-display-flex ml-flex-dir-row ml-align-center padding-right-10"
              >
                <files.Authorization processName="manageIO">
                  <CustomButton
                    text={"Add New"}
                    icon={<AddCircleOutlineOutlinedIcon color="primary" />}
                    callingFunction={handleOpenTest}
                  />
                </files.Authorization>
              </core.Grid>
              {finalTestObjectState?.tests?.length > 0 && (
                <files.Authorization processName="manageIO">
                  <core.Grid
                    item
                    className="ml-display-flex ml-flex-dir-row ml-align-center padding-right-10"
                  >
                    <CustomButton
                      text={"Run All"}
                      icon={<PlayCircleOutlineIcon color="primary" />}
                      callingFunction={runAllTest}
                    />
                    <CustomButton
                      text={"Delete All"}
                      icon={<DeleteOutlineOutlinedIcon color="primary" />}
                      callingFunction={() => openDeleteDialogFunc()}
                    />
                  </core.Grid>
                </files.Authorization>
              )}
            </core.Box>
          </core.Grid>
          <core.Grid item>
            {finalTestObjectState?.tests?.length > 0 ? (
              finalTestObjectState?.tests?.map((test, index) => (
                <Accordion
                  key={`panel${index + 1}`}
                  expanded={expanded === `panel${index + 1}`}
                  onChange={handleChange(`panel${index + 1}`)}
                >
                  <AccordionSummary>
                    <core.Grid
                      container
                      className="ml-display-flex ml-space-between ml-align-center"
                    >
                      <core.Grid item xs={9}>
                        <core.Typography
                          variant="body1"
                          className="ml-display-flex ml-align-center"
                        >
                          {getTestIcon(test?.subTests)}
                          {getTestHeader(test?.inputs)}
                        </core.Typography>
                      </core.Grid>
                      <core.Grid
                        item
                        xs={3}
                        className="ml-display-flex calc-toggle-mode ml-align-center ml-justify-end"
                      >
                        <files.Authorization processName="manageIO">
                          <core.IconButton
                            onClick={(e) => preventProgation(e, index)}
                          >
                            <Edit color="primary" />
                          </core.IconButton>
                          <core.IconButton
                            onClick={(e) =>
                              preventProgation(
                                e,
                                index,
                                test.subTests,
                                test.inputs
                              )
                            }
                          >
                            <PlayCircleOutlineIcon color="primary" />
                          </core.IconButton>
                          <core.IconButton
                            onClick={() => openDeleteDialogFunc(index)}
                          >
                            <DeleteOutlineOutlinedIcon color="primary" />
                          </core.IconButton>
                        </files.Authorization>
                      </core.Grid>
                    </core.Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {test.subTests.map((subTest, index) => (
                      <MuiAccordionSummary
                        className="ml-display-flex ml-align-center"
                        key={`subtest-${index}`}
                      >
                        <core.Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <core.Grid
                            item
                            xs={12}
                            md={subTest.actualData ? 8 : 12}
                            style={{ marginLeft: theme.spacing(3) }}
                            className="ml-display-flex ml-align-center"
                          >
                            {getIconBasedOnStatus(
                              "test",
                              subTest.status,
                              false,
                              classes
                            )}
                            <Typography variant="body1">
                              {subTestHeader(subTest, "main")}
                            </Typography>
                          </core.Grid>
                          {subTest.actualData && (
                            <core.Grid
                              item
                              xs={12}
                              md={3}
                              className="ml-display-flex ml-justify-end"
                            >
                              <core.Typography
                                variant="body1"
                                className="ed-small"
                              >
                                {subTestHeader(subTest, "output")}
                              </core.Typography>
                            </core.Grid>
                          )}
                        </core.Grid>
                      </MuiAccordionSummary>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <core.Typography variant="body2">
                No tests added yet.
              </core.Typography>
            )}
          </core.Grid>
        </core.Grid>{" "}
        <br />
        <br />
        {finalTestObjectState?.tests?.length > 0 && (
          <core.Grid>
            <core.Box className="eval-m-1-4-0 ml-p-5">
              <core.Typography variant="body1">Legend:</core.Typography>
              <core.Typography variant="body2" className="p-legend">
                <Stack direction="row" spacing={2} alignItems="center">
                  {Object.keys(TEST_STATUS).map((statusKey) => (
                    <React.Fragment key={statusKey}>
                      {getIconBasedOnStatus(
                        "legend",
                        TEST_STATUS[statusKey],
                        false,
                        classes
                      )}
                      {TEST_LEGEND_DESC[statusKey]}
                    </React.Fragment>
                  ))}
                </Stack>
              </core.Typography>
            </core.Box>
          </core.Grid>
        )}
      </core.Card>
      <files.AddTestForm
        openNewTest={openNewTest}
        setOpenNewTest={setOpenNewTest}
        updateTest={updateTest}
        setUpdateTest={setUpdateTest}
        index={index}
        addForm={addForm}
        setAddForm={setAddForm}
        sendTest={postTest}
        setSendTest={setPostTest}
        postTest={getAllTestApi}
      />
      <core.Dialog
        onKeyDown={(e) => handleKeyDown(e, setOpenDeleteDialog)}
        open={openDeleteDialog}
      >
        <files.AlertBox
          heading={editAlertBox.heading}
          content={editAlertBox.content}
          closeDialog={() => setOpenDeleteDialog(false)}
          callFunction={() =>
            !EMPTY_STATES.includes(deleteIndex)
              ? deleteSingleTest(deleteIndex)
              : deleteSingleTest()
          }
        />
      </core.Dialog>
    </files.CommonerrorPageHandle>
  )
}

export default MainTest
